import axios from 'axios';
import { BASE_URL } from './ip';

export const setGymAppSetting = async (type, formData, token) => {
	try {
		const config = {
			headers: {
				token: token,
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.post(`${BASE_URL}/gym/add-gym-setting?type=${type}`, formData, config);
		return data;
	} catch (error) {
		return error;
	}
};

export const getGymAppSetting = async (type, token) => {
	try {
		const config = {
			headers: {
				token: token,
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.get(`${BASE_URL}/gym/get-gym-setting?type=${type}`, config);
		return data;
	} catch (error) {
		return error;
	}
};
