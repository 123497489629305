import axios from 'axios';
import {
	GYM_REQUEST,
	GYM_SUCCESS,
	GYM_FAIL,
	DELETE_GYM_SUCCESS,
	DELETE_GYM_REQUEST,
	DELETE_GYM_FAIL,
	CREATE_NEW_GYM_REQUEST,
	CREATE_NEW_GYM_SUCCESS,
	CREATE_NEW_GYM_FAIL,
	UPDATE_GYM_REQUEST,
	UPDATE_GYM_RESET,
	UPDATE_GYM_FAIL,
	UPDATE_GYM_SUCCESS,
	CHANGE_STATUS_GYM_REQUEST,
	CHANGE_STATUS_GYM_SUCCESS,
	CHANGE_STATUS_GYM_FAIL,
	GET_ALL_ENABLE_GYM_REQUEST,
	GET_ALL_ENABLE_GYM_SUCCESS,
	GET_ALL_ENABLE_GYM_FAIL,
	GET_MENU_SETTINGS_REQUEST,
	GET_MENU_SETTINGS_SUCCESS,
	GET_MENU_SETTINGS_FAIL,
	CHANGE_MENU_SETTINGS_REQUEST,
	CHANGE_MENU_SETTINGS_SUCCESS,
	CHANGE_MENU_SETTINGS_FAIL
} from '../constants/gymConstants';
import { BASE_URL } from './ip';

export const getGymListAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GYM_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			`${BASE_URL}/gym/get-gym?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}`,
			config
		);

		dispatch({
			type: GYM_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GYM_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteGymAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_GYM_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.delete(`${BASE_URL}/gym/delete-gym?gymID=${id}`, config);

		dispatch({
			type: DELETE_GYM_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_GYM_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const createNewGymAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CREATE_NEW_GYM_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/gym/create-gym`, formData, config);

		dispatch({
			type: CREATE_NEW_GYM_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CREATE_NEW_GYM_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const changeStatusGymAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_STATUS_GYM_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.patch(`${BASE_URL}/gym/enable-disable-gym?userID=${id}`, formData, config);

		dispatch({
			type: CHANGE_STATUS_GYM_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_STATUS_GYM_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getAllEnabledGymListAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_ENABLE_GYM_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/gym/get-enable-gym`, config);

		dispatch({
			type: GET_ALL_ENABLE_GYM_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_ENABLE_GYM_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getMenuSettingsAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_MENU_SETTINGS_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/gym/get-gym-menu-setting-by-token`, config);

		dispatch({
			type: GET_MENU_SETTINGS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_MENU_SETTINGS_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const changeMenuSettingsAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_MENU_SETTINGS_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.patch(
			`${BASE_URL}/gym/change-menu-setting?userID=${id}`,
			{ appMenu: formData },
			config
		);

		dispatch({
			type: CHANGE_MENU_SETTINGS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_MENU_SETTINGS_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
