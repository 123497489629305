import React from 'react';
import SkeletonStyles from './Skeletons.module.css';

const DashboardCard = () => {
	const data = [1, 2, 3, 4];
	return (
		<div className={SkeletonStyles.cardSkeletons}>
			{data &&
				data.map((d) => <div key={d} className={[SkeletonStyles.cardSkeleton, SkeletonStyles.skeleton].join(' ')} />)}
		</div>
	);
};

export default DashboardCard;
