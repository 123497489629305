import React from 'react';
import ReadMore from '../../components/Readmore';
import TemplateDetailsStyles from './TemplateDetails.module.css';

const TemplateRound = ({ data,color }) => {
	const newLineText = (text) => {
		const newText = text.split('\n').map((str) => <p>{str}</p>);
		return newText;
	};

	// const colors = ['#ff6968','##7a54fe','#ff8f61','#2ac3ff','#5a65ff','#96da45']
	// style={{backgroundColor:color}}

	return (
		<div className={TemplateDetailsStyles.roundCard}>
			
			<div className={TemplateDetailsStyles.roundCardExercise}>
			
				<div className={TemplateDetailsStyles.roundCardDetail1}>
				<h4 className={TemplateDetailsStyles.roundCardHeading}>{data.roundName}</h4>
				<div style={{display:'flex',color:'#15181f'}}>
				<span className={TemplateDetailsStyles.roundCardText}>Repeatation: </span>
				 <p className={TemplateDetailsStyles.roundCardInfo}>{data.reps}</p>
				</div>

				{data.note ? (
					<div style={{display:'flex',color:'#15181f'}}>
					<span className={TemplateDetailsStyles.roundCardText}>Rep Type: </span>
					 {/* <div className={TemplateDetailsStyles.roundCardInfo}>{data.note}</div> */}
					</div>
				// <p className={TemplateDetailsStyles.roundCardNote}>{data.note}</p>
			) : (
				<div style={{display:'flex',color:'#15181f'}}>
					<span className={TemplateDetailsStyles.roundCardText}>Rep Type: </span>
					 <ReadMore >{data.repType}</ReadMore>
					</div>
				// <p className={TemplateDetailsStyles.roundCardRepType}>{newLineText(data.repType)}</p>
			)}
			{/* <p className={TemplateDetailsStyles.roundCardNextRound}>{newLineText(data.nextRoundText)}</p> */}
			<div style={{display:'flex',color:'#15181f'}}>
					<span className={TemplateDetailsStyles.roundCardText}>Next Round Text: </span>
					 <ReadMore>{data.nextRoundText}</ReadMore>
					</div>
					
				</div>
				<div className={TemplateDetailsStyles.roundCardDetail2}>
				<p className={TemplateDetailsStyles.roundCardExerciseReps}>{data.exercise ? data.exercise : 'NA'}</p>
					<p className={TemplateDetailsStyles.roundCardExerciseName}>Exercises</p>
					
				</div>
			</div>

		
		</div>
	);
};

export default TemplateRound;
