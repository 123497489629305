import axios from 'axios';
import {
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT,
	CREATE_NEW_USER_REQUEST,
	CREATE_NEW_USER_SUCCESS,
	CREATE_NEW_USER_FAIL,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LIST_FAIL,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAIL,
	CHANGE_STATUS_USER_REQUEST,
	CHANGE_STATUS_USER_SUCCESS,
	CHANGE_STATUS_USER_FAIL,
	GET_USER_BY_TOKEN_REQUEST,
	GET_USER_BY_TOKEN_SUCCESS,
	GET_USER_BY_TOKEN_FAIL,
	CHANGE_PASSWORD_USER_REQUEST,
	CHANGE_PASSWORD_USER_SUCCESS,
	CHANGE_PASSWORD_USER_FAIL,
	CHANGE_PASSWORD_USER_RESET,

	CHANGE_ADMIN_REQUEST,
	CHANGE_ADMIN_SUCCESS,
	CHANGE_ADMIN_FAIL,
	CHANGE_ADMIN_RESET,
	LOGOUT_USER_REQUEST,
	LOGOUT_USER_SUCCESS,
	LOGOUT_USER_FAIL
} from '../constants/userConstants';

import { BASE_URL, AdClinIQAPIUrl } from './ip';

export const login = (email, password) => async (dispatch) => {
	try {
		dispatch({ type: USER_LOGIN_REQUEST });

		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		let webUrl = window.location.host; //'jarfit.the-algo.com';  
		const { data } = await axios.post(`${BASE_URL}/users/login?webUrl=${webUrl}`, { email, password }, config);

		dispatch({
			type: USER_LOGIN_SUCCESS,
			payload: data
		});

		localStorage.setItem('userInfo', JSON.stringify(data));
	} catch (err) {
		dispatch({
			type: USER_LOGIN_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const createNewUserAction = (userData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CREATE_NEW_USER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};


		const { data } = await axios.post(`${BASE_URL}/users/user-signup`, userData, config);

		dispatch({
			type: CREATE_NEW_USER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CREATE_NEW_USER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getUserListAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_LIST_REQUEST });


		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};


		const { data } = await axios.get(
			`${BASE_URL}/users/get-user?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}`,
			config
		);

		dispatch({
			type: USER_LIST_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: USER_LIST_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteUserAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_USER_REQUEST });


		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.delete(`${BASE_URL}/users/delete-user?userID=${id}`, config);

		dispatch({
			type: DELETE_USER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_USER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const activeUserAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_STATUS_USER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};


		const { data } = await axios.patch(`${BASE_URL}/users/enable-disable-user?userID=${id}`, formData, config);

		dispatch({
			type: CHANGE_STATUS_USER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_STATUS_USER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getUserByTokenAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_USER_BY_TOKEN_REQUEST });
		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/users/get-user-by-token`, config);

		dispatch({
			type: GET_USER_BY_TOKEN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_USER_BY_TOKEN_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};



export const changePasswordUserAction = (userData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_PASSWORD_USER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.patch(`${BASE_URL}/users/change-password`, userData, config);

		dispatch({
			type: CHANGE_PASSWORD_USER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_PASSWORD_USER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const changeAdminAction = (id, userData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_ADMIN_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.patch(`${BASE_URL}/users/update-gym-for-superadmin?gymID=${id}`, userData, config);


		localStorage.setItem('userInfo', JSON.stringify(data));

		dispatch({
			type: CHANGE_ADMIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_ADMIN_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const logOutUserAction = (userData) => async (dispatch, getState) => {
	try {
		dispatch({ type: LOGOUT_USER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.patch(`${BASE_URL}/users/logout-user`, userData, config);

		dispatch({
			type: LOGOUT_USER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: LOGOUT_USER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const logout = () => (dispatch) => {
	localStorage.removeItem('userInfo');
	dispatch({ type: USER_LOGOUT });
};


export const getAdsDataAction = (formData) => async (dispatch, getState) => {
	try {

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
		};

		const { data } = await axios.post(`${AdClinIQAPIUrl}/api/v1/advertise/view-request?country=US`, formData, config);

		return data;
	} catch (err) {
		return err.response && err.response.data.msg
			? err.response.data.msg
			: err.message;
	}
};