import React from "react";
import Modal from "../../components/Modal";
import ModalHeading from "../../components/Modal/ModalHeading";
import ModalForm from "../../components/Modal/ModalForm";
import InputsSection from "../../components/Modal/InputsSection";
import InputBox from "../../components/formInputs/InputBox";
import ModalButton from "../../components/Modal/ModalButton";
import SelectBox from "../../components/formInputs/SelectBox";
import { useSelector } from "react-redux";

const CreateResourceModal = ({ data }) => {
  const {
    resource,
    resourceType,
    availabilityFrom,
    availabilityTo,
    frequency,

    setResource,
    setResourceType,
    setAvailabilityFrom,
    setAvailabilityTo,
    setFrequency,

    resourceError,
    resourceTypeError,
    availabilityFromError,
    availabilityToError,
    frequencyError,

    setResourceError,
    setResourceTypeError,
    setAvailabilityFromError,
    setAvailabilityToError,
    setFrequencyError,

    reservationFees,
    reservationFeesError,
    setReservationFees,
    setReservationFeesError,

    showAddModal,
    handleAddModalClose,
    handleSubmit,
    selectedId,
  } = data;

  const enabledResourceTypesList = useSelector(
    (state) => state.enabledResourceTypesList
  );

  return (
    <Modal show={showAddModal}>
      <ModalHeading
        heading={selectedId ? "Update Resource" : "Add New Resource"}
        onClose={handleAddModalClose}
      />
      <ModalForm
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(selectedId);
        }}
        style={{ marginBottom: "2.5rem" }}
      >
        <InputsSection>
          <InputBox
            label="Resource Name"
            icon="stack"
            placeholder="eg, Room A, Main Hall"
            value={resource}
            onChange={(event) => {
              setResource(event.target.value);
              setResourceError("");
            }}
            errorMessage={resourceError}
          />
          <SelectBox
            value={resourceType}
            onChange={(e) => {
              setResourceType(e.target.value);
              setResourceTypeError("");
            }}
            errorMessage={resourceTypeError}
            label="Resource Type"
            icon="stack"
            iconStyles={{
              height: "1.8rem",
              width: "1.8rem",
              marginTop: 4,
              padding: 0,
            }}
            style={{
              marginLeft: "3rem",
            }}
            name="resource-type"
          >
            {selectedId ? (
              <></>
            ) : (
              <option className="optionBox" value={""} disabled>
                Select Resource
              </option>
            )}
            {enabledResourceTypesList &&
              enabledResourceTypesList.userInfo &&
              enabledResourceTypesList.userInfo.data &&
              enabledResourceTypesList.userInfo.data.result &&
              enabledResourceTypesList.userInfo.data.result.map((res) => (
                <option value={res._id} key={res._id}>
                  {res.name}
                </option>
              ))}
          </SelectBox>
        </InputsSection>
        <InputsSection>
          <InputBox
            type="time"
            label="Available From"
            placeholder="eg, 9:00 AM"
            value={availabilityFrom}
            onChange={(event) => {
              setAvailabilityFrom(event.target.value);
              setAvailabilityFromError("");
              setAvailabilityToError("");
              setFrequencyError("");
            }}
            errorMessage={availabilityFromError}
            inputStyles={{ height: 'auto' }}
          />

          <InputBox
            label="Available Till"
            type="time"
            placeholder="eg, 6:00 PM"
            value={availabilityTo}
            onChange={(event) => {
              setAvailabilityTo(event.target.value);
              setAvailabilityToError("");
              setAvailabilityFromError("");
              setFrequencyError("");
            }}
            errorMessage={availabilityToError}
            inputStyles={{ height: 'auto' }}
          />
        </InputsSection>
        <InputsSection>
          <InputBox
            label="Frequency (in mins)"
            icon="clock"
            type="number"
            placeholder="eg, 90"
            value={frequency}
            onChange={(event) => {
              setFrequency(event.target.value);
              setAvailabilityToError("");
              setAvailabilityFromError("");
              setFrequencyError("");
            }}
            errorMessage={frequencyError}
          />

          <InputBox
            label='Reservation Fees (in $)' 
            icon='cash'
            placeholder='eg, $ 10.5'
            value={reservationFees}
            onChange={(e) => {
              if (e.target.value < 0) e.target.value = e.target.value * -1;
              setReservationFees(e.target.value);
              setReservationFeesError('');
            }}
            errorMessage={reservationFeesError}
          />
        </InputsSection>

        <ModalButton
          label={selectedId ? "Update Resource" : "Add New"}
          icon={selectedId ? "edit" : "plus"}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(selectedId);
          }}
        />
      </ModalForm>
    </Modal>
  );
};

export default CreateResourceModal;
