import {
	GET_ALL_TEMPLATE_REQUEST,
	GET_ALL_TEMPLATE_SUCCESS,
	GET_ALL_TEMPLATE_FAIL,
	GET_ALL_TEMPLATE_RESET,
	ADD_TEMPLATE_REQUEST,
	ADD_TEMPLATE_SUCCESS,
	ADD_TEMPLATE_FAIL,
	ADD_TEMPLATE_RESET,
	CHANGE_TEMPLATE_STATUS_REQUEST,
	CHANGE_TEMPLATE_STATUS_SUCCESS,
	CHANGE_TEMPLATE_STATUS_FAIL,
	CHANGE_TEMPLATE_STATUS_RESET,
	DELETE_TEMPLATE_REQUEST,
	DELETE_TEMPLATE_SUCCESS,
	DELETE_TEMPLATE_FAIL,
	DELETE_TEMPLATE_RESET,
	GET_ALL_ENABLE_TEMPLATE_REQUEST,
	GET_ALL_ENABLE_TEMPLATE_SUCCESS,
	GET_ALL_ENABLE_TEMPLATE_FAIL,
	GET_ALL_ENABLE_TEMPLATE_RESET,
	ADD_TEMPLATE_EXERCISE_RESET,
	ADD_TEMPLATE_EXERCISE_FAIL,
	ADD_TEMPLATE_EXERCISE_SUCCESS,
	ADD_TEMPLATE_EXERCISE_REQUEST,
	ASSIGN_TEMPLATE_EXERCISE_REQUEST,
	ASSIGN_TEMPLATE_EXERCISE_SUCCESS,
	ASSIGN_TEMPLATE_EXERCISE_FAIL,
	ASSIGN_TEMPLATE_EXERCISE_RESET,
	GET_TEMPLATE_EXERCISE_MUSCLETYPE_REQUEST,
	GET_TEMPLATE_EXERCISE_MUSCLETYPE_SUCCESS,
	GET_TEMPLATE_EXERCISE_MUSCLETYPE_FAIL,
	GET_TEMPLATE_EXERCISE_MUSCLETYPE_RESET,
	GET_TEMPLATE_MUSCLETYPE_REQUEST,
	GET_TEMPLATE_MUSCLETYPE_SUCCESS,
	GET_TEMPLATE_MUSCLETYPE_RESET,
	GET_TEMPLATE_MUSCLETYPE_FAIL,
	GET_ASSIGN_TEMPLATE_BY_USER_REQUEST,
	GET_ASSIGN_TEMPLATE_BY_USER_SUCCESS,
	GET_ASSIGN_TEMPLATE_BY_USER_FAIL,
	GET_ASSIGN_TEMPLATE_BY_USER_RESET,
	DELETE_EXERCISE_TEMPLATE_REQUEST,
	DELETE_EXERCISE_TEMPLATE_RESET,
	DELETE_EXERCISE_TEMPLATE_FAIL,
	DELETE_EXERCISE_TEMPLATE_SUCCESS,

	GET_ALL_NUTRITIONS_TEMPLATE_REQUEST,
	GET_ALL_NUTRITIONS_TEMPLATE_SUCCESS,
	GET_ALL_NUTRITIONS_TEMPLATE_FAIL,
	GET_ALL_NUTRITIONS_TEMPLATE_RESET,
	ADD_NUTRITIONS_TEMPLATE_REQUEST,
	ADD_NUTRITIONS_TEMPLATE_SUCCESS,
	ADD_NUTRITIONS_TEMPLATE_FAIL,
	ADD_NUTRITIONS_TEMPLATE_RESET,
	CHANGE_NUTRITIONS_TEMPLATE_STATUS_REQUEST,
	CHANGE_NUTRITIONS_TEMPLATE_STATUS_SUCCESS,
	CHANGE_NUTRITIONS_TEMPLATE_STATUS_FAIL,
	CHANGE_NUTRITIONS_TEMPLATE_STATUS_RESET,
	DELETE_NUTRITIONS_TEMPLATE_REQUEST,
	DELETE_NUTRITIONS_TEMPLATE_SUCCESS,
	DELETE_NUTRITIONS_TEMPLATE_FAIL,
	DELETE_NUTRITIONS_TEMPLATE_RESET,
	GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_REQUEST,
	GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_SUCCESS,
	GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_FAIL,
	GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_RESET,
	ADD_ROOM_REQUEST,
	ADD_ROOM_SUCCESS,
	ADD_ROOM_FAIL,
	ADD_ROOM_RESET,
	GET_WORKOUT_REQUEST,
	GET_WORKOUT_SUCCESS,
	GET_WORKOUT_FAIL,
	GET_WORKOUT_RESET,
	GET_ALL_ROOM_RESET,
	GET_ALL_ROOM_FAIL,
	GET_ALL_ROOM_SUCCESS,
	GET_ALL_ROOM_REQUEST,
	CHANGE_ROOM_REQUEST,
	CHANGE_ROOM_SUCCESS,
	CHANGE_ROOM_FAIL,
	CHANGE_ROOM_RESET,
	DELETE_ROOM_REQUEST,
	DELETE_ROOM_SUCCESS,
	DELETE_ROOM_FAIL,
	DELETE_ROOM_RESET,
	DELETE_WORKOUT_REQUEST,
	DELETE_WORKOUT_SUCCESS,
	DELETE_WORKOUT_FAIL,
	DELETE_WORKOUT_RESET,
	ADD_WORKOUT_REQUEST,
	ADD_WORKOUT_SUCCESS,
	ADD_WORKOUT_FAIL,
	ADD_WORKOUT_RESET,
	CHANGE_WORKOUT_REQUEST,
	CHANGE_WORKOUT_SUCCESS,
	CHANGE_WORKOUT_FAIL,
	CHANGE_WORKOUT_RESET,
} from '../constants/templateConstants';

export const getAllTemplateReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_TEMPLATE_REQUEST:
			return { loading: true };

		case GET_ALL_TEMPLATE_SUCCESS:
			return { categories: action.payload, loading: false };

		case GET_ALL_TEMPLATE_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_TEMPLATE_RESET:
			return {};

		default:
			return state;
	}
};

export const createTemplateReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_TEMPLATE_REQUEST:
			return { loading: true };

		case ADD_TEMPLATE_SUCCESS:
			return { category: action.payload, loading: false };

		case ADD_TEMPLATE_FAIL:
			return { loading: false, error: action.payload };

		case ADD_TEMPLATE_RESET:
			return {};

		default:
			return state;
	}
};

export const createRoomReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_ROOM_REQUEST:
			return { loading: true };

		case ADD_ROOM_SUCCESS:
			return { data: action.payload, loading: false };

		case ADD_ROOM_FAIL:
			return { loading: false, error: action.payload };

		case ADD_ROOM_RESET:
			return {};

		default:
			return state;
	}
};

export const createWorkoutReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_WORKOUT_REQUEST:
			return { loading: true };

		case ADD_WORKOUT_SUCCESS:
			return { data: action.payload, loading: false };

		case ADD_WORKOUT_FAIL:
			return { loading: false, error: action.payload };

		case ADD_WORKOUT_RESET:
			return {};

		default:
			return state;
	}
};

export const updateRoomReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_ROOM_REQUEST:
			return { loading: true };

		case CHANGE_ROOM_SUCCESS:
			return { data: action.payload, loading: false };

		case CHANGE_ROOM_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_ROOM_RESET:
			return {};

		default:
			return state;
	}
};

export const updateWorkoutReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_WORKOUT_REQUEST:
			return { loading: true };

		case CHANGE_WORKOUT_SUCCESS:
			return { data: action.payload, loading: false };

		case CHANGE_WORKOUT_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_WORKOUT_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteRoomReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_ROOM_REQUEST:
			return { loading: true };

		case DELETE_ROOM_SUCCESS:
			return { data: action.payload, loading: false };

		case DELETE_ROOM_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_ROOM_RESET:
			return {};

		default:
			return state;
	}
};

export const getAllRoomReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_ROOM_REQUEST:
			return { loading: true };

		case GET_ALL_ROOM_SUCCESS:
			return { data: action.payload.data, loading: false };

		case GET_ALL_ROOM_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_ROOM_RESET:
			return {};

		default:
			return state;
	}
};

export const getAllWorkoutReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_WORKOUT_REQUEST:
			return { loading: true };

		case GET_WORKOUT_SUCCESS:
			return { data: action.payload.data, loading: false };

		case GET_WORKOUT_FAIL:
			return { loading: false, error: action.payload };

		case GET_WORKOUT_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteWorkoutReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_WORKOUT_REQUEST:
			return { loading: true };

		case DELETE_WORKOUT_SUCCESS:
			return { data: action.payload, loading: false };

		case DELETE_WORKOUT_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_WORKOUT_RESET:
			return {};

		default:
			return state;
	}
};

export const templateStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_TEMPLATE_STATUS_REQUEST:
			return { loading: true };

		case CHANGE_TEMPLATE_STATUS_SUCCESS:
			return { category: action.payload, loading: false };

		case CHANGE_TEMPLATE_STATUS_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_TEMPLATE_STATUS_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteTemplateReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_TEMPLATE_REQUEST:
			return { loading: true };

		case DELETE_TEMPLATE_SUCCESS:
			return { category: action.payload, loading: false };

		case DELETE_TEMPLATE_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_TEMPLATE_RESET:
			return {};

		default:
			return state;
	}
};


export const deleteExerciseTemplateReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_EXERCISE_TEMPLATE_REQUEST:
			return { loading: true };

		case DELETE_EXERCISE_TEMPLATE_SUCCESS:
			return { category: action.payload, loading: false };

		case DELETE_EXERCISE_TEMPLATE_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_EXERCISE_TEMPLATE_RESET:
			return {};

		default:
			return state;
	}
};

export const getAllEnableTemplateReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_ENABLE_TEMPLATE_REQUEST:
			return { loading: true };

		case GET_ALL_ENABLE_TEMPLATE_SUCCESS:
			return { categories: action.payload, loading: false };

		case GET_ALL_ENABLE_TEMPLATE_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_ENABLE_TEMPLATE_RESET:
			return {};

		default:
			return state;
	}
};


export const createTemplateExrciseReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_TEMPLATE_EXERCISE_REQUEST:
			return { loading: true };

		case ADD_TEMPLATE_EXERCISE_SUCCESS:
			return { category: action.payload, loading: false };

		case ADD_TEMPLATE_EXERCISE_FAIL:
			return { loading: false, error: action.payload };

		case ADD_TEMPLATE_EXERCISE_RESET:
			return {};

		default:
			return state;
	}
};


export const assignTemplateExrciseReducer = (state = {}, action) => {
	switch (action.type) {
		case ASSIGN_TEMPLATE_EXERCISE_REQUEST:
			return { loading: true };

		case ASSIGN_TEMPLATE_EXERCISE_SUCCESS:
			return { category: action.payload, loading: false };

		case ASSIGN_TEMPLATE_EXERCISE_FAIL:
			return { loading: false, error: action.payload };

		case ASSIGN_TEMPLATE_EXERCISE_RESET:
			return {};

		default:
			return state;
	}
};


export const getTemplateExerciseMuscleTypeReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TEMPLATE_EXERCISE_MUSCLETYPE_REQUEST:
			return { loading: true };

		case GET_TEMPLATE_EXERCISE_MUSCLETYPE_SUCCESS:
			return { categories: action.payload, loading: false };

		case GET_TEMPLATE_EXERCISE_MUSCLETYPE_FAIL:
			return { loading: false, error: action.payload };

		case GET_TEMPLATE_EXERCISE_MUSCLETYPE_RESET:
			return {};

		default:
			return state;
	}
};

export const getTemplateExerciseByUserReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ASSIGN_TEMPLATE_BY_USER_REQUEST:
			return { loading: true };

		case GET_ASSIGN_TEMPLATE_BY_USER_SUCCESS:
			return { categories: action.payload, loading: false };

		case GET_ASSIGN_TEMPLATE_BY_USER_FAIL:
			return { loading: false, error: action.payload };

		case GET_ASSIGN_TEMPLATE_BY_USER_RESET:
			return {};

		default:
			return state;
	}
};



export const getMuscleTypeReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TEMPLATE_MUSCLETYPE_REQUEST:
			return { loading: true };

		case GET_TEMPLATE_MUSCLETYPE_SUCCESS:
			return { categories: action.payload, loading: false };

		case GET_TEMPLATE_MUSCLETYPE_FAIL:
			return { loading: false, error: action.payload };

		case GET_TEMPLATE_MUSCLETYPE_RESET:
			return {};

		default:
			return state;
	}
};


export const getAllNutritionTemplateReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_NUTRITIONS_TEMPLATE_REQUEST:
			return { loading: true };

		case GET_ALL_NUTRITIONS_TEMPLATE_SUCCESS:
			return { categories: action.payload, loading: false };

		case GET_ALL_NUTRITIONS_TEMPLATE_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_NUTRITIONS_TEMPLATE_RESET:
			return {};

		default:
			return state;
	}
};

export const createNutritionTemplateReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_NUTRITIONS_TEMPLATE_REQUEST:
			return { loading: true };

		case ADD_NUTRITIONS_TEMPLATE_SUCCESS:
			return { category: action.payload, loading: false };

		case ADD_NUTRITIONS_TEMPLATE_FAIL:
			return { loading: false, error: action.payload };

		case ADD_NUTRITIONS_TEMPLATE_RESET:
			return {};

		default:
			return state;
	}
};

export const nutritionTemplateStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_NUTRITIONS_TEMPLATE_STATUS_REQUEST:
			return { loading: true };

		case CHANGE_NUTRITIONS_TEMPLATE_STATUS_SUCCESS:
			return { category: action.payload, loading: false };

		case CHANGE_NUTRITIONS_TEMPLATE_STATUS_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_NUTRITIONS_TEMPLATE_STATUS_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteNutritionTemplateReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_NUTRITIONS_TEMPLATE_REQUEST:
			return { loading: true };

		case DELETE_NUTRITIONS_TEMPLATE_SUCCESS:
			return { category: action.payload, loading: false };

		case DELETE_NUTRITIONS_TEMPLATE_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_NUTRITIONS_TEMPLATE_RESET:
			return {};

		default:
			return state;
	}
};

export const getAllEnableNutritionTemplateReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_REQUEST:
			return { loading: true };

		case GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_SUCCESS:
			return { categories: action.payload, loading: false };

		case GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_RESET:
			return {};

		default:
			return state;
	}
};

