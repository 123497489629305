import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReadMore from '../../components/Readmore';
import TemplateDetailsStyles from './WorkoutDetailScreen.module.css';
import TemplateStyles from './WorkoutTemplateScreen.module.css'
import DeleteTemplate from '../WorkoutTemplateScreen/DeleteTemplate';
import { useDispatch } from 'react-redux';
import EditWorkoutModal from './EditWorkoutModal';

const TemplateRound = ({ data, color, props }) => {

	const {
		enableTemplateHandler,
		onDeleteHandler,
		handleUpdate
	} = props;

	const dispatch = useDispatch();
	const newLineText = (text) => {
		const newText = text.split('\n').map((str) => <p>{str}</p>);
		return newText;
	};
	const [modalDeleteState, setModalDeleteState] = useState(false);
	const [modalEditState, setModalEditState] = useState(false);
	const [workoutData, setWorkoutData] = useState(false);
	const [selectedWorkoutId, setSelectedWorkoutId] = useState('');

	useEffect(() => {	
		setWorkoutData(data)
	}, [])

	const onDeleteModalClose = () => {
		setModalDeleteState(false);
	};

	const onEditTemplateHandler = useCallback(() =>{
		setModalEditState(true)
		setSelectedWorkoutId(data._id)
	},[data._id])

	const totalListLengthSum = useMemo(() =>data.rounds.reduce((sum, round) => {
		return sum + round.list.length;
	  }, 0),[data.rounds]);

	return (
		<div className={TemplateDetailsStyles.roundCard}>
			<div className={TemplateDetailsStyles.roundCardTextTitle}>
				<span className={TemplateDetailsStyles.roundCardText}>Workout : </span>
				{/* <p className={TemplateDetailsStyles.roundCardInfo}>{data.reps}</p> */}
				<p className={TemplateDetailsStyles.roundCardInfo}>{data.muscleType}</p>
			</div>
			<div className={TemplateDetailsStyles.roundCardExercise}>

				<div className={TemplateDetailsStyles.roundCardDetail1}>
					<h4 className={TemplateDetailsStyles.roundCardHeading}>{data.roundTitle}</h4>
					<div style={{ display: 'flex', color: '#15181f' }}>
					</div>

					{data.note ? (
						<div style={{ display: 'flex', color: '#15181f' }}>
							<span className={TemplateDetailsStyles.roundCardText}>Rounds : </span>
							{/* <div className={TemplateDetailsStyles.roundCardInfo}>{data.note}</div> */}
						</div>
						// <p className={TemplateDetailsStyles.roundCardNote}>{data.note}</p>
					) : (
						<div style={{ display: 'flex', color: '#15181f', alignItems: 'center' }}>
							<span className={TemplateDetailsStyles.roundCardText}>Rounds : </span>
							{/* <span className={TemplateDetailsStyles.roundCardText1}>{data.rounds.length}</span> */}
						</div>
						// <p className={TemplateDetailsStyles.roundCardRepType}>{newLineText(data.repType)}</p>
					)}
					{/* <p className={TemplateDetailsStyles.roundCardNextRound}>{newLineText(data.nextRoundText)}</p> */}
					<div style={{ display: 'flex', color: '#15181f' }}>
						<span className={TemplateDetailsStyles.roundCardText}>Total Exercises : </span>
					</div>

				</div>
				<div className={TemplateDetailsStyles.roundCardDetail2}>
					{/* <p className={TemplateDetailsStyles.roundCardExerciseReps}>{data.exercise ? data.exercise : 'NA'}</p> */}
					<p className={TemplateDetailsStyles.roundCardExerciseReps}>{totalListLengthSum}</p>
					<p className={TemplateDetailsStyles.roundCardExerciseName}>{totalListLengthSum > 1  ? 'Exercises' : 'Exercise'}</p>

				</div>
			</div>
			<div className={TemplateStyles.templateCardActions}>
				<label className='switch'>
					<input
						checked={data.enable}
						onChange={() => {
							enableTemplateHandler(data._id, data, !data.enable);
						}}
						type='checkbox'
						className='checkbox'
						name='active'
					/>
					<span className='slider round' />
				</label>
				<div className={TemplateStyles.templateCardActionBtn}>
					<button
						className={TemplateStyles.templateCardAction}
						onClick={onEditTemplateHandler}>
						<svg className={TemplateStyles.templateCardActionIcon}>
							<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
						</svg>
					</button>

					<button
						className={TemplateStyles.templateCardAction}
						onClick={() => {
							// setTemplateId(data._id);
							setModalDeleteState(true);
						}}>
						<svg className={TemplateStyles.templateCardActionIcon}>
							<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
						</svg>
					</button>
				</div>
			</div>
			<EditWorkoutModal data={{ modalEditState, selectedWorkoutId, setModalEditState, handleUpdate }} workoutData={workoutData} setWorkoutData={setWorkoutData} />
			<DeleteTemplate data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }} workoutId={data._id}/>
		</div>
	);
};

export default TemplateRound;
