export const phoneMasking = (number) => {
    const num = `${number.substring(0, 3)}${number.substring(3) ? '-' : ''}${number.substring(3, 6)}${number.substring(6) ? '-' : ''}${number.substring(6, number.length)}`;
    return num
};

export const inputPhoneMasking = (e) => {
    let value = e
    value = value.replace(/\D/g, "")
    value = value.replace(/(\d{3})(\d)/, "$1-$2")
    value = value.replace(/(\d{3})(\d)/, "$1-$2")
    value = value.substr(0, 12)

    return value

};

export const unMasking = (e) => {
    let value = e
    value = value.replaceAll("-", "")

    return value
}

export const sortData = (arr) => {
    return arr.sort(function (a, b) { 
        if (a.name < b.name) {
            return -1;
        }
        // if (a.name > b.name) {
        //     return 1;
        // } 
        return 0;
    });
}
