import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import InputsSection from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import ModalHeading from '../../components/Modal/ModalHeading';
import NoData from '../../components/NoData';
import { TRAINER_INSURANCE_RESET } from '../../redux/constants/trainerConstants';
import Styles from './Trainers.module.css'

const TrainerInsurance = ({ modalInsuranceState, setModalInsuranceState }) => {
	const dispatch = useDispatch();

	const trainerInsurance = useSelector((state) => state.trainerInsurance);

	const handleClose = () => {
		setModalInsuranceState(false);
		dispatch({ type: TRAINER_INSURANCE_RESET });
	};

	return (
		<Modal show={modalInsuranceState}>
			<ModalHeading heading='Trainer Insurance Form' onClose={handleClose} />
			<ModalForm>
				<InputsSection style={Styles.trainer_container}>
					{trainerInsurance && trainerInsurance.loading ? (
						<NoData title='Loading!' subTitle='Please wait, we are fetching insurance image' height='30vh' />
					) : trainerInsurance && trainerInsurance.userInfo && trainerInsurance.userInfo.status ? (
						trainerInsurance.userInfo.data && (
							<div className={Styles.insuarance_img}>
								<img src={trainerInsurance.userInfo.data.formURL} alt='img'  />
							</div>
							
						)
					) : (
						<NoData title='No Data Found!' subTitle='Trainer has not uploaded insurance image' height='30vh' />
					)}
				</InputsSection>
			</ModalForm>
		</Modal>
	);
};

export default TrainerInsurance;
