import React, { useState } from 'react';
import './SelectBox.css';
import Select, { components } from 'react-select';

const MultiSelectBox = ({ children, icon, name, label, value, onChange, errorMessage, multiple, inputValue, onInputChange, isLoading = false }) => {

	const colourStyles = {
		menuList: (styles) => ({
			...styles,
			backgroundColor: '#FFF',
			zIndex: 999,
		}),
		option: (styles, { isFocused, isSelected }) => ({
			...styles,
			color: isSelected ? '#000' : '#000',
			backgroundColor: isSelected ? '#FFF' : '#FFF',
			zIndex: 1
		}),
		menu: (base) => ({
			...base,
			zIndex: 999,
		})
	};


	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span>No company found with name</span>
			</components.NoOptionsMessage>
		);
	};
	return (
		<div className="form_input">
			{label && (
				<label htmlFor={label} className="form_input__label">
					{label}
				</label>
			)}
			<div className="textBox">
				{icon && (
					<label className="textBox__label" style={{ top: '0rem' }}>
						<svg className="textBox__label--icon">
							<use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
						</svg>
					</label>
				)}
				<div className='textBox_select_input' >
					<Select
						isLoading={isLoading}
						isMulti={multiple}
						options={children}
						inputValue={inputValue}
						onChange={onChange}
						styles={colourStyles}
						placeholder={label}
						components={{ NoOptionsMessage }}
						className='textBox_select_input--box'
						onInputChange={onInputChange}
					/>
				</div>
			</div>

			{errorMessage && <p className="form_input__error">{errorMessage}</p>}
		</div>
	);
};

export default MultiSelectBox;




