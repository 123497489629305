import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Content from "../../components/Content";
import CreateResourceModal from "./CreateResourceModal";
import {
  changeStatusResourceAction,
  changeStatusResourceTypeAction,
  createNewResourceAction,
  createNewResourceTypeAction,
  deleteResourceAction,
  deleteResourceTypeAction,
  getAllEnabledResourceTypes,
  getAllResource,
  getAllResourceType,
  updateResourceAction,
  updateResourceTypeAction,
} from "../../redux/actions/resourceActions";
import CreateResourceTypeModal from "./CreateResourceTypeModal";
import DeleteResourceModal from "./DeleteResourceModal";
import DeleteResourceTypeModal from "./DeleteResourceTypeModal";
import ResourceData from "./ResourceData";
import { toast } from "react-toastify";
import {
  CHANGE_STATUS_RESOURCE_RESET,
  CHANGE_STATUS_RESOURCE_TYPE_RESET,
  CREATE_NEW_RESOURCE_RESET,
  CREATE_NEW_RESOURCE_TYPE_RESET,
  DELETE_RESOURCE_RESET,
  DELETE_RESOURCE_TYPE_RESET,
  GET_ALL_RESOURCE_RESET,
  GET_ENABLED_RESOURCE_TYPES_RESET,
  UPDATE_RESOURCE_RESET,
  UPDATE_RESOURCE_TYPE_RESET,
} from "../../redux/constants/resourceConstants";
import ResourceTypeData from "./ResourceTypeData";
import Pagination from "../../components/Pagination";
import { validateOnlyNumberAllDecimal } from "../../utils/validators";

const ResourcesScreen = ({ history }) => {
  // redux hooks: Dispatch
  const dispatch = useDispatch();
  // redux hooks: Selectors
  const getUserInfo = useSelector((state) => state.getUserInfo);

  // resource selectors
  const resourceList = useSelector((state) => state.resourceList);
  const createResource = useSelector((state) => state.createResource);
  const updateResource = useSelector((state) => state.updateResource);
  const changeStatusResource = useSelector(
    (state) => state.changeStatusResource
  );
  const deleteResource = useSelector((state) => state.deleteResource);

  // resource type selectors
  const resourceTypeList = useSelector((state) => state.resourceTypeList);
  const createResourceType = useSelector((state) => state.createResourceType);
  const updateResourceType = useSelector((state) => state.updateResourceType);
  const changeStatusResourceType = useSelector(
    (state) => state.changeStatusResourceType
  );
  const deleteResourceType = useSelector((state) => state.deleteResourceType);

  const pageLimit = 16;

  // Modal States
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddResourceTypeModal, setShowAddResourceTypeModal] = useState(
    false
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [
    showDeleteResourceTypeModal,
    setShowDeleteResourceTypeModal,
  ] = useState(false);

  // base required states
  const [search, setSearch] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [resourceTypeSearch, setResourceTypeSearch] = useState("");

  // State Variables
  const [resource, setResource] = useState("");
  const [frequency, setFrequency] = useState(10);
  const [resourceType, setResourceType] = useState("");
  const [availabilityFrom, setAvailabilityFrom] = useState();
  const [availabilityTo, setAvailabilityTo] = useState();
  const [newResourceType, setNewResourceType] = useState("");

  // Error States
  const [newResourceTypeError, setNewResourceTypeError] = useState("");
  const [frequencyError, setFrequencyError] = useState("");
  const [resourceTypeError, setResourceTypeError] = useState("");
  const [availabilityFromError, setAvailabilityFromError] = useState();
  const [availabilityToError, setAvailabilityToError] = useState();
  const [resourceError, setResourceError] = useState("");

  const [reservationFees, setReservationFees] = useState("");
  const [reservationFeesError, setReservationFeesError] = useState("");

  // Page States
  const [totalPageSize, setTotalPageSize] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const clearData = () => {
    setResource("");
    setFrequency("");
    setResourceType("");
    setAvailabilityFrom("");
    setAvailabilityTo("");
    setFrequency("");
    setNewResourceType("");
    setResourceError("");
    setFrequencyError("");
    setResourceTypeError("");
    setAvailabilityFromError("");
    setAvailabilityToError("");
    setFrequencyError("");
    setNewResourceTypeError("");
    setReservationFees("");
    setReservationFeesError("");
  };

  // Events
  // Opens Add/Update Resource
  const handleAddBtnClick = () => {
    clearData();
    if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      (getUserInfo.userInfo.data.role === "gym" ||
        getUserInfo.userInfo.data.role === "gymadmin" ||
        getUserInfo.userInfo.data.role === "superadmin")
    ) {
      setShowAddModal(true);
    } else if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      getUserInfo.userInfo.data.role === "admin"
    ) {
      setShowAddResourceTypeModal(true);
    }
  };

  // Closes Add/Update Resource
  const handleAddModalClose = () => {
    clearData();
    setShowAddModal(false);
    setSelectedId();
  };

  // Opens Add Resource Modal with Resource Data prefilled for Updating the Resource
  const onUpdateResource = (id) => {
    if (!id) {
      return;
    }
    if (
      resourceList &&
      resourceList.userInfo &&
      resourceList.userInfo.status &&
      resourceList.userInfo.data.result &&
      resourceList.userInfo.data.result.length > 0
    ) {
      let data = resourceList.userInfo.data.result.find((d) => d._id === id);
      setResource(data.name);
      setResourceType(data.type._id);
      setAvailabilityFrom(data.availabilityFrom);
      setAvailabilityTo(data.availabilityTo);
      setFrequency(data.frequency);
      setReservationFees(data.reservationFees); 
      setShowAddModal(true);
    }
  };

  // Opens Add Resource Type Modal with Resource Type Data prefilled for Updating the Resource Type
  const onUpdateResourceType = (id) => {
    if (!id) {
      return;
    }
    if (
      resourceTypeList &&
      resourceTypeList.userInfo &&
      resourceTypeList.userInfo.status &&
      resourceTypeList.userInfo.data.result &&
      resourceTypeList.userInfo.data.result.length > 0
    ) {
      let data = resourceTypeList.userInfo.data.result.find(
        (d) => d._id === id
      );
      setNewResourceType(data.name);
      setShowAddResourceTypeModal(true);
    }
  };

  // Makes Backend API call to update the resource status
  const changeResourceStatus = (id) => {
    if (!id) {
      return;
    }
    if (
      resourceList &&
      resourceList.userInfo &&
      resourceList.userInfo.status &&
      resourceList.userInfo.data.result &&
      resourceList.userInfo.data.result.length > 0
    ) {
      let data = resourceList.userInfo.data.result.find((d) => d._id === id);
      dispatch(changeStatusResourceAction(id, { enable: !data.enable }));
    }
  };

  // Makes Backend API call to update the resource type status
  const changeResourceTypeStatus = (id) => {
    if (!id) {
      return;
    }
    if (
      resourceTypeList &&
      resourceTypeList.userInfo &&
      resourceTypeList.userInfo.status &&
      resourceTypeList.userInfo.data.result &&
      resourceTypeList.userInfo.data.result.length > 0
    ) {
      let data = resourceTypeList.userInfo.data.result.find(
        (d) => d._id === id
      );
      dispatch(changeStatusResourceTypeAction(id, { enable: !data.enable }));
    }
  };

  // Makes Backend API call to update the resource list for next page's data
  const handlePageChange = async (currentPage) => {
    const selectedPage = currentPage.selected;
    if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      (getUserInfo.userInfo.data.role === "gym" ||
        getUserInfo.userInfo.data.role === "gymadmin" ||
        getUserInfo.userInfo.data.role === "superadmin")
    ) {
      dispatch(
        getAllResource({
          pageNumber: pageNumber,
          pageSize: pageLimit,
          filter: '',
        })
      );
      if (!search) {
        dispatch(
          getAllEnabledResourceTypes({ pageNumber: 1, pageSize: 100, filter: "" })
        );
      }
    } else if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      getUserInfo.userInfo.data.role === "admin"
    ) {
      dispatch(
        getAllResourceType({ pageNumber: 1, pageSize: 100, filter: "" })
      );
    } else {
      history.push("/");
    }
    setPageNumber(selectedPage + 1);
  };

  // Closes Add/Update Resource Type Modal
  const handleResourceTypeModalClose = () => {
    clearData();
    setShowAddResourceTypeModal(false);
    setSelectedId();
  };

  // Makes Backend API call to update or create a new resource type in the backend
  const handleCreateResourceTypeSubmit = (id) => {
    if (newResourceType === "" && newResourceType.trim() === "") {
      setNewResourceTypeError("Please enter resource type");
    } else {
      if (id) {
        dispatch(updateResourceTypeAction(id, { name: newResourceType }));
      } else {
        dispatch(createNewResourceTypeAction({ name: newResourceType }));
      }
      clearData();
      handleResourceTypeModalClose();
    }
  };

  // Makes Backend API call to update or create a new resource in the backend
  const handleSubmit = (id) => {
    let af = availabilityFrom;
    let at = availabilityTo;

    af = af.split(':');
    at = at.split(':');

    let totalSecondsAf = parseInt(af[0] * 3600 + af[1] * 60);
    let totalSecondsAt = parseInt(at[0] * 3600 + at[1] * 60);
    if (resource === "" && resource.trim() === "") {
      setResourceError("Please enter resource");
    } else if (resourceType === "" && resourceType.trim() === "") {
      setResourceTypeError("Please select resource type");
    } else if ((availabilityFrom === "" && availabilityFrom.trim() === "") || (totalSecondsAf >= totalSecondsAt)) {
      setAvailabilityFromError("Please enter valid time");
    } else if ((availabilityTo === "" && availabilityTo.trim() === "") || (totalSecondsAt <= totalSecondsAf) || (frequency * 60 > (totalSecondsAt - totalSecondsAf))) {
      setAvailabilityToError("Please enter valid time");
    } else if ((frequency === "" && frequency.trim() === "") || frequency < 5 || frequency > 120) {
      setFrequencyError("Please select a valid Frequency between 5 and 120");
    } else if ((frequency === "" && frequency.trim() === "") || frequency < 5 || frequency > 120) {
      setFrequencyError("Please select a valid Frequency between 5 and 120");
    } else if (reservationFees === '') {
      setReservationFeesError('Please enter your reservation fees');
    } else if (!validateOnlyNumberAllDecimal(reservationFees)) {
      setReservationFeesError('Please enter valid reservation fees');
    } else {
      let resourceData = {
        name: resource,
        type: resourceType,
        availabilityFrom,
        availabilityTo,
        frequency,
        reservationFees,
      };

      if (id) {
        dispatch(updateResourceAction(id, resourceData));
      } else {
        dispatch(createNewResourceAction(resourceData));
      }
      clearData();
      handleAddModalClose();
    }
  };

  // handles search input
  const onSearchHandler = (event) => {
    setSearch(event.target.value);
    if (search.trim !== "" && search.length > 0) {
      setPageNumber(1);
    }
  };

  // Closes Delete a Resource Modal
  const onDeleteModalClose = () => {
    setSelectedId();
    setShowDeleteModal(false);
  };

  // Makes a backend API call to delete a resource
  const onDeleteHandler = (id) => {
    if (!id) {
      toast.error("Error");
    }
    dispatch(deleteResourceAction(id));
    onDeleteModalClose();
  };

  // Closes Delete a Resource Type Modal
  const onDeleteResourceTypeModalClose = () => {
    setSelectedId();
    setShowDeleteResourceTypeModal(false);
  };

  // Makes a backend API call to delete a resource type
  const onDeleteResourceTypeHandler = (id) => {
    if (!id) {
      toast.error("Error");
    }
    dispatch(deleteResourceTypeAction(id));
    dispatch(getAllResourceType({ pageNumber: 1, pageSize: 100, filter: "" }));
    dispatch({ type: DELETE_RESOURCE_TYPE_RESET });
    onDeleteResourceTypeModalClose();
  };

  // get data from backend API considering the user role
  useEffect(() => {
    if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      getUserInfo.userInfo.data.appMenu &&
      getUserInfo.userInfo.data.appMenu.resource &&
      (getUserInfo.userInfo.data.role === "gym" ||
        getUserInfo.userInfo.data.role === "gymadmin" ||
        getUserInfo.userInfo.data.role === "superadmin")
    ) {
      dispatch(
        getAllResource({
          pageNumber: pageNumber,
          pageSize: pageLimit,
          filter: search,
        })
      );
      dispatch(
        getAllEnabledResourceTypes({ pageNumber: 1, pageSize: 100, filter: "" })
      );
    } else if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      getUserInfo.userInfo.data.role === "admin"
    ) {
      dispatch(
        getAllResourceType({ pageNumber: pageNumber, pageSize: pageLimit, filter: search })
      );
    } else {
      history.push("/");
    }
    return () => {
      dispatch({ type: GET_ALL_RESOURCE_RESET });
      dispatch({ type: GET_ENABLED_RESOURCE_TYPES_RESET });
    };
  }, [getUserInfo, dispatch, history, pageNumber, search]);

  useEffect(() => {
    if (
      resourceList &&
      resourceList.userInfo &&
      resourceList.userInfo.status &&
      resourceList.userInfo.data &&
      resourceList.userInfo.data.result.length > 0
    ) {
      setTotalPageSize(resourceList.userInfo.data.totalPageSize);
    }
  }, [resourceList]);

  // Create Resource Effects
  useEffect(
    () => {
      if (
        createResource &&
        createResource.userInfo &&
        createResource.userInfo.status
      ) {
        setSearch("");
        clearData();
        setShowAddModal(false);
        dispatch({ type: CREATE_NEW_RESOURCE_RESET });
        toast.success(createResource.userInfo.message);
        dispatch(
          getAllResource({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
      } else if (
        createResource &&
        createResource.userInfo &&
        !createResource.userInfo.status
      ) {
        dispatch({ type: CREATE_NEW_RESOURCE_RESET });
        dispatch(
          getAllResource({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
        toast.error(createResource.userInfo.message);
      }
    },
    // eslint-disable-next-line
    [createResource, dispatch]
  );

  // Create Resource Type Effect
  useEffect(
    () => {
      if (
        createResourceType &&
        createResourceType.userInfo &&
        createResourceType.userInfo.status
      ) {
        setSearch("");
        clearData();
        setShowAddModal(false);
        dispatch({ type: CREATE_NEW_RESOURCE_TYPE_RESET });
        toast.success(createResourceType.userInfo.message);
        dispatch(
          getAllResourceType({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
      } else if (
        createResourceType &&
        createResourceType.userInfo &&
        !createResourceType.userInfo.status
      ) {
        dispatch({ type: CREATE_NEW_RESOURCE_TYPE_RESET });
        dispatch(
          getAllResourceType({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
        toast.error(createResourceType.userInfo.message);
      }
    },
    // eslint-disable-next-line
    [createResourceType, dispatch]
  );

  // Update Resource Effect
  useEffect(
    () => {
      if (
        updateResource &&
        updateResource.userInfo &&
        updateResource.userInfo.status
      ) {
        setSearch("");
        clearData();
        setShowAddModal(false);
        dispatch({ type: UPDATE_RESOURCE_RESET });
        toast.success(updateResource.userInfo.message);
        dispatch(
          getAllResource({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
      } else if (
        updateResource &&
        updateResource.userInfo &&
        !updateResource.userInfo.status
      ) {
        dispatch({ type: UPDATE_RESOURCE_RESET });
        dispatch(
          getAllResource({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
        toast.error(updateResource.userInfo.message);
      }
    },
    // eslint-disable-next-line
    [updateResource, dispatch]
  );

  // Update Resource Type Effect
  useEffect(
    () => {
      if (
        updateResourceType &&
        updateResourceType.userInfo &&
        updateResourceType.userInfo.status
      ) {
        setSearch("");
        clearData();
        setShowAddModal(false);
        dispatch({ type: UPDATE_RESOURCE_TYPE_RESET });
        toast.success(updateResourceType.userInfo.message);
        dispatch(
          getAllResourceType({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
      } else if (
        updateResourceType &&
        updateResourceType.userInfo &&
        !updateResourceType.userInfo.status
      ) {
        dispatch({ type: UPDATE_RESOURCE_TYPE_RESET });
        dispatch(
          getAllResourceType({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
        toast.error(updateResourceType.userInfo.message);
      }
    },
    // eslint-disable-next-line
    [updateResourceType, dispatch]
  );

  // Delete Resource Effect
  useEffect(
    () => {
      if (
        deleteResource &&
        deleteResource.userInfo &&
        deleteResource.userInfo.status
      ) {
        toast.success(deleteResource.userInfo.message);
        dispatch({ type: DELETE_RESOURCE_RESET });
        dispatch(
          getAllResource({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filterValue: search,
          })
        );
      } else if (
        deleteResource &&
        deleteResource.userInfo &&
        !deleteResource.userInfo.status
      ) {
        toast.error(deleteResource.userInfo.message);
        dispatch({ type: DELETE_RESOURCE_RESET });
      }
    },
    // eslint-disable-next-line
    [deleteResource, dispatch, search]
  );

  // Delete Resource Type Effect
  useEffect(
    () => {
      if (
        deleteResourceType &&
        deleteResourceType.userInfo &&
        deleteResourceType.userInfo.data &&
        deleteResourceType.userInfo.data.status
      ) {
        toast.success(deleteResourceType.userInfo.data.message);
        dispatch({ type: DELETE_RESOURCE_TYPE_RESET })
        dispatch(getAllResourceType({ pageNumber: 1, pageSize: pageLimit, filter: "" }));
      } else if (
        deleteResourceType &&
        deleteResourceType.userInfo &&
        deleteResourceType.userInfo.data &&
        !deleteResourceType.userInfo.data.status
      ) {
        toast.error(deleteResourceType.userInfo.data.message);
        dispatch({ type: DELETE_RESOURCE_TYPE_RESET })
        dispatch(getAllResourceType({ pageNumber: 1, pageSize: pageLimit, filter: "" }));
      }
    },
    // eslint-disable-next-line
    [deleteResourceType, dispatch]
  );

  // Resource Status Change Effect
  useEffect(
    () => {
      if (
        changeStatusResource &&
        changeStatusResource.userInfo &&
        changeStatusResource.userInfo.status
      ) {
        toast.success(changeStatusResource.userInfo.message);
        setSelectedId();
        dispatch({ type: CHANGE_STATUS_RESOURCE_RESET });
        dispatch(
          getAllResource({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
      } else if (
        changeStatusResource &&
        changeStatusResource.userInfo &&
        !changeStatusResource.userInfo.status
      ) {
        console.warn(changeStatusResource);
        toast.error(changeStatusResource.userInfo.message);
        dispatch({ type: CHANGE_STATUS_RESOURCE_RESET });
        dispatch(
          getAllResource({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
      }
    },
    // eslint-disable-next-line
    [changeStatusResource, dispatch]
  );

  // Resource Type Status Change Effect
  useEffect(
    () => {
      if (
        changeStatusResourceType &&
        changeStatusResourceType.userInfo &&
        changeStatusResourceType.userInfo.status
      ) {
        toast.success(changeStatusResourceType.userInfo.message);
        setSelectedId();
        dispatch({ type: CHANGE_STATUS_RESOURCE_TYPE_RESET });
        dispatch(
          getAllResourceType({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
      } else if (
        changeStatusResourceType &&
        changeStatusResourceType.userInfo &&
        !changeStatusResourceType.userInfo.status
      ) {
        toast.error(changeStatusResourceType.userInfo.message);
        dispatch({ type: CHANGE_STATUS_RESOURCE_TYPE_RESET });
        dispatch(
          getAllResourceType({
            pageNumber: pageNumber,
            pageSize: pageLimit,
            filter: search,
          })
        );
      }
    },
    // eslint-disable-next-line
    [changeStatusResourceType, dispatch]
  );

  return (
    <Content
      currentMenu="resources"
      headerTitle={getUserInfo &&
        getUserInfo.userInfo &&
        getUserInfo.userInfo.data &&
        (getUserInfo.userInfo.data.role === "gym" ||
          getUserInfo.userInfo.data.role === "gymadmin" ||
          getUserInfo.userInfo.data.role === "superadmin")
        ? "Resource"
        : getUserInfo &&
          getUserInfo.userInfo &&
          getUserInfo.userInfo.data &&
          getUserInfo.userInfo.data.role === "admin"
          ? "Resource Type"
          : ""}
      addBtn={
        getUserInfo &&
          getUserInfo.userInfo &&
          getUserInfo.userInfo.data &&
          (getUserInfo.userInfo.data.role === "gym" ||
            getUserInfo.userInfo.data.role === "gymadmin" ||
            getUserInfo.userInfo.data.role === "superadmin" ||
            getUserInfo.userInfo.data.role === "admin")
          ? true
          : false
      }
      addBtnText={
        getUserInfo &&
          getUserInfo.userInfo &&
          getUserInfo.userInfo.data &&
          (getUserInfo.userInfo.data.role === "gym" ||
            getUserInfo.userInfo.data.role === "gymadmin" ||
            getUserInfo.userInfo.data.role === "superadmin")
          ? "Add Resource"
          : getUserInfo &&
            getUserInfo.userInfo &&
            getUserInfo.userInfo.data &&
            getUserInfo.userInfo.data.role === "admin"
            ? "Resource Type"
            : ""
      }
      addBtnIcon="plus"
      addBtnClick={handleAddBtnClick}
      search={true}
      searchPlaceholder="Search..."
      searchIcon="search"
      searchvalue={search}
      searchOnChange={onSearchHandler}
    >
      {getUserInfo &&
        getUserInfo.userInfo &&
        getUserInfo.userInfo.data &&
        (getUserInfo.userInfo.data.role === "gym" ||
          getUserInfo.userInfo.data.role === "gymadmin" ||
          getUserInfo.userInfo.data.role === "superadmin") && (
          <>
            <ResourceData
              data={{
                resourceList,
                onUpdateResource,
                setShowDeleteModal,
                setSelectedId,
                changeResourceStatus,
                pageNumber,
                pageLimit
              }}
            />
            {totalPageSize > 1 && (
              <div className="tableContainer--paginater">
                <p className="totalCountData">
                  Total:<span>{`${totalPageSize}`} </span>
                </p>
                <Pagination
                  list={resourceList.userInfo}
                  onPageChange={handlePageChange}
                  rowsPerPage={pageLimit}
                  totalPageSize={totalPageSize}
                  pageNumber={pageNumber}
                />
              </div>
            )}
            <CreateResourceModal
              data={{
                resource,
                resourceType,
                availabilityFrom,
                availabilityTo,
                frequency,

                setResource,
                setResourceType,
                setAvailabilityFrom,
                setAvailabilityTo,
                setFrequency,

                resourceError,
                resourceTypeError,
                availabilityFromError,
                availabilityToError,
                frequencyError,

                setResourceError,
                setResourceTypeError,
                setAvailabilityFromError,
                setAvailabilityToError,
                setFrequencyError,

                showAddModal,
                handleAddModalClose,
                handleSubmit,
                selectedId,
                resourceTypeSearch,
                setResourceTypeSearch,
                setSelectedId,


                reservationFees,
                reservationFeesError,
                setReservationFees,
                setReservationFeesError,
              }}
            />
            <DeleteResourceModal
              data={{
                showDeleteModal,
                onDeleteModalClose,
                onDeleteHandler,
                selectedId,
              }}
            />
          </>
        )}

      {getUserInfo &&
        getUserInfo.userInfo &&
        getUserInfo.userInfo.data &&
        getUserInfo.userInfo.data.role === "admin" && (
          <>
            <ResourceTypeData
              data={{
                setShowDeleteResourceTypeModal,
                setSelectedId,
                onUpdateResourceType,
                changeResourceTypeStatus,
              }}
            />
            <CreateResourceTypeModal
              data={{
                newResourceType,
                setNewResourceType,

                newResourceTypeError,
                setNewResourceTypeError,

                showAddResourceTypeModal,
                handleResourceTypeModalClose,
                handleCreateResourceTypeSubmit,
                selectedId,
              }}
            />
            <DeleteResourceTypeModal
              data={{
                showDeleteResourceTypeModal,
                onDeleteResourceTypeModalClose,
                onDeleteResourceTypeHandler,
                selectedId,
              }}
            />
          </>
        )}

    </Content>
  );
};

export default ResourcesScreen;
