import React from "react";
import Modal from "../../components/Modal";
import ModalHeading from "../../components/Modal/ModalHeading";
import ModalForm from "../../components/Modal/ModalForm";
import InputsSection from "../../components/Modal/InputsSection";
import InputBox from "../../components/formInputs/InputBox";
import ModalButton from "../../components/Modal/ModalButton";
import SelectBox from "../../components/formInputs/SelectBox";
import { useSelector } from "react-redux";

const CreateResourceBookingModal = ({ data }) => {
  const {
    selectedDate,
    selectedResource,
    selectedTime,
    selectedTrainer,

    setSelectedDate,
    setSelectedResource,
    setSelectedTime,
    setSelectedTrainer,

    selectedDateError,
    selectedResourceError,
    selectedTimeError,
    selectedTrainerError,

    setSelectedDateError,
    setSelectedResourceError,
    setSelectedTimeError,
    setSelectedTrainerError,

    showAddModal,
    handleAddModalClose,
    handleSubmit,
    selectedId,
  } = data;

  const availableResources = useSelector((state) => state.availableResources);
  const availableTimeForResourceBooking = useSelector(
    (state) => state.availableTimeForResourceBooking
  );
  const enabledTrainers = useSelector((state) => state.enabledTrainers);

  // const getResourceName = () => { 
  //   console.warn('selectedRes', selectedResource);
  //   const resource =
  //     availableResources &&
  //     availableResources.userInfo &&
  //     availableResources.userInfo.status &&
  //     availableResources.userInfo.data.result &&
  //     availableResources.userInfo.data.result.length > 0 ? (
  //     availableResources.userInfo.data.result.find(r => r._id === selectedResource)) : {};
  //   return resource?.name;
  // };

  // const getTrainerName = () => {
  //   console.warn('selectedTraienr', selectedTrainer);
  //   const trainer =
  //     enabledTrainers &&
  //     enabledTrainers.userInfo &&
  //     enabledTrainers.userInfo.status &&
  //     enabledTrainers.userInfo.data.result &&
  //     enabledTrainers.userInfo.data.result.length > 0 ? (
  //     enabledTrainers.userInfo.data.result.find(t => t._id === selectedTrainer)) : {};
  //   return trainer?.name;
  // };

  const getMaxDate = () => {
    let today = new Date();
    today.setDate(today.getDate() + 90);
    let dd = today.getDate();
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const getTodaysDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <Modal show={showAddModal}>
      <ModalHeading
        heading={selectedId ? "Update Resource Booking" : "Book New Resource"}
        onClose={handleAddModalClose}
      />
      <ModalForm
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(selectedId);
        }}
        style={{ marginBottom: "2.5rem" }}
      >
        <InputsSection>
          <InputBox
            label="Select Date"
            icon="calendar"
            placeholder="Date"
            value={selectedDate}
            type="date"
            onChange={(event) => {
              setSelectedDate(event.target.value);
              setSelectedDateError("");
            }}
            errorMessage={selectedDateError}
            maxValue={getMaxDate()}
            minValue={getTodaysDate()}
          />
          <SelectBox
            value={selectedResource.name}
			      onChange={(e) => {
              setSelectedResource(e.target.value);
              setSelectedResourceError("");
            }}
            errorMessage={selectedResourceError}
            label="Resource"
            icon="stack"
            iconStyles={{
              height: "1.8rem",
              width: "1.8rem",
              marginTop: 4,
              padding: 0,
            }}
            style={{
              marginLeft: "3rem",
            }}
            name="resource"
          >
            {selectedId ? <></> : <option className='optionBox' value={selectedResource} disabled selected>
              {selectedId ? selectedResource.name : 'Select Resource'}
            </option>
            }
            {availableResources &&
              availableResources.userInfo &&
              availableResources.userInfo.data &&
              availableResources.userInfo.data.result &&
              availableResources.userInfo.data.result.map((res) => (
                <option value={res._id} key={res._id}>
                  {res.name}
                </option>
              ))}
          </SelectBox>
        </InputsSection>
        <InputsSection>
          <SelectBox
            value={selectedTime}
            onChange={(e) => {
              setSelectedTime(e.target.value);
              setSelectedTimeError("");
            }}
            errorMessage={selectedTimeError}
            label="Time"
            icon="clock"
            iconStyles={{
              height: "1.8rem",
              width: "1.8rem",
              marginTop: 4,
              padding: 0,
            }}
            style={{
              marginLeft: "3rem",
            }}
            name="time"
            >
            {selectedId ? <></> : <option className='optionBox' value={selectedTime} disabled selected>
              {selectedTime || 'Select Time'}
            </option>
            }
            {availableTimeForResourceBooking &&
              availableTimeForResourceBooking.userInfo &&
              availableTimeForResourceBooking.userInfo.data &&
              availableTimeForResourceBooking.userInfo.data.map(
                (time, index) =>
                  !time.isBooked && (
                    <option value={time.time} key={index}>
                      {time.time}
                    </option>
                  )
              )}
          </SelectBox>
          <SelectBox
            value={selectedTrainer}
			      onChange={(e) => {
              setSelectedTrainer(e.target.value);
              setSelectedTrainerError("");
            }}
            errorMessage={selectedTrainerError}
            label="Trainer"
            icon="user"
            iconStyles={{
              height: "1.8rem",
              width: "1.8rem",
              marginTop: 4,
              padding: 0,
            }}
            style={{
              marginLeft: "3rem",
            }}
            name="trainer"
          >
            {selectedId ? <></> : <option className='optionBox' value={selectedId ? selectedTrainer.name : ''} disabled selected>
              {selectedId ? selectedTrainer.name : 'Select Trainer'}
            </option>
            }
            {enabledTrainers &&
              enabledTrainers.trainers &&
              enabledTrainers.trainers.data &&
              enabledTrainers.trainers.data.result &&
              enabledTrainers.trainers.data.result.map((t) => (
                <option value={t._id} key={t._id}>
                  {t.name}
                </option>
              ))}
          </SelectBox>
        </InputsSection>
        <ModalButton
          label={selectedId ? "Update Booking" : "Add New"}
          icon={selectedId ? "edit" : "plus"}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(selectedId);
          }}
        />
      </ModalForm>
    </Modal>
  );
};

export default CreateResourceBookingModal;
