import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import BookingsData from './BookingsData';
import Content from '../../components/Content';
import {
  createResourceBookingAction,
  deleteResourceBookingAction,
  getAllResourceBookings,
  getAvailableResourcesAction,
  getAvailableTimeForResourceBookingAction,
  updateBookedResourceAction
} from '../../redux/actions/resourceActions';
import { CANCEL_RESOURCE_BOOKING_RESET, CREATE_RESOURCE_BOOKING_RESET, GET_ALL_RESOURCE_BOOKINGS_RESET, GET_AVAILABLE_RESOURCE_RESET, GET_TIME_RESOURCE_RESET, UPDATE_BOOKED_RESOURCE_RESET } from '../../redux/constants/resourceConstants';
import CancelResourceBookingModal from './CancelResourceBookingModal';
import Pagination from '../../components/Pagination';
import CreateResourceBookingModal from './CreateResourceBookingModal';
import { addMinutes, convertDateTimeToDDSlashMMSlashYYYY, convertDateTimeToYYYYDashMMDashDD } from '../../utils/date';
import { getEnabledTrainerAction } from '../../redux/actions/trainerActions';

const ResourceBookingsScreen = ({ history }) => {
  const dispatch = useDispatch();
  // redux hooks: Selectors
  const getUserInfo = useSelector((state) => state.getUserInfo);
  const deleteResourceBooking = useSelector((state) => state.deleteResourceBooking)
  const resourceBookingList = useSelector((state) => state.resourceBookingList)
  const createResourceBooking = useSelector(state => state.createResourceBooking);
  const updateBookedResource = useSelector(state => state.updateBookedResource);
  const availableResources = useSelector((state) => state.availableResources);

  const [selectedId, setSelectedId] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedResource, setSelectedResource] = useState('')
  const [selectedTime, setSelectedTime] = useState('')
  const [selectedTrainer, setSelectedTrainer] = useState('')

  const [selectedDateError, setSelectedDateError] = useState('')
  const [selectedResourceError, setSelectedResourceError] = useState('')
  const [selectedTimeError, setSelectedTimeError] = useState('')
  const [selectedTrainerError, setSelectedTrainerError] = useState('')

  const [showAddModal, setShowAddModal] = useState(false)

  const [showCancelBookingModal, setShowCancelBookingModal] = useState(false)

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(0);

  const pageLimit = 16;

  const getResourceFrequency = (id) => {
    const resourceFrequency =
      availableResources &&
        availableResources.userInfo &&
        availableResources.userInfo.status &&
        availableResources.userInfo.data.result &&
        availableResources.userInfo.data.result.length > 0 ? (
        availableResources.userInfo.data.result.find(r => r._id === id)) : {};
    return resourceFrequency.frequency;
  };

  const handleAddModalClose = () => {
    clearData();
    setSelectedId('');
    setShowAddModal(false);
  };

  const handleSubmit = (id) => {
    if ((selectedDate === "" && selectedDate.trim() === "") || !selectedDate) {
      setSelectedDateError("Please enter valid date");
    } else if ((selectedResource === "" && selectedResource.trim() === "") || !selectedResource) {
      setSelectedResourceError("Please select a valid resource");
    } else if ((selectedTime === "" && selectedTime.trim() === "") || !selectedTime) {
      setSelectedTimeError("Please select valid time");
    } else if ((selectedTrainer === "" && selectedTrainer.trim() === "") || !selectedTrainer) {
      setSelectedTrainerError("Please select valid trainer");
    } else {

      if (id) {
        dispatch(updateBookedResourceAction(id, {
          bookingDateString: convertDateTimeToDDSlashMMSlashYYYY(new Date(selectedDate)),
          bookingDate: new Date(selectedDate).getTime(),
          resource: selectedResource,
          trainer: selectedTrainer,
          fromTime: selectedTime,
          toTime: addMinutes(selectedTime, getResourceFrequency(selectedResource)),
        }));
      } else {
        dispatch(
          createResourceBookingAction({
            bookingDateString: convertDateTimeToDDSlashMMSlashYYYY(new Date(selectedDate)),
            bookingDate: new Date(selectedDate).getTime(),
            resource: selectedResource,
            trainer: selectedTrainer,
            fromTime: selectedTime,
            toTime: addMinutes(selectedTime, getResourceFrequency(selectedResource)),
          }),
        );
      }
    }
  };

  const clearData = () => {
    setSelectedDate('');
    setSelectedResource('');
    setSelectedTime('');
    setSelectedTrainer('');
    setSelectedDateError('');
    setSelectedResourceError('');
    setSelectedTimeError('');
    setSelectedTrainerError('')
  };

  const handleAddBtnClick = () => {
    setShowAddModal(true)
  }

  const onCancelModalClose = () => {
    setSelectedId('')
    setShowCancelBookingModal(false)
  }

  const onCancelHandler = (id) => {
    if (!id) {
      return;
    }
    dispatch(deleteResourceBookingAction(id));
    onCancelModalClose();
    dispatch({ type: CANCEL_RESOURCE_BOOKING_RESET })
    dispatch(
      getAllResourceBookings({
        pageNumber: pageNumber,
        pageSize: pageLimit,
      })
    );
  }

  const handlePageChange = async (currentPage) => {
    const selectedPage = currentPage.selected;
    setPageNumber(selectedPage + 1);
  };

  const openUpdateBookedResourceModal = (id) => {
    if (!id) {
      return;
    }
    if (
      resourceBookingList &&
      resourceBookingList.userInfo &&
      resourceBookingList.userInfo.status &&
      resourceBookingList.userInfo.data.result &&
      resourceBookingList.userInfo.data.result.length > 0
    ) {
      let data = resourceBookingList.userInfo.data.result.find((d) => d._id === id);
      setSelectedDate(convertDateTimeToYYYYDashMMDashDD(new Date(data.bookingDate)));
      setSelectedResource(data.resource._id);
      setSelectedTrainer(data.trainer._id);
      setSelectedTime(data.fromTime);
      setShowAddModal(true);
    }
  };

  useEffect(() => {
    if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      getUserInfo.userInfo.data.appMenu &&
      getUserInfo.userInfo.data.appMenu.resource &&
      (getUserInfo.userInfo.data.role === "gym" ||
        getUserInfo.userInfo.data.role === "gymadmin" ||
        getUserInfo.userInfo.data.role === "superadmin")
    ) {
      dispatch(
        getAllResourceBookings({
          pageNumber: pageNumber,
          pageSize: pageLimit,
        })
      );
    }
    else {
      history.push("/");
    }
    return () => {
      dispatch({ type: GET_ALL_RESOURCE_BOOKINGS_RESET });
    };
  }, [getUserInfo, dispatch, history, pageNumber]);

  useEffect(
    () => {
      if (
        deleteResourceBooking &&
        deleteResourceBooking.userInfo &&
        deleteResourceBooking.userInfo.status
      ) {
        toast.success(deleteResourceBooking.userInfo.message);
        dispatch(
          getAllResourceBookings({
            pageNumber: pageNumber,
            pageSize: pageLimit,
          })
        );
      } else if (
        deleteResourceBooking &&
        deleteResourceBooking.userInfo &&
        !deleteResourceBooking.userInfo.status
      ) {
        toast.error(deleteResourceBooking.userInfo.message);
        dispatch(
          getAllResourceBookings({
            pageNumber: pageNumber,
            pageSize: pageLimit,
          })
        );
      }
    },

    // eslint-disable-next-line
    [deleteResourceBooking, dispatch]);

  useEffect(() => {
    if (
      resourceBookingList &&
      resourceBookingList.userInfo &&
      resourceBookingList.userInfo.status &&
      resourceBookingList.userInfo.data &&
      resourceBookingList.userInfo.data.result.length > 0
    ) {
      setTotalPageSize(resourceBookingList.userInfo.data.totalPageSize);
    }
  }, [resourceBookingList]);

  // To get Resources
  useEffect(() => {
    if (selectedDate) {
      dispatch(getAvailableResourcesAction())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDate])

  // To get Timings
  useEffect(() => {
    if (selectedResource) {
      setSelectedTime()
      dispatch(getAvailableTimeForResourceBookingAction({
        resource: selectedResource,
        bookingDate: convertDateTimeToDDSlashMMSlashYYYY(new Date(selectedDate))
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDate, selectedResource])

  // To get Trainers
  useEffect(() => {
    dispatch(getEnabledTrainerAction());
  }, [dispatch])

  useEffect(
    () => {
      if (
        createResourceBooking &&
        createResourceBooking.userInfo &&
        createResourceBooking.userInfo.status
      ) {
        toast.success(createResourceBooking.userInfo.message);
        dispatch({ type: CREATE_RESOURCE_BOOKING_RESET });
        clearData();
        handleAddModalClose();
        dispatch(
          getAllResourceBookings({
            pageNumber: pageNumber,
            pageSize: pageLimit
          })
        );
      } else if (
        createResourceBooking &&
        createResourceBooking.userInfo &&
        !createResourceBooking.userInfo.status
      ) {
        toast.error(createResourceBooking.userInfo.message);
        dispatch({ type: CREATE_RESOURCE_BOOKING_RESET });
      }
    },
    // eslint-disable-next-line
    [createResourceBooking, dispatch]);

  useEffect(
    () => {
      if (
        updateBookedResource &&
        updateBookedResource.userInfo &&
        updateBookedResource.userInfo.status
      ) {
        toast.success(updateBookedResource.userInfo.message);
        dispatch({ type: UPDATE_BOOKED_RESOURCE_RESET });
        clearData();
        handleAddModalClose();
        dispatch(
          getAllResourceBookings({
            pageNumber: pageNumber,
            pageSize: pageLimit,
          })
        );
      } else if (
        updateBookedResource &&
        updateBookedResource.userInfo &&
        !updateBookedResource.userInfo.status
      ) {
        toast.error(updateBookedResource.userInfo.message);
        dispatch({ type: UPDATE_BOOKED_RESOURCE_RESET });
      }
    },
    // eslint-disable-next-line
    [updateBookedResource, dispatch]);

  useEffect(() => {
    if (selectedId && selectedResource._id) {
      dispatch(getAvailableResourcesAction())
      dispatch(getAvailableTimeForResourceBookingAction({
        resource: selectedResource._id,
        bookingDate: convertDateTimeToDDSlashMMSlashYYYY(new Date(selectedDate))
      }))
    } else {
      dispatch({ type: GET_AVAILABLE_RESOURCE_RESET })
      dispatch({ type: GET_TIME_RESOURCE_RESET })
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [dispatch, selectedId])

  return (
    <Content
      currentMenu="resources"
      headerTitle="Resources Bookings"
      addBtn={
        getUserInfo &&
          getUserInfo.userInfo &&
          getUserInfo.userInfo.data &&
          (getUserInfo.userInfo.data.role === "gym" ||
            getUserInfo.userInfo.data.role === "gymadmin" ||
            getUserInfo.userInfo.data.role === "superadmin")
          ? true
          : false
      }
      addBtnText={
        getUserInfo &&
          getUserInfo.userInfo &&
          getUserInfo.userInfo.data &&
          (getUserInfo.userInfo.data.role === "gym" ||
            getUserInfo.userInfo.data.role === "gymadmin" ||
            getUserInfo.userInfo.data.role === "superadmin")
          ? "Book Resource"
          : ""
      }
      addBtnIcon="plus"
      addBtnClick={handleAddBtnClick}
    >
      <CreateResourceBookingModal
        data={{
          selectedDate,
          selectedResource,
          selectedTime,
          selectedTrainer,

          setSelectedDate,
          setSelectedResource,
          setSelectedTime,
          setSelectedTrainer,

          selectedDateError,
          selectedResourceError,
          selectedTimeError,
          selectedTrainerError,

          setSelectedDateError,
          setSelectedResourceError,
          setSelectedTimeError,
          setSelectedTrainerError,

          showAddModal,
          handleAddModalClose,
          handleSubmit,
          selectedId,
        }}
      />
      <BookingsData
        data={{
          setSelectedId,
          openUpdateBookedResourceModal,
          setShowCancelBookingModal,
          pageNumber,
          pageLimit
        }} />
      <CancelResourceBookingModal data={{ showCancelModal: showCancelBookingModal, onCancelModalClose, onCancelHandler, selectedId }} />
      {totalPageSize > 1 && (
        <div className="tableContainer--paginater">
          <p className="totalCountData">
            Total:<span>{`${totalPageSize}`} </span>
          </p>
          <Pagination
            list={resourceBookingList.userInfo}
            onPageChange={handlePageChange}
            rowsPerPage={pageLimit}
            totalPageSize={totalPageSize}
            pageNumber={pageNumber}
          />
        </div>
      )}
    </Content>
  );
};

export default ResourceBookingsScreen;