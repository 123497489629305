import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import { createTemplateAction, deleteTemplateAction, getAllTemplateAction, TemplateStatusAction } from '../../redux/actions/templateActions';
import {
	ADD_TEMPLATE_RESET,
	CHANGE_TEMPLATE_STATUS_RESET,
	DELETE_TEMPLATE_RESET,
	GET_ALL_TEMPLATE_RESET
} from '../../redux/constants/templateConstants';
import AddNewTemplateModal from './AddNewTemplateModal';
import TemplateData from './TemplateData';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import TemplateStyles from './TemplatesScreen.module.css';
import DeleteTemplate from './DeleteTemplate';
import Spinner from '../../components/Spinner/Spinner';
// import Pagination from '../../components/Pagination';
import NoData from '../../components/NoData';

const TemplatesScreen = () => {
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.userLogin);
	const userRole = useSelector((state) => state.getUserInfo);

	const userList = useSelector((state) => state.userList);
	const templatesData = useSelector((state) => state.getAllTemplate);
	const createTemplate = useSelector((state) => state.createTemplate);
	const deleteTemplate = useSelector((state) => state.deleteTemplate);
	const templateStatus = useSelector((state) => state.templateStatus);

	const [templateName, setTemplateName] = useState({ value: '', error: '' });
	const [templateColor, setTemplateColor] = useState({ value: '', error: '' });
	const [addModalShow, setAddModalShow] = useState(false);
	const [modalDeleteState, setModalDeleteState] = useState(false);
	const [templateId, setTemplateId] = useState('');

	const [search, setSearch] = useState('');
	const [totalPageSize, setTotalPageSize] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const pageLimit = 15;
	console.log(templatesData, 'templatesData');


	useEffect(
		() => {
			dispatch(getAllTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
		},
		[dispatch, pageNumber]
	);

	useEffect(
		() => {
			console.log(createTemplate, 'createTemplate');
			if (createTemplate && createTemplate.category && createTemplate.category.status) {
				clearData();
				setAddModalShow(false);
				setSearch('');
				toast.success(createTemplate.category.message);
				dispatch({ type: ADD_TEMPLATE_RESET });
				dispatch(getAllTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (createTemplate && createTemplate.category && !createTemplate.category.status) {
				dispatch({ type: ADD_TEMPLATE_RESET });
				toast.error(createTemplate.category.message);
			}
		},
		[createTemplate, pageNumber, pageLimit, dispatch]
	);

	useEffect(
		() => {
			if (deleteTemplate && deleteTemplate.category && deleteTemplate.category.status) {
				toast.success(deleteTemplate.category.message);
				dispatch({ type: DELETE_TEMPLATE_RESET });
				dispatch(getAllTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (deleteTemplate && deleteTemplate.category && !deleteTemplate.category.status) {
				toast.error(deleteTemplate.category.message);
				dispatch({ type: DELETE_TEMPLATE_RESET });
				dispatch(getAllTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		[deleteTemplate, pageNumber, pageLimit, dispatch]
	);

	useEffect(
		() => {
			if (templateStatus && templateStatus.category && templateStatus.category.status) {
				toast.success(templateStatus.category.message);
				dispatch({ type: CHANGE_TEMPLATE_STATUS_RESET });
				dispatch(getAllTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (templateStatus && templateStatus.category && !templateStatus.category.status) {
				toast.error(templateStatus.category.message);
				dispatch({ type: CHANGE_TEMPLATE_STATUS_RESET });
				dispatch(getAllTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		[templateStatus, pageNumber, pageLimit, dispatch]
	);


	const handleSubmit = (e) => {
		e.preventDefault();
		if (templateName.value === '' || templateName.value.trim() === '') {
			setTemplateName({ ...templateName, error: 'Please enter template name' });
		} else if (templateColor.value === '' || templateColor.value.trim() === '') {
			setTemplateColor({ ...templateColor, error: 'Please enter template color' });
		} else {
			if (templateId) {
				dispatch(createTemplateAction({ _id: templateId, templateName: templateName.value, templateColor: templateColor.value }));
			} else {
				dispatch(createTemplateAction({ templateName: templateName.value, templateColor: templateColor.value }));
			}
		}
	};

	const handleAddModalClose = () => {
		clearData();
		setAddModalShow(false);
	};

	const clearData = () => {
		setTemplateId('');
		setTemplateName({ value: '', error: '' });
		setTemplateColor({ value: '', error: '' });
	};


	const enableTemplateHandler = (id, enableStatus) => {
		dispatch(TemplateStatusAction(id, enableStatus));
	};

	const onDeleteModalClose = () => {
		clearData();
		setModalDeleteState(false);
	};

	const onDeleteHandler = () => {
		dispatch(deleteTemplateAction(templateId));
		onDeleteModalClose();
	};


	const onEditTemplateHandler = (data) => {
		console.log(data, 'onEditTemplateHandler');
		setTemplateId(data._id);
		setTemplateName({ value: data.templateName, error: '' });
		setTemplateColor({ value: data.templateColor, error: '' });
		setAddModalShow(true);
	};


	const handlePageChange = async (currentPage) => {
		const selectedPage = currentPage.selected;
		dispatch(getAllTemplateAction({ pageNumber: selectedPage + 1, pageSize: pageLimit, filter: '' }));
		setPageNumber(selectedPage + 1);
	};

	return (
		<Content
			currentMenu='templates'
			headerTitle='Exercise'
			addBtn={true}
			addBtnText='New Exercise'
			addBtnClick={() => setAddModalShow(true)}
			addBtnIcon='plus'>

			<div className={TemplateStyles.templates}>
				{templatesData && templatesData.loading ? (
					<Spinner />
				) :
					templatesData &&
						templatesData.categories &&
						templatesData.categories.data.length > 0 ? (
						templatesData.categories.data.map((data, index) =>
							<TemplateData key={index + 1} props={{ data, setTemplateId, onEditTemplateHandler, setModalDeleteState, enableTemplateHandler }} />
						)
					) : (
						<div className='not_data_found'>
							<NoData title='No Data Found!' subTitle='We could not find any template. please add one.' height='40vh' />
						</div>
					)}
				{/* 
				{totalPageSize > 1 && (
					<div className='tableContainer--paginater'>
						<Pagination
							list={templatesData.categories}
							onPageChange={handlePageChange}
							rowsPerPage={pageLimit}
							totalPageSize={totalPageSize}
							pageNumber={pageNumber}
						/>
					</div>
				)} */}
			</div>

			<AddNewTemplateModal
				addModalActive={addModalShow}
				handleSubmit={handleSubmit}
				handleAddModalClose={handleAddModalClose}
				data={{ templateId, templateName, setTemplateName, templateColor, setTemplateColor }}
			/>

			<DeleteTemplate data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }} />

		</Content>
	);
};

export default TemplatesScreen;
