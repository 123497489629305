import axios from 'axios';
import {
	TRAINER_REQUEST,
	TRAINER_SUCCESS,
	TRAINER_FAIL,
	DELETE_TRAINER_SUCCESS,
	DELETE_TRAINER_REQUEST,
	DELETE_TRAINER_FAIL,
	CREATE_NEW_TRAINER_REQUEST,
	CREATE_NEW_TRAINER_SUCCESS,
	CREATE_NEW_TRAINER_FAIL,
	CHANGE_STATUS_TRAINER_REQUEST,
	CHANGE_STATUS_TRAINER_SUCCESS,
	CHANGE_STATUS_TRAINER_FAIL,
	TRAINER_INSURANCE_REQUEST,
	TRAINER_INSURANCE_SUCCESS,
	TRAINER_INSURANCE_FAIL,
	GET_ENABLED_TRAINER_REQUEST,
	GET_ENABLED_TRAINER_SUCCESS,
	GET_ENABLED_TRAINER_FAIL
} from '../constants/trainerConstants';
import { BASE_URL } from './ip';

export const getTrainerListAction = (formData, special = false) => async (dispatch, getState) => {
	try {
		dispatch({ type: TRAINER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		let uri;

		if (special) {
			uri = `${BASE_URL}/trainer/get-enable-trainer?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}&sortBy=${formData.sortBy}&sortByValue=${formData.sortByValue}`;
		} else {
			uri = `${BASE_URL}/trainer/get-trainer?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}&sortBy=${formData.sortBy}&sortByValue=${formData.sortByValue}`;
		}

		const { data } = await axios.get(uri, config);

		dispatch({
			type: TRAINER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: TRAINER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getEnabledTrainerAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ENABLED_TRAINER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				token: userInfo.data.token
			}
		};
		const { data } = await axios.get(`${BASE_URL}/trainer/get-enable-trainer`, config);

		dispatch({
			type: GET_ENABLED_TRAINER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ENABLED_TRAINER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const deleteTrainerAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_TRAINER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const data = await axios.delete(`${BASE_URL}/trainer/delete-trainer?trainerId=${id}`, config);

		dispatch({
			type: DELETE_TRAINER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_TRAINER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const createNewTrainerAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CREATE_NEW_TRAINER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/trainer/create-trainer`, formData, config);

		dispatch({
			type: CREATE_NEW_TRAINER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CREATE_NEW_TRAINER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const changeStatusTrainerAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_STATUS_TRAINER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.patch(`${BASE_URL}/trainer/enable-disable-trainer?userID=${id}`, formData, config);

		dispatch({
			type: CHANGE_STATUS_TRAINER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_STATUS_TRAINER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const trainerInsuranceAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: TRAINER_INSURANCE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/form/get-insurance-by-id?id=${id}`, config);

		dispatch({
			type: TRAINER_INSURANCE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: TRAINER_INSURANCE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
