import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Content from '../../components/Content';
import Spinner from '../../components/Spinner/Spinner';
import {
	changeStatusTrainerAction,
	createNewTrainerAction,
	deleteTrainerAction,
	getTrainerListAction
} from '../../redux/actions/trainerActions';
import { validateEmail, validatePhone } from '../../utils/validators';
import { getAllEnableCompanyAction, getAllEnableCompanyBycharAction } from '../../redux/actions/companyActions';
import { changePasswordUserAction, logOutUserAction } from '../../redux/actions/userActions';
import { CHANGE_PASSWORD_USER_RESET, LOGOUT_USER_RESET } from '../../redux/constants/userConstants';
import {
	TRAINER_RESET,
	CHANGE_STATUS_TRAINER_RESET,
	CREATE_NEW_TRAINER_RESET,
	DELETE_TRAINER_RESET
} from '../../redux/constants/trainerConstants';
import CreateTrainer from './CreateTrainer';
import DeleteTrainer from './DeleteTrainer';
import TrainerData from './TrainerData';
import ChangePwdUser from '../UsersScreen/ChangePwdUser';
import FormScreen2 from '../DeclarationForms/FormScreen2';
import IAFormScreenJARFIT from '../DeclarationForms/IAFormScreenJARFIT';
import {
	CREATE_NEW_INDEPENT_AGREEMENT_RESET,
	GET_NEW_INDEPENT_AGREEMENT_RESET
} from '../../redux/constants/formConstants';
import {
	createIndepentAgreementByTrainerAction,
	getFormByIndependentAgreementAction
} from '../../redux/actions/formActions';
import Pagination from '../../components/Pagination';
import { GET_ALL_ENABLE_COMPANY_RESET } from '../../redux/constants/companyConstants';
import TrainerInsurance from './TrainerInsurance';
import { inputPhoneMasking, unMasking } from '../../utils/phonemask';
import moment from 'moment';
import LogOutUser from '../../components/Modal/LogOutUser';

const TrainersScreen = ({ showFilter = false, history }) => {
	const dispatch = useDispatch();
	const getUserInfo = useSelector((state) => state.getUserInfo);
	const trainerList = useSelector((state) => state.trainerList);
	// const companyList = useSelector((state) => state.enableComanyList);
	const companyList = useSelector((state) => state.enableComanyBycharList);

	const createTrainer = useSelector((state) => state.createTrainer);
	const changeStatusTrainer = useSelector((state) => state.changeStatusTrainer);
	const deleteTrainer = useSelector((state) => state.deleteTrainer);
	const changePasswordUser = useSelector((state) => state.changePasswordUser);
	const createFormByIndependentAgreement = useSelector((state) => state.createFormByIndependentAgreement);
	const getFormByIndependentAgreement = useSelector((state) => state.getFormByIndependentAgreement);
	const logOutUser = useSelector((state) => state.logOutUser);

	const [uploadFileData, setUploadFileData] = useState('');
	const [uploadFileDataError, setUploadFileDataError] = useState('');

	const [showAddModal, setShowAddModal] = useState(false);
	const [modalDeleteState, setModalDeleteState] = useState(false);
	const [modalDoumentState, setModalDoumentState] = useState(false);
	const [modalInsuranceState, setModalInsuranceState] = useState(false);
	const [modalChangePasswordState, setModalChangePasswordState] = useState(false);
	const [modalLogOutUserState, setModalLogOutUserState] = useState(false);


	const [selectUpdateModel, setSelectUpdateModel] = useState({});
	const [totalPageSize, setTotalPageSize] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const pageLimit = 16;

	const [id, setUserId] = useState('');
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [address, setAddress] = useState('');
	const [addressError, setAddressError] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');

	const [gender, setGender] = useState('');
	const [genderError, setGenderError] = useState('');
	const [age, setAge] = useState('');
	const [ageError, setAgeError] = useState('');
	const [dob, setDOB] = useState('');
	const [dobError, setDOBError] = useState('');

	const [imageSrc, setImageSrc] = useState('');
	const [imageSrcError, setImageSrcError] = useState('');
	const [search, setSearch] = useState('');

	const [company, setCompany] = useState('');
	const [companyError, setCompanyError] = useState('');

	const [trainerSignature, setTrainerSignature] = useState('');

	const [gymSignature, setGymSignature] = useState('');
	const [gymSignatureError, setGymSignatureError] = useState('');

	const [gymSignatureSrc, setGymSignatureSrc] = useState('');

	const [trainerSignatureDate, setTrainerSignatureDate] = useState('');
	const [gymSignatureDate, setGymSignatureDate] = useState('');
	const [gymSignatureDateError, setGymSignatureDateError] = useState('');
	const [userDataList, setUserDataList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [sortTrainersBy, setSortTrainersBy] = useState('');
	const [sortTrainersByValue, setSortTrainersByValue] = useState(0);
	
	useEffect(
		() => {
			if (
				getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				(getUserInfo.userInfo.data.role === 'gym' ||
					getUserInfo.userInfo.data.role === 'gymadmin' ||
					getUserInfo.userInfo.data.role === 'admin' ||
					getUserInfo.userInfo.data.role === 'superadmin')
			) {
				dispatch(getTrainerListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '', sortBy: sortTrainersBy, sortByValue: sortTrainersByValue }));
				dispatch(getAllEnableCompanyAction({ pageNumber: 1, pageSize: 100, filter: '' }));

				return () => {
					dispatch({ type: TRAINER_RESET });
					dispatch({ type: GET_ALL_ENABLE_COMPANY_RESET });
				};
			} else {
				history.push('/');
			}
		},
		[getUserInfo, dispatch, history, pageNumber, sortTrainersBy, sortTrainersByValue]
	);

	useEffect(
		() => {
			if (
				trainerList &&
				trainerList.userInfo &&
				trainerList.userInfo.status &&
				trainerList.userInfo.data &&
				trainerList.userInfo.data.result.length > 0
			) {
				setTotalPageSize(trainerList.userInfo.data.totalPageSize);
			}
		},
		[trainerList]
	);

	useEffect(
		() => {
			if (createTrainer && createTrainer.userInfo && createTrainer.userInfo.status) {
				setSearch('');
				clearData();
				setShowAddModal(false);
				dispatch({ type: CREATE_NEW_TRAINER_RESET });
				toast.success(createTrainer.userInfo.message);
				dispatch(getTrainerListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (createTrainer && createTrainer.userInfo && !createTrainer.userInfo.status) {
				dispatch({ type: CREATE_NEW_TRAINER_RESET });
				toast.error(createTrainer.userInfo.message);
			}
		},
		// eslint-disable-next-line
		[createTrainer, dispatch]
	);

	useEffect(
		() => {
			if (deleteTrainer && deleteTrainer.userInfo && deleteTrainer.userInfo.status) {
				toast.success(deleteTrainer.userInfo.message);
				dispatch({ type: DELETE_TRAINER_RESET });
				dispatch(getTrainerListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (deleteTrainer && deleteTrainer.userInfo && !deleteTrainer.userInfo.status) {
				toast.error(deleteTrainer.userInfo.message);
				dispatch({ type: DELETE_TRAINER_RESET });
				dispatch(getTrainerListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		// eslint-disable-next-line
		[deleteTrainer, dispatch]
	);

	useEffect(
		() => {
			if (changeStatusTrainer && changeStatusTrainer.userInfo && changeStatusTrainer.userInfo.status) {
				toast.success(changeStatusTrainer.userInfo.message);
				setSelectUpdateModel({});
				dispatch({ type: CHANGE_STATUS_TRAINER_RESET });
				dispatch(getTrainerListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (changeStatusTrainer && changeStatusTrainer.userInfo && !changeStatusTrainer.userInfo.status) {
				toast.error(changeStatusTrainer.userInfo.message);
				dispatch({ type: CHANGE_STATUS_TRAINER_RESET });
				dispatch(getTrainerListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		// eslint-disable-next-line
		[changeStatusTrainer, dispatch]
	);

	useEffect(
		() => {
			if (changePasswordUser && changePasswordUser.userInfo && changePasswordUser.userInfo.status) {
				toast.success(changePasswordUser.userInfo.message);
				setSelectUpdateModel({});
				dispatch({ type: CHANGE_PASSWORD_USER_RESET });
			} else if (changePasswordUser && changePasswordUser.userInfo && !changePasswordUser.userInfo.status) {
				toast.error(changePasswordUser.userInfo.message);
				dispatch({ type: CHANGE_PASSWORD_USER_RESET });
			}
		},
		// eslint-disable-next-line
		[changePasswordUser, dispatch]
	);


	useEffect(
		() => {
			if (logOutUser && logOutUser.userInfo && logOutUser.userInfo.status) {
				toast.success(logOutUser.userInfo.message);
				setSelectUpdateModel({});
				dispatch({ type: LOGOUT_USER_RESET });
			} else if (logOutUser && logOutUser.userInfo && !logOutUser.userInfo.status) {
				toast.error(logOutUser.userInfo.message);
				dispatch({ type: LOGOUT_USER_RESET });
			}
		},
		// eslint-disable-next-line
		[logOutUser, dispatch]
	);


	useEffect(
		() => {
			if (
				createFormByIndependentAgreement &&
				createFormByIndependentAgreement.userInfo &&
				createFormByIndependentAgreement.userInfo.status
			) {
				clearData();
				dispatch({ type: CREATE_NEW_INDEPENT_AGREEMENT_RESET });
				toast.success(createFormByIndependentAgreement.userInfo.message);
			} else if (
				createFormByIndependentAgreement &&
				createFormByIndependentAgreement.userInfo &&
				!createFormByIndependentAgreement.userInfo.status
			) {
				dispatch({ type: CREATE_NEW_INDEPENT_AGREEMENT_RESET });
				toast.error(createFormByIndependentAgreement.userInfo.message);
			}
		},
		// eslint-disable-next-line
		[createFormByIndependentAgreement, dispatch]
	);

	useEffect(
		() => {
			dispatch(getFormByIndependentAgreementAction(selectUpdateModel._id));

			return () => {
				dispatch({ type: GET_NEW_INDEPENT_AGREEMENT_RESET });
			};
		},
		[selectUpdateModel, dispatch]
	);

	useEffect(
		() => {
			if (
				getFormByIndependentAgreement &&
				getFormByIndependentAgreement.userInfo &&
				getFormByIndependentAgreement.userInfo.data
			) {
				setGymSignatureSrc(getFormByIndependentAgreement.userInfo.data.gymSignature);
				setGymSignatureDate(moment(getFormByIndependentAgreement.userInfo.data.gymSignatureDate).format('YYYY-MM-DD'));
				setTrainerSignature(getFormByIndependentAgreement.userInfo.data.trainerSignature);
				setTrainerSignatureDate(moment(getFormByIndependentAgreement.userInfo.data.trainerSignatureDate).format('YYYY-MM-DD'));
			}
		},
		[getFormByIndependentAgreement, dispatch]
	);

	useEffect(
		() => {
			if (
				companyList &&
				companyList.categories &&
				companyList.categories.data &&
				companyList.categories.data.result &&
				companyList.categories.data.result.length > 0
			) {
				let tmparr = [];
				setIsLoading(false);
				let arrComp = Array.from(new Set(companyList.categories.data.result))
				arrComp.map((item) => {
					tmparr.push({
						label: item,
						value: item
					})
				});
				setUserDataList(tmparr);
			} else if (
				companyList &&
				companyList.categories &&
				companyList.categories.data &&
				!companyList.categories.data.status
			) {
				setIsLoading(false);
			}
		},
		[companyList]
	);

	const handleAddModalClose = () => {
		clearData();
		setShowAddModal(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (name === '' && name.trim() === '') {
			setNameError('Please enter name');
		}
		else if (email === '' && email.trim() === '') {
			setEmailError('Please enter email address');
		} else if (!validateEmail(email)) {
			setEmailError('Please enter a valid email address');
		} else if (phone === '' && phone.trim() === '') {
			setPhoneError('Please enter phone');
		} else if (!validatePhone(unMasking(phone))) {
			setPhoneError('Phone number must be 10 digits');
		} else if (address === '' && address.trim() === '') {
			setAddressError('Please enter address');
		} else if (id === '' && password === '') {
			setPasswordError('Please enter a valid password');
		} else if (id === '' && password.length < 7) {
			setPasswordError('Password must be at least 8 characters');
		} else if (gender === '' && gender.trim() === '') {
			setGenderError('Please select gender.');
		} else if (dob === '' && dob === '') {
			setDOBError('Please enter date of birth');
		} else if (age === '' && age.trim() === '') {
			setDOBError('Please enter age.');
		} else if (age < 18) {
			setDOBError('Age must be greater than 18.');
		}
		// else if (!imageSrc) {
		// 	setUploadFileDataError('Please select files to upload');
		// }
		else if (company === '' && company.trim() === '') {
			setCompanyError('Please select company');
		} else {
			let fileData = new FormData();
			if (id !== '') {
				fileData.append('id', id);
			} else {
				fileData.append('password', password);
			}

			fileData.append('name', name);
			fileData.append('email', email);
			fileData.append('address', address);
			fileData.append('phone', unMasking(phone));
			fileData.append('company', company);
			fileData.append('dob', dob);
			fileData.append('age', age);
			fileData.append('gender', gender);
			for (let i = 0; i < uploadFileData.length; i++) {
				fileData.append('image', uploadFileData[i]);
			}

			dispatch(createNewTrainerAction(fileData));
		}
	};

	const onDeleteModalClose = () => {
		setSelectUpdateModel({});
		setModalDeleteState(false);
	};

	const onDeleteHandler = (d) => {
		dispatch(deleteTrainerAction(selectUpdateModel._id));
		dispatch(getTrainerListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
		onDeleteModalClose();
	};

	const handlePageChange = async (currentPage) => {
		const selectedPage = currentPage.selected;
		dispatch(getTrainerListAction({ pageNumber: selectedPage + 1, pageSize: pageLimit, filter: '', sortBy: sortTrainersBy, sortByValue: sortTrainersByValue }));				
		// dispatch(getTrainerListAction({ pageNumber: selectedPage + 1, pageSize: pageLimit, filter: '' }));
		setPageNumber(selectedPage + 1);
	};

	const onChangeHandler = (d) => {
		dispatch(
			changeStatusTrainerAction(d._id, {
				enable: d.active ? true : false
			})
		);
	};

	const clearData = () => {
		setSelectUpdateModel({});
		setGymSignatureSrc('');
		setGymSignatureDate('');
		setUserId('');
		setEmail('');
		setPassword('');
		setPhone('');
		setAddress('');
		setCompany('');
		setName('');
		setEmailError('');
		setPasswordError('');
		setPhoneError('');
		setAddressError('');
		setNameError('');
		setImageSrc('');
		setImageSrcError('');
		setCompanyError('');
		setAge('');
		setAgeError('');
		setDOB('');
		setDOBError('');
		setGender('');
		setGenderError('');
		setGymSignatureError('');
		if (gymSignature) {
			gymSignature.clear();
		}
	};

	const handleEditModalTrainer = (d) => {
		setUserId(d._id);
		setEmail(d.email);
		setPhone(inputPhoneMasking(d.phone));
		setName(d.name);
		setAddress(d.address);
		setImageSrc(d.photo);
		setGender(d.gender);
		setAge(d.age);
		setDOB(d.dob);
		if (d.company) {
			setCompany(d.company.name);
		} else {
			setCompany(null);
		}
		setShowAddModal(true);
	};

	const onChangePwdUserHandler = () => {
		let d = selectUpdateModel;
		let type = 'byAdmin';
		dispatch(changePasswordUserAction({ id: d._id, type, email: d.email }));
		onChangePwdModalClose();
	};

	const onChangePwdModalClose = () => {
		setModalChangePasswordState(false);
	};

	const onFormModalClose = () => {
		setModalDoumentState(false);
	};

	const onLogOutUserClose = () => {
		setModalLogOutUserState(false);
	};

	const onLogOutUserHandler = () => {
		let d = selectUpdateModel;
		let type = 'byAdmin';
		dispatch(logOutUserAction({ id: d._id, type, email: d.email }));
		onLogOutUserClose();
	};

	const onFormHandler = (e) => {
		e.preventDefault();
		if (gymSignature.isEmpty()) {
			setGymSignatureError('Gym signature is invalid');
		} else if (gymSignatureDate === '' && gymSignatureDate.trim() === '') {
			setGymSignatureError('');
			setGymSignatureDateError('Please select signature date');
		} else if (selectUpdateModel) {
			let base64 = '';
			if (!gymSignatureSrc) {
				base64 = gymSignature.getTrimmedCanvas().toDataURL('image/png');
			} else {
				base64 = gymSignatureSrc;
			}
			dispatch(
				createIndepentAgreementByTrainerAction({
					gymSignature: base64,
					trainer: selectUpdateModel._id,
					gymSignatureDate
				})
			);
			onFormModalClose();
		}
	};

	const onSearchHandler = (event) => {
		setSearch(event.target.value);
		if (search.trim !== '' && search.length > 0) {
			setPageNumber(1);
		}
		dispatch(getTrainerListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: event.target.value }));
	};


	const onInputChangeHandler = (event) => {
		console.log(event, 'event 00');
		setUserDataList([]);
		setIsLoading(true);
		dispatch(getAllEnableCompanyBycharAction(event));
	};

	return (
		<Content
			currentMenu='trainers'
			headerTitle='Trainers'
			addBtn={
				getUserInfo &&
					getUserInfo.userInfo &&
					getUserInfo.userInfo.data &&
					(getUserInfo.userInfo.data.role === 'gym' ||
						getUserInfo.userInfo.data.role === 'gymadmin' ||
						getUserInfo.userInfo.data.role === 'superadmin') ? (
					true
				) : (
					false
				)
			}
			addBtnText='Add New Trainer'
			addBtnIcon='plus'
			addBtnClick={() => setShowAddModal(true)}
			search={true}
			searchPlaceholder='Search Trainers...'
			searchIcon='search'
			searchvalue={search}
			searchOnChange={onSearchHandler}>
			<div>
				<TrainerData
					trainerList={trainerList}
					showFilter={showFilter}
					setShowAddModal={setShowAddModal}
					setModalDeleteState={setModalDeleteState}
					setSelectUpdateModel={setSelectUpdateModel}
					handleEditModalTrainer={handleEditModalTrainer}
					onChangeHandler={onChangeHandler}
					setModalChangePasswordState={setModalChangePasswordState}
					setModalDoumentState={setModalDoumentState}
					setModalInsuranceState={setModalInsuranceState}
					setModalLogOutUserState={setModalLogOutUserState}
					setSortTrainersBy={setSortTrainersBy}
					setSortTrainersByValue={setSortTrainersByValue}
					sortTrainersBy={sortTrainersBy}
					sortTrainersByValue={sortTrainersByValue}
				/>
				{totalPageSize > 1 && (
					<div className='tableContainer--paginater'>
						<p className='totalCountData'>
							Total:<span>{`${totalPageSize}`}  </span>
						</p>
						<Pagination
							list={trainerList.userInfo}
							onPageChange={handlePageChange}
							rowsPerPage={pageLimit}
							totalPageSize={totalPageSize}
							pageNumber={pageNumber}
						/>
					</div>
				)}
			</div>

			<CreateTrainer
				data={{
					name,
					email,
					password,
					address,
					phone,

					setUserId,
					setEmail,
					setPhone,
					setName,
					setAddress,
					setPassword,

					emailError,
					phoneError,
					nameError,
					passwordError,
					addressError,

					setEmailError,
					setPhoneError,
					setNameError,
					setAddressError,
					setPasswordError,

					gender,
					setGender,
					setGenderError,
					genderError,
					dob,
					dobError,
					setDOB,
					setDOBError,
					age,
					setAge,
					setAgeError,
					ageError,

					companyList,
					company,
					setCompany,
					companyError,
					setCompanyError,

					userDataList,
					setUserDataList,

					showAddModal,
					selectUpdateModel,
					handleAddModalClose,
					handleSubmit,
					isLoading,
					onInputChangeHandler,

					imageSrc,
					setImageSrc,
					imageSrcError,
					setImageSrcError,
					setUploadFileData,
					uploadFileDataError,
					setUploadFileDataError
				}}
			/>
			<DeleteTrainer data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }} />
			<ChangePwdUser data={{ modalChangePasswordState, onChangePwdModalClose, onChangePwdUserHandler }} />
			<LogOutUser data={{ modalLogOutUserState, onLogOutUserClose, onLogOutUserHandler }} />

			{
				getUserInfo &&
					getUserInfo.userInfo &&
					getUserInfo.userInfo.data &&
					getUserInfo.userInfo.data.packageName === 'io.thealgorithm.jarfit' ? (
					<IAFormScreenJARFIT
						data={{
							gymSignature,
							gymSignatureDate,
							gymSignatureDateError,
							gymSignatureSrc,
							setGymSignatureSrc,
							setGymSignature,
							setGymSignatureDate,
							setGymSignatureDateError,

							gymSignatureError,
							setGymSignatureError,

							trainerSignature,
							trainerSignatureDate,

							createFormByIndependentAgreement,
							selectUpdateModel,
							modalDoumentState,
							onFormModalClose,

							onFormHandler
						}}
					/>) : (
					<FormScreen2
						data={{
							gymSignature,
							gymSignatureDate,
							gymSignatureDateError,
							gymSignatureSrc,
							setGymSignatureSrc,
							setGymSignature,
							setGymSignatureDate,
							setGymSignatureDateError,

							gymSignatureError,
							setGymSignatureError,

							trainerSignature,
							trainerSignatureDate,

							createFormByIndependentAgreement,
							selectUpdateModel,
							modalDoumentState,
							onFormModalClose,

							onFormHandler
						}}
					/>
				)}

			<TrainerInsurance modalInsuranceState={modalInsuranceState} setModalInsuranceState={setModalInsuranceState} />
		</Content>
	);
};

export default TrainersScreen;
