import axios from 'axios';
import {
	CANCEL_RESOURCE_BOOKING_FAIL,
	CANCEL_RESOURCE_BOOKING_REQUEST,
	CANCEL_RESOURCE_BOOKING_SUCCESS,
	CHANGE_STATUS_RESOURCE_FAIL,
	CHANGE_STATUS_RESOURCE_REQUEST,
	CHANGE_STATUS_RESOURCE_SUCCESS,
	CHANGE_STATUS_RESOURCE_TYPE_FAIL,
	CHANGE_STATUS_RESOURCE_TYPE_REQUEST,
	CHANGE_STATUS_RESOURCE_TYPE_SUCCESS,
	CREATE_NEW_RESOURCE_FAIL,
	CREATE_NEW_RESOURCE_REQUEST,
	CREATE_NEW_RESOURCE_SUCCESS,
	CREATE_NEW_RESOURCE_TYPE_FAIL,
	CREATE_NEW_RESOURCE_TYPE_REQUEST,
	CREATE_NEW_RESOURCE_TYPE_SUCCESS,
	CREATE_RESOURCE_BOOKING_FAIL,
	CREATE_RESOURCE_BOOKING_REQUEST,
	CREATE_RESOURCE_BOOKING_SUCCESS,
	DELETE_RESOURCE_FAIL,
	DELETE_RESOURCE_REQUEST,
	DELETE_RESOURCE_SUCCESS,
	DELETE_RESOURCE_TYPE_FAIL,
	DELETE_RESOURCE_TYPE_REQUEST,
	DELETE_RESOURCE_TYPE_SUCCESS,
	GET_ALL_RESOURCE_BOOKINGS_FAIL,
	GET_ALL_RESOURCE_BOOKINGS_REQUEST,
	GET_ALL_RESOURCE_BOOKINGS_SUCCESS,
	GET_ALL_RESOURCE_FAIL,
	GET_ALL_RESOURCE_REQUEST,
	GET_ALL_RESOURCE_SUCCESS,
	GET_ALL_RESOURCE_TYPE_FAIL,
	GET_ALL_RESOURCE_TYPE_REQUEST,
	GET_ALL_RESOURCE_TYPE_SUCCESS,
	GET_AVAILABLE_RESOURCE_FAIL,
	GET_AVAILABLE_RESOURCE_REQUEST,
	GET_AVAILABLE_RESOURCE_SUCCESS,
	GET_ENABLED_RESOURCE_TYPES_FAIL,
	GET_ENABLED_RESOURCE_TYPES_REQUEST,
	GET_ENABLED_RESOURCE_TYPES_SUCCESS,
	GET_TIME_RESOURCE_FAIL,
	GET_TIME_RESOURCE_REQUEST,
	GET_TIME_RESOURCE_SUCCESS,
	UPDATE_BOOKED_RESOURCE_FAIL,
	UPDATE_BOOKED_RESOURCE_REQUEST,
	UPDATE_BOOKED_RESOURCE_SUCCESS,
	UPDATE_RESOURCE_FAIL,
	UPDATE_RESOURCE_REQUEST,
	UPDATE_RESOURCE_SUCCESS,
	UPDATE_RESOURCE_TYPE_FAIL,
	UPDATE_RESOURCE_TYPE_REQUEST,
	UPDATE_RESOURCE_TYPE_SUCCESS
} from '../constants/resourceConstants';
import { BASE_URL } from './ip';

export const createNewResourceAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CREATE_NEW_RESOURCE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/resources`, formData, config);

		dispatch({
			type: CREATE_NEW_RESOURCE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CREATE_NEW_RESOURCE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const updateResourceAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: UPDATE_RESOURCE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.put(`${BASE_URL}/resources?id=${id}`, formData, config);

		dispatch({
			type: UPDATE_RESOURCE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: UPDATE_RESOURCE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const updateResourceTypeAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: UPDATE_RESOURCE_TYPE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.put(`${BASE_URL}/resources-type?id=${id}`, formData, config);

		dispatch({
			type: UPDATE_RESOURCE_TYPE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: UPDATE_RESOURCE_TYPE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const createNewResourceTypeAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CREATE_NEW_RESOURCE_TYPE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/resources-type`, formData, config);

		dispatch({
			type: CREATE_NEW_RESOURCE_TYPE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CREATE_NEW_RESOURCE_TYPE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getAllEnabledResourceTypes = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ENABLED_RESOURCE_TYPES_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/resources-type/enabled-resources-type`, config);

		dispatch({
			type: GET_ENABLED_RESOURCE_TYPES_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ENABLED_RESOURCE_TYPES_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getAllResource = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_RESOURCE_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/resources?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}`, config);

		dispatch({
			type: GET_ALL_RESOURCE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_RESOURCE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getAllResourceBookings = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_RESOURCE_BOOKINGS_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/book-resources?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}`, config);

		dispatch({
			type: GET_ALL_RESOURCE_BOOKINGS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_RESOURCE_BOOKINGS_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getAllResourceType = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_RESOURCE_TYPE_REQUEST });

		const { userLogin: { userInfo } } = getState();
		console.warn(userInfo);
		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/resources-type?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filter=${formData.filter}`, config);

		dispatch({
			type: GET_ALL_RESOURCE_TYPE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_RESOURCE_TYPE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const changeStatusResourceTypeAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_STATUS_RESOURCE_TYPE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.patch(`${BASE_URL}/resources-type/change-status-resources-type?id=${id}`, formData, config);

		dispatch({
			type: CHANGE_STATUS_RESOURCE_TYPE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_STATUS_RESOURCE_TYPE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const changeStatusResourceAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_STATUS_RESOURCE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};
		console.warn('formData', formData);
		const { data } = await axios.patch(`${BASE_URL}/resources/change-status-resources?id=${id}`, formData, config);

		dispatch({
			type: CHANGE_STATUS_RESOURCE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_STATUS_RESOURCE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteResourceAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_RESOURCE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const data = await axios.delete(`${BASE_URL}/resources?id=${id}`, config);

		dispatch({
			type: DELETE_RESOURCE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_RESOURCE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteResourceBookingAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: CANCEL_RESOURCE_BOOKING_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const data = await axios.delete(`${BASE_URL}/book-resources?id=${id}`, config);

		dispatch({
			type: CANCEL_RESOURCE_BOOKING_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CANCEL_RESOURCE_BOOKING_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteResourceTypeAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_RESOURCE_TYPE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const data = await axios.delete(`${BASE_URL}/resources-type?id=${id}`, config);

		dispatch({
			type: DELETE_RESOURCE_TYPE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_RESOURCE_TYPE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getAvailableResourcesAction = () => async (dispatch, getState) => {
	try {
	  dispatch({type: GET_AVAILABLE_RESOURCE_REQUEST});
  
	  const { userLogin: { userInfo } } = getState();
  
	  const config = {
		headers: {
		  'Content-Type': 'application/json',
		  token: userInfo.data.token,
		},
	  };
	  const {data} = await axios.get(
		`${BASE_URL}/resources/enabled-resources?pageNumber=1&pageSize=100`,
		config,
	  );
	  dispatch({
		type: GET_AVAILABLE_RESOURCE_SUCCESS,
		payload: data,
	  });
	} catch (err) {
	  dispatch({
		type: GET_AVAILABLE_RESOURCE_FAIL,
		payload:
		  err.response && err.response.data.message
			? err.response.data.message
			: err.message,
	  });
	}
  };
  
  export const getAvailableTimeForResourceBookingAction =
	formData => async (dispatch, getState) => {
	  try {
		dispatch({type: GET_TIME_RESOURCE_REQUEST});
  
		const {
		  userLogin: {userInfo},
		} = getState();
  
		const config = {
		  headers: {
			'Content-Type': 'application/json',
			token: userInfo.data.token,
		  },
		};
		const {data} = await axios.get(
		  `${BASE_URL}/book-resources/get-time?resource=${formData.resource}&bookingDate=${formData.bookingDate}`,
		  config,
		);
		dispatch({
		  type: GET_TIME_RESOURCE_SUCCESS,
		  payload: data,
		});
	  } catch (err) {
		dispatch({
		  type: GET_TIME_RESOURCE_FAIL,
		  payload:
			err.response && err.response.data.message
			  ? err.response.data.message
			  : err.message,
		});
	  }
	};
  
  export const createResourceBookingAction =
	formData => async (dispatch, getState) => {
	  try {
		dispatch({type: CREATE_RESOURCE_BOOKING_REQUEST});
  
		const {
		  userLogin: {userInfo},
		} = getState();
  
		const config = {
		  headers: {
			'Content-Type': 'application/json',
			token: userInfo.data.token,
		  },
		};
		const {data} = await axios.post(
		  `${BASE_URL}/book-resources/book-resources-by-admin`,
		  formData,
		  config,
		);
		dispatch({
		  type: CREATE_RESOURCE_BOOKING_SUCCESS,
		  payload: data,
		});
	  } catch (err) {
		dispatch({
		  type: CREATE_RESOURCE_BOOKING_FAIL,
		  payload:
			err.response && err.response.data.message
			  ? err.response.data.message
			  : err.message,
		});
	  }
	};

	
export const updateBookedResourceAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: UPDATE_BOOKED_RESOURCE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.put(`${BASE_URL}/book-resources/update-book-resources-by-admin?id=${id}`, formData, config);

		dispatch({
			type: UPDATE_BOOKED_RESOURCE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: UPDATE_BOOKED_RESOURCE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
