import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputsSectionColumn from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import FileUpload from '../../components/formInputs/FileUpload';
import TextareaBox from '../../components/formInputs/TextareaBox';
import Toggle from '../../components/formInputs/Toggle';
import { inputPhoneMasking } from '../../utils/phonemask';

const CreateEditGym = ({ data }) => {
	const {
		showAddModal,
		handleAddModalClose,
		handleSubmit,
		selectUpdateModel,

		name,
		email,
		address,
		phone,

		setName,
		setEmail,
		setPhone,
		setAddress,

		nameError,
		addressError,
		phoneError,
		emailError,

		setNameError,
		setAddressError,
		setPhoneError,
		setEmailError,

		password,
		setPassword,
		passwordError,
		setPasswordError,
		specialApp,
		setSpecialApp,
		gymPackageName,
		setGymPackageName,
		gymUrl,
		setGymUrl,
		imageSrc,
		setImageSrc,
		setUploadFileData,
		uploadFileDataError,
		setUploadFileDataError,

		setUploadDarkFileData,
		uploadDarkFileDataError,
		setUploadDarkFileDataError,
		darkimageSrc,
		setDarkImageSrc
	} = data;

	const handleChangeImage = (e) => {
		var file = e.target.files[0];
		var reader = new FileReader();
		if (e.target.files[0]) {
			var url = reader.readAsDataURL(file);
			reader.onloadend = () => {
				setImageSrc(reader.result);
			};
		}
	};
	const handleChangeImage2 = (e) => {
		var file = e.target.files[0];
		var reader = new FileReader();
		if (e.target.files[0]) {
			var url = reader.readAsDataURL(file);
			reader.onloadend = () => {
				setDarkImageSrc(reader.result);
			};
		}
	};

	return (
		<Modal show={showAddModal}>
			<ModalHeading heading={selectUpdateModel._id ? 'Update Gym' : 'Add New Gym'} onClose={handleAddModalClose} />
			<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
				<InputsSection>
					<InputBox
						label='Gym Name'
						icon='gym'
						placeholder='eg, Gold Gym'
						value={name}
						onChange={(event) => {
							setName(event.target.value);
							setNameError('');
						}}
						errorMessage={nameError}
					/>
					<InputBox
						label='Email'
						icon='email'
						placeholder='eg, johndoe@example.com'
						value={email}
						onChange={(event) => {
							setEmail(event.target.value);
							setEmailError('');
						}}
						errorMessage={emailError}
					/>
					<InputBox
						label='Phone'
						icon='phone'
						placeholder='eg, 123 456 7890'
						value={phone}
						onChange={(event) => {
							setPhone(inputPhoneMasking(event.target.value));
							setPhoneError('');
						}}
						errorMessage={phoneError}
					/>
				</InputsSection>
				<InputsSectionColumn>
					<div className='flex' style={{ gap: '2rem', alignItems: 'flex-start' }}>
						<TextareaBox
							rows={1}
							label='Address'
							icon='pin'
							placeholder='eg, Denver, USA'
							value={address}
							onChange={(event) => {
								setAddress(event.target.value);
								setAddressError('');
							}}
							errorMessage={addressError}
						/>

						{!selectUpdateModel._id && (
							<InputBox
								label='Password'
								icon='key'
								placeholder='**********'
								type='password'
								value={password}
								onChange={(event) => {
									setPassword(event.target.value);
									setPasswordError('');
								}}
								errorMessage={passwordError}
							/>
						)}

						<Toggle label='Special App' checked={specialApp} onChange={() => setSpecialApp(!specialApp)} />
					</div>
				</InputsSectionColumn>
				{specialApp && (
					<InputsSection>
						<InputBox
							label='Package Name'
							icon='key'
							placeholder='eg, io.thealgoritym.gymwyse'
							type='text'
							value={gymPackageName.value}
							onChange={(e) => {
								setGymPackageName({ value: e.target.value, error: '' });
							}}
							errorMessage={gymPackageName.error}
						/>
						<InputBox
							label='URL'
							icon='key'
							placeholder='eg, www.example.com'
							type='text'
							value={gymUrl.value}
							onChange={(e) => {
								setGymUrl({ value: e.target.value, error: '' });
							}}
							errorMessage={gymUrl.error}
						/>
					</InputsSection>
				)}
				<InputsSection>
					<FileUpload
						label='Upload Gym Light Logo'
						icon='upload'
						image={imageSrc}
						onChange={(e) => {
							handleChangeImage(e);
							setUploadFileData(e.target.files);
							setUploadFileDataError('');
						}}
						errorMessage={uploadFileDataError}
					/>
					<FileUpload
						label='Upload Gym Dark Logo'
						icon='upload'
						image={darkimageSrc}
						onChange={(e) => {
							handleChangeImage2(e);
							setUploadDarkFileData(e.target.files);
							setUploadDarkFileDataError('');
						}}
						errorMessage={uploadDarkFileDataError}
					/>
				</InputsSection>
				<ModalButton
					label={selectUpdateModel._id ? 'Update Gym' : 'Add New'}
					icon={selectUpdateModel._id ? 'edit' : 'plus'}
					onClick={handleSubmit}
				/>
			</ModalForm>
		</Modal>
	);
};

export default CreateEditGym;
