import {
	ATTENDANCE_LIST_FAIL,
	ATTENDANCE_LIST_REQUEST,
	ATTENDANCE_LIST_SUCCESS,
	ATTENDANCE_LIST_RESET,
	CHECKINOUT_REQUEST,
	CHECKINOUT_SUCCESS,
	CHECKINOUT_FAIL,
	CHECKINOUT_RESET,
	DELETE_CHECKIN_REQUEST,
	DELETE_CHECKIN_SUCCESS,
	DELETE_CHECKIN_FAIL,
	DELETE_CHECKIN_RESET,
	GET_TRAINER_SESSIONS_REQUEST,
	GET_TRAINER_SESSIONS_SUCCESS,
	GET_TRAINER_SESSIONS_FAIL,
	GET_TRAINER_SESSIONS_RESET,
	ADD_TRAINER_SESSION_REQUEST,
	ADD_TRAINER_SESSION_SUCCESS,
	ADD_TRAINER_SESSION_FAIL,
	ADD_TRAINER_SESSION_RESET,
	TOTAL_TRAINER_SESSION_REQUEST,
	TOTAL_TRAINER_SESSION_SUCCESS,
	TOTAL_TRAINER_SESSION_FAIL,
	TOTAL_TRAINER_SESSION_RESET
} from '../constants/attendanceConstants';

export const checkInOutReducer = (state = {}, action) => {
	switch (action.type) {
		case CHECKINOUT_REQUEST:
			return { loading: true };

		case CHECKINOUT_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHECKINOUT_FAIL:
			return { loading: false, error: action.payload };

		case CHECKINOUT_RESET:
			return {};

		default:
			return state;
	}
};

export const getAttendanceListReducer = (state = {}, action) => {
	switch (action.type) {
		case ATTENDANCE_LIST_REQUEST:
			return { loading: true };

		case ATTENDANCE_LIST_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case ATTENDANCE_LIST_FAIL:
			return { loading: false, error: action.payload };

		case ATTENDANCE_LIST_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteCheckinReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_CHECKIN_REQUEST:
			return { loading: true };

		case DELETE_CHECKIN_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case DELETE_CHECKIN_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_CHECKIN_RESET:
			return {};

		default:
			return state;
	}
};

export const getTrainerSessionsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TRAINER_SESSIONS_REQUEST:
			return { loading: true };

		case GET_TRAINER_SESSIONS_SUCCESS:
			return { sessions: action.payload, loading: false };

		case GET_TRAINER_SESSIONS_FAIL:
			return { loading: false, error: action.payload };

		case GET_TRAINER_SESSIONS_RESET:
			return {};

		default:
			return state;
	}
};

export const addTrainerSessionReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_TRAINER_SESSION_REQUEST:
			return { loading: true };

		case ADD_TRAINER_SESSION_SUCCESS:
			return { session: action.payload, loading: false };

		case ADD_TRAINER_SESSION_FAIL:
			return { loading: false, error: action.payload };

		case ADD_TRAINER_SESSION_RESET:
			return {};

		default:
			return state;
	}
};

export const totalTrainerSessionReducer = (state = {}, action) => {
	switch (action.type) {
		case TOTAL_TRAINER_SESSION_REQUEST:
			return { loading: true };

		case TOTAL_TRAINER_SESSION_SUCCESS:
			return { session: action.payload, loading: false };

		case TOTAL_TRAINER_SESSION_FAIL:
			return { loading: false, error: action.payload };

		case TOTAL_TRAINER_SESSION_RESET:
			return {};

		default:
			return state;
	}
};
