import React from 'react';
import fileUploadBox from './FileUpload.module.css';

const FileUpload = ({ onChange, errorMessage, label, icon, image, style }) => {
	return (
		<div>
			<div className='modalContainer__form--sections'>
				<div className={fileUploadBox.form_input} style={style}>
					{/* {label && (
						<label htmlFor={label} className={fileUploadBox.form_input__label} style={{ marginBottom:'1rem' }}>
							{label}
						</label>
					)} */}
					<div className='btn-file'>
						{image ? (
							<img alt='image' src={image} className='fileUpload__label--image' />
						) : (
							<span>
								<svg className='file_upload--icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
								</svg>{' '}
								<br />
								{label}
								{errorMessage && <p className={fileUploadBox.form_input__error}>{errorMessage}</p>}
							</span>
						)}
						<input type='file' name='uploadFiles' id='uploadFiles' accept='image/*' onChange={onChange} />
					</div>
				</div>
			</div>

		</div>
	);
};

export default FileUpload;
