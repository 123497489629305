export const months = [
	{ month: 1, name: 'January' },
	{ month: 2, name: 'February' },
	{ month: 3, name: 'March' },
	{ month: 4, name: 'April' },
	{ month: 5, name: 'May' },
	{ month: 6, name: 'June' },
	{ month: 7, name: 'July' },
	{ month: 8, name: 'August' },
	{ month: 9, name: 'September' },
	{ month: 10, name: 'October' },
	{ month: 11, name: 'November' },
	{ month: 12, name: 'December' }
];
