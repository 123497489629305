import React, { useState } from 'react';
import TextBox from './TextBox.module.css';

const InputBox = ({ type = 'text', placeholder, value, onChange, errorMessage, label, icon, style, disabled, defaultValue, id, minValue, maxValue, inputStyles={} }) => {
	const [inputType, setInputType] = useState(type);

	const handlePasswordView = () => {
		if (type === 'password') {
			if (inputType === 'text') setInputType('password');
			else setInputType('text');
		}
	};

	const getTodaysDate = () => {
		let today = new Date();
		let dd = today.getDate();
		let mm = String(today.getMonth() + 1).padStart(2, '0');
		let yyyy = today.getFullYear();

		return yyyy + '-' + mm + '-' + dd;
	};

	return (
		<div className={TextBox.form_input}>
			{label && (
				<label htmlFor={label} className={TextBox.form_input__label}>
					{label}
				</label>
			)}
			<div className={TextBox.form_input__container} style={style}>
				{icon && (
					<svg className={TextBox.form_input__icon}>
						<use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
					</svg>
				)}
				<input
					type={inputType}
					id={id}
					name={id}
					disabled={disabled ? disabled : false}
					placeholder={placeholder}
					value={value}
					defaultValue={defaultValue}
					onChange={onChange}
					className={TextBox.form_input__box}
					min={type === 'date' ? minValue ? minValue : '' : ''}
					max={type === 'date' ? maxValue ? maxValue : getTodaysDate() : ''}
					style={inputStyles}
				/>
				{type === 'password' && (
					<svg className={TextBox.form_input__icon} onClick={handlePasswordView}>
						<use xlinkHref={`/assets/sprite.svg#icon-${inputType === 'text' ? 'visibility_off' : 'visibility'}`} />
					</svg>
				)}
			</div>
			{errorMessage && <p className={TextBox.form_input__error}>{errorMessage}</p>}
		</div>
	);
};

export default InputBox;
