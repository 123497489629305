import {
	GET_ALL_PRODUCT_CATEGORY_REQUEST,
	GET_ALL_PRODUCT_CATEGORY_SUCCESS,
	GET_ALL_PRODUCT_CATEGORY_FAIL,
	GET_ALL_PRODUCT_CATEGORY_RESET,
	ADD_PRODUCT_CATEGORY_REQUEST,
	ADD_PRODUCT_CATEGORY_SUCCESS,
	ADD_PRODUCT_CATEGORY_FAIL,
	ADD_PRODUCT_CATEGORY_RESET,
	PRODUCT_CATEGORY_STATUS_REQUEST,
	PRODUCT_CATEGORY_STATUS_SUCCESS,
	PRODUCT_CATEGORY_STATUS_FAIL,
	PRODUCT_CATEGORY_STATUS_RESET,
	DELETE_PRODUCT_CATEGORY_REQUEST,
	DELETE_PRODUCT_CATEGORY_SUCCESS,
	DELETE_PRODUCT_CATEGORY_FAIL,
	DELETE_PRODUCT_CATEGORY_RESET,
	GET_ALL_PRODUCTS_REQUEST,
	GET_ALL_PRODUCTS_SUCCESS,
	GET_ALL_PRODUCTS_FAIL,
	GET_ALL_PRODUCTS_RESET,
	GET_ONE_PRODUCT_REQUEST,
	GET_ONE_PRODUCT_SUCCESS,
	GET_ONE_PRODUCT_FAIL,
	GET_ONE_PRODUCT_RESET,
	CREATE_PRODUCT_REQUEST,
	CREATE_PRODUCT_SUCCESS,
	CREATE_PRODUCT_FAIL,
	CREATE_PRODUCT_RESET,
	CHANGE_PRODUCT_STATUS_REQUEST,
	CHANGE_PRODUCT_STATUS_SUCCESS,
	CHANGE_PRODUCT_STATUS_FAIL,
	CHANGE_PRODUCT_STATUS_RESET,
	DELETE_PRODUCT_REQUEST,
	DELETE_PRODUCT_SUCCESS,
	DELETE_PRODUCT_FAIL,
	DELETE_PRODUCT_RESET,

	ADD_PRODUCT_STOCK_REQUEST,
	ADD_PRODUCT_STOCK_SUCCESS,
	ADD_PRODUCT_STOCK_FAIL,
	ADD_PRODUCT_STOCK_RESET,
	
	GET_PRODUCT_ORDER_REQUEST,
	GET_PRODUCT_ORDER_RESET,
	GET_PRODUCT_ORDER_FAIL,
	GET_PRODUCT_ORDER_SUCCESS,

	CHANGE_ORDER_STATUS_REQUEST,
	CHANGE_ORDER_STATUS_SUCCESS,
	CHANGE_ORDER_STATUS_RESET,
	CHANGE_ORDER_STATUS_FAIL,
} from '../constants/productConstants';

export const getAllProductCategoriesReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_PRODUCT_CATEGORY_REQUEST:
			return { loading: true };

		case GET_ALL_PRODUCT_CATEGORY_SUCCESS:
			return { categories: action.payload, loading: false };

		case GET_ALL_PRODUCT_CATEGORY_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_PRODUCT_CATEGORY_RESET:
			return {};

		default:
			return state;
	}
};

export const createProductCategoryReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_PRODUCT_CATEGORY_REQUEST:
			return { loading: true };

		case ADD_PRODUCT_CATEGORY_SUCCESS:
			return { category: action.payload, loading: false };

		case ADD_PRODUCT_CATEGORY_FAIL:
			return { loading: false, error: action.payload };

		case ADD_PRODUCT_CATEGORY_RESET:
			return {};

		default:
			return state;
	}
};

export const productCategoryStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case PRODUCT_CATEGORY_STATUS_REQUEST:
			return { loading: true };

		case PRODUCT_CATEGORY_STATUS_SUCCESS:
			return { category: action.payload, loading: false };

		case PRODUCT_CATEGORY_STATUS_FAIL:
			return { loading: false, error: action.payload };

		case PRODUCT_CATEGORY_STATUS_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteProductCategoryReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_PRODUCT_CATEGORY_REQUEST:
			return { loading: true };

		case DELETE_PRODUCT_CATEGORY_SUCCESS:
			return { category: action.payload, loading: false };

		case DELETE_PRODUCT_CATEGORY_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_PRODUCT_CATEGORY_RESET:
			return {};

		default:
			return state;
	}
};

export const getAllProductsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_PRODUCTS_REQUEST:
			return { loading: true };

		case GET_ALL_PRODUCTS_SUCCESS:
			return { products: action.payload, loading: false };

		case GET_ALL_PRODUCTS_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_PRODUCTS_RESET:
			return {};

		default:
			return state;
	}
};

export const getOneProductReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ONE_PRODUCT_REQUEST:
			return { loading: true };

		case GET_ONE_PRODUCT_SUCCESS:
			return { product: action.payload, loading: false };

		case GET_ONE_PRODUCT_FAIL:
			return { loading: false, error: action.payload };

		case GET_ONE_PRODUCT_RESET:
			return {};

		default:
			return state;
	}
};

export const createProductReducer = (state = {}, action) => {
	switch (action.type) {
		case CREATE_PRODUCT_REQUEST:
			return { loading: true };

		case CREATE_PRODUCT_SUCCESS:
			return { product: action.payload, loading: false };

		case CREATE_PRODUCT_FAIL:
			return { loading: false, error: action.payload };

		case CREATE_PRODUCT_RESET:
			return {};

		default:
			return state;
	}
};

export const changeProductStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_PRODUCT_STATUS_REQUEST:
			return { loading: true };

		case CHANGE_PRODUCT_STATUS_SUCCESS:
			return { product: action.payload, loading: false };

		case CHANGE_PRODUCT_STATUS_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_PRODUCT_STATUS_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteProductReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_PRODUCT_REQUEST:
			return { loading: true };

		case DELETE_PRODUCT_SUCCESS:
			return { product: action.payload, loading: false };

		case DELETE_PRODUCT_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_PRODUCT_RESET:
			return {};

		default:
			return state;
	}
};




export const getProductOrderHistoryReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PRODUCT_ORDER_REQUEST:
			return { loading: true };

		case GET_PRODUCT_ORDER_SUCCESS:
			return { categories: action.payload, loading: false };

		case GET_PRODUCT_ORDER_FAIL:
			return { loading: false, error: action.payload };

		case GET_PRODUCT_ORDER_RESET:
			return {};

		default:
			return state;
	}
};

export const addProductStockReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_PRODUCT_STOCK_REQUEST:
			return { loading: true };

		case ADD_PRODUCT_STOCK_SUCCESS:
			return { category: action.payload, loading: false };

		case ADD_PRODUCT_STOCK_FAIL:
			return { loading: false, error: action.payload };

		case ADD_PRODUCT_STOCK_RESET:
			return {};

		default:
			return state;
	}
};


 
export const changeOrderStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_ORDER_STATUS_REQUEST:
			return { loading: true };

		case CHANGE_ORDER_STATUS_SUCCESS:
			return { categories: action.payload, loading: false };

		case CHANGE_ORDER_STATUS_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_ORDER_STATUS_RESET:
			return {};

		default:
			return state;
	}
};
