import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import UploadFile from '../../components/Modal/UploadFile';

const CreateNutrition = ({ data }) => {
	const {
		addModalActive,
		handleAddModalClose,
		title, setTitle,
		handleSubmit, selectedNutrition,
		pdfFile,
		setPDFFile,
	} = data;
	const createNutritionTemplate = useSelector((state) => state.createNutritionTemplate);

	return (
		<Modal show={addModalActive}>
			<ModalHeading
				heading={selectedNutrition._id ? 'Update Nutrition' : 'Create New Nutrition'}
				onClose={handleAddModalClose}
			/>

			<ModalForm onSubmit={handleSubmit} style={{
				marginBottom: '2.5rem'
			}}>
				<InputsSection style={{
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<InputBox
						label='Nutrition Name'
						icon='archive'
						placeholder='eg, 7_28s_Training Nutrition'
						value={title.value}
						onChange={(e) => setTitle({ value: e.target.value })}
						errorMessage={title.error}
					/>
				</InputsSection>

				<InputsSection style={{
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<UploadFile
						label='Upload PDF file'
						icon='upload'
						accept='.pdf'
						onChange={(e) => {
							// convertVideo(e);
							e.target.files.length > 0 && setPDFFile({ value: e.target.files[0], error: '' });
						}}
						errorMessage={pdfFile.error}
					/>
				</InputsSection>

				{createNutritionTemplate && createNutritionTemplate.loading ? (
					<ModalButton label={'Please wait'} icon={'plus'} />
				) : (
					<ModalButton label={selectedNutrition._id ? 'Update Nutrition' : 'Add New'} icon={selectedNutrition._id ? 'edit' : 'plus'} onClick={handleSubmit} />
				)}
			</ModalForm>
		</Modal>
	);
};

export default CreateNutrition;
