import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Content from '../../components/Content';
import { addTrainerSessionAction, getTrainerSessionsAction } from '../../redux/actions/attendanceActions';
import { ADD_TRAINER_SESSION_RESET, GET_TRAINER_SESSIONS_RESET } from '../../redux/constants/attendanceConstants';
import { validateOnlyNumberDecimal } from '../../utils/validators';

import { months, days } from './calendarData';
import TrainerSessionStyles from './TrainerSession.module.css';
import { amountToStringWithSymbol } from '../../utils/numbers';

const TrainerSessionScreen = ({ history, match }) => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [currentMonthNumber, setCurrentMonthNumber] = useState(null);
	const [currentMonth, setCurrentMonth] = useState({});
	const [currentMonthDates, setCurrentMonthDates] = useState([]);
	const [currentYear, setCurrentYear] = useState('');
	const [currentDate, setCurrentDate] = useState('');
	const [currentDayName, setCurrentDayName] = useState('');
	const [currentMonthStartDay, setCurrentMonthStartDay] = useState('');
	const [currentMonthStartDayMargin, setCurrentMonthStartDayMargin] = useState(0);
	const [yesterdayDate, setYesterdayDate] = useState({});
	const [dayBeforeYesterdayDate, setDayBeforeYesterdayDate] = useState({});
	const [sessionBoxVisible, setSessionBoxVisible] = useState({ visible: false, current: '' });
	const [sessionText, setSessionText] = useState({ value: '', error: '' });

	const userLogin = useSelector((state) => state.userLogin);
	const getTrainerSessions = useSelector((state) => state.getTrainerSessions);
	const addTrainerSession = useSelector((state) => state.addTrainerSession);
	const getUserInfo = useSelector((state) => state.getUserInfo);

	useEffect(
		() => {
			if (userLogin && userLogin.userInfo && userLogin.userInfo.data && !userLogin.userInfo.data.token) {
				history.push('/login');
			}
		},
		[userLogin, history]
	);

	useEffect(
		() => {
			if (currentMonthNumber !== null) {
				if (match.params.trainerId) {
					dispatch(getTrainerSessionsAction(currentMonthNumber + 1, currentYear, match.params.trainerId));
				} else {
					dispatch(getTrainerSessionsAction(currentMonthNumber + 1, currentYear));
				}
			}
			setSessionBoxVisible({ visible: false, current: '' });
			setSessionText({ value: '', error: '' });

			return () => {
				dispatch({ type: GET_TRAINER_SESSIONS_RESET });
			};
		},
		[dispatch, currentMonthNumber, match]
	);

	useEffect(() => {
		const date = new Date(Date.now());

		setCurrentYear(date.getFullYear());
		setCurrentMonthNumber(date.getMonth());

		const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
		const dayBeforeYesterday = new Date(new Date().setDate(new Date().getDate() - 2));
		setYesterdayDate({ date: yesterday.getDate(), month: yesterday.getMonth(), year: yesterday.getFullYear() });
		setDayBeforeYesterdayDate({
			date: dayBeforeYesterday.getDate(),
			month: dayBeforeYesterday.getMonth(),
			year: dayBeforeYesterday.getFullYear()
		});
	}, []);

	useEffect(
		() => {
			const date = new Date(Date.now());

			months.map((month, i) => {
				if (i === currentMonthNumber) {
					if (month && month.name === 'February' && currentYear % 4 === 0) {
						let tmpmonth = month;
						tmpmonth.days = 29;
						setCurrentMonth(tmpmonth);
					} else if (month && month.name === 'February' && currentYear % 4 !== 0) {
						let tmpmonth = month;
						tmpmonth.days = 28;
						setCurrentMonth(tmpmonth);
					} else {
						setCurrentMonth(month)
					}
				}
			});
			setCurrentDate(date.getDate());
			// setCurrentDay(date.getDay());
			days.map((day, i) => i === date.getDay() && setCurrentDayName(day));

			setCurrentMonthStartDay(new Date(currentYear, currentMonthNumber, 1).getDay());
			setCurrentMonthStartDayMargin(currentMonthStartDay * 13.6);
		},
		[currentMonthNumber, currentYear, currentMonthStartDay]
	);

	useEffect(
		() => {
			const days = [];

			if (currentMonth && currentMonth.days) {
				for (let i = 1; i <= currentMonth.days; i++) {
					days.push({ date: i, session: 0 });
				}
			}
			const days3 =
				getTrainerSessions &&
				getTrainerSessions.sessions &&
				getTrainerSessions.sessions.data &&
				getTrainerSessions.sessions.data.result &&
				getTrainerSessions.sessions.data.result.length > 0 &&
				getTrainerSessions.sessions.data.result;

			setLoading(getTrainerSessions && getTrainerSessions.loading);

			if (days && days.length > 0 && days3 && days3.length > 0) {
				for (let i = days.length - 1; i >= 0; i--) {
					for (let j = days3.length - 1; j >= 0; j--) {
						if (days[i].date === days3[j].date) {
							days[i] = days3[j];
						}
					}
				}
			}

			setCurrentMonthDates(days);
		},
		[currentYear, currentMonth, getTrainerSessions]
	);

	useEffect(
		() => {
			if (addTrainerSession && addTrainerSession.session && addTrainerSession.session.status) {
				setSessionBoxVisible({ visible: false, current: '' });
				setSessionText({ value: '', error: '' });
				toast.success(addTrainerSession.session.message);
				if (currentMonthNumber !== null) {
					if (match.params.trainerId) {
						dispatch(getTrainerSessionsAction(currentMonthNumber + 1, currentYear, match.params.trainerId));
					} else {
						dispatch(getTrainerSessionsAction(currentMonthNumber + 1, currentYear));
					}
				}
				dispatch({ type: ADD_TRAINER_SESSION_RESET });
			} else if (addTrainerSession && addTrainerSession.session && !addTrainerSession.session.status) {
				toast.success(addTrainerSession.session.message);
				dispatch({ type: ADD_TRAINER_SESSION_RESET });
			}
		},
		// eslint-disable-next-line
		[addTrainerSession, dispatch, currentMonthNumber, match]
	);

	const handlePreviousMonth = () => {
		if (currentMonthNumber === 0) {
			setCurrentMonthNumber(11);
			setCurrentYear(currentYear - 1);
		} else setCurrentMonthNumber(currentMonthNumber - 1);
	};
	const handleNextMonth = () => {
		if (currentMonthNumber === 11) {
			setCurrentMonthNumber(0);
			setCurrentYear(currentYear + 1);
		} else setCurrentMonthNumber(currentMonthNumber + 1);
	};

	const handleAddSession = (current, sessionValue) => {
		if (
			(current === dayBeforeYesterdayDate.date &&
				currentMonthNumber === dayBeforeYesterdayDate.month &&
				currentYear === dayBeforeYesterdayDate.year) ||
			(current === yesterdayDate.date &&
				currentMonthNumber === yesterdayDate.month &&
				currentYear === yesterdayDate.year) ||
			(current === currentDate &&
				currentMonthNumber === new Date(Date.now()).getMonth() &&
				currentYear === new Date(Date.now()).getFullYear())
		) {
			setSessionBoxVisible({ visible: !sessionBoxVisible.visible, current });
			setSessionText({ value: sessionValue, error: '' });
		} else if (
			getUserInfo &&
			getUserInfo.userInfo &&
			getUserInfo.userInfo.data &&
			(getUserInfo.userInfo.data.role === 'gym' || getUserInfo.userInfo.data.role === 'trainer')
		) {
			setSessionBoxVisible({ visible: !sessionBoxVisible.visible, current });
			setSessionText({ value: sessionValue, error: '' });
		} else return;
	};

	const addSession = (date, days) => {
		if (sessionText.value === '') {
			setSessionText({ ...sessionText, error: 'Please enter session value' });
		} else if (!validateOnlyNumberDecimal(sessionText.value)) {
			setSessionText({ ...sessionText, error: 'Please enter valid session' });
		} else {
			const formData = {
				sessionDate: `${currentMonthNumber + 1}/${date}/${currentYear}`,
				session: sessionText.value
			};

			if (days._id) formData.id = days._id;
			if (match.params.trainerId) formData.trainer = match.params.trainerId;

			dispatch(addTrainerSessionAction(formData));
		}
	};

	return (
		<Content
			currentMenu={`${match.params.trainerId ? 'trainers' : 'trainer-sessions'}`}
			headerTitle={match.params.trainerName ? match.params.trainerName + ' Sessions' : ' Sessions'}>
			<div className={TrainerSessionStyles.month}>
				<div className={TrainerSessionStyles.headers}>
					<button className={TrainerSessionStyles.prevNextBtn} onClick={handlePreviousMonth}>
						&#10094;
					</button>
					<p className={TrainerSessionStyles.dayMonth}>
						{currentMonthNumber === new Date(Date.now()).getMonth() &&
							currentYear === new Date(Date.now()).getFullYear() &&
							currentDayName + ' ' + currentDate
						}
						<span>
							{currentMonth && currentMonth.name && currentMonth.name} {currentYear}
						</span>
					</p>
					<button className={TrainerSessionStyles.prevNextBtn} onClick={handleNextMonth}>
						&#10095;
					</button>
				</div>
				<ul className={TrainerSessionStyles.weekdays}>
					{days && days.map((day, index) => <li key={index}>{day}</li>)}
				</ul>
				<ul className={TrainerSessionStyles.days}>
					{currentMonthDates &&
						currentMonthDates.length > 0 &&
						currentMonthDates.map((days, index) => (
							<li key={index} style={{ marginLeft: days.date === 1 ? currentMonthStartDayMargin + '%' : 0 }}>
								{currentMonthNumber === new Date(Date.now()).getMonth() &&
									currentYear === new Date(Date.now()).getFullYear() &&
									currentDate === days.date ? (
									<span className={TrainerSessionStyles.active}>{days.date}</span>
								) : (
									days.date
								)}
								{/* {(days.date === dayBeforeYesterdayDate.date &&
									currentMonthNumber === dayBeforeYesterdayDate.month &&
									currentYear === dayBeforeYesterdayDate.year) ||
									(days.date === yesterdayDate.date &&
										currentMonthNumber === yesterdayDate.month &&
										currentYear === yesterdayDate.year) ||
									(days.date === currentDate &&
										currentMonthNumber === new Date(Date.now()).getMonth() &&
										currentYear === new Date(Date.now()).getFullYear()) ? null : (
									getUserInfo &&
									getUserInfo.userInfo &&
									getUserInfo.userInfo.data &&
									getUserInfo.userInfo.data.role === 'trainer' && (
										<svg className={TrainerSessionStyles.lockIcon}>
											<use xlinkHref={`/assets/sprite.svg#icon-lock`} />
										</svg>
									)
								)} */}

								{sessionBoxVisible.visible && sessionBoxVisible.current === days.date ? addTrainerSession &&
									addTrainerSession.loading ? (
									<p className={TrainerSessionStyles.sessionData}>
										<span>•</span> Saving Session...
									</p>
								) : (
									<Fragment>
										<div className={TrainerSessionStyles.sessionInputs}>
											<input
												type='number'
												placeholder='eg, 3.5'
												autoFocus={sessionBoxVisible.visible}
												value={sessionText.value}
												onChange={(e) => {
													if (e.target.value < 0) e.target.value = e.target.value * -1;
													setSessionText({ value: e.target.value, error: '' });
												}}
												className={TrainerSessionStyles.sessionInput}
											/>
											<button
												className={TrainerSessionStyles.sessionInputBtn}
												onClick={() => addSession(days.date, days)}>
												<svg className={TrainerSessionStyles.sessionInputIcon_check}>
													<use xlinkHref={`/assets/sprite.svg#icon-check`} />
												</svg>
											</button>
											<button
												className={TrainerSessionStyles.sessionInputBtn}
												onClick={() => handleAddSession(days.date, '')}>
												<svg className={TrainerSessionStyles.sessionInputIcon_close}>
													<use xlinkHref={`/assets/sprite.svg#icon-close`} />
												</svg>
											</button>
										</div>
										{sessionText.error && <p className={TrainerSessionStyles.errorText}>{sessionText.error}</p>}
									</Fragment>
								) : loading ? (
									<p className={TrainerSessionStyles.sessionData}>
										<span>•</span> Loading...
									</p>
								) : (
									<p
										className={TrainerSessionStyles.sessionData}
										onDoubleClick={() => handleAddSession(days.date, days.session)}>
										{(getUserInfo &&
											getUserInfo.userInfo &&
											getUserInfo.userInfo.data &&
											(getUserInfo.userInfo.data.role === 'gym' || getUserInfo.userInfo.data.role === 'trainer') ? (
											<Fragment>
												{days.session === 0 ? (
													<button
														className={TrainerSessionStyles.sessionDataAddBtn}
														onClick={() => handleAddSession(days.date, '')}>
														Add Sessions
													</button>
												) : (
													<Fragment>
														<span>•</span> {days.session} sessions
														<button
															className={TrainerSessionStyles.sessionDataBtn}
															onClick={() => handleAddSession(days.date, days.session)}>
															<svg className={TrainerSessionStyles.sessionDataIcon}>
																<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
															</svg>
														</button>
													</Fragment>
												)}
											</Fragment>
										) : (
											days.date === dayBeforeYesterdayDate.date &&
											currentMonthNumber === dayBeforeYesterdayDate.month &&
											currentYear === dayBeforeYesterdayDate.year
										)) ||
											(days.date === yesterdayDate.date &&
												currentMonthNumber === yesterdayDate.month &&
												currentYear === yesterdayDate.year) ||
											(days.date === currentDate &&
												currentMonthNumber === new Date(Date.now()).getMonth() &&
												currentYear === new Date(Date.now()).getFullYear()) ? (
											<Fragment>
												{days.session === 0 ? (
													<button
														className={TrainerSessionStyles.sessionDataAddBtn}
														onClick={() => handleAddSession(days.date, '')}>
														Add Sessions
													</button>
												) : (
													<Fragment>
														<span>•</span> {days.session} sessions
														<button
															className={TrainerSessionStyles.sessionDataBtn}
															onClick={() => handleAddSession(days.date, days.session)}>
															<svg className={TrainerSessionStyles.sessionDataIcon}>
																<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
															</svg>
														</button>
													</Fragment>
												)}
											</Fragment>
										) : (
											<Fragment>
												<span>•</span> {days.session} sessions
											</Fragment>
										)}
									</p>
								)}
							</li>
						))}
				</ul>

				{getTrainerSessions &&
					getTrainerSessions.sessions &&
					getTrainerSessions.sessions.data &&
					getTrainerSessions.sessions.data.totalSessionHr ? (
					<div className={TrainerSessionStyles.totalSection}>
						<p className={TrainerSessionStyles.totalSectionData}>
							Total Sessions <span>{getTrainerSessions.sessions.data.totalSessionHr.toFixed(2)} Hours</span>
						</p>
						<p className={TrainerSessionStyles.totalSectionData}>
							Hours Owed <span>{amountToStringWithSymbol(getTrainerSessions.sessions.data.totalSessionHrOwed)}</span>
						</p>
						<p className={TrainerSessionStyles.totalSectionData}>
							Total Reservation Fees <span>{amountToStringWithSymbol(getTrainerSessions.sessions.data.totalReservationFees)}</span>
						</p>
						{getTrainerSessions.sessions.data.utilitiesOwed ? (
							<p className={TrainerSessionStyles.totalSectionData}>
								Utilities Owed <span>{amountToStringWithSymbol(getTrainerSessions.sessions.data.utilitiesOwed)}</span>
							</p>
						) : null}
						{getTrainerSessions.sessions.data.totalOwed ? (
							<p className={TrainerSessionStyles.totalSectionData}>
								Total Owed <span>{amountToStringWithSymbol(getTrainerSessions.sessions.data.totalOwed)}</span>
							</p>
						) : null}
					</div>
				) : (
					<div className={TrainerSessionStyles.totalSection}>
						<p className={TrainerSessionStyles.totalSectionData}>
							Total Sessions <span>0 Hours</span>
						</p>
						<p className={TrainerSessionStyles.totalSectionData}>
							Total Reservation Fees <span>{amountToStringWithSymbol(0)}</span>
						</p>
						<p className={TrainerSessionStyles.totalSectionData}>
							Hours Owed <span>{`$0`}</span>
						</p>
					</div>
				)}
			</div>
		</Content>
	);
};

export default TrainerSessionScreen;
