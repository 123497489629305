import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';
import { phoneMasking } from '../../utils/phonemask';
import { validatePhoneMaskingString } from '../../utils/validators';

const UsersData = ({
	userList,
	showFilter,
	setModalDeleteState,
	setSelectUpdateModelUser,
	handleEditModalUser,
	onChangeHandler,
	setModalDoumentState,
	setModalSettingState,
	setModalLogOutUserState
}) => {
	const [role, setRole] = useState('');
	const [specialApp, setSpecialApp] = useState('');
	const getUserInfo = useSelector((state) => state.getUserInfo);

	useEffect(
		() => {
			if (getUserInfo && getUserInfo.userInfo && getUserInfo.userInfo.data && getUserInfo.userInfo.data.role) {
				setRole(getUserInfo.userInfo.data.role);
				if (getUserInfo.userInfo.data.gym && getUserInfo.userInfo.data.gym.isSpecialAp)
					setSpecialApp(getUserInfo.userInfo.data.gym.isSpecialAp);
			}
		},
		[getUserInfo]
	);

	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '65vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>Name</th>
						{role !== 'trainer' && <th>Trainer</th>}
						<th>Email</th>
						<th>Phone</th>
						<th>OS</th>
						{(role === 'trainer' || role === 'gym' || role === 'gymadmin') && (
							<Fragment>
								<th>Active</th>
								<th>Actions</th>
								<th />
							</Fragment>
						)}
						{getUserInfo &&
							getUserInfo.userInfo &&
							getUserInfo.userInfo.data &&
							getUserInfo.userInfo.data.isSpecialApp && <th />}
						{getUserInfo &&
							getUserInfo.userInfo &&
							getUserInfo.userInfo.data &&
							getUserInfo.userInfo.data.gym &&
							getUserInfo.userInfo.data.gym.isSpecialApp && <th />}
					</tr>
				</thead>
				<tbody>
					{userList.loading ? (
						<tr>
							<td colSpan='7' style={{ textAlign: 'center' }}>
								<Spinner />
							</td>
						</tr>
					) : userList &&
						userList.userInfo &&
						userList.userInfo.status &&
						userList.userInfo.data.result &&
						userList.userInfo.data.result.length > 0 ? (
						userList.userInfo.data.result.map((d, index) => (
							<tr key={d._id}>
								<td>{index + 1}</td>
								<td style={{ textAlign: 'left', textTransform: 'capitalize' }}> {d.name}</td>
								{role !== 'trainer' && <td style={{ textAlign: 'left', textTransform: 'capitalize' }}>{d.trainer ? d.trainer.name : 'N/A'}</td>}
								<td>{d.email}</td>
								
								<td>{d.phone && validatePhoneMaskingString(d.phone) ? phoneMasking(d.phone) :d.phone}</td>
								<td>{d.platform ? d.platform : 'N/A'}</td>
								{(role === 'trainer' || role === 'gym' || role === 'gymadmin') && (
									<Fragment>
										<td>
											<label className='switch'>
												<input
													id={d._id}
													checked={d.active}
													onChange={(e) => {
														onChangeHandler(d);
													}}
													type='checkbox'
													className='checkbox'
													name='active'
												/>
												<span className='slider round' />
											</label>
										</td>
										<td>
											<div className='table__iconBox'>
												<button
													className='table__button'
													onClick={() => {
														setSelectUpdateModelUser(d);
														handleEditModalUser(d);
													}}>
													<svg className='table__button--icon'>
														<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
													</svg>
													<span>Edit User Details</span>
												</button>
												<button
													className='table__button table__button--doc'
													onClick={() => {
														setSelectUpdateModelUser(d);
														setModalDoumentState(true);
													}}>
													<svg className='table__button--icon-doc'>
														<use xlinkHref={`/assets/sprite.svg#icon-document`} />
													</svg>
													<span>User Liability Form</span>
												</button>
												<button
													className='table__button table__button--delete'
													onClick={() => {
														setSelectUpdateModelUser(d);
														setModalDeleteState(true);
													}}>
													<svg className='table__button--icon-red'>
														<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
													</svg>
													<span>Delete User</span>
												</button>
												<button
													className='table__button table__button--delete'
													onClick={() => {
														setSelectUpdateModelUser(d);
														setModalLogOutUserState(true);
													}}>
													<svg className='table__button--icon-red'>
														<use xlinkHref={`/assets/sprite.svg#icon-logout`} />
													</svg>
													<span>Logout User</span>
												</button>
											</div>
										</td>
									</Fragment>
								)}
								{getUserInfo &&
									getUserInfo.userInfo &&
									getUserInfo.userInfo.data &&
									getUserInfo.userInfo.data.isSpecialApp && (
										<td>
											<div className='table__iconBox'>
												<Link to={`assign-template/${d._id}/${d.name}`} className='table__button--second'>
													<span>Assign Exercise</span>
													<div className='table__button--secondIconContainer'>
														<svg className='table__button--secondIcon'>
															<use xlinkHref={`/assets/sprite.svg#icon-chevron-right`} />
														</svg>
													</div>
												</Link>
											</div>
										</td>
									)}
								{getUserInfo &&
									getUserInfo.userInfo &&
									getUserInfo.userInfo.data &&
									getUserInfo.userInfo.data.gym &&
									getUserInfo.userInfo.data.gym.isSpecialApp && (
										<td>
											<div className='table__iconBox'>
												<Link to={`assign-template/${d._id}/${d.name}`} className='table__button--second'>
													<span>Assign Exercise</span>
													<div className='table__button--secondIconContainer'>
														<svg className='table__button--secondIcon'>
															<use xlinkHref={`/assets/sprite.svg#icon-chevron-right`} />
														</svg>
													</div>
												</Link>
											</div>
										</td>
									)}
								{getUserInfo &&
									getUserInfo.userInfo &&
									getUserInfo.userInfo.data &&
									getUserInfo.userInfo.data.role === 'trainer' && (
										<td>
											<div className='table__iconBox'>
												<Link to={`assign-template/${d._id}/${d.name}`} className='table__button--second'>
													<span>Assign Exercise</span>
													<div className='table__button--secondIconContainer'>
														<svg className='table__button--secondIcon'>
															<use xlinkHref={`/assets/sprite.svg#icon-chevron-right`} />
														</svg>
													</div>
												</Link>
											</div>
										</td>
									)}
							</tr>
						))
					) : (
						<tr>
							<td colSpan='7' style={{ textAlign: 'center' }}>
								<NoData title='No Data Found!' subTitle='We could not find any user. Please add one' height='40vh' />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default UsersData;
