import React, { useEffect } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputsSectionColumn from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import SelectBox from '../../components/formInputs/SelectBox';
import { useSelector } from 'react-redux';
import TextareaBox from '../../components/formInputs/TextareaBox';
import { inputPhoneMasking } from '../../utils/phonemask';

const CreateStaffUser = ({ data }) => {
	const userData = useSelector((state) => state.getUserInfo);

	const {
		showAddModal,
		handleAddModalClose,
		handleSubmit,
		name,
		email,
		phone,
		address,
		role,

		setName,
		setEmail,
		setPhone,
		setRole,
		setAddress,

		nameError,
		phoneError,
		emailError,
		addressError,
		roleError,

		setRoleError,
		setNameError,
		setPhoneError,
		setEmailError,
		setAddressError,

		password,
		setPassword,
		passwordError,
		setPasswordError,

		gymId,
		setGymId,
		gymError,
		setGymIdError,

		gymList,
		roleList,
		selectUpdateModel
	} = data;

	useEffect(
		() => { 
			if (userData &&
				userData.userInfo &&
				userData.userInfo.data &&
				userData.userInfo.data && userData.userInfo.data.gym
				&& userData.userInfo.data.role === 'superadmin' && gymId === '') {
				setGymId(userData.userInfo.data.gym._id);
			} else if (userData &&
				userData.userInfo &&
				userData.userInfo.data &&
				userData.userInfo.data && userData.userInfo.data.role === "gym" && gymId === '') {
				setGymId(userData.userInfo.data._id);
			}
		},
		[userData]
	);

	return (
		<Modal show={showAddModal}>
			<ModalHeading heading={selectUpdateModel._id ? 'Update Staff' : 'Add New Staff'} onClose={handleAddModalClose} />
			<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
				<InputsSection>
					<InputBox
						label='User Name'
						icon='user'
						placeholder='eg, John Doe'
						value={name}
						onChange={(event) => {
							setName(event.target.value);
							setNameError('');
						}}
						errorMessage={nameError}
					/>
					<InputBox
						label='Email'
						icon='email'
						placeholder='eg, johndoe@example.com'
						value={email}
						onChange={(event) => {
							setEmail(event.target.value);
							setEmailError('');
						}}
						errorMessage={emailError}
					/>
					<InputBox
						label='Phone'
						icon='phone'
						placeholder='eg, 123-456-7890'
						value={phone}
						onChange={(event) => {
							setPhone(inputPhoneMasking(event.target.value));
							setPhoneError('');
						}}
						errorMessage={phoneError}
					/>
				</InputsSection>

				<InputsSectionColumn>
					<div className='flex' style={{ gap: '2rem', alignItems: 'flex-start' }}>
						{userData &&
							userData.userInfo &&
							userData.userInfo.data &&
							userData.userInfo.data.role === 'admin' && (
								// || userData.userInfo.data.role === 'superadmin'
								<SelectBox
									value={gymId}
									onChange={(e) => setGymId(e.target.value)}
									errorMessage={gymError}
									label='Select Gym'
									icon='gym'
									name='gym-name'>
									<option className='optionBox' value=''>
										Select Gym
									</option>
									{gymList &&
										gymList.userInfo &&
										gymList.userInfo.data &&
										gymList.userInfo.data.result.map((gym, index) => (
											<option value={gym._id} key={gym._id}>
												{gym.name}
											</option>
										))}
								</SelectBox>
							)}

						<SelectBox
							value={role}
							onChange={(e) => setRole(e.target.value)}
							errorMessage={roleError}
							label='Select Role'
							icon='trainer'
							name='role-name'>
							<option className='optionBox' value=''>
								Select Role
							</option>
							{roleList &&
								roleList.map((role) => (
									<option value={role.value} key={role._id}>
										{role.name}
									</option>
								))}
						</SelectBox>
						{!selectUpdateModel._id && (
							<InputBox
								label='Password'
								icon='key'
								placeholder='••••••••••••••••'
								type='password'
								value={password}
								onChange={(event) => {
									setPassword(event.target.value);
									setPasswordError('');
								}}
								errorMessage={passwordError}
							/>
						)}
					</div>
				</InputsSectionColumn>
				<InputsSectionColumn>
					<div className='flex' style={{ gap: '2rem', alignItems: 'flex-start' }}>
						<TextareaBox
							label='Address'
							icon='pin'
							placeholder='Enter Address'
							value={address}
							onChange={(event) => {
								setAddress(event.target.value);
								setAddressError('');
							}}
							errorMessage={addressError}
						/>
					</div>
				</InputsSectionColumn>

				<ModalButton
					label={selectUpdateModel._id ? 'Update Staff' : 'Add New'}
					icon={selectUpdateModel._id ? 'edit' : 'plus'}
					onClick={handleSubmit}
				/>
			</ModalForm>
		</Modal>
	);
};

export default CreateStaffUser;

{
	/* <RadioButtons
							label='Gender'
							placeholder='Gender'
							radioButtons={[
								{
									label: 'Male',
									value: 'male'
								},
								{
									label: 'Female',
									value: 'female'
								}
							]}
							checked={gender}
							onChange={onChangeContentType}
							error={genderError}
						/> */
}
