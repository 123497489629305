import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { changeAdminAction, getUserByTokenAction, logout } from '../../redux/actions/userActions';
import { CHANGE_ADMIN_RESET } from '../../redux/constants/userConstants';
import InputBox from '../formInputs/InputBox';
import Spinner from '../Spinner/Spinner';
import './Header.css';
import { toast } from 'react-toastify';

const Header = ({
	title,
	addBtn = false,
	addBtnText,
	addBtnIcon,
	addBtnClick,
	search = false,
	searchIcon,
	searchPlaceholder,
	searchvalue,
	searchOnChange,

	listFilter,
	listType,
	getTypeList,
	onTypeListChange,

	deleteBtn,
	deleteBtnText,
	onDeleteTemplateHandler
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const userData = useSelector((state) => state.getUserInfo);
	const userLogin = useSelector((state) => state.userLogin);
	const changeAdmin = useSelector((state) => state.changeAdmin);
	const [openDropdown, setOpenDropdown] = useState(false);
	const gymEnabledList = useSelector((list) => list.gymEnabledList);
	const [currentClient, setCurrentClient] = useState({});

	useEffect(
		() => {
			if (userData && userData.error && userData.error.status === false) {
				dispatch(logout());
				history.push('/login');
			} else if (userData && userData.userInfo && userData.userInfo.status && userData.userInfo.data) {
				setCurrentClient(userData.userInfo.data);
			}
		},
		[userData, history, dispatch]
	);

	useEffect(
		() => {
			if (changeAdmin && changeAdmin.userInfo && changeAdmin.userInfo.status) {
				toast.success(changeAdmin.userInfo.message);
				dispatch(getUserByTokenAction());
				window.location.reload(false);
				dispatch({ type: CHANGE_ADMIN_RESET });
			} else if (changeAdmin && changeAdmin.userInfo && !changeAdmin.userInfo.status) {
				toast.error(changeAdmin.userInfo.message);
				dispatch({ type: CHANGE_ADMIN_RESET });
			}
		},
		// eslint-disable-next-line
		[changeAdmin, dispatch]
	);

	const onChangeClient = async (item) => {
		setCurrentClient(item.gym);
		dispatch(changeAdminAction(item._id, { enable: true }));
		history.push('/');
		setOpenDropdown(false);
	};

	return (
		<div className='header'>
			<div className='header__left'>
				<div className='header__container'>
					{location.pathname !== '/' && (
						<button className='header__container--backBtn' onClick={() => history.goBack()}>
							<svg className='header__container--backBtnIcon'>
								<use xlinkHref={`/assets/sprite.svg#icon-back`} />
							</svg>
						</button>
					)}
					<h2 className='header__heading textCapitalize'>{title}</h2>
					{
						listFilter && (
							<select
								value={listType}
								onChange={(e) => onTypeListChange(e.target.value)}
								name='listType'
								id='listType'
								className='muscleTypeSelectBox'>
								{getTypeList &&
									getTypeList.length > 0 &&
									getTypeList.map((type, index) => (
										<option value={type.value} key={index + 1}>
											{type.label}
										</option>
									))}
							</select>
						)
					}

					{addBtn && (
						<button onClick={addBtnClick} className='header__container--btn'>
							<p>
								<svg className='header__container--icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-${addBtnIcon}`} />
								</svg>
							</p>
							<span>{addBtnText}</span>
						</button>
					)}

					{
						deleteBtn && (
							<button
								className='table__button table__button--delete'
								onClick={onDeleteTemplateHandler}>
								<svg className='table__button--icon-red'>
									<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
								</svg>
								<span>{deleteBtnText}</span>
							</button>
						)
					}

					{search && (
						<InputBox
							placeholder={searchPlaceholder}
							icon={searchIcon}
							value={searchvalue}
							onChange={searchOnChange}
							style={{ backgroundColor: '#ffffff' }}
						/>
					)}

				</div>
			</div>
			{userData && userData.loading ? (
				<Spinner />
			) : (
				<div style={{ display: 'flex', gap: '2rem' }}>
					{currentClient && (
						<div className='dropdown_container'>
							<div className='dropdown'>
								<svg className='form_input__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-user`} />
								</svg>
								<h2 className='header__heading'>
									{currentClient.name}{' '}
									<span style={{ fontSize: '1.1rem', textTransform: 'capitalize', color: '#ff9000' }}>
										({currentClient.role})
									</span>{' '}
								</h2>
							</div>
						</div>
					)}

					{userData &&
						userData.userInfo &&
						userData.userInfo.status &&
						userData.userInfo.data &&
						userData.userInfo.data.role === 'superadmin' && (
							<div
								className='dropdown_container'
								onBlur={() => setOpenDropdown(false)}
								tabIndex='0'
								style={{ zIndex: 102 }}>
								<div className='dropdown' onClick={() => setOpenDropdown(!openDropdown)}>
									{currentClient &&
										currentClient.gym && (
											<div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
												<img src={currentClient.gym.photoDark} alt='role' className='role_image' />
												<h2 className='header__heading'>{currentClient.gym.name}</h2>
											</div>
										)}
									{openDropdown ? (
										<svg className='form_input__icon'>
											<use xlinkHref={`/assets/sprite.svg#icon-up`} />
										</svg>
									) : (
										<svg className='form_input__icon'>
											<use xlinkHref={`/assets/sprite.svg#icon-down`} />
										</svg>
									)}
									{currentClient &&
										currentClient.gym &&
										openDropdown && (
											<div className='list_container'>
												{gymEnabledList &&
													gymEnabledList.userInfo &&
													gymEnabledList.userInfo.data &&
													gymEnabledList.userInfo.data.result.map((item, index) => (
														<div
															className={currentClient.gym._id === item._id ? 'selected_dropdown_item' : 'dropdown_item'}
															key={index}
															onClick={() => onChangeClient(item)}>
															<p className='dropdown_item_userName'> {item.name}</p>
														</div>
													))}
											</div>
										)}
								</div>
							</div>
						)}
				</div>
			)}
		</div>
	);
};

export default Header;
