import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
	changeStatusUserReducer,
	createNewUserReducer,
	deleteUserReducer,
	getUserByTokenReducer,
	getUserListReducer,
	userLoginReducer,
	changePasswordUserReducer,
	changeAdminUserReducer,
	logOutUserReducer
} from './reducers/userReducers';

import {
	changeAppMenuSettingReducer,
	changeStatusGymReducer,
	createNewGymReducer,
	deleteGymReducer,
	getAllEnabledGymsReducer,
	getAppMenuSettingReducer,
	getGymListReducer
} from './reducers/gymReducers';

import {
	changeStatusTrainerReducer,
	createNewTrainerReducer,
	deleteTrainerReducer,
	getEnabledTrainersReducer,
	getTrainerListReducer,
	trainerInsuranceReducer
} from './reducers/trainerReducers';

import {
	checkInOutReducer,
	getAttendanceListReducer,
	deleteCheckinReducer,
	getTrainerSessionsReducer,
	addTrainerSessionReducer,
	totalTrainerSessionReducer
} from './reducers/attendanceReducers';

import {
	getAllVideoCategoriesReducer,
	createVideoCategoryReducer,
	videoCategoryStatusReducer,
	deleteVideoCategoryReducer,
	getAllVideosReducer,
	getOneVideoReducer,
	createVideoReducer,
	changeVideoStatusReducer,
	deleteVideoReducer
} from './reducers/videoReducers';

import {
	addProductStockReducer,
	changeOrderStatusReducer,
	changeProductStatusReducer,
	createProductCategoryReducer,
	createProductReducer,
	deleteProductCategoryReducer,
	deleteProductReducer,
	getAllProductCategoriesReducer,
	getAllProductsReducer,
	getOneProductReducer,
	getProductOrderHistoryReducer,
	productCategoryStatusReducer
} from './reducers/productReducers';

import {
	changeStatusGymStaffReducer,
	createNewGymStaffReducer,
	deleteGymStaffReducer,
	getGymStaffListReducer
} from './reducers/gymStaffReducers';

import {
	changeCompanyStatusReducer,
	createCompanyReducer,
	deleteCompanyReducer,
	getAllCompanyReducer,
	getAllEnableCompanyBycharReducer,
	getAllEnableCompanyReducer
} from './reducers/companyReducers';

import {
	createFormByIndependentAgreementReducer,
	getFormByIndependentAgreementReducer,
	getFormByWaierLiabilityReducer
} from './reducers/formReducers';

import {
	changeAppVersionStatusReducer,
	createAppVersionReducer,
	deleteAppVersionReducer,
	getAllAppVersionReducer
} from './reducers/appVersionReducers';

import {
	getEarningReportByMonthReducers,
	getGeneralCountReducers,
	getSessionReportByMonthReducers,
	getSessionReportChartReducers,
	getSessionReportReducers,
	getTotalSalesReportByMonthReducers,
	getTrainerSessionByMonthReducer,
	getTrainerSessionByMonthChartReducer,
	getTrainerEarningByMonthChartReducer,
	generalCountAdminReducer,
	totalSessionByMonthAdminReducer,
	totalSessionByMonthAdminChartReducer,
	totalEarningByMonthAdminReducer,
	totalEarningByMonthAdminChartReducer
} from './reducers/reportReducers';
import { allNotificationsReducer, sendNotificationReducer } from './reducers/notificationReducers';
import {
	assignTemplateExrciseReducer,
	createTemplateExrciseReducer,
	createTemplateReducer,
	deleteExerciseTemplateReducer,
	deleteTemplateReducer,
	getAllEnableTemplateReducer,
	getAllTemplateReducer,
	getMuscleTypeReducer,
	getTemplateExerciseByUserReducer,
	getTemplateExerciseMuscleTypeReducer,
	templateStatusReducer,

	createNutritionTemplateReducer,
	getAllEnableNutritionTemplateReducer,
	getAllNutritionTemplateReducer,
	deleteNutritionTemplateReducer,
	nutritionTemplateStatusReducer,
	createRoomReducer,
	getAllWorkoutReducer,
	getAllRoomReducer,
	updateRoomReducer,
	deleteRoomReducer,
	createWorkoutReducer,
	deleteWorkoutReducer,
	updateWorkoutReducer
} from './reducers/templateReducers';
import {
	changeStatusResourceReducer,
	changeStatusResourceTypeReducer,
	createNewResourceReducer,
	createNewResourceTypeReducer,
	createResourceBookingReducer,
	deleteResourceBookingReducer,
	deleteResourceReducer,
	deleteResourceTypeReducer,
	getAvailableResourcesReducer,
	getAvailableTimeForResourceBookingReducer,
	getEnabledResourceTypesListReducer,
	getResourceBookingListReducer,
	getResourceListReducer,
	getResourceTypeListReducer,
	updateBookedResourceReducer,
	updateResourceReducer,
	updateResourceTypeReducer
} from './reducers/resourcesReducer';

const reducer = combineReducers({
	userLogin: userLoginReducer,
	userList: getUserListReducer,
	createUser: createNewUserReducer,
	deleteUser: deleteUserReducer,
	changeStatusUser: changeStatusUserReducer,
	getUserInfo: getUserByTokenReducer,
	changePasswordUser: changePasswordUserReducer,
	changeAdmin: changeAdminUserReducer,
	changeAppMenuSetting: changeAppMenuSettingReducer,
	getAppMenuSetting: getAppMenuSettingReducer,

	gymList: getGymListReducer,
	gymEnabledList: getAllEnabledGymsReducer,
	createGym: createNewGymReducer,
	deleteGym: deleteGymReducer,
	changeStatusGym: changeStatusGymReducer,

	// Resources Reduces
	createResource: createNewResourceReducer,
	deleteResource: deleteResourceReducer,
	changeStatusResource: changeStatusResourceReducer,
	resourceList: getResourceListReducer,
	updateResource: updateResourceReducer,

	// Resource Types Reducers
	enabledResourceTypesList: getEnabledResourceTypesListReducer,
	resourceTypeList: getResourceTypeListReducer,
	createResourceType: createNewResourceTypeReducer,
	deleteResourceType: deleteResourceTypeReducer,
	changeStatusResourceType: changeStatusResourceTypeReducer,
	updateResourceType: updateResourceTypeReducer,

	// Resource Bookings
	resourceBookingList: getResourceBookingListReducer,
	deleteResourceBooking: deleteResourceBookingReducer,
	updateBookedResource: updateBookedResourceReducer,
	availableResources: getAvailableResourcesReducer,
	createResourceBooking: createResourceBookingReducer,
	availableTimeForResourceBooking: getAvailableTimeForResourceBookingReducer,
	
	// Trainer reducers
	trainerList: getTrainerListReducer,
	trainerInsurance: trainerInsuranceReducer,
	createTrainer: createNewTrainerReducer,
	changeStatusTrainer: changeStatusTrainerReducer,
	deleteTrainer: deleteTrainerReducer,
	enabledTrainers: getEnabledTrainersReducer,

	attendanceList: getAttendanceListReducer,
	checkInOut: checkInOutReducer,
	deleteCheckin: deleteCheckinReducer,
	getTrainerSessions: getTrainerSessionsReducer,
	addTrainerSession: addTrainerSessionReducer,
	totalTrainerSession: totalTrainerSessionReducer,

	// Video reducers
	getAllVideoCategories: getAllVideoCategoriesReducer,
	createVideoCategory: createVideoCategoryReducer,
	videoCategoryStatus: videoCategoryStatusReducer,
	deleteVideoCategory: deleteVideoCategoryReducer,
	getAllVideos: getAllVideosReducer,
	getOneVideo: getOneVideoReducer,
	createVideo: createVideoReducer,
	changeVideoStatus: changeVideoStatusReducer,
	deleteVideo: deleteVideoReducer,

	// Product reducers
	getAllProductCategories: getAllProductCategoriesReducer,
	createProductCategory: createProductCategoryReducer,
	productCategoryStatus: productCategoryStatusReducer,
	deleteProductCategory: deleteProductCategoryReducer,
	getAllProducts: getAllProductsReducer,
	getOneProduct: getOneProductReducer,
	createProduct: createProductReducer,
	changeProductStatus: changeProductStatusReducer,
	deleteProduct: deleteProductReducer,
	addProductStock: addProductStockReducer,
	orderList: getProductOrderHistoryReducer,
	changeOrderStatus: changeOrderStatusReducer,

	//Gym staff reducers
	gymStaffList: getGymStaffListReducer,
	createGymStaff: createNewGymStaffReducer,
	deleteGymStaff: deleteGymStaffReducer,
	changeStatusGymStaff: changeStatusGymStaffReducer,

	//Company reducers
	companyList: getAllCompanyReducer,
	enableComanyList: getAllEnableCompanyReducer,
	createCompany: createCompanyReducer,
	changeCompanyStatus: changeCompanyStatusReducer,
	deleteCompany: deleteCompanyReducer,
	enableComanyBycharList: getAllEnableCompanyBycharReducer,

	//agreement forms
	getFormByWaierLiability: getFormByWaierLiabilityReducer,
	createFormByIndependentAgreement: createFormByIndependentAgreementReducer,
	getFormByIndependentAgreement: getFormByIndependentAgreementReducer,

	//App versions
	appVersionList: getAllAppVersionReducer,
	createAppVersion: createAppVersionReducer,
	changeAppVersionStatus: changeAppVersionStatusReducer,
	deleteAppVersion: deleteAppVersionReducer,

	//Reports Get Session Report by month
	sessionReport: getSessionReportReducers,
	sessionReportByMonth: getSessionReportByMonthReducers,
	earningReportByMonth: getEarningReportByMonthReducers,
	totalSalesReportByMonth: getTotalSalesReportByMonthReducers,
	generalCountReport: getGeneralCountReducers,
	sessionChartReport: getSessionReportChartReducers,
	getTrainerSessionByMonth: getTrainerSessionByMonthReducer,
	getTrainerSessionByMonthChart: getTrainerSessionByMonthChartReducer,
	getTrainerEarningByMonthChart: getTrainerEarningByMonthChartReducer,
	generalCountAdmin: generalCountAdminReducer,
	totalSessionByMonthAdmin: totalSessionByMonthAdminReducer,
	totalSessionByMonthAdminChart: totalSessionByMonthAdminChartReducer,
	totalEarningByMonthAdmin: totalEarningByMonthAdminReducer,
	totalEarningByMonthAdminChart: totalEarningByMonthAdminChartReducer,
	allNotifications: allNotificationsReducer,
	sendNotification: sendNotificationReducer,

	// Template reducers
	getAllTemplate: getAllTemplateReducer,
	createTemplate: createTemplateReducer,
	templateStatus: templateStatusReducer,
	deleteTemplate: deleteTemplateReducer,
	deleteExerciseTemplate: deleteExerciseTemplateReducer,
	getAllEnableTemplate: getAllEnableTemplateReducer,
	createExrciseTemplate: createTemplateExrciseReducer,
	assignExrciseTemplate: assignTemplateExrciseReducer,
	getTemplateExerciseMuscleType: getTemplateExerciseMuscleTypeReducer,
	getMuscleType: getMuscleTypeReducer,
	getTemplateExerciseByUser: getTemplateExerciseByUserReducer,


	getAllNutritionTemplate: getAllNutritionTemplateReducer,
	createNutritionTemplate: createNutritionTemplateReducer,
	templateNutritionStatus: nutritionTemplateStatusReducer,
	deleteNutritionTemplate: deleteNutritionTemplateReducer,
	getAllEnableNutritionTemplate: getAllEnableNutritionTemplateReducer,

	//workout reducers
	createRoom: createRoomReducer,
	updateRoom: updateRoomReducer,
	deleteRoom: deleteRoomReducer,
	getAllRooms: getAllRoomReducer,
	getAllWorkout: getAllWorkoutReducer,
	createWorkout: createWorkoutReducer,
	deleteWorkout: deleteWorkoutReducer,
	editWorkout: updateWorkoutReducer,

	logOutUser:logOutUserReducer 
});

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
	userLogin: { userInfo: userInfoFromStorage }
};
const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
