import React, { useCallback, useState } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import TextareaBox from '../../components/formInputs/TextareaBox';
import { validateOnlyChacters, validateOnlyNumber } from '../../utils/validators';
import TemplateDetailsStyles from './WorkoutDetailScreen.module.css';
import SelectBox from '../../components/formInputs/SelectBox';
import { useDispatch } from 'react-redux';
import { updateWorkoutById } from '../../redux/actions/workoutAction';

const EditWorkoutModal = ({ addModalActive, data, workoutData, setWorkoutData }) => {
	const {
		modalEditState,
		setModalEditState,
		selectedWorkoutId,
		handleUpdate
	} = data;

	const [editWorkoutData, setEditWorkoutData] = useState();
	const [workoutFieldsError, setWorkoutFieldsError] = useState({ value: '' })
	const [workoutTitleError, setWorkoutTitleError] = useState({ value: '' })

	const dispatch = useDispatch();

	const handleAddExercise = (roundIndex) => {
		const updatedWorkoutData = { ...editWorkoutData };
		updatedWorkoutData.rounds[roundIndex].list.push({ title: '', count: '' });
		setEditWorkoutData(updatedWorkoutData);

		const exerciseElements = document.querySelectorAll(`.exercise-${roundIndex}`);
		const lastExerciseElement = exerciseElements[exerciseElements.length - 1];
		if (lastExerciseElement) {
			lastExerciseElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}
	};

	const handleSubtitleChange = (index, value) => {
		const updatedWorkoutData = { ...editWorkoutData };
		updatedWorkoutData.rounds[index].roundTitle = value;
		setWorkoutData(updatedWorkoutData);
	};

	const handleExerciseChange = (subtitleIndex, exerciseIndex, property, value) => {
		const updatedWorkoutData = { ...editWorkoutData };
		updatedWorkoutData.rounds[subtitleIndex].list[exerciseIndex][property] = value;
		setWorkoutData(updatedWorkoutData);
	};

	React.useEffect(() => {
		setEditWorkoutData(workoutData)
	}, [workoutData]);

	const handleExerciseDelete = (roundIndex, exerciseIndex) => {

		const updatedWorkoutData = { ...workoutData };
		updatedWorkoutData.rounds[roundIndex].list.splice(exerciseIndex, 1);
		setEditWorkoutData(updatedWorkoutData);
	};

	const handleAddRound = useCallback(() => {
		const updatedWorkoutData = { ...editWorkoutData };
		updatedWorkoutData.rounds.push({
			roundTitle: '',
			list: [{
				title: '',
				description: '',
				count: 0,
			}]
		});
		setEditWorkoutData(updatedWorkoutData);
	}, [editWorkoutData]);

	const handleAddModalClose = useCallback(() => {
		setWorkoutTitleError({ ...workoutTitleError, value: '' })
		setWorkoutFieldsError({ ...workoutFieldsError, value: '' })
		setModalEditState(false);
	}, [setModalEditState, workoutFieldsError, workoutTitleError]);

	const handleSubmit = (e) => {
		e.preventDefault();
		// const isNumeric = (value) => !isNaN(value) && !isNaN(parseFloat(value));

		if (editWorkoutData.muscleType.trim() === '') {
			setWorkoutTitleError({ ...workoutTitleError, value: 'Please enter the workout title' })
			return;
		}
		else setWorkoutTitleError({ ...workoutTitleError, value: '' })
		for (const round of workoutData.rounds) {
			if (round.roundTitle.trim() === '') {
				setWorkoutFieldsError({ ...workoutFieldsError, value: 'Round title cannot be empty' });
				return;
			}
			else setWorkoutFieldsError({ ...workoutFieldsError, value: '' })
			for (const exercise of round.list) {
				if (exercise.title.trim() === '') {
					setWorkoutFieldsError({ ...workoutFieldsError, value: 'Excerise name cannot be empty' });
					return;
				}
				else setWorkoutFieldsError({ ...workoutFieldsError, value: '' })
			}
		}
		handleUpdate(selectedWorkoutId, {
			workOut: editWorkoutData
		});
		handleAddModalClose()
	}


	return (

		<Modal show={modalEditState} style={{ height: '58rem' }}>
			<ModalHeading heading='Add New Workout' onClose={handleAddModalClose} />
			<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
				<div>
					<div className={TemplateDetailsStyles.inputBoxTopContainer} style={{ padding: '1.2rem' }} >
						<InputBox
							label='Workout title'
							placeholder='Enter workout title'
							value={editWorkoutData?.muscleType}
							style={{ padding: '0rem 0rem', width: '24rem' }}
							inputStyles={{ marginBottom: '0rem' }}
							onChange={(e) => setWorkoutData({ ...editWorkoutData, muscleType: e.target.value })}
							errorMessage={workoutTitleError.value}
						/>
					</div>
				</div>
				<div className={TemplateDetailsStyles.roundInfoContainer}>
					<span>Rounds</span>
					{/* {subtitleIndex === editWorkoutData.subtitles.length - 1 && ( */}
					<button type='button' onClick={handleAddRound} className={TemplateDetailsStyles.custom_button1}>Add Round +</button>
					{/* )} */}
				</div>
				<div>
					<div
						className={TemplateDetailsStyles.inputBoxContainers}
						style={workoutFieldsError.value !== '' ? { height: '24rem' } : { height: '27rem' }}>
						{editWorkoutData?.rounds?.map((round, roundIndex) => (
							<div key={roundIndex}>
								<div className={TemplateDetailsStyles.inputBoxContainer} style={{ padding: '1.2rem' }}>
									<InputBox
										label='Round'
										placeholder='eg, 2 Rounds'
										style={{ padding: '0rem 0rem' }}
										inputStyles={{ marginBottom: '0rem' }}
										value={round.roundTitle}
										onChange={(e) => handleSubtitleChange(roundIndex, e.target.value)}
									// errorMessage={'Please enter number of rounds'}

									/>
									<div className={TemplateDetailsStyles.info_container}>
										<span className={TemplateDetailsStyles.excersie_name}>Exercise name</span>
										<span className={TemplateDetailsStyles.excersie_name}>Repetitions</span>
									</div>
									{round.list.map((exercise, exerciseIndex) => (
										<div key={exerciseIndex} >
											<div className={TemplateDetailsStyles.inputBoxes} style={{ marginRight: roundIndex > 0 ? '2rem' : '0rem' }}>
												<InputBox
													inputStyles={{ marginBottom: '0rem' }}
													placeholder='Enter excersie name'
													value={exercise.title}
													onChange={(e) => handleExerciseChange(roundIndex, exerciseIndex, 'title', e.target.value)}
													style={{ width: '28.5rem', padding: '0rem 0rem' }}
												// errorMessage={round.repsError}
												/>
												<InputBox
													placeholder='eg, 15'
													inputStyles={{ marginBottom: '0rem' }}
													value={exercise.count}
													onChange={(e) => handleExerciseChange(roundIndex, exerciseIndex, 'count', e.target.value)}
													// errorMessage={round.repsError}
													style={{ width: '5.6rem', padding: '0rem 0rem' }}

												/>
												{exerciseIndex > 0 && <button
													className={TemplateDetailsStyles.templateCardAction}
													onClick={() => handleExerciseDelete(roundIndex, exerciseIndex)}>
													<svg className={TemplateDetailsStyles.templateCardActionIcon}>
														<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
													</svg>
												</button>}
											</div>
											{exerciseIndex === round.list.length - 1 && (
												<button onClick={() => handleAddExercise(roundIndex)} className={TemplateDetailsStyles.custom_button}>Add Exercise +</button>
											)}
										</div>
									))}
								</div>
							</div>
						))}
					</div>
					{workoutFieldsError.value !== '' ? <p style={{ color: 'red', marginBottom: '2rem', fontSize: '1.2rem' }}>**{workoutFieldsError.value}</p> : null}
				</div>
				<div className={TemplateDetailsStyles.saveButton}>
					<ModalButton label={'Update Workout'} icon={'edit'} onClick={handleSubmit} />
				</div>
			</ModalForm>
		</Modal>
	);
};

export default EditWorkoutModal;

