import {
	GYM_REQUEST,
	GYM_SUCCESS,
	GYM_FAIL,
	GYM_RESET,
	CREATE_NEW_GYM_REQUEST,
	CREATE_NEW_GYM_SUCCESS,
	CREATE_NEW_GYM_FAIL,
	CREATE_NEW_GYM_RESET,
	UPDATE_GYM_REQUEST,
	UPDATE_GYM_RESET,
	UPDATE_GYM_FAIL,
	UPDATE_GYM_SUCCESS,
	DELETE_GYM_FAIL,
	DELETE_GYM_RESET,
	DELETE_GYM_REQUEST,
	DELETE_GYM_SUCCESS,
	CHANGE_STATUS_GYM_REQUEST,
	CHANGE_STATUS_GYM_SUCCESS,
	CHANGE_STATUS_GYM_FAIL,
	CHANGE_STATUS_GYM_RESET,
	GET_ALL_ENABLE_GYM_REQUEST,
	GET_ALL_ENABLE_GYM_FAIL,
	GET_ALL_ENABLE_GYM_RESET,
	GET_ALL_ENABLE_GYM_SUCCESS,
	GET_MENU_SETTINGS_REQUEST,
	GET_MENU_SETTINGS_SUCCESS,
	GET_MENU_SETTINGS_FAIL,
	GET_MENU_SETTINGS_RESET,
	CHANGE_MENU_SETTINGS_REQUEST,
	CHANGE_MENU_SETTINGS_SUCCESS,
	CHANGE_MENU_SETTINGS_FAIL,
	CHANGE_MENU_SETTINGS_RESET
} from '../constants/gymConstants';

export const getGymListReducer = (state = {}, action) => {
	switch (action.type) {
		case GYM_REQUEST:
			return { loading: true };

		case GYM_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GYM_FAIL:
			return { loading: false, error: action.payload };

		case GYM_RESET:
			return {};

		default:
			return state;
	}
};

export const createNewGymReducer = (state = {}, action) => {
	switch (action.type) {
		case CREATE_NEW_GYM_REQUEST:
			return { loading: true };

		case CREATE_NEW_GYM_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CREATE_NEW_GYM_FAIL:
			return { loading: false, error: action.payload };

		case CREATE_NEW_GYM_RESET:
			return {};

		default:
			return state;
	}
};

export const updateGymReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_GYM_REQUEST:
			return { loading: true };

		case UPDATE_GYM_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_GYM_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_GYM_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteGymReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_GYM_REQUEST:
			return { loading: true };

		case DELETE_GYM_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case DELETE_GYM_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_GYM_RESET:
			return {};

		default:
			return state;
	}
};

export const changeStatusGymReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_STATUS_GYM_REQUEST:
			return { loading: true };

		case CHANGE_STATUS_GYM_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_STATUS_GYM_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_STATUS_GYM_RESET:
			return {};

		default:
			return state;
	}
};

export const getAllEnabledGymsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_ENABLE_GYM_REQUEST:
			return { loading: true };

		case GET_ALL_ENABLE_GYM_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_ALL_ENABLE_GYM_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_ENABLE_GYM_RESET:
			return {};

		default:
			return state;
	}
};

export const getAppMenuSettingReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_MENU_SETTINGS_REQUEST:
			return { loading: true };

		case GET_MENU_SETTINGS_SUCCESS:
			return { settings: action.payload, loading: false };

		case GET_MENU_SETTINGS_FAIL:
			return { loading: false, error: action.payload };

		case GET_MENU_SETTINGS_RESET:
			return {};

		default:
			return state;
	}
};

export const changeAppMenuSettingReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_MENU_SETTINGS_REQUEST:
			return { loading: true };

		case CHANGE_MENU_SETTINGS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_MENU_SETTINGS_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_MENU_SETTINGS_RESET:
			return {};

		default:
			return state;
	}
};
