import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import RadioButtons from '../../components/formInputs/RadioButtons/RadioButtons';
import SelectBox from '../../components/formInputs/SelectBox';
import AddSkeleton from '../../components/Skeletons/AddSkeleton';
import { inputPhoneMasking } from '../../utils/phonemask';

const CreateUser = ({ data }) => {
	const {
		showAddModal,
		handleAddModalClose,
		handleSubmit,
		name,
		email,
		phone,
		gender,
		setGender,
		setGenderError,
		genderError,
		dob,
		dobError,
		setDOB,
		setDOBError,
		setAge,
		trainer,
		setTrainer,
		setTrainerError,
		trainerIdError,
		setName,
		setEmail,
		setPhone,
		nameError,
		phoneError,
		emailError,
		setNameError,
		setPhoneError,
		setEmailError,
		trainerList,
		selectUpdateModelUser
	} = data;

	const createUserState = useSelector((state) => state.createUser);

	const onChangeContentType = (e) => {
		setGenderError('');
		setGender(e.target.value);
	};

	const getAge = (birthDateString) => {
		var today = new Date();
		var birthDate = new Date(birthDateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		console.log(age, birthDate, birthDateString);
		return age;
	};

	return (
		<Modal show={showAddModal}>
			<ModalHeading
				heading={selectUpdateModelUser._id ? 'Update User' : 'Add New User'}
				onClose={handleAddModalClose}
			/>
			{createUserState && createUserState.loading ? (
				<AddSkeleton rows={2} textBoxes={3} />
			) : (
				<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
					<InputsSection>
						<InputBox
							label='User Name'
							icon='user'
							placeholder='eg, John Doe'
							value={name}
							onChange={(event) => {
								setName(event.target.value);
								setNameError('');
							}}
							errorMessage={nameError}
						/>
						<InputBox
							label='Email'
							icon='email'
							placeholder='eg, johndoe@example.com'
							value={email}
							onChange={(event) => {
								setEmail(event.target.value);
								setEmailError('');
							}}
							errorMessage={emailError}
						/>
						<InputBox
							label='Phone'
							icon='phone'
							placeholder='eg, 123 456 7890'
							value={phone}
							onChange={(event) => {
								setPhone(inputPhoneMasking(event.target.value));
								setPhoneError('');
							}}
							errorMessage={phoneError}
						/>
					</InputsSection>

					<InputsSection>
						<RadioButtons
							label='Gender'
							placeholder='Gender'
							radioButtons={[
								{
									label: 'Male',
									value: 'male'
								},
								{
									label: 'Female',
									value: 'female'
								}
							]}
							checked={gender}
							onChange={onChangeContentType}
							error={genderError}
						/>

						<InputBox
							label='Date of Birth'
							icon='birthday-cake'
							placeholder='Age'
							value={dob}
							type='date'
							onChange={(event) => {
								setAge(getAge(event.target.value));
								setDOB(event.target.value);
								setDOBError('');
							}}
							errorMessage={dobError}
						/>
						{/* <SelectBox
							label='Gym Name'
							icon='gym'
							name='gym-name'
							value={gym_name}
							onChange={(e) => setGymName(e.target.value)}>
							<option value=''>Select Gym</option>
							{gymList &&
								gymList.data &&
								gymList.data.map((gym) => (
									<option value={gym._id} key={gym._id}>
										{gym.name}
									</option>
								))}
						</SelectBox> */}

						<SelectBox
							value={trainer}
							onChange={(e) => {
								setTrainer(e.target.value);
								setTrainerError('');
							}}
							errorMessage={trainerIdError}
							label='Select Trainer'
							icon='trainer'
							name='trainer-name'>
							<option className='optionBox' value=''>
								Select Trainer
							</option>
							{trainerList &&
								trainerList.userInfo &&
								trainerList.userInfo.status &&
								trainerList.userInfo.data.result.length > 0 &&
								trainerList.userInfo.data.result.map((trainer) => (
									<option value={trainer._id} key={trainer._id}>
										{trainer.name}
									</option>
								))}
						</SelectBox>
					</InputsSection>

					<ModalButton
						label={selectUpdateModelUser._id ? 'Update User' : 'Add New'}
						icon={selectUpdateModelUser._id ? 'edit' : 'plus'}
						onClick={handleSubmit}
					/>
				</ModalForm>
			)}
		</Modal>
	);
};

export default CreateUser;
