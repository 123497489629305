import {
	GYM_STAFF_REQUEST,
	GYM_STAFF_SUCCESS,
	GYM_STAFF_FAIL,
	GYM_STAFF_RESET,

	CREATE_NEW_GYM_STAFF_REQUEST,
	CREATE_NEW_GYM_STAFF_SUCCESS,
	CREATE_NEW_GYM_STAFF_FAIL,
	CREATE_NEW_GYM_STAFF_RESET,

	UPDATE_GYM_STAFF_REQUEST,
	UPDATE_GYM_STAFF_RESET,
	UPDATE_GYM_STAFF_FAIL,
	UPDATE_GYM_STAFF_SUCCESS,

	DELETE_GYM_STAFF_FAIL,
	DELETE_GYM_STAFF_RESET,
	DELETE_GYM_STAFF_REQUEST,
	DELETE_GYM_STAFF_SUCCESS,

	CHANGE_STATUS_GYM_STAFF_REQUEST,
	CHANGE_STATUS_GYM_STAFF_SUCCESS,
	CHANGE_STATUS_GYM_STAFF_FAIL,
	CHANGE_STATUS_GYM_STAFF_RESET
} from '../constants/gymStaffConstants';


export const getGymStaffListReducer = (state = {}, action) => {
	switch (action.type) {
		case GYM_STAFF_REQUEST:
			return { loading: true };

		case GYM_STAFF_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GYM_STAFF_FAIL:
			return { loading: false, error: action.payload };

		case GYM_STAFF_RESET:
			return {};

		default:
			return state;
	}
};



export const createNewGymStaffReducer = (state = {}, action) => {
	switch (action.type) {
		case CREATE_NEW_GYM_STAFF_REQUEST:
			return { loading: true };

		case CREATE_NEW_GYM_STAFF_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CREATE_NEW_GYM_STAFF_FAIL:
			return { loading: false, error: action.payload };

		case CREATE_NEW_GYM_STAFF_RESET:
			return {};

		default:
			return state;
	}
};


export const updateGymStaffReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_GYM_STAFF_REQUEST:
			return { loading: true };

		case UPDATE_GYM_STAFF_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_GYM_STAFF_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_GYM_STAFF_RESET:
			return {};

		default:
			return state;
	}
};




export const deleteGymStaffReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_GYM_STAFF_REQUEST:
			return { loading: true };

		case DELETE_GYM_STAFF_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case DELETE_GYM_STAFF_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_GYM_STAFF_RESET:
			return {};

		default:
			return state;
	}
};


export const changeStatusGymStaffReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_STATUS_GYM_STAFF_REQUEST:
			return { loading: true };

		case CHANGE_STATUS_GYM_STAFF_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_STATUS_GYM_STAFF_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_STATUS_GYM_STAFF_RESET:
			return {};

		default:
			return state;
	}
};


