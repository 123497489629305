import React, { useState, useEffect } from 'react';
import InputBox from '../../components/formInputs/InputBox';
import TextareaBox from '../../components/formInputs/TextareaBox';
import TemplateAssignStyles from './TemplateAssign.module.css';

const RoundCard = ({ data }) => {
	const {
		exerciseData,
		mainIndex,
		exercises,
		indexType,
		setExercises
	} = data;

	let handleChange = (e, item) => {
		let newFormValues = [...exercises];
		newFormValues[indexType].exercises[mainIndex][e.target.name] = e.target.value;
		setExercises(newFormValues);
		item[e.target.name] = e.target.value;
	}


	let handleExerciseChange = (i, e, item) => {
		let newFormValues = [...exercises];
		newFormValues[indexType].exercises[mainIndex].exercise[i][e.target.name] = e.target.value;
		setExercises(newFormValues);
		item[e.target.name] = e.target.value;
	}

	return (
		<div className={TemplateAssignStyles.roundCard}>
			<div className={TemplateAssignStyles.roundCardHead}>
				<h4 className={TemplateAssignStyles.roundCardHeading}>Round {mainIndex + 1}</h4>
				{/* <div className='table__iconBox'>
					<button
						className='table__button'
						onClick={() => {
							etSelectUpdateModelUser(d);
							handleEditModalUser(d);
						}}>
						<svg className='table__button--icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
						</svg>
						<span>Edit Assignment Template</span>
					</button>
					<button
						className='table__button table__button--delete'
						onClick={() => {
							setSelectUpdateModelUser(d);
							setModalDeleteState(true);
						}}>
						<svg className='table__button--icon-red'>
							<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
						</svg>
						<span>Delete Assignment Template</span>
					</button>
				</div> */}
			</div>

			{
				exerciseData &&
				exerciseData.exercise &&
				exerciseData.exercise.length > 0 &&
				exerciseData.exercise.map((item, i) => (
					<div className='flex' key={i}>
						<InputBox
							id={'exerciseName'}
							placeholder='Enter exercise name'
							style={{ width: '30rem' }}
							value={item.exerciseName}
							onChange={e => handleExerciseChange(i, e, exerciseData)}
						/>
						<InputBox
							id={'reps'}
							placeholder='eg, 15'
							style={{ width: '10rem' }}
							value={item.reps}
							onChange={e => handleExerciseChange(i, e, exerciseData)}
						/>
					</div>
				))}

			{
				exerciseData &&
				<div className='flex' style={{ marginTop: '1rem' }}>
					<TextareaBox
						id='repType'
						label='Rep Type'
						placeholder='Rep Type'
						style={{ width: '20rem' }}
						value={exerciseData.repType}
						onChange={e => handleChange(e, exerciseData)}
					/>
					<TextareaBox
						id='nextRoundText'
						label='Next Round Text'
						style={{ width: '20rem' }}
						placeholder='Next Round Text'
						value={exerciseData.nextRoundText}
						onChange={e => handleChange(e, exerciseData)}
					/>
				</div>
			}
		</div>
	);
};

export default RoundCard;












