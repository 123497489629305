export const CREATE_NEW_RESOURCE_REQUEST = 'CREATE_NEW_RESOURCE_REQUEST';
export const CREATE_NEW_RESOURCE_SUCCESS = 'CREATE_NEW_RESOURCE_SUCCESS';
export const CREATE_NEW_RESOURCE_FAIL = 'CREATE_NEW_RESOURCE_FAIL';
export const CREATE_NEW_RESOURCE_RESET = 'CREATE_NEW_RESOURCE_RESET';

export const CREATE_NEW_RESOURCE_TYPE_REQUEST = 'CREATE_NEW_RESOURCE_TYPE_REQUEST';
export const CREATE_NEW_RESOURCE_TYPE_SUCCESS = 'CREATE_NEW_RESOURCE_TYPE_SUCCESS';
export const CREATE_NEW_RESOURCE_TYPE_FAIL = 'CREATE_NEW_RESOURCE_TYPE_FAIL';
export const CREATE_NEW_RESOURCE_TYPE_RESET = 'CREATE_NEW_RESOURCE_TYPE_RESET';

export const GET_ALL_RESOURCE_REQUEST = 'GET_ALL_RESOURCE_REQUEST';
export const GET_ALL_RESOURCE_SUCCESS = 'GET_ALL_RESOURCE_SUCCESS';
export const GET_ALL_RESOURCE_FAIL = 'GET_ALL_RESOURCE_FAIL';
export const GET_ALL_RESOURCE_RESET = 'GET_ALL_RESOURCE_RESET';

export const GET_ALL_RESOURCE_BOOKINGS_REQUEST = 'GET_ALL_RESOURCE_BOOKINGS_REQUEST';
export const GET_ALL_RESOURCE_BOOKINGS_SUCCESS = 'GET_ALL_RESOURCE_BOOKINGS_SUCCESS';
export const GET_ALL_RESOURCE_BOOKINGS_FAIL = 'GET_ALL_RESOURCE_BOOKINGS_FAIL';
export const GET_ALL_RESOURCE_BOOKINGS_RESET = 'GET_ALL_RESOURCE_BOOKINGS_RESET';

export const GET_AVAILABLE_RESOURCE_REQUEST = 'GET_AVAILABLE_RESOURCE_REQUEST';
export const GET_AVAILABLE_RESOURCE_SUCCESS = 'GET_AVAILABLE_RESOURCE_SUCCESS';
export const GET_AVAILABLE_RESOURCE_FAIL = 'GET_AVAILABLE_RESOURCE_FAIL';
export const GET_AVAILABLE_RESOURCE_RESET = 'GET_AVAILABLE_RESOURCE_RESET';

export const GET_TIME_RESOURCE_REQUEST = 'GET_TIME_RESOURCE_REQUEST';
export const GET_TIME_RESOURCE_SUCCESS = 'GET_TIME_RESOURCE_SUCCESS';
export const GET_TIME_RESOURCE_FAIL = 'GET_TIME_RESOURCE_FAIL';
export const GET_TIME_RESOURCE_RESET = 'GET_TIME_RESOURCE_RESET';

export const CREATE_RESOURCE_BOOKING_REQUEST = 'CREATE_RESOURCE_BOOKING_REQUEST';
export const CREATE_RESOURCE_BOOKING_SUCCESS = 'CREATE_RESOURCE_BOOKING_SUCCESS';
export const CREATE_RESOURCE_BOOKING_FAIL = 'CREATE_RESOURCE_BOOKING_FAIL';
export const CREATE_RESOURCE_BOOKING_RESET = 'CREATE_RESOURCE_BOOKING_RESET';

export const CANCEL_RESOURCE_BOOKING_REQUEST = 'CANCEL_RESOURCE_BOOKING_REQUEST';
export const CANCEL_RESOURCE_BOOKING_SUCCESS = 'CANCEL_RESOURCE_BOOKING_SUCCESS';
export const CANCEL_RESOURCE_BOOKING_FAIL = 'CANCEL_RESOURCE_BOOKING_FAIL';
export const CANCEL_RESOURCE_BOOKING_RESET = 'CANCEL_RESOURCE_BOOKING_RESET';

export const GET_ALL_RESOURCE_TYPE_REQUEST = 'GET_ALL_RESOURCE_TYPE_REQUEST';
export const GET_ALL_RESOURCE_TYPE_SUCCESS = 'GET_ALL_RESOURCE_TYPE_SUCCESS';
export const GET_ALL_RESOURCE_TYPE_FAIL = 'GET_ALL_RESOURCE_TYPE_FAIL';
export const GET_ALL_RESOURCE_TYPE_RESET = 'GET_ALL_RESOURCE_TYPE_RESET';

export const GET_ENABLED_RESOURCE_TYPES_REQUEST = 'GET_ENABLED_RESOURCE_TYPES_REQUEST';
export const GET_ENABLED_RESOURCE_TYPES_SUCCESS = 'GET_ENABLED_RESOURCE_TYPES_SUCCESS';
export const GET_ENABLED_RESOURCE_TYPES_FAIL = 'GET_ENABLED_RESOURCE_TYPES_FAIL';
export const GET_ENABLED_RESOURCE_TYPES_RESET = 'GET_ENABLED_RESOURCE_TYPES_RESET';

export const UPDATE_RESOURCE_REQUEST = 'UPDATE_RESOURCE_REQUEST';
export const UPDATE_RESOURCE_SUCCESS = 'UPDATE_RESOURCE_SUCCESS';
export const UPDATE_RESOURCE_FAIL = 'UPDATE_RESOURCE_FAIL';
export const UPDATE_RESOURCE_RESET = 'UPDATE_RESOURCE_RESET';

export const UPDATE_BOOKED_RESOURCE_REQUEST = 'UPDATE_BOOKED_RESOURCE_REQUEST';
export const UPDATE_BOOKED_RESOURCE_SUCCESS = 'UPDATE_BOOKED_RESOURCE_SUCCESS';
export const UPDATE_BOOKED_RESOURCE_FAIL = 'UPDATE_BOOKED_RESOURCE_FAIL';
export const UPDATE_BOOKED_RESOURCE_RESET = 'UPDATE_BOOKED_RESOURCE_RESET';

export const UPDATE_RESOURCE_TYPE_REQUEST = 'UPDATE_RESOURCE_TYPE_REQUEST';
export const UPDATE_RESOURCE_TYPE_SUCCESS = 'UPDATE_RESOURCE_TYPE_SUCCESS';
export const UPDATE_RESOURCE_TYPE_FAIL = 'UPDATE_RESOURCE_TYPE_FAIL';
export const UPDATE_RESOURCE_TYPE_RESET = 'UPDATE_RESOURCE_TYPE_RESET';

export const CHANGE_STATUS_RESOURCE_REQUEST = 'CHANGE_STATUS_RESOURCE_REQUEST';
export const CHANGE_STATUS_RESOURCE_SUCCESS = 'CHANGE_STATUS_RESOURCE_SUCCESS';
export const CHANGE_STATUS_RESOURCE_FAIL = 'CHANGE_STATUS_RESOURCE_FAIL';
export const CHANGE_STATUS_RESOURCE_RESET = 'CHANGE_STATUS_RESOURCE_RESET';

export const CHANGE_STATUS_RESOURCE_TYPE_REQUEST = 'CHANGE_STATUS_RESOURCE_TYPE_REQUEST';
export const CHANGE_STATUS_RESOURCE_TYPE_SUCCESS = 'CHANGE_STATUS_RESOURCE_TYPE_SUCCESS';
export const CHANGE_STATUS_RESOURCE_TYPE_FAIL = 'CHANGE_STATUS_RESOURCE_TYPE_FAIL';
export const CHANGE_STATUS_RESOURCE_TYPE_RESET = 'CHANGE_STATUS_RESOURCE_TYPE_RESET';

export const DELETE_RESOURCE_REQUEST = 'DELETE_RESOURCE_REQUEST';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_FAIL = 'DELETE_RESOURCE_FAIL';
export const DELETE_RESOURCE_RESET = 'DELETE_RESOURCE_RESET';

export const DELETE_RESOURCE_TYPE_REQUEST = 'DELETE_RESOURCE_TYPE_REQUEST';
export const DELETE_RESOURCE_TYPE_SUCCESS = 'DELETE_RESOURCE_TYPE_SUCCESS';
export const DELETE_RESOURCE_TYPE_FAIL = 'DELETE_RESOURCE_TYPE_FAIL';
export const DELETE_RESOURCE_TYPE_RESET = 'DELETE_RESOURCE_TYPE_RESET';
