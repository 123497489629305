import {
	TRAINER_REQUEST,
	TRAINER_SUCCESS,
	TRAINER_FAIL,
	TRAINER_RESET,
	GET_ENABLED_TRAINER_REQUEST,
	GET_ENABLED_TRAINER_SUCCESS,
	GET_ENABLED_TRAINER_FAIL,
	GET_ENABLED_TRAINER_RESET,
	CREATE_NEW_TRAINER_REQUEST,
	CREATE_NEW_TRAINER_SUCCESS,
	CREATE_NEW_TRAINER_FAIL,
	CREATE_NEW_TRAINER_RESET,
	UPDATE_TRAINER_REQUEST,
	UPDATE_TRAINER_RESET,
	UPDATE_TRAINER_FAIL,
	UPDATE_TRAINER_SUCCESS,
	DELETE_TRAINER_REQUEST,
	DELETE_TRAINER_SUCCESS,
	DELETE_TRAINER_FAIL,
	DELETE_TRAINER_RESET,
	CHANGE_STATUS_TRAINER_REQUEST,
	CHANGE_STATUS_TRAINER_SUCCESS,
	CHANGE_STATUS_TRAINER_FAIL,
	CHANGE_STATUS_TRAINER_RESET,
	TRAINER_INSURANCE_REQUEST,
	TRAINER_INSURANCE_SUCCESS,
	TRAINER_INSURANCE_FAIL,
	TRAINER_INSURANCE_RESET
} from '../constants/trainerConstants';

export const getTrainerListReducer = (state = {}, action) => {
	switch (action.type) {
		case TRAINER_REQUEST:
			return { loading: true };

		case TRAINER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case TRAINER_FAIL:
			return { loading: false, error: action.payload };

		case TRAINER_RESET:
			return {};

		default:
			return state;
	}
};

export const getEnabledTrainersReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ENABLED_TRAINER_REQUEST:
			return { loading: true };

		case GET_ENABLED_TRAINER_SUCCESS:
			return { trainers: action.payload, loading: false };

		case GET_ENABLED_TRAINER_FAIL:
			return { loading: false, error: action.payload };

		case GET_ENABLED_TRAINER_RESET:
			return { userInfo: {}, loading: false };

		default:
			return state;
	}
};

export const createNewTrainerReducer = (state = {}, action) => {
	switch (action.type) {
		case CREATE_NEW_TRAINER_REQUEST:
			return { loading: true };

		case CREATE_NEW_TRAINER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CREATE_NEW_TRAINER_FAIL:
			return { loading: false, error: action.payload };

		case CREATE_NEW_TRAINER_RESET:
			return {};

		default:
			return state;
	}
};

export const updateTrainerReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_TRAINER_REQUEST:
			return { loading: true };

		case UPDATE_TRAINER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_TRAINER_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_TRAINER_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteTrainerReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_TRAINER_REQUEST:
			return { loading: true };

		case DELETE_TRAINER_SUCCESS:
			return { userInfo: action.payload.userInfo, loading: false };

		case DELETE_TRAINER_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_TRAINER_RESET:
			return {};

		default:
			return state;
	}
};

export const changeStatusTrainerReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_STATUS_TRAINER_REQUEST:
			return { loading: true };

		case CHANGE_STATUS_TRAINER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_STATUS_TRAINER_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_STATUS_TRAINER_RESET:
			return {};

		default:
			return state;
	}
};

export const trainerInsuranceReducer = (state = {}, action) => {
	switch (action.type) {
		case TRAINER_INSURANCE_REQUEST:
			return { loading: true };

		case TRAINER_INSURANCE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case TRAINER_INSURANCE_FAIL:
			return { loading: false, error: action.payload };

		case TRAINER_INSURANCE_RESET:
			return {};

		default:
			return state;
	}
};
