import React from 'react';
import { Link } from 'react-router-dom';
import TemplateStyles from './WorkoutTemplateScreen.module.css';

const TemplateData = ({ props }) => {
	const {
		data,
		setModalDeleteState,
		setTemplateId,
		onEditTemplateHandler,
		enableTemplateHandler
	} = props;

	return (
		<div>
			<div className={TemplateStyles.templateData}>
				<Link to={`/workout/${data._id}/${data.roomName}`} className={TemplateStyles.template}>
					<div className={TemplateStyles.templateImage} style={{ backgroundColor: data.colorCode ? data.colorCode : '#ff9000' }}>
						<svg className={TemplateStyles.templateImageIcon}>
							<use xlinkHref={`/assets/sprite.svg#icon-room`} />
						</svg>
					</div>
					<h1 className={TemplateStyles.templateName}>{data.roomName}</h1>
					{/* <svg className={TemplateStyles.templateButtonIcon} style={{ fill: data.templateColor }}>
						<use xlinkHref={`/assets/sprite.svg#icon-arrow_right`} />
					</svg> */}
				</Link>
				<div className={TemplateStyles.templateCardActions} style={{justifyContent: 'space-between', gap:'1rem'}}>
				<Link to={`/workout-display/${data._id}/${data.roomName}`} className={TemplateStyles.template1}>
				<span className={TemplateStyles.viewButton} style={{ color: data.colorCode ? data.colorCode : '#ff9000' }}>View</span>
				</Link>
					<div style={{display: 'flex', gap:'1rem'}}>
						<button
							className={TemplateStyles.templateCardAction}
							style={{justifyContent: 'flex-end'}}
							onClick={() => {
								setTemplateId('anil');
								onEditTemplateHandler(data);
							}}>
							<svg className={TemplateStyles.templateCardActionIcon}>
								<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
							</svg>
						</button>
					<div className={TemplateStyles.templateCardActionBtn}>
					<button
							className={TemplateStyles.templateCardAction}
							onClick={() => {
								setTemplateId(data._id);
								setModalDeleteState(true);
							}}>
							<svg className={TemplateStyles.templateCardActionIcon}>
								<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
							</svg>
						</button>
						{/* <button
							className={TemplateStyles.templateCardAction}
							onClick={() => {
								setTemplateId(data._id);
								setModalDeleteState(true);
							}}>
							<svg className={TemplateStyles.templateCardActionIcon}>
								<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
							</svg>
						</button> */}

					</div>
					</div>
				</div>
			</div>
			<div className={TemplateStyles.templateBar} style={{ backgroundColor: data.colorCode ? data.colorCode : '#ff9000' }} />
		</div>
	);
};

export default TemplateData;
