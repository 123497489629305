import axios from 'axios';
import {
	ATTENDANCE_LIST_FAIL,
	ATTENDANCE_LIST_REQUEST,
	ATTENDANCE_LIST_SUCCESS,
	CHECKINOUT_REQUEST,
	CHECKINOUT_SUCCESS,
	CHECKINOUT_FAIL,
	DELETE_CHECKIN_REQUEST,
	DELETE_CHECKIN_SUCCESS,
	DELETE_CHECKIN_FAIL,
	GET_TRAINER_SESSIONS_REQUEST,
	GET_TRAINER_SESSIONS_SUCCESS,
	GET_TRAINER_SESSIONS_FAIL,
	ADD_TRAINER_SESSION_REQUEST,
	ADD_TRAINER_SESSION_SUCCESS,
	ADD_TRAINER_SESSION_FAIL,
	TOTAL_TRAINER_SESSION_REQUEST,
	TOTAL_TRAINER_SESSION_SUCCESS,
	TOTAL_TRAINER_SESSION_FAIL
} from '../constants/attendanceConstants';
import { BASE_URL } from './ip';

const utcTimeOffset = new Date().getTimezoneOffset();

export const checkIAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHECKINOUT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/attendance/attendance?offset=${utcTimeOffset}`, formData, config);

		dispatch({
			type: CHECKINOUT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHECKINOUT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getAttendListAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ATTENDANCE_LIST_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			`${BASE_URL}/attendance/get-todays-attendance-by-gym?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&offset=${utcTimeOffset}`,
			config
		);

		dispatch({
			type: ATTENDANCE_LIST_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: ATTENDANCE_LIST_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteCheckinAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_CHECKIN_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.delete(`${BASE_URL}/attendance/delete-attendance?id=${id}`, config);

		dispatch({
			type: DELETE_CHECKIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_CHECKIN_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getTrainerSessionsAction = (month, year, trainerId) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_TRAINER_SESSIONS_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		let uri;

		if (trainerId) {
			uri = `${BASE_URL}/session/get-session-by-trainer?month=${month}&year=${year}&offset=-330&id=${trainerId}`;
		} else {
			uri = `${BASE_URL}/session/get-session-by-trainer?month=${month}&year=${year}&offset=-330`;
		}

		const { data } = await axios.get(uri, config);

		dispatch({
			type: GET_TRAINER_SESSIONS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_TRAINER_SESSIONS_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const addTrainerSessionAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ADD_TRAINER_SESSION_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/session/add-session?offset=-330`, formData, config);

		dispatch({
			type: ADD_TRAINER_SESSION_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: ADD_TRAINER_SESSION_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const totalTrainerSessionAction = (month, year) => async (dispatch, getState) => {
	try {
		dispatch({ type: TOTAL_TRAINER_SESSION_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(
			`${BASE_URL}/session/get-total-session-by-trainer?month=${month}&year=${year}&offset=-330`,
			config
		);

		dispatch({
			type: TOTAL_TRAINER_SESSION_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: TOTAL_TRAINER_SESSION_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
