import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputSections from '../../components/Modal/InputSections';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import SelectBox from '../../components/formInputs/SelectBox';
import UploadFile from '../../components/Modal/UploadFile';
import TextareaBox from '../../components/formInputs/TextareaBox';
import AddSkeleton from '../../components/Skeletons/AddSkeleton';
// import VideoConverter from 'convert-video';

import { getAllVideoCategoriesAction } from '../../redux/actions/videoActions';
import { getTrainerListAction } from '../../redux/actions/trainerActions';

const CreateVideo = ({ data }) => {
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.getUserInfo);
	const getAllVideoCategories = useSelector((state) => state.getAllVideoCategories);
	const trainerList = useSelector((state) => state.trainerList);
	const createVideo = useSelector((state) => state.createVideo);

	const {
		addModalActive,
		handleAddModalClose,
		handleSubmit,

		videoTitle,
		setVideoTitle,
		videoDesc,
		setVideoDesc,
		videoCategory,
		setVideoCategory,
		videoTrainer,
		setVideoTrainer,
		videoFile,
		setVideoFile,

		videoTrainerError,
		setVideoTrainerError
		// videoPoster,
		// setVideoPoster,
	} = data;

	useEffect(
		() => {
			dispatch(getAllVideoCategoriesAction({ pageNumber: 1, pageSize: 1000, filter: '' }));
			dispatch(getTrainerListAction({ pageNumber: 1, pageSize: 1000, filter: '' }));

			// return () => {
			// 	dispatch({ type: GET_ALL_VIDEO_CATEGORY_RESET });
			// };
		},
		[dispatch]
	);


	// const convertVideo = async (input) => {
	// 	console.log(input, 'input',input.target.files[0]);
	// 	let sourceVideoFile = input.target.files[0];
	// 	let targetVideoFormat = 'mp4'
	// 	let convertedVideoDataObj = await VideoConverter.convert(sourceVideoFile, targetVideoFormat);
	// 	console.log(convertedVideoDataObj, 'convertedVideoDataObj');
	// 	setVideoFile({ video: convertedVideoDataObj, error: '' })
	// }

	return (
		<Modal show={addModalActive}>
			<ModalHeading heading='Upload New Video' onClose={handleAddModalClose} />
			{createVideo && createVideo.loading ? (
				<AddSkeleton rows={3} textBoxes={2} />
			) : (
				<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
					<InputsSection>
						<InputBox
							label='Video Title'
							icon='film'
							placeholder='eg, Cardio Exercise'
							value={videoTitle.value}
							onChange={(e) => setVideoTitle({ value: e.target.value, error: '' })}
							errorMessage={videoTitle.error}
						/>
						<TextareaBox
							rows={1}
							label='Video Description'
							icon='film'
							placeholder='eg, Video description'
							value={videoDesc.value}
							onChange={(e) => setVideoDesc({ value: e.target.value, error: '' })}
							errorMessage={videoDesc.error}
						/>
					</InputsSection>
					<InputsSection>
						<SelectBox
							value={videoCategory.value}
							onChange={(e) => setVideoCategory({ value: e.target.value, error: '' })}
							label='Video Category'
							icon='archive'
							name='video-category'
							errorMessage={videoCategory.error}>
							<option className='optionBox' value=''>
								Select Category
							</option>
							{getAllVideoCategories &&
								getAllVideoCategories.categories &&
								getAllVideoCategories.categories.data &&
								getAllVideoCategories.categories.data.result &&
								getAllVideoCategories.categories.data.result.map((res) => (
									<option value={res._id} key={res._id}>
										{res.categoryName}
									</option>
								))}
						</SelectBox>

						{userData &&
							userData.userInfo &&
							userData.userInfo.status &&
							userData.userInfo.data &&
							userData.userInfo.data.role !== 'trainer' && (
								<SelectBox
									value={videoTrainer}
									onChange={(e) => {
										setVideoTrainer(e.target.value);
										setVideoTrainerError('');
									}}
									label='Trainer Name'
									icon='trainer'
									name='trainer-name'
									errorMessage={videoTrainerError}>
									<option className='optionBox' value=''>
										Select Trainer
									</option>
									{trainerList &&
										trainerList.userInfo &&
										trainerList.userInfo.status &&
										trainerList.userInfo.data.result.length > 0 ? (
										trainerList.userInfo.data.result.map((res) => (
											<option value={res._id} key={res._id}>
												{res.name}
											</option>
										))
									) : (
										<option value=''>No trainers found</option>
									)}
								</SelectBox>
							)}
					</InputsSection>
					<InputSections>
						{/* <InputsSection>
						<UploadFile
							label='Upload Video Poster'
							icon='upload'
							accept='image/*'
							onChange={(e) => {
								e.target.files.length > 0 && setVideoPoster({ image: e.target.files[0] });
							}}
							errorMessage={videoPoster.error}
						/>
					</InputsSection> */}
						<InputsSection>
							<UploadFile
								label='Upload Video'
								icon='upload'
								accept='video/*'
								onChange={(e) => {
									// convertVideo(e);
									e.target.files.length > 0 && setVideoFile({ video: e.target.files[0], error: '' });
								}}
								errorMessage={videoFile.error}
							/>
						</InputsSection>
					</InputSections>

					<ModalButton label={'Add New'} icon={'plus'} onClick={handleSubmit} />
				</ModalForm>
			)}
		</Modal>
	);
};

export default CreateVideo;
