import React from 'react';
import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';
import { phoneMasking } from '../../utils/phonemask';
import { validatePhoneMaskingString } from '../../utils/validators';

const GymStaffData = ({ showFilter, staffList, setModalDeleteState, setSelectUpdateModel, setModalChangePasswordState, handleEditModalUser, onChangeHandler }) => {

	const getRoleLabelHandler = (role) => {
		switch (role) {
			case 'admin':
				return 'Admin'
			case 'gym':
				return 'Gym'
			case 'trainer':
				return 'Trainer'
			case 'user':
				return 'User'
			case 'gymadmin':
				return 'Gym Admin'
			case 'superadmin':
				return 'Super Admin'
			case 'company':
				return 'Company'
			default:
				return role
		}
	};

	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '65vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>Name</th>
						<th>Email</th>
						<th>Phone</th>
						<th className='text_wrap_desc'>Address</th>
						<th>Role</th>
						<th>Active</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{staffList.loading ? (
						<tr>
							<td colSpan='7' style={{ textAlign: 'center' }}>
								<Spinner />
							</td>
						</tr>
					) : staffList &&
						staffList.userInfo &&
						staffList.userInfo.status &&
						staffList.userInfo.data.result &&
						staffList.userInfo.data.result.length > 0 ? (
						staffList.userInfo.data.result.map((d, index) => (
							<tr key={d._id}>
								<td>{index + 1}</td>
								<td style={{ textAlign: 'left' }}> {d.name}</td>
								<td>{d.email}</td>
								<td>{validatePhoneMaskingString(d.phone) ? phoneMasking(d.phone) :d.phone}</td> 
								<td>{d.address}</td>
								<td>{getRoleLabelHandler(d.role)}</td>
								<td>
									<label className='switch'>
										<input id={d._id}
											checked={d.active}
											onChange={() => {
												onChangeHandler(d)
											}}

											type='checkbox'
											className='checkbox'
											name='active' />
										<span className='slider round' />
									</label>
								</td>
								<td>
									<div className='table__iconBox'>
										<button className='table__button' onClick={() => {
											setSelectUpdateModel(d);
											handleEditModalUser(d);
										}}>
											<svg className='table__button--icon'>
												<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
											</svg>
											<span>Edit Gym Staff User</span>
										</button>


										<button className='table__button table__button--changepwd' onClick={() => {
											setSelectUpdateModel(d);
											setModalChangePasswordState(true);
										}}>
											<svg className='table__button--icon-green'>
												<use xlinkHref={`/assets/sprite.svg#icon-key`} />
											</svg>
											<span>Change Password</span>
										</button>


										<button className='table__button table__button--delete' onClick={() => {
											setSelectUpdateModel(d);
											setModalDeleteState(true);
										}}>
											<svg className='table__button--icon-red'>
												<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
											</svg>
											<span>Delete Gym Staff User</span>
										</button>
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan='7' style={{ textAlign: 'center' }}>
								<NoData title='No Data Found!' subTitle='We could not find any gym staff user. Please add one' height='40vh' />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default GymStaffData;
