import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';
import { timeStringformatAMPM } from '../../utils/date';

const ResourceData = ({ data }) => {
	const {
		resourceList,
		onUpdateResource,
		setShowDeleteModal,
		showFilter,
		setSelectedId,
		changeResourceStatus,
		pageNumber,
		pageLimit
	} = data

	const getUserInfo = useSelector((state) => state.getUserInfo);

	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '65vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>
							Name
						</th>
						<th>Type</th>
						<th>Available From</th>
						<th>Available Till</th>
						<th>Frequency (in mins)</th>
						<th>Reservation Fees</th>
						<th>Active</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{resourceList.loading ? (
						<tr>
							<td colSpan='8' style={{ textAlign: 'center' }}>
								<Spinner />
							</td>
						</tr>
					) : resourceList &&
						resourceList.userInfo &&
						resourceList.userInfo.status &&
						resourceList.userInfo.data.result &&
						resourceList.userInfo.data.result.length > 0 ? (
						resourceList.userInfo.data.result.map((d, index) => (
							<tr key={d._id}>
								<td>{pageNumber > 1 ? ((pageLimit * (pageNumber - 1)) + index + 1) : index + 1}</td>
								<td style={{ textAlign: 'left', textTransform: 'capitalize' }}>{d.name}</td>
								<td>{d.type.name || 'NA'}</td>
								<td>{timeStringformatAMPM(d.availabilityFrom)}</td>
								<td>{timeStringformatAMPM(d.availabilityTo)}</td>
								<td>{d.frequency}</td>
								{d.reservationFees ?
									<td>$ {d.reservationFees}</td>
									: <td>$ 0</td>} 
									
								{getUserInfo &&
									getUserInfo.userInfo &&
									getUserInfo.userInfo.data &&
									(getUserInfo.userInfo.data.role === 'gym' ||
										getUserInfo.userInfo.data.role === 'gymadmin' ||
										getUserInfo.userInfo.data.role === 'superadmin') && (
										<td>
											<label className='switch'>
												<input
													id='active'
													checked={d.enable}
													onChange={() => {
														changeResourceStatus(d._id);
													}}
													type='checkbox'
													className='checkbox'
													name='active'
												/>
												<span className='slider round' />
											</label>
										</td>
									)}
								<td>
									<div className='table__iconBox'>
										{getUserInfo &&
											getUserInfo.userInfo &&
											getUserInfo.userInfo.data &&
											(getUserInfo.userInfo.data.role === 'gym' ||
												getUserInfo.userInfo.data.role === 'gymadmin' ||
												getUserInfo.userInfo.data.role === 'superadmin') && (
												<Fragment>
													<button
														className='table__button'
														onClick={() => {
															setSelectedId(d._id);
															onUpdateResource(d._id);
														}}>
														<svg className='table__button--icon'>
															<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
														</svg>
														<span>Edit Resource Details</span>
													</button>
													<button
														className='table__button table__button--delete'
														onClick={() => {
															setSelectedId(d._id)
															setShowDeleteModal(true);
														}}>
														<svg className='table__button--icon-red'>
															<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
														</svg>
														<span>Delete Resource</span>
													</button>
												</Fragment>
											)}
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan='8' style={{ textAlign: 'center' }}>
								<NoData title='No Data Found!' subTitle='We could not find any resource. Please add one' height='40vh' />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default ResourceData;
