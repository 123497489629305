export const GET_SESSION_REPORT_REQUEST = 'GET_SESSION_REPORT_REQUEST';
export const GET_SESSION_REPORT_SUCCESS = 'GET_SESSION_REPORT_SUCCESS';
export const GET_SESSION_REPORT_FAIL = 'GET_SESSION_REPORT_FAIL';
export const GET_SESSION_REPORT_RESET = 'GET_SESSION_REPORT_RESET';

export const GET_SESSION_BY_MONTH_REPORT_REQUEST = 'GET_SESSION_BY_MONTH_REPORT_REQUEST';
export const GET_SESSION_BY_MONTH_REPORT_SUCCESS = 'GET_SESSION_BY_MONTH_REPORT_SUCCESS';
export const GET_SESSION_BY_MONTH_REPORT_FAIL = 'GET_SESSION_BY_MONTH_REPORT_FAIL';
export const GET_SESSION_BY_MONTH_REPORT_RESET = 'GET_SESSION_BY_MONTH_REPORT_RESET';

export const GET_EARNING_BY_MONTH_REPORT_REQUEST = 'GET_EARNING_BY_MONTH_REPORT_REQUEST';
export const GET_EARNING_BY_MONTH_REPORT_SUCCESS = 'GET_EARNING_BY_MONTH_REPORT_SUCCESS';
export const GET_EARNING_BY_MONTH_REPORT_FAIL = 'GET_EARNING_BY_MONTH_REPORT_FAIL';
export const GET_EARNING_BY_MONTH_REPORT_RESET = 'GET_EARNING_BY_MONTH_REPORT_RESET';

export const GET_TOTAL_SALES_BY_MONTH_REPORT_REQUEST = 'GET_TOTAL_SALES_BY_MONTH_REPORT_REQUEST';
export const GET_TOTAL_SALES_BY_MONTH_REPORT_SUCCESS = 'GET_TOTAL_SALES_BY_MONTH_REPORT_SUCCESS';
export const GET_TOTAL_SALES_BY_MONTH_REPORT_FAIL = 'GET_TOTAL_SALES_BY_MONTH_REPORT_FAIL';
export const GET_TOTAL_SALES_BY_MONTH_REPORT_RESET = 'GET_TOTAL_SALES_BY_MONTH_REPORT_RESET';

export const GET_GENERAL_COUNT_REQUEST = 'GET_GENERAL_COUNT_REQUEST';
export const GET_GENERAL_COUNT_SUCCESS = 'GET_GENERAL_COUNT_SUCCESS';
export const GET_GENERAL_COUNT_FAIL = 'GET_GENERAL_COUNT_FAIL';
export const GET_GENERAL_COUNT_RESET = 'GET_GENERAL_COUNT_RESET';

export const GET_SESSION_DATA_REPORT_REQUEST = 'GET_SESSION_DATA_REPORT_REQUEST';
export const GET_SESSION_DATA_REPORT_SUCCESS = 'GET_SESSION_DATA_REPORT_SUCCESS';
export const GET_SESSION_DATA_REPORT_FAIL = 'GET_SESSION_DATA_REPORT_FAIL';
export const GET_SESSION_DATA_REPORT_RESET = 'GET_SESSION_DATA_REPORT_RESET';

export const GET_TRAINER_TOTAL_SESSION_BY_MONTH_REQUEST = 'GET_TRAINER_TOTAL_SESSION_BY_MONTH_REQUEST';
export const GET_TRAINER_TOTAL_SESSION_BY_MONTH_SUCCESS = 'GET_TRAINER_TOTAL_SESSION_BY_MONTH_SUCCESS';
export const GET_TRAINER_TOTAL_SESSION_BY_MONTH_FAIL = 'GET_TRAINER_TOTAL_SESSION_BY_MONTH_FAIL';
export const GET_TRAINER_TOTAL_SESSION_BY_MONTH_RESET = 'GET_TRAINER_TOTAL_SESSION_BY_MONTH_RESET';

export const GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_REQUEST = 'GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_REQUEST';
export const GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_SUCCESS = 'GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_SUCCESS';
export const GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_FAIL = 'GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_FAIL';
export const GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_RESET = 'GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_RESET';

export const GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_REQUEST = 'GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_REQUEST';
export const GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_SUCCESS = 'GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_SUCCESS';
export const GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_FAIL = 'GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_FAIL';
export const GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_RESET = 'GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_RESET';

export const GENERAL_COUNT_ADMIN_REQUEST = 'GENERAL_COUNT_ADMIN_REQUEST';
export const GENERAL_COUNT_ADMIN_SUCCESS = 'GENERAL_COUNT_ADMIN_SUCCESS';
export const GENERAL_COUNT_ADMIN_FAIL = 'GENERAL_COUNT_ADMIN_FAIL';
export const GENERAL_COUNT_ADMIN_RESET = 'GENERAL_COUNT_ADMIN_RESET';

export const TOTAL_SESSION_BY_MONTH_ADMIN_REQUEST = 'TOTAL_SESSION_BY_MONTH_ADMIN_REQUEST';
export const TOTAL_SESSION_BY_MONTH_ADMIN_SUCCESS = 'TOTAL_SESSION_BY_MONTH_ADMIN_SUCCESS';
export const TOTAL_SESSION_BY_MONTH_ADMIN_FAIL = 'TOTAL_SESSION_BY_MONTH_ADMIN_FAIL';
export const TOTAL_SESSION_BY_MONTH_ADMIN_RESET = 'TOTAL_SESSION_BY_MONTH_ADMIN_RESET';

export const TOTAL_SESSION_BY_MONTH_ADMIN_CHART_REQUEST = 'TOTAL_SESSION_BY_MONTH_ADMIN_CHART_REQUEST';
export const TOTAL_SESSION_BY_MONTH_ADMIN_CHART_SUCCESS = 'TOTAL_SESSION_BY_MONTH_ADMIN_CHART_SUCCESS';
export const TOTAL_SESSION_BY_MONTH_ADMIN_CHART_FAIL = 'TOTAL_SESSION_BY_MONTH_ADMIN_CHART_FAIL';
export const TOTAL_SESSION_BY_MONTH_ADMIN_CHART_RESET = 'TOTAL_SESSION_BY_MONTH_ADMIN_CHART_RESET';

export const TOTAL_EARNING_BY_MONTH_ADMIN_REQUEST = 'TOTAL_EARNING_BY_MONTH_ADMIN_REQUEST';
export const TOTAL_EARNING_BY_MONTH_ADMIN_SUCCESS = 'TOTAL_EARNING_BY_MONTH_ADMIN_SUCCESS';
export const TOTAL_EARNING_BY_MONTH_ADMIN_FAIL = 'TOTAL_EARNING_BY_MONTH_ADMIN_FAIL';
export const TOTAL_EARNING_BY_MONTH_ADMIN_RESET = 'TOTAL_EARNING_BY_MONTH_ADMIN_RESET';

export const TOTAL_EARNING_BY_MONTH_ADMIN_CHART_REQUEST = 'TOTAL_EARNING_BY_MONTH_ADMIN_CHART_REQUEST';
export const TOTAL_EARNING_BY_MONTH_ADMIN_CHART_SUCCESS = 'TOTAL_EARNING_BY_MONTH_ADMIN_CHART_SUCCESS';
export const TOTAL_EARNING_BY_MONTH_ADMIN_CHART_FAIL = 'TOTAL_EARNING_BY_MONTH_ADMIN_CHART_FAIL';
export const TOTAL_EARNING_BY_MONTH_ADMIN_CHART_RESET = 'TOTAL_EARNING_BY_MONTH_ADMIN_CHART_RESET';
