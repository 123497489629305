import React from 'react';
import InputsSection from '../../components/Modal/InputsSection';
import SelectBox from '../../components/formInputs/SelectBox';
import TemplateAssignStyles from './TemplateAssign.module.css';

const SelectBoxes = ({ data }) => {
	const {
		templatesList,
		selectedTemplate,
		setSelectedTemplate,
		nutritionTemplateList,
		selectedNutrition,
		setSelectedNutrition,
		handleAssignExercise,
		getUserInfo
	} = data;

	return (
		<div className='flex'>
			<InputsSection>
				<div className={TemplateAssignStyles.muscleType}>
					<SelectBox
						value={selectedTemplate}
						onChange={(e) => {
							setSelectedTemplate(e.target.value);
						}}
						name='template'
						id='template'
						icon='trainer'
						label='Select Template'
						style={{ marginLeft: '3rem' }}
						containerStyle={{ width: '20rem' }}
						className='muscleTypeSelectBox'>
						<option className='optionBox' value=''>
							Select Template
						</option>
						{templatesList && templatesList.length > 0 ? (
							templatesList.map((template, index) => (
								<option className='optionBox' value={template._id} key={index + 1}>
									{template.templateName}
								</option>
							))
						) : (
							<option className='optionBox' value=''>
								No templates
							</option>
						)}
					</SelectBox>

					<SelectBox
						value={selectedNutrition}
						onChange={(e) => {
							setSelectedNutrition(e.target.value);
						}}
						name='Nutrition'
						id='Nutrition'
						icon='trainer'
						label='Select Nutrition'
						style={{ marginLeft: '3rem' }}
						containerStyle={{ width: '20rem' }}
						className='muscleTypeSelectBox'>
						<option className='optionBox' value=''>
							Select Nutrition
						</option>
						{nutritionTemplateList && nutritionTemplateList.length > 0 ? (
							nutritionTemplateList.map((type, i) => (
								<option className='optionBox' value={type._id} key={i + 1}>
									{type.title}
								</option>
							))
						) : (
							<option className='optionBox' value=''>
								No Nutrition
							</option>
						)}
					</SelectBox>
					{/* <button className={TemplateAssignStyles.muscleTypeGoBtn} onClick={onChangeHandler}>
						<svg className={TemplateAssignStyles.muscleTypeGoBtnIcon}>
							<use xlinkHref={`/assets/sprite.svg#icon-arrow_right`} />
						</svg>
					</button> */}
					{getUserInfo &&
						getUserInfo.userInfo &&
						getUserInfo.userInfo.data &&
						(getUserInfo.userInfo.data.role === 'trainer'
							|| getUserInfo.userInfo.data.role === 'gym'
							|| getUserInfo.userInfo.data.role === 'gymadmin'
							|| getUserInfo.userInfo.data.role === 'superadmin'
						) && (
							<button className={TemplateAssignStyles.muscleTypeEditBtn} onClick={handleAssignExercise}>
								<svg className={TemplateAssignStyles.muscleTypeEditBtnIcon}>
									<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
								</svg>
								Assign
							</button>
						)}
				</div>
			</InputsSection>
		</div>
	);
};

export default SelectBoxes;
