import React from 'react';
import UserDetailsStyle from './userCard.module.css';

const HeadingCardsTrainer = ({ label, count, icon, description, style, textColor }) => {
	return (
		<div className={UserDetailsStyle.headingCardTrainer} style={style}>
			<h2 className={UserDetailsStyle.headingCardTitle}>{label}</h2>
			<h2
				className={[
					UserDetailsStyle.headingCardContent,
					textColor === 'green'
						? UserDetailsStyle.headingCardContentGreen
						: textColor === 'primary'
							? UserDetailsStyle.headingCardContentPrimary
							: textColor === 'red' ? UserDetailsStyle.headingCardContentRed : UserDetailsStyle.headingCardContentBlack
				].join(' ')}>
				<svg
					className={[
						UserDetailsStyle.headingCardContentIcon,
						textColor === 'green'
							? UserDetailsStyle.headingCardContentIconGreen
							: textColor === 'primary'
								? UserDetailsStyle.headingCardContentIconPrimary
								: textColor === 'red'
									? UserDetailsStyle.headingCardContentIconRed
									: UserDetailsStyle.headingCardContentIconBlack
					].join(' ')}>
					<use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
				</svg>
				{count}
			</h2>
			<h2 className={UserDetailsStyle.headingCardTitle}>{description}</h2>
		</div>
	);
};

export default HeadingCardsTrainer;
 