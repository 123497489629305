import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/formInputs/Button';
import InputBox from '../../components/formInputs/InputBox';
import LoginSkeleton from '../../components/Skeletons/LoginSkeleton';
import { getAllEnabledGymListAction } from '../../redux/actions/gymActions';
import { getUserByTokenAction, login } from '../../redux/actions/userActions';
import './LoginScreen.css';
import { validateEmail, validatePhone } from '../../utils/validators';

const LoginScreen = ({ history }) => {
	const dispatch = useDispatch();
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo, loading, error } = userLogin;

	const [email, setEmail] = useState({ value: '', error: '' });
	const [password, setPassword] = useState({ value: '', error: '' });

	useEffect(
		() => {
			dispatch(getUserByTokenAction());
			dispatch(getAllEnabledGymListAction());
			if (userInfo && userInfo.status) {
				history.push('/');
			} else if (userInfo && !userInfo.status) {
				setPassword({ ...password, error: userInfo.message });
			}
		},
		[userInfo, error, dispatch]
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (email.value === '' && email.value.trim() === '') {
			setEmail({ ...email, error: 'Please enter email address' });
		} else if (!validateEmail(email.value)) {
			setEmail({ ...email, error: 'Please enter a valid email address' });
		} else if (password.value === '' && password.value.trim() === '') {
			setPassword({ ...password, error: 'Please enter your password' });
		} else if (password.value.length < 7) {
			setPassword({ ...password, error: 'Password must be at least 8 characters' });
		} else {
			setEmail({ ...email, error: '' });
			setPassword({ ...password, error: '' });
			dispatch(login(email.value, password.value));
		}
	};
	return (
		<div className='container'>
			<div
				className='login_container'
				style={{
					backgroundImage:
						'linear-gradient(to bottom, rgba(255,255,255, .0), rgba(255,255,255, .0)), url(/assets/login-background.png)'
				}}>
				<div className='login_container__form'>
					<img src='./assets/logo-dark.png' alt='Gymwyse Logo' className='login_container__logo' />
					<div>
						<h1 className='login_container__heading'>Sign in</h1>
						<h3 className='login_container__subheading'>Enter your credentials to log into your account.</h3>
					</div>
					{loading ? (
						<LoginSkeleton />
					) : (
						<Fragment>
							<InputBox
								type='email'
								placeholder='eg, johndoe@example.com'
								value={email.value}
								onChange={(e) => setEmail({ value: e.target.value })}
								errorMessage={email.error}
								label='Email'
								icon='email'
							/>
							<InputBox
								type='password'
								placeholder='•••••••••••••'
								value={password.value}
								onChange={(e) => setPassword({ value: e.target.value })}
								errorMessage={password.error}
								label='Password'
								icon='key'
							/>

							<Button onClick={handleSubmit} label='Login' icon='arrow_right' />
							<div className='login__copyright'>
								<p className='login__copyright-text'>&copy; {new Date(Date.now()).getFullYear()} Gymwyse</p>
								<p className='login__copyright-text'>
									Proudly Powered By{' '}
									<a href='https://www.the-algo.com/' target='_blank'>
										The Algorithm
									</a>
								</p>
							</div>
						</Fragment>
					)}
				</div>

			</div>
		</div>
	);
};

export default LoginScreen;
