import React from 'react'
import Style from './WorkoutDisplay.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { getWorkoutById } from '../../redux/actions/workoutAction'
import Spinner from '../../components/Spinner/Spinner'
import NoData from '../../components/NoData'
import WorkOutCard from './WorkOutCards/WorkOutCard'

const WorkoutDisplayScreen = ({ match }) => { 
  const dispatch = useDispatch();
  const workoutList = useSelector(state => state.getAllWorkout);

  React.useEffect(() => {
    dispatch(getWorkoutById(match.params.roomId))
  }, [dispatch, match.params.roomId]);

  return (
    <main className='container'>
      <div className='mainContent_for_adsscreen'> 
        <div className={[ 'reportHeader--tab',  Style.reportHeader ].join(' ')} style={{ margin: '1rem 2rem', gap: '1.8rem', height: '92vh', overflowX: 'auto' }}>
          {workoutList && workoutList.loading ? (
            <Spinner />
          ) :
            workoutList.data &&
              workoutList.data.length > 0 ? (
              workoutList.data.map((workout) =>
                workout.workOut.length > 0 ? (
                  workout.workOut.map((workout, index) =>
                  (<WorkOutCard key={index} item={workout} />
                  )))
                  :
                  (<div className='not_data_found'>
                    <NoData title='No Data Found!' subTitle='We could not find any exercise template. please add one.' height='40vh' />
                  </div>)

              )) : (
              <div className='not_data_found'>
                <NoData title='No Data Found!' subTitle='We could not find any exercise template. please add one.' height='40vh' />
              </div>)
          }
        </div>
      </div> 
    </main>
  )
}

export default WorkoutDisplayScreen
