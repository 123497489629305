import React from 'react'
import Modal from '../../components/Modal'
import ModalHeading from '../../components/Modal/ModalHeading'
import ModalForm from '../../components/Modal/ModalForm'
import InputsSectionColumn from '../../components/Modal/InputsSectionColumn'
import ModalButton from '../../components/Modal/ModalButton'
import settingStyle from './Gyms.module.css'

const MenuSettingUser = ({ data }) => {
  const {
    appMenu,
    setAppMenu,
    onSettingModalClose,
    modalSettingState,
    onSettingHandler,
  } = data

  return (
    <Modal show={modalSettingState}>
      <ModalHeading heading='Gym App Settings' onClose={onSettingModalClose} />
      <ModalForm onSubmit={onSettingHandler} style={{ marginBottom: '2.5rem' }}>
        <InputsSectionColumn>
          <label className={settingStyle.form_input__label}>App Settings</label>
          <div
            className='flex'
            style={{ gap: '2rem', flexWrap: 'wrap', rowGap: '3rem' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '30%',
              }}>
              <label className='switch'>
                <input
                  id='isVideo'
                  checked={appMenu.isVideo}
                  onChange={() => {
                    setAppMenu({ ...appMenu, isVideo: !appMenu.isVideo })
                  }}
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <label htmlFor='active' className='inputBox__toggle--label'>
                Video
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '30%',
              }}>
              <label className='switch'>
                <input
                  id='isCheckin'
                  checked={appMenu.isCheckin}
                  onChange={() => {
                    setAppMenu({ ...appMenu, isCheckin: !appMenu.isCheckin })
                  }}
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <label htmlFor='active' className='inputBox__toggle--label'>
                Checkin
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '30%',
              }}>
              <label className='switch'>
                <input
                  id='isPOS'
                  checked={appMenu.isPOS}
                  onChange={() => {
                    setAppMenu({ ...appMenu, isPOS: !appMenu.isPOS })
                  }}
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <label htmlFor='active' className='inputBox__toggle--label'>
                Point of Sales
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '30%',
              }}>
              <label className='switch'>
                <input
                  id='isBMICalculator'
                  checked={appMenu.isBMICalculator}
                  onChange={() => {
                    setAppMenu({
                      ...appMenu,
                      isBMICalculator: !appMenu.isBMICalculator,
                    })
                  }}
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <label htmlFor='active' className='inputBox__toggle--label'>
                BMR Calculator
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '30%',
              }}>
              <label className='switch'>
                <input
                  id='isLiabilityFormMandatory'
                  checked={appMenu.isLiabilityFormMandatory}
                  onChange={() => {
                    setAppMenu({
                      ...appMenu,
                      isLiabilityFormMandatory:
                        !appMenu.isLiabilityFormMandatory,
                    })
                  }}
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <label htmlFor='active' className='inputBox__toggle--label'>
                Liability Form Mandatory
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '30%',
              }}>
              <label className='switch'>
                <input
                  id='isDeleteUser'
                  checked={appMenu.isDeleteUser}
                  onChange={() => {
                    setAppMenu({
                      ...appMenu,
                      isDeleteUser:
                        !appMenu.isDeleteUser,
                    })
                  }}
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <label htmlFor='active' className='inputBox__toggle--label'>
                Delete User by self
              </label>
            </div>
          </div>
          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                width: '30%',
              }}>
              <label className='switch'>
                <input
                  id='resource'
                  checked={appMenu.resource}
                  onChange={() => {
                    setAppMenu({
                      ...appMenu,
                      resource:
                        !appMenu.resource,
                    })
                  }}
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <label htmlFor='active' className='inputBox__toggle--label'>
                Resource Management
              </label>
            </div>
        </InputsSectionColumn>
        <ModalButton
          label='Update Settings'
          icon='setting'
          onClick={onSettingHandler}
        />
      </ModalForm>
    </Modal>
  )
}

export default MenuSettingUser
