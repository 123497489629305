export const options = {
	responsive: true,
	scaleFontColor: 'white',
	scales: {
		xAxes: [{
			stacked: true
		}],
		yAxes: [{
			stacked: true
		}]
	},

	plugins: {
		legend: {
			position: 'top'
		},
		title: {
			display: false,
			text: 'Trainer Session Chart'
		}
	},
	tooltips: {
		mode: 'single'
	}
};



export const optionsStacked = {
	plugins: {
		title: {
			display: false,

		},
	},
	responsive: true,
	scales: {
		x: {
			stacked: true,
		},
		y: {
			stacked: true
		}
	}
};