export const months = [
	{ index: 0, name: 'January', days: 31 },
	{ index: 1, name: 'February', days: 28 },
	{ index: 2, name: 'March', days: 31 },
	{ index: 3, name: 'April', days: 30 },
	{ index: 4, name: 'May', days: 31 },
	{ index: 5, name: 'June', days: 30 },
	{ index: 6, name: 'July', days: 31 },
	{ index: 7, name: 'August', days: 31 },
	{ index: 8, name: 'September', days: 30 },
	{ index: 9, name: 'October', days: 31 },
	{ index: 10, name: 'November', days: 30 },
	{ index: 11, name: 'December', days: 31 }
];

export const days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
