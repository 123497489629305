import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Content from '../../components/Content';
import Spinner from '../../components/Spinner/Spinner';
import {
	createNutritionTemplateAction,
	deleteNutritionTemplateAction,
	getAllNutritionTemplateAction,
	nutritionTemplateStatusAction
} from '../../redux/actions/templateActions';

import Pagination from '../../components/Pagination';
import NoData from '../../components/NoData';
import CreateNutrition from './CreateNutrition';
import DeleteNutrition from './DeleteNutrition';
import {
	ADD_NUTRITIONS_TEMPLATE_RESET,
	CHANGE_NUTRITIONS_TEMPLATE_STATUS_RESET,
	DELETE_NUTRITIONS_TEMPLATE_RESET,
	GET_ALL_NUTRITIONS_TEMPLATE_RESET
} from '../../redux/constants/templateConstants';
import { Link } from 'react-router-dom';

const NutritionScreen = ({ history }) => {
	const dispatch = useDispatch();
	const getUserInfo = useSelector((state) => state.getUserInfo);

	const [addModalActive, setAddModalActive] = useState(false);
	const [modalDeleteState, setModalDeleteState] = useState(false);
	const [search, setSearch] = useState('');

	const [title, setTitle] = useState({ value: '', error: '' });
	const [selectedNutrition, setSelectedNutrition] = useState({});
	const [pdfFile, setPDFFile] = useState({ value: '', error: '' });

	const getAllNutritionTemplate = useSelector((state) => state.getAllNutritionTemplate);
	const createNutritionTemplate = useSelector((state) => state.createNutritionTemplate);
	const deleteNutritionTemplate = useSelector((state) => state.deleteNutritionTemplate);
	const templateNutritionStatus = useSelector((state) => state.templateNutritionStatus);


	const [totalPageSize, setTotalPageSize] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const pageLimit = 20;

	useEffect(
		() => {
			if (
				getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				(getUserInfo.userInfo.data.role === 'gym' ||
					getUserInfo.userInfo.data.role === 'gymadmin' ||
					getUserInfo.userInfo.data.role === 'trainer' ||
					getUserInfo.userInfo.data.role === 'superadmin')
			) {
				dispatch(getAllNutritionTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));

				return () => {
					dispatch({ type: GET_ALL_NUTRITIONS_TEMPLATE_RESET });
				};
			} else {
				history.push('/');
			}
		},
		[getUserInfo, dispatch, history, pageNumber]
	);

	useEffect(
		() => {
			if (createNutritionTemplate && createNutritionTemplate.category && createNutritionTemplate.category.status) {
				toast.success(createNutritionTemplate.category.message);
				dispatch({ type: ADD_NUTRITIONS_TEMPLATE_RESET });
				dispatch(getAllNutritionTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
				handleAddModalClose();
			}
		},
		[createNutritionTemplate, dispatch, pageNumber]
	);

	useEffect(
		() => {
			if (templateNutritionStatus && templateNutritionStatus.category && templateNutritionStatus.category.status) {
				toast.success(templateNutritionStatus.category.message);
				dispatch({ type: CHANGE_NUTRITIONS_TEMPLATE_STATUS_RESET });
				dispatch(getAllNutritionTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		[templateNutritionStatus, dispatch, pageNumber]
	);

	useEffect(
		() => {
			if (deleteNutritionTemplate && deleteNutritionTemplate.category && deleteNutritionTemplate.category.status) {
				toast.success(deleteNutritionTemplate.category.message);
				dispatch({ type: DELETE_NUTRITIONS_TEMPLATE_RESET });
				dispatch(getAllNutritionTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		[deleteNutritionTemplate, dispatch, pageNumber]
	);

	useEffect(
		() => {
			if (selectedNutrition && selectedNutrition._id) {
				setTitle({ value: selectedNutrition.title });
			}
		},
		[selectedNutrition]
	);

	const handleAddModalClose = () => {
		setTitle({ value: '', error: '' });
		setSelectedNutrition({});
		setAddModalActive(false);
	};

	// const handleEditButton = (data) => {
	// 	setSelectedNutrition(data);
	// 	setAddModalActive(true);
	// };

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(pdfFile, 'pdfFile');
		if (title.value === '' && title.value.trim() === '') {
			setTitle({ ...title, error: 'Please enter nutrition name' });
		} else if (!pdfFile.value || pdfFile.value === '') {
			setPDFFile({ ...pdfFile, error: 'Please select a pdf' });
		}
		// else if (!pdfFile.value && !pdfFile.value.type.startsWith('application/pdf')) {
		// 	setPDFFile({ ...pdfFile, error: 'Please select a valid pdf file' });
		// } 
		else {
			let data = new FormData();
			data.append('title', title.value);
			data.append('pdf', pdfFile.value);
			dispatch(createNutritionTemplateAction(data));
		}
	};

	const handleEnableNutrition = (id, enableStatus) => {
		dispatch(nutritionTemplateStatusAction(id, enableStatus));
	};

	const onDeleteModalClose = () => {
		setTitle({ value: '', error: '' });
		setSelectedNutrition({});
		setModalDeleteState(false);
	};

	const onDeleteHandler = () => {
		dispatch(deleteNutritionTemplateAction(selectedNutrition._id));
		onDeleteModalClose();
	};

	const onSearchHandler = (event) => {
		setSearch(event.target.value);
		if (search.trim !== '' && search.length > 0) {
			setPageNumber(1);
		}
		dispatch(getAllNutritionTemplateAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: event.target.value }));
	};

	const handlePageChange = async (currentPage) => {
		const selectedPage = currentPage.selected;
		dispatch(getAllNutritionTemplateAction({ pageNumber: selectedPage + 1, pageSize: pageLimit, filter: '' }));
		setPageNumber(selectedPage + 1);
	};

	return (
		<Content
			currentMenu='templates'
			headerTitle='Nutrition'
			addBtn={
				getUserInfo &&
					getUserInfo.userInfo &&
					getUserInfo.userInfo.data &&
					(getUserInfo.userInfo.data.role === 'gym' ||
						getUserInfo.userInfo.data.role === 'gymadmin' ||
						getUserInfo.userInfo.data.role === 'superadmin') ? (
					true
				) : (
					false
				)
			}
			addBtnText='Add Category'
			addBtnIcon='plus'
			addBtnClick={() => setAddModalActive(true)}
			search={false}
			searchPlaceholder='Search Categories...'
			searchIcon='search'
			searchvalue={search}
			searchOnChange={onSearchHandler}>
			<div className='tableContainer' style={{ height: '65vh' }}>
				<table className='table'>
					<thead>
						<tr>
							<th>#</th>
							<th>Nutrition Name</th>
							<th>Active Status</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{getAllNutritionTemplate && getAllNutritionTemplate.loading ? (
							<tr>
								<td colSpan='7' style={{ textAlign: 'center' }}>
									<Spinner />
								</td>
							</tr>
						) : (
							getAllNutritionTemplate &&
								getAllNutritionTemplate.categories &&
								getAllNutritionTemplate.categories.data &&
								getAllNutritionTemplate.categories.data.result &&
								getAllNutritionTemplate.categories.data.result.length > 0 ? (
								getAllNutritionTemplate.categories.data.result.map((res, index) => (
									<tr key={res._id}>
										<td>{index + 1}</td>
										<td>{res.title}</td>
										<td>
											<label className='switch'>
												<input
													checked={res.enable}
													onChange={() => {
														handleEnableNutrition(res._id, res.enable);
													}}
													type='checkbox'
													className='checkbox'
													name='active'
												/>
												<span className='slider round' />
											</label>
										</td>
										<td>
											<div className='table__iconBox'>
												<button
													className='table__button table__button--doc'
													onClick={() => {
														window.open(res.pdfURL.toString())
													}}>
													<svg className='table__button--icon-doc'>
														<use xlinkHref={`/assets/sprite.svg#icon-document`} />
													</svg>
													<span>View Nutrition Document</span>
												</button>
												<button
													className='table__button table__button--delete'
													onClick={() => {
														setSelectedNutrition(res);
														setModalDeleteState(true);
													}}>
													<svg className='table__button--icon-red'>
														<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
													</svg>
													<span>Delete Nutrition</span>
												</button>
											</div>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan='7' style={{ textAlign: 'center' }}>
										<NoData title='No Data Found!' subTitle='We could not find any nutrition. Please add one' height='40vh' />
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
			{totalPageSize > 1 && (
				<div className='tableContainer--paginater'>
					<Pagination
						list={getAllNutritionTemplate.categories}
						onPageChange={handlePageChange}
						rowsPerPage={pageLimit}
						totalPageSize={totalPageSize}
						pageNumber={pageNumber}
					/>
				</div>
			)}

			<CreateNutrition
				data={{
					addModalActive, handleAddModalClose,
					title, setTitle,
					pdfFile, setPDFFile,
					handleSubmit, selectedNutrition
				}}
			/>
			<DeleteNutrition data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }} />
		</Content >
	);
};

export default NutritionScreen;
