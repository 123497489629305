import React from 'react';
import { Link } from 'react-router-dom';
import NoData from '../../components/NoData';
import Content from '../../components/Content';
import Spinner from '../../components/Spinner/Spinner';
import ReportStyles from '../ReportScreen/Report.module.css';
import RangeDatePickerModal from '../ReportScreen/RangeDatePickerModal';
import { getSessionReportAction } from '../../redux/actions/reportsActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { sortData } from '../../utils/phonemask';

const AllTrainerSessions = () => {
	const dispatch = useDispatch(); 
  const [searchText, setSearchText] = React.useState('');  
	const getUserInfo = useSelector((state) => state.getUserInfo);  
  const [fromDate, setFromDate] = React.useState(moment().startOf('month').format('MM-DD-YYYY'));
	const [toDate, setToDate] = React.useState(moment().endOf('month').format('MM-DD-YYYY'));  
  const sessionReport = useSelector((state) => state.sessionReport);


  const datePickerHandler = (startDate, endDate) => {
    console.log(startDate, 'startDate', endDate, 'toDate');
    if (getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      (getUserInfo.userInfo.data.role === 'gym' ||
        getUserInfo.userInfo.data.role === 'gymadmin' ||
        getUserInfo.userInfo.data.role === 'superadmin')
    ) {
      dispatch(getSessionReportAction({ fromDate: startDate, toDate: endDate }));
    }
  };

  return (
    <Content
      currentMenu='trainers'
      headerTitle='List of all trainer sessions'
      search={false}
      searchPlaceholder='Search Trainer'
      searchvalue={searchText}
      searchOnChange={e => setSearchText(e.target.value)}
    >
      <RangeDatePickerModal data={{
        fromDate,
        toDate,
        setFromDate,
        setToDate,
        datePickerHandler
      }} />

      <div className='flex gap-2 flexWrap'>
        {sessionReport && sessionReport.loading ? (
          <Spinner />
        ) :
          sessionReport && sessionReport.report && sessionReport.report.data && sessionReport.report.data.length ? ( 
            sortData(sessionReport.report.data).map((res, index) => (
                <Link
                  to={`/trainer-sessions/${res.trainer._id}/${res.trainer.name}`}
                  key={index + 1}
                  className={`${ReportStyles.trainerList} ${ReportStyles.trainerList_white}`} 
                >
                  <div className={ReportStyles.trainerListData}>
                    <img src={res.trainer.photo} alt={res.trainer.name} className={ReportStyles.trainerListImage} style={{height:'7rem', backgroundColor:"#aaaaad"}} />
                    <div>
                      <h4 className={ReportStyles.trainerListName}>{res.trainer.name}</h4>
                      <h6 className={ReportStyles.trainerListSessions}>
                        <span>{res.session.toFixed(2)}</span> Sessions
                      </h6>
                      {res.earning && <p className={ReportStyles.trainerListEarning}>{'Total Earning $' + res.earning.toFixed(2)}</p>}
                      {res.sessionEarning && <p className={ReportStyles.trainerListEarning}>{'Session Earning $' + res.sessionEarning.toFixed(2)}</p>}
                      {res.reservationFees ? (
                        <p className={ReportStyles.trainerListEarning}>{'Reservation Earning $' + res.reservationFees.toFixed(2)}</p>
                      ) : (
                        <p className={ReportStyles.trainerListEarning}>Reservation Earning $0</p>
                      )}
                    </div>
                  </div>
                  <svg className={ReportStyles.trainerListIcon}>
                    <use xlinkHref={`/assets/sprite.svg#icon-chevron-right`} />
                  </svg>
                </Link>
              ))
          ) : (
            <NoData
              title={searchText ? 'No search results found!' : 'No Sessions Found!'}
              subTitle={searchText ? 'We could not find any trainer.' : 'We could not find any trainer sessions.'}
            />
          )}
      </div>
    </Content>
  );
};

export default AllTrainerSessions;
