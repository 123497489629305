import React from 'react';
import Modal from '../../components/Modal';
import ModalButton from '../../components/Modal/ModalButton';
import ModalForm from '../../components/Modal/ModalForm';
import ModalHeading from '../../components/Modal/ModalHeading';

const CancelResourceBookingModal = ({ data }) => {
	const { showCancelModal, onCancelModalClose, onCancelHandler, selectedId } = data;

	return (
		<Modal show={showCancelModal}>
			<ModalHeading heading='Cancel Booking' onClose={onCancelModalClose} />
			<ModalForm>
				<p className='modal__text'>Are you sure you want to cancel this Booking?</p>
				<ModalButton varient='danger' label='Cancel' icon='delete' onClick={() => onCancelHandler(selectedId)} />
			</ModalForm>
		</Modal>
	);
};

export default CancelResourceBookingModal;
