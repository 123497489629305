import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../../components/NoData';
import WorkOutCard from './WorkOutCard';
import { getAdsDataAction } from '../../redux/actions/userActions'; 
import WorkOutCardStyle from './WorkOutCard.module.css'

const AdsScreen = ({ match }) => {
	const screenId = match.params.id;
	const dispatch = useDispatch();
	const [adsData, setAdsData] = React.useState({
		loading: false,
		data: {},
		error: '',
	});


	const roundData = [
		{
			"title": "Wednesday Big Room",
			"round": [
				{
					"roundTitle": "1 Round",
					"round": [
						{
							"name": "Pink band warmup",
							"value": "25"
						},
						{
							"name": "Banded tricep",
							"value": "25"
						},
						{
							"name": "Banded bicep Then,",
							"value": "25"
						}
					]
				},
				{
					"roundTitle": "4 Rounds",
					"round": [
						{
							"name": "Cal rear delt erg",
							"value": "4"
						},
						{
							"name": "Seated dumbbell overhead press Then, ",
							"value": "12"
						}
					]
				},
				{
					"roundTitle": "4 Rounds",
					"round": [
						{
							"name": "Tricep tri",
							"value": "10"
						},
						{
							"name": "Banded curls Then, ",
							"value": "25"
						}
					]
				},
				{
					"roundTitle": "3 Rounds",
					"round": [
						{
							"name": "Delt raise",
							"value": "12"
						},
						{
							"name": "4 count curls Then,  ",
							"value": "12"
						}
					]
				},
				{
					"roundTitle": "3 Rounds",
					"round": [
						{
							"name": "Plate front raise",
							"value": "12"
						},
						{
							"name": "Dips",
							"value": "12"
						},
						{
							"name": "1 min farmers hold heavy",
							"value": ""
						}
					]
				}
			]
		},
		{
			"title": "SPICY",
			"round": [
				{
					"roundTitle": "3 Rounds",
					"round": [
						{
							"name": "Cal row",
							"value": "18"
						},
						{
							"name": "Rower dips ",
							"value": "18"
						},
						{
							"name": "Dumbbell overhead press Then, ",
							"value": "18"
						}
					]
				},
				{
					"roundTitle": "3 Rounds",
					"round": [
						{
							"name": "Cal bike (10 legs, 5 arms 2X)",
							"value": "30"
						},
						{
							"name": "Sit up front press ",
							"value": "15"
						},
						{
							"name": "Each side, curl hold leg lifts over dumbbells  Then,  ",
							"value": "15"
						}
					]
				},
				{
					"roundTitle": "	3 Rounds ",
					"round": [
						{
							"name": "Cal ski (5 tricep, 10 full 2X)",
							"value": "30"
						},
						{
							"name": "Devil press ",
							"value": "15"
						},
						{
							"name": "1 min leg hold Then,",
							"value": ""
						},
						{
							"name": "Curl walk suicide up stairs",
							"info": "(15 v-ups every time you come back down)",
							"value": ""
						}
					]
				}
			]
		},
		{
			"title": "ATHLETE",
			"round": [
				{
					"roundTitle": "5 Rounds",
					"round": [
						{
							"name": "Cal ski sprint",
							"value": "10"
						},
						{
							"name": "Heavy curl press",
							"value": "10"
						},
						{
							"name": "Diamond pushups  Then, ",
							"value": "15"
						}
					]
				},
				{
					"roundTitle": "5 Rounds",
					"round": [
						{
							"name": "Cal row sprint",
							"value": "10"
						},
						{
							"name": "Knees to elbows ",
							"value": "10"
						},
						{
							"name": "Barbell snatch Then,",
							"value": "10"
						}
					]
				},
				{
					"roundTitle": "Outside up ramp suicide",
					"round": [
						{
							"name": "Sled push",
							"value": ""
						},
						{
							"name": "Farmers carry",
							"value": ""
						},
						{
							"name": "Ground skull crushers",
							"value": ""
						},
						{
							"name": "Ground overhead press",
							"value": ""
						},
						{
							"name": "Ground curls ",
							"info": "(Ground work reps.. 20,15,10,5)",
							"value": ""
						}
					]
				},
			]
		},
	];


	const getData = React.useCallback(async () => {
		const data = await dispatch(getAdsDataAction());
		console.log(data, "getAdsDataAction");
		if (data && data.data) {
			setAdsData({ data: data.data, loading: false, error: '' });
		}
	}, [dispatch]);

	React.useEffect(() => {
		getData();
	}, [getData]);


	return (
		<main className='container'>
			<div className='mainContent_for_adsscreen'>
				{
					screenId == 3 ?
						<div style={{ gap: '4rem', height: '100%', display: 'flex', background: '#000' }}>
							<img alt='footerImage1' style={{ width: '52vh', margin: 'auto' }} src="/assets/ad_2.jpeg" />
						</div>
						: screenId == 2 ?
							<div style={{ gap: '4rem', height: '100%', display: 'flex' }}>
								<img alt='footerImage1' style={{ width: '52vh', margin: 'auto' }} src="/assets/ad_1.png" />
								{/* src={adsData && adsData.data && AdClinIQAPIUrl + '/uploads/v1/' + adsData.data.imageurl} */}
							</div>
							:
							<div className={[ 'reportHeader--tab',  WorkOutCardStyle.reportHeader ].join(' ')} style={{ margin: '1rem 2rem', gap: '1.8rem', height: '92vh', overflowX: 'auto' }}>
								{roundData &&
									roundData.length > 0 &&
									roundData.map((item, index) => (
										<WorkOutCard key={index + 1} item={item} />
									))} 
							</div>
				}
			</div>
		</main>
	);
};

export default AdsScreen;