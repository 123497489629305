import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';

const TrainerSidebar = ({ current, userData }) => {
	return (
		<Fragment>
			<div className={`${current === 'dashboard' ? 'currentNav ' : ''}`}>
				<li className='navigation__list--item'>
					<Link to='/' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-dashboard`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/' className='navigation__list--link'>
						<span className='navigation__list--link--span'>
							<svg className='navigation__icon'>
								<use xlinkHref={`/assets/sprite.svg#icon-dashboard`} />
							</svg>
							<span>Dashboard</span>
						</span>
					</Link>
				</li>
				{current === 'dashboard' && <div className='activeNav' />}
			</div>
			<div className={`${current === 'users' && 'currentNav'}`}>
				<li className='navigation__list--item'>
					<Link to='/users' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-users`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/users' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-users`} />
						</svg>
						<span>Users</span>
					</Link>
				</li>
				{current === 'users' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'trainer-sessions' && 'currentNav'}`}>
				<li className='navigation__list--item'>
					<Link to='/trainer-sessions' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-trainer`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/trainer-sessions' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-trainer`} />
						</svg>
						<span>My Sessions</span>
					</Link>
				</li>
				{current === 'trainer-sessions' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'videos' ? 'currentNav ' : ''}`}>
				<li className='navigation__list--item'>
					<Link to='/videos' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-film`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover hasChildren'>
					<div className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-film`} />
						</svg>
						<span>Videos</span>
					</div>
					<ul>
						<li>
							<NavLink to='/videos' activeClassName='navigation__sub__active'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-film`} />
								</svg>
								All Videos
							</NavLink>
						</li>
					</ul>
				</li>
				{current === 'videos' && <div className='activeNav' />}
			</div>

			{userData &&
				userData.userInfo &&
				userData.userInfo.data &&
				userData.userInfo.data.isSpecialApp && (
					<div className={`${current === 'templates' ? 'currentNav' : ''}`}>
						<li className='navigation__list--item'>
							<Link to='/templates' className='navigation__list--link'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-shopping-bag`} />
								</svg>
								&nbsp;
							</Link>
						</li>
						<li className='navigation__list--itemHover hasChildren'>
							<div className='navigation__list--link navigation__list-parent--link'>
								<span className='navigation__list--link--span'>
									<svg className='navigation__icon'>
										<use xlinkHref={`/assets/sprite.svg#icon-dumbell`} />
									</svg>
									<span>Templates</span>
								</span>
							</div>
							<ul>
								<li>
									<NavLink to='/templates' activeClassName='navigation__sub__active'>
										<svg className='navigation__icon'>
											<use xlinkHref={`/assets/sprite.svg#icon-dumbell`} />
										</svg>
										Exercise
									</NavLink>
								</li>
								<li>
									<NavLink to='/nutrition-templates' activeClassName='navigation__sub__active'>
										<svg className='navigation__icon'>
											<use xlinkHref={`/assets/sprite.svg#icon-dumbell`} />
										</svg>
										Nutrition
									</NavLink>
								</li>
							</ul>
						</li>
						{current === 'products' && <div className='activeNav' />}
					</div>)}
		</Fragment>
	);
};

export default TrainerSidebar;
