export const GET_ALL_COMPANY_REQUEST = 'GET_ALL_COMPANY_REQUEST';
export const GET_ALL_COMPANY_SUCCESS = 'GET_ALL_COMPANY_SUCCESS';
export const GET_ALL_COMPANY_FAIL = 'GET_ALL_COMPANY_FAIL';
export const GET_ALL_COMPANY_RESET = 'GET_ALL_COMPANY_RESET';

export const GET_ALL_ENABLE_COMPANY_REQUEST = 'GET_ALL_ENABLE_COMPANY_REQUEST';
export const GET_ALL_ENABLE_COMPANY_SUCCESS = 'GET_ALL_ENABLE_COMPANY_SUCCESS';
export const GET_ALL_ENABLE_COMPANY_FAIL = 'GET_ALL_ENABLE_COMPANY_FAIL';
export const GET_ALL_ENABLE_COMPANY_RESET = 'GET_ALL_ENABLE_COMPANY_RESET';

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';
export const ADD_COMPANY_RESET = 'ADD_COMPANY_RESET';

export const CHANGE_COMPANY_STATUS_REQUEST = 'CHANGE_COMPANY_STATUS_REQUEST';
export const CHANGE_COMPANY_STATUS_SUCCESS = 'CHANGE_COMPANY_STATUS_SUCCESS';
export const CHANGE_COMPANY_STATUS_FAIL = 'CHANGE_COMPANY_STATUS_FAIL';
export const CHANGE_COMPANY_STATUS_RESET = 'CHANGE_COMPANY_STATUS_RESET';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';
export const DELETE_COMPANY_RESET = 'DELETE_COMPANY_RESET';

export const GET_ALL_ENABLE_COMPANY_BY_CHAR_REQUEST = 'GET_ALL_ENABLE_COMPANY_BY_CHAR_REQUEST';
export const GET_ALL_ENABLE_COMPANY_BY_CHAR_SUCCESS = 'GET_ALL_ENABLE_COMPANY_BY_CHAR_SUCCESS';
export const GET_ALL_ENABLE_COMPANY_BY_CHAR_FAIL = 'GET_ALL_ENABLE_COMPANY_BY_CHAR_FAIL';
export const GET_ALL_ENABLE_COMPANY_BY_CHAR_RESET = 'GET_ALL_ENABLE_COMPANY_BY_CHAR_RESET';