import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputsSectionColumn from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import FileUpload from '../../components/formInputs/FileUpload';
import TextareaBox from '../../components/formInputs/TextareaBox';
import { useSelector } from 'react-redux';
import { inputPhoneMasking } from '../../utils/phonemask';

const EditProfile = ({ data }) => {
	const userData = useSelector((state) => state.getUserInfo);
	const {
		name,
		email,
		address,
		phone,
		setName,
		setEmail,
		setPhone,
		setAddress,
		sessionCharges,
		setSessionCharges,
		sessionChargesError,
		setSessionChargesError,

		nameError,
		addressError,
		phoneError,
		emailError,

		setNameError,
		setAddressError,
		setPhoneError,
		setEmailError,

		imageSrc,
		setImageSrc,
		imageSrcError,
		setImageSrcError,
		setUploadFileData,
		uploadFileDataError,
		setUploadFileDataError,

		setUploadDarkFileData,
		uploadDarkFileDataError,
		setUploadDarkFileDataError,
		darkimageSrc,
		setDarkImageSrc,

		handleEditModalClose,
		handleSubmit,
		showEditModal,
		setShowEditModal,
		selectUpdateModel,
		setSelectUpdateModel
	} = data;

	console.log(darkimageSrc)
	const handleChangeImage = (e) => {
		var file = e.target.files[0];
		var reader = new FileReader();
		if (e.target.files[0]) {
			var url = reader.readAsDataURL(file);
			reader.onloadend = () => {
				setImageSrc(reader.result);
			};
		}
	};

	const handleChangeImage2 = (e) => {
		var file = e.target.files[0];
		var reader = new FileReader();
		if (e.target.files[0]) {
			var url = reader.readAsDataURL(file);
			reader.onloadend = () => {
				setDarkImageSrc(reader.result);
			};
		}
	};

	return (
		<Modal show={showEditModal}>
			<ModalHeading heading='Update your profile information' onClose={handleEditModalClose} />
			<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
				<InputsSection>
					<InputBox
						label='Name'
						icon='gym'
						placeholder='eg, John Doe'
						value={name}
						onChange={(event) => {
							setName(event.target.value);
							setNameError('');
						}}
						errorMessage={nameError}
					/>
					<InputBox
						label='Email'
						icon='email'
						disabled={
							userData && userData.userInfo && userData.userInfo.data && userData.userInfo.data.role === 'trainer' ? (
								true
							) : (
								false
							)
						}
						placeholder='eg, johndoe@example.com'
						value={email}
						onChange={(event) => {
							setEmail(event.target.value);
							setEmailError('');
						}}
						errorMessage={emailError}
					/>
					<InputBox
						label='Phone'
						icon='phone'
						disabled={
							userData && userData.userInfo && userData.userInfo.data && userData.userInfo.data.role === 'trainer' ? (
								true
							) : (
								false
							)
						}
						placeholder='eg, 123-456-7890'
						value={phone}
						onChange={(event) => {
							setPhone(inputPhoneMasking(event.target.value));
							setPhoneError('');
						}}
						errorMessage={phoneError}
					/>
				</InputsSection>
				<InputsSection>
					<TextareaBox
						rows={1}
						label='Address'
						icon='pin'
						placeholder='eg, Denver, USA'
						value={address}
						onChange={(event) => {
							setAddress(event.target.value);
							setAddressError('');
						}}
						errorMessage={addressError}
					/>

					{userData &&
					userData.userInfo &&
					userData.userInfo.data &&
					(userData.userInfo.data.role === 'trainer' || userData.userInfo.data.role === 'gym') && (
						<InputBox
							label='Session Charges (in $)'
							icon='cash'
							placeholder='eg, $ 10.5'
							value={sessionCharges}
							onChange={(e) => {
								if (e.target.value < 0) e.target.value = e.target.value * -1;
								setSessionCharges(e.target.value);
								setSessionChargesError('');
							}}
							errorMessage={sessionChargesError}
						/>
					)}
				</InputsSection>
				<InputsSection>
					<FileUpload
						label='Upload Your Profile Photo'
						icon='upload'
						image={imageSrc}
						onChange={(e) => {
							handleChangeImage(e);
							setUploadFileData(e.target.files);
							setUploadFileDataError('');
						}}
						errorMessage={uploadFileDataError}
					/>
					{userData &&
					userData.userInfo &&
					userData.userInfo.status &&
					userData.userInfo.data &&
					userData.userInfo.data.role === 'gym' && (
						<FileUpload
							label='Upload Gym Dark Logo'
							icon='upload'
							image={darkimageSrc}
							onChange={(e) => {
								handleChangeImage2(e);
								setUploadDarkFileData(e.target.files);
								setUploadDarkFileDataError('');
							}}
							errorMessage={uploadDarkFileDataError}
						/>
					)}
				</InputsSection>

				<ModalButton label='Update Profile' icon='edit' onClick={handleSubmit} />
			</ModalForm>
		</Modal>
	);
};

export default EditProfile;
