import {
	GET_SESSION_REPORT_FAIL,
	GET_SESSION_REPORT_REQUEST,
	GET_SESSION_REPORT_RESET,
	GET_SESSION_REPORT_SUCCESS,
	GET_SESSION_BY_MONTH_REPORT_REQUEST,
	GET_SESSION_BY_MONTH_REPORT_SUCCESS,
	GET_SESSION_BY_MONTH_REPORT_FAIL,
	GET_SESSION_BY_MONTH_REPORT_RESET,
	GET_EARNING_BY_MONTH_REPORT_REQUEST,
	GET_EARNING_BY_MONTH_REPORT_SUCCESS,
	GET_EARNING_BY_MONTH_REPORT_FAIL,
	GET_EARNING_BY_MONTH_REPORT_RESET,
	GET_TOTAL_SALES_BY_MONTH_REPORT_REQUEST,
	GET_TOTAL_SALES_BY_MONTH_REPORT_SUCCESS,
	GET_TOTAL_SALES_BY_MONTH_REPORT_FAIL,
	GET_TOTAL_SALES_BY_MONTH_REPORT_RESET,
	GET_GENERAL_COUNT_REQUEST,
	GET_GENERAL_COUNT_SUCCESS,
	GET_GENERAL_COUNT_FAIL,
	GET_GENERAL_COUNT_RESET,
	GET_SESSION_DATA_REPORT_REQUEST,
	GET_SESSION_DATA_REPORT_SUCCESS,
	GET_SESSION_DATA_REPORT_FAIL,
	GET_SESSION_DATA_REPORT_RESET,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_REQUEST,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_SUCCESS,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_FAIL,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_RESET,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_REQUEST,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_SUCCESS,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_FAIL,
	GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_RESET,
	GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_REQUEST,
	GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_SUCCESS,
	GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_FAIL,
	GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_RESET,
	GENERAL_COUNT_ADMIN_REQUEST,
	GENERAL_COUNT_ADMIN_SUCCESS,
	GENERAL_COUNT_ADMIN_FAIL,
	GENERAL_COUNT_ADMIN_RESET,
	TOTAL_SESSION_BY_MONTH_ADMIN_REQUEST,
	TOTAL_SESSION_BY_MONTH_ADMIN_SUCCESS,
	TOTAL_SESSION_BY_MONTH_ADMIN_FAIL,
	TOTAL_SESSION_BY_MONTH_ADMIN_RESET,
	TOTAL_SESSION_BY_MONTH_ADMIN_CHART_REQUEST,
	TOTAL_SESSION_BY_MONTH_ADMIN_CHART_SUCCESS,
	TOTAL_SESSION_BY_MONTH_ADMIN_CHART_FAIL,
	TOTAL_SESSION_BY_MONTH_ADMIN_CHART_RESET,
	TOTAL_EARNING_BY_MONTH_ADMIN_REQUEST,
	TOTAL_EARNING_BY_MONTH_ADMIN_SUCCESS,
	TOTAL_EARNING_BY_MONTH_ADMIN_FAIL,
	TOTAL_EARNING_BY_MONTH_ADMIN_RESET,
	TOTAL_EARNING_BY_MONTH_ADMIN_CHART_REQUEST,
	TOTAL_EARNING_BY_MONTH_ADMIN_CHART_SUCCESS,
	TOTAL_EARNING_BY_MONTH_ADMIN_CHART_FAIL,
	TOTAL_EARNING_BY_MONTH_ADMIN_CHART_RESET
} from '../constants/reportsConstants';

export const getSessionReportReducers = (state = {}, action) => {
	switch (action.type) {
		case GET_SESSION_REPORT_REQUEST:
			return { loading: true };

		case GET_SESSION_REPORT_SUCCESS:
			return { report: action.payload, loading: false };

		case GET_SESSION_REPORT_FAIL:
			return { loading: false, error: action.payload };

		case GET_SESSION_REPORT_RESET:
			return {};

		default:
			return state;
	}
};

export const getSessionReportByMonthReducers = (state = {}, action) => {
	switch (action.type) {
		case GET_SESSION_BY_MONTH_REPORT_REQUEST:
			return { loading: true };

		case GET_SESSION_BY_MONTH_REPORT_SUCCESS:
			return { report: action.payload, loading: false };

		case GET_SESSION_BY_MONTH_REPORT_FAIL:
			return { loading: false, error: action.payload };

		case GET_SESSION_BY_MONTH_REPORT_RESET:
			return {};

		default:
			return state;
	}
};

export const getEarningReportByMonthReducers = (state = {}, action) => {
	switch (action.type) {
		case GET_EARNING_BY_MONTH_REPORT_REQUEST:
			return { loading: true };

		case GET_EARNING_BY_MONTH_REPORT_SUCCESS:
			return { report: action.payload, loading: false };

		case GET_EARNING_BY_MONTH_REPORT_FAIL:
			return { loading: false, error: action.payload };

		case GET_EARNING_BY_MONTH_REPORT_RESET:
			return {};

		default:
			return state;
	}
};

export const getTotalSalesReportByMonthReducers = (state = {}, action) => {
	switch (action.type) {
		case GET_TOTAL_SALES_BY_MONTH_REPORT_REQUEST:
			return { loading: true };

		case GET_TOTAL_SALES_BY_MONTH_REPORT_SUCCESS:
			return { report: action.payload, loading: false };

		case GET_TOTAL_SALES_BY_MONTH_REPORT_FAIL:
			return { loading: false, error: action.payload };

		case GET_TOTAL_SALES_BY_MONTH_REPORT_RESET:
			return {};

		default:
			return state;
	}
};

export const getGeneralCountReducers = (state = {}, action) => {
	switch (action.type) {
		case GET_GENERAL_COUNT_REQUEST:
			return { loading: true };

		case GET_GENERAL_COUNT_SUCCESS:
			return { report: action.payload, loading: false };

		case GET_GENERAL_COUNT_FAIL:
			return { loading: false, error: action.payload };

		case GET_GENERAL_COUNT_RESET:
			return {};

		default:
			return state;
	}
};

export const getSessionReportChartReducers = (state = {}, action) => {
	switch (action.type) {
		case GET_SESSION_DATA_REPORT_REQUEST:
			return { loading: true };

		case GET_SESSION_DATA_REPORT_SUCCESS:
			return { report: action.payload, loading: false };

		case GET_SESSION_DATA_REPORT_FAIL:
			return { loading: false, error: action.payload };

		case GET_SESSION_DATA_REPORT_RESET:
			return {};

		default:
			return state;
	}
};

export const getTrainerSessionByMonthReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TRAINER_TOTAL_SESSION_BY_MONTH_REQUEST:
			return { loading: true };

		case GET_TRAINER_TOTAL_SESSION_BY_MONTH_SUCCESS:
			return { report: action.payload, loading: false };

		case GET_TRAINER_TOTAL_SESSION_BY_MONTH_FAIL:
			return { loading: false, error: action.payload };

		case GET_TRAINER_TOTAL_SESSION_BY_MONTH_RESET:
			return {};

		default:
			return state;
	}
};
export const getTrainerSessionByMonthChartReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_REQUEST:
			return { loading: true };

		case GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_SUCCESS:
			return { report: action.payload, loading: false };

		case GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_FAIL:
			return { loading: false, error: action.payload };

		case GET_TRAINER_TOTAL_SESSION_BY_MONTH_CHART_RESET:
			return {};

		default:
			return state;
	}
};

export const getTrainerEarningByMonthChartReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_REQUEST:
			return { loading: true };

		case GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_SUCCESS:
			return { report: action.payload, loading: false };

		case GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_FAIL:
			return { loading: false, error: action.payload };

		case GET_TRAINER_TOTAL_EARNING_BY_MONTH_CHART_RESET:
			return {};

		default:
			return state;
	}
};

export const generalCountAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case GENERAL_COUNT_ADMIN_REQUEST:
			return { loading: true };

		case GENERAL_COUNT_ADMIN_SUCCESS:
			return { report: action.payload, loading: false };

		case GENERAL_COUNT_ADMIN_FAIL:
			return { loading: false, error: action.payload };

		case GENERAL_COUNT_ADMIN_RESET:
			return {};

		default:
			return state;
	}
};

export const totalSessionByMonthAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case TOTAL_SESSION_BY_MONTH_ADMIN_REQUEST:
			return { loading: true };

		case TOTAL_SESSION_BY_MONTH_ADMIN_SUCCESS:
			return { report: action.payload, loading: false };

		case TOTAL_SESSION_BY_MONTH_ADMIN_FAIL:
			return { loading: false, error: action.payload };

		case TOTAL_SESSION_BY_MONTH_ADMIN_RESET:
			return {};

		default:
			return state;
	}
};

export const totalSessionByMonthAdminChartReducer = (state = {}, action) => {
	switch (action.type) {
		case TOTAL_SESSION_BY_MONTH_ADMIN_CHART_REQUEST:
			return { loading: true };

		case TOTAL_SESSION_BY_MONTH_ADMIN_CHART_SUCCESS:
			return { report: action.payload, loading: false };

		case TOTAL_SESSION_BY_MONTH_ADMIN_CHART_FAIL:
			return { loading: false, error: action.payload };

		case TOTAL_SESSION_BY_MONTH_ADMIN_CHART_RESET:
			return {};

		default:
			return state;
	}
};

export const totalEarningByMonthAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case TOTAL_EARNING_BY_MONTH_ADMIN_REQUEST:
			return { loading: true };

		case TOTAL_EARNING_BY_MONTH_ADMIN_SUCCESS:
			return { report: action.payload, loading: false };

		case TOTAL_EARNING_BY_MONTH_ADMIN_FAIL:
			return { loading: false, error: action.payload };

		case TOTAL_EARNING_BY_MONTH_ADMIN_RESET:
			return {};

		default:
			return state;
	}
};

export const totalEarningByMonthAdminChartReducer = (state = {}, action) => {
	switch (action.type) {
		case TOTAL_EARNING_BY_MONTH_ADMIN_CHART_REQUEST:
			return { loading: true };

		case TOTAL_EARNING_BY_MONTH_ADMIN_CHART_SUCCESS:
			return { report: action.payload, loading: false };

		case TOTAL_EARNING_BY_MONTH_ADMIN_CHART_FAIL:
			return { loading: false, error: action.payload };

		case TOTAL_EARNING_BY_MONTH_ADMIN_CHART_RESET:
			return {};

		default:
			return state;
	}
};
