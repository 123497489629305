export const GET_ALL_PRODUCT_CATEGORY_REQUEST = 'GET_ALL_PRODUCT_CATEGORY_REQUEST';
export const GET_ALL_PRODUCT_CATEGORY_SUCCESS = 'GET_ALL_PRODUCT_CATEGORY_SUCCESS';
export const GET_ALL_PRODUCT_CATEGORY_FAIL = 'GET_ALL_PRODUCT_CATEGORY_FAIL';
export const GET_ALL_PRODUCT_CATEGORY_RESET = 'GET_ALL_PRODUCT_CATEGORY_RESET';

export const ADD_PRODUCT_CATEGORY_REQUEST = 'ADD_PRODUCT_CATEGORY_REQUEST';
export const ADD_PRODUCT_CATEGORY_SUCCESS = 'ADD_PRODUCT_CATEGORY_SUCCESS';
export const ADD_PRODUCT_CATEGORY_FAIL = 'ADD_PRODUCT_CATEGORY_FAIL';
export const ADD_PRODUCT_CATEGORY_RESET = 'ADD_PRODUCT_CATEGORY_RESET';

export const PRODUCT_CATEGORY_STATUS_REQUEST = 'PRODUCT_CATEGORY_STATUS_REQUEST';
export const PRODUCT_CATEGORY_STATUS_SUCCESS = 'PRODUCT_CATEGORY_STATUS_SUCCESS';
export const PRODUCT_CATEGORY_STATUS_FAIL = 'PRODUCT_CATEGORY_STATUS_FAIL';
export const PRODUCT_CATEGORY_STATUS_RESET = 'PRODUCT_CATEGORY_STATUS_RESET';

export const DELETE_PRODUCT_CATEGORY_REQUEST = 'DELETE_PRODUCT_CATEGORY_REQUEST';
export const DELETE_PRODUCT_CATEGORY_SUCCESS = 'DELETE_PRODUCT_CATEGORY_SUCCESS';
export const DELETE_PRODUCT_CATEGORY_FAIL = 'DELETE_PRODUCT_CATEGORY_FAIL';
export const DELETE_PRODUCT_CATEGORY_RESET = 'DELETE_PRODUCT_CATEGORY_RESET';

export const GET_ALL_PRODUCTS_REQUEST = 'GET_ALL_PRODUCTS_REQUEST';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_FAIL = 'GET_ALL_PRODUCTS_FAIL';
export const GET_ALL_PRODUCTS_RESET = 'GET_ALL_PRODUCTS_RESET';

export const GET_ONE_PRODUCT_REQUEST = 'GET_ONE_PRODUCT_REQUEST';
export const GET_ONE_PRODUCT_SUCCESS = 'GET_ONE_PRODUCT_SUCCESS';
export const GET_ONE_PRODUCT_FAIL = 'GET_ONE_PRODUCT_FAIL';
export const GET_ONE_PRODUCT_RESET = 'GET_ONE_PRODUCT_RESET';

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL';
export const CREATE_PRODUCT_RESET = 'CREATE_PRODUCT_RESET';

export const CHANGE_PRODUCT_STATUS_REQUEST = 'CHANGE_PRODUCT_STATUS_REQUEST';
export const CHANGE_PRODUCT_STATUS_SUCCESS = 'CHANGE_PRODUCT_STATUS_SUCCESS';
export const CHANGE_PRODUCT_STATUS_FAIL = 'CHANGE_PRODUCT_STATUS_FAIL';
export const CHANGE_PRODUCT_STATUS_RESET = 'CHANGE_PRODUCT_STATUS_RESET';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';
export const DELETE_PRODUCT_RESET = 'DELETE_PRODUCT_RESET';

export const GET_PRODUCT_ORDER_REQUEST = 'GET_PRODUCT_ORDER_REQUEST';
export const GET_PRODUCT_ORDER_SUCCESS = 'GET_PRODUCT_ORDER_SUCCESS';
export const GET_PRODUCT_ORDER_FAIL = 'GET_PRODUCT_ORDER_FAIL';
export const GET_PRODUCT_ORDER_RESET = 'GET_PRODUCT_ORDER_RESET';

export const ADD_PRODUCT_STOCK_REQUEST = 'ADD_PRODUCT_STOCK_REQUEST';
export const ADD_PRODUCT_STOCK_SUCCESS = 'ADD_PRODUCT_STOCK_SUCCESS';
export const ADD_PRODUCT_STOCK_FAIL = 'ADD_PRODUCT_STOCK_FAIL';
export const ADD_PRODUCT_STOCK_RESET = 'ADD_PRODUCT_STOCK_RESET';

export const CHANGE_ORDER_STATUS_REQUEST = 'CHANGE_ORDER_STATUS_REQUEST';
export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS';
export const CHANGE_ORDER_STATUS_FAIL = 'CHANGE_ORDER_STATUS_FAIL';
export const CHANGE_ORDER_STATUS_RESET = 'CHANGE_ORDER_STATUS_RESET';