import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import TextareaBox from '../../components/formInputs/TextareaBox';
import { validateOnlyChacters, validateOnlyNumber } from '../../utils/validators';
import TemplateDetailsStyles from './TemplateDetails.module.css';
import SelectBox from '../../components/formInputs/SelectBox';

const AddNewMuscleModal = ({ addModalActive, handleAddModalClose, handleSubmit, data }) => {
	const {
		getMuscleTypeList,
		muscleTypeName,
		setMuscleTypeName,
		round,
		setRound,
		roundTwo,
		setRoundTwo,
		roundThree,
		setRoundThree,
		roundFour,
		setRoundFour,
		roundFive,
		setRoundFive,
		roundSix,
		setRoundSix,
		editBtnEnable, 
	} = data;

	return (

		<Modal show={addModalActive} style={{ height: '85vh', overflow: 'auto' }}>
			<ModalHeading heading='Add New Training Template Exercise' onClose={handleAddModalClose} />
			<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
				<InputsSection style={{ width: 'fit-content' }}>
					<div className={TemplateDetailsStyles.inputBoxContainer}>
						<SelectBox
							disabled={true} //editBtnEnable ? true : false
							value={muscleTypeName.value}
							onChange={(e) => { 
								if (e.target.value.length > 0 && !validateOnlyChacters(e.target.value)) {
									setMuscleTypeName({ ...muscleTypeName, error: 'Alphabets only' });
								} else {
									setMuscleTypeName({ value: e.target.value, error: '' });
								}
							}}
							errorMessage={muscleTypeName.error}
							name='muscleType'
							id='muscleType'
							icon='trainer'
							label='Select Muscle Type'
							className='muscleTypeSelectBox'>
							<option value=''>Select Muscle Type</option>
							{getMuscleTypeList &&
								getMuscleTypeList.categories &&
								getMuscleTypeList.categories.data.length > 0 &&
								getMuscleTypeList.categories.data.map((type, index) => (
									<option value={type.value} key={index + 1}>
										{type.label}
									</option>
								))}
						</SelectBox>
					</div>
				</InputsSection>

				<div className={TemplateDetailsStyles.inputBoxContainers}>
					{/* Round One */}
					<div className={TemplateDetailsStyles.inputBoxContainer}>
						<div className={TemplateDetailsStyles.inputBoxes}>
							<InputBox
								label='Round'
								disabled
								placeholder='eg, 1'
								value={round.round}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRound({ ...round, roundError: 'Digits only' });
									} else {
										setRound({ ...round, round: e.target.value, roundError: '' });
									}
								}}
								errorMessage={round.roundError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Exercises'
								placeholder='eg, 5'
								value={round.exercises}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRound({ ...round, exercisesError: 'Digits only' });
									} else {
										setRound({ ...round, exercises: e.target.value, exercisesError: '' });
									}
								}}
								errorMessage={round.exercisesError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Reps'
								placeholder='eg, 15'
								value={round.reps}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRound({ ...round, repsError: 'Digits only' });
									} else {
										setRound({ ...round, reps: e.target.value, repsError: '' });
									}
								}}
								errorMessage={round.repsError}
								style={{ width: '8.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<TextareaBox
								label='Rep Type'
								placeholder='eg, Normal reps for each exercise'
								value={round.repType}
								onChange={(e) => setRound({ ...round, repType: e.target.value, repTypeError: '' })}
								errorMessage={round.repTypeError}
								style={{ width: '27.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<InputBox
								label='Next Round Text'
								placeholder='Move to next round, No Rest'
								value={round.nextRound}
								onChange={(e) => setRound({ ...round, nextRound: e.target.value, nextRoundError: '' })}
								errorMessage={round.nextRoundError}
								style={{ width: '27.5rem' }}
							/>
						</div>
					</div>
					{/* Round Two */}
					<div className={TemplateDetailsStyles.inputBoxContainer}>
						<div className={TemplateDetailsStyles.inputBoxes}>
							<InputBox
								label='Round'
								placeholder='eg, 1'
								value={roundTwo.round}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundTwo({ ...roundTwo, roundError: 'Digits only' });
									} else {
										setRoundTwo({ ...roundTwo, round: e.target.value, roundError: '' });
									}
								}}
								errorMessage={roundTwo.roundError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Exercises'
								placeholder='eg, 5'
								value={roundTwo.exercises}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundTwo({ ...roundTwo, exercisesError: 'Digits only' });
									} else {
										setRoundTwo({ ...roundTwo, exercises: e.target.value, exercisesError: '' });
									}
								}}
								errorMessage={roundTwo.exercisesError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Reps'
								placeholder='eg, 15'
								value={roundTwo.reps}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundTwo({ ...roundTwo, repsError: 'Digits only' });
									} else {
										setRoundTwo({ ...roundTwo, reps: e.target.value, repsError: '' });
									}
								}}
								errorMessage={roundTwo.repsError}
								style={{ width: '8.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<TextareaBox
								label='Rep Type'
								placeholder='eg, Normal reps for each exercise'
								value={roundTwo.repType}
								onChange={(e) => setRoundTwo({ ...roundTwo, repType: e.target.value, repTypeError: '' })}
								errorMessage={roundTwo.repTypeError}
								style={{ width: '27.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<InputBox
								label='Next Round Text'
								placeholder='Move to next round, No Rest'
								value={roundTwo.nextRound}
								onChange={(e) => setRoundTwo({ ...roundTwo, nextRound: e.target.value, nextRoundError: '' })}
								errorMessage={roundTwo.nextRoundError}
								style={{ width: '27.5rem' }}
							/>
						</div>
					</div>

					{/* Round Three */}
					<div className={TemplateDetailsStyles.inputBoxContainer}>
						<div className={TemplateDetailsStyles.inputBoxes}>
							<InputBox
								label='Round'
								placeholder='eg, 1'
								value={roundThree.round}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundThree({ ...roundThree, roundError: 'Digits only' });
									} else {
										setRoundThree({ ...roundThree, round: e.target.value, roundError: '' });
									}
								}}
								errorMessage={roundThree.roundError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Exercises'
								placeholder='eg, 5'
								value={roundThree.exercises}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundThree({ ...roundThree, exercisesError: 'Digits only' });
									} else {
										setRoundThree({ ...roundThree, exercises: e.target.value, exercisesError: '' });
									}
								}}
								errorMessage={roundThree.exercisesError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Reps'
								placeholder='eg, 15'
								value={roundThree.reps}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundThree({ ...roundThree, repsError: 'Digits only' });
									} else {
										setRoundThree({ ...roundThree, reps: e.target.value, repsError: '' });
									}
								}}
								errorMessage={roundThree.repsError}
								style={{ width: '8.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<TextareaBox
								label='Rep Type'
								placeholder='eg, Normal reps for each exercise'
								value={roundThree.repType}
								onChange={(e) => setRoundThree({ ...roundThree, repType: e.target.value, repTypeError: '' })}
								errorMessage={roundThree.repTypeError}
								style={{ width: '27.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<InputBox
								label='Next Round Text'
								placeholder='Move to next round, No Rest'
								value={roundThree.nextRound}
								onChange={(e) => setRoundThree({ ...roundThree, nextRound: e.target.value, nextRoundError: '' })}
								errorMessage={roundThree.nextRoundError}
								style={{ width: '27.5rem' }}
							/>
						</div>
					</div>

					{/* Round Four */}
					<div className={TemplateDetailsStyles.inputBoxContainer}>
						<div className={TemplateDetailsStyles.inputBoxes}>
							<InputBox
								label='Round'
								placeholder='eg, 1'
								value={roundFour.round}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundFour({ ...roundFour, roundError: 'Digits only' });
									} else {
										setRoundFour({ ...roundFour, round: e.target.value, roundError: '' });
									}
								}}
								errorMessage={roundFour.roundError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Exercises'
								placeholder='eg, 5'
								value={roundFour.exercises}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundFour({ ...roundFour, exercisesError: 'Digits only' });
									} else {
										setRoundFour({ ...roundFour, exercises: e.target.value, exercisesError: '' });
									}
								}}
								errorMessage={roundFour.exercisesError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Reps'
								placeholder='eg, 15'
								value={roundFour.reps}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundFour({ ...roundFour, repsError: 'Digits only' });
									} else {
										setRoundFour({ ...roundFour, reps: e.target.value, repsError: '' });
									}
								}}
								errorMessage={roundFour.repsError}
								style={{ width: '8.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<TextareaBox
								label='Rep Type'
								placeholder='eg, Normal reps for each exercise'
								value={roundFour.repType}
								onChange={(e) => setRoundFour({ ...roundFour, repType: e.target.value, repTypeError: '' })}
								errorMessage={roundFour.repTypeError}
								style={{ width: '27.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<InputBox
								label='Next Round Text'
								placeholder='Move to next round, No Rest'
								value={roundFour.nextRound}
								onChange={(e) => setRoundFour({ ...roundFour, nextRound: e.target.value, nextRoundError: '' })}
								errorMessage={roundFour.nextRoundError}
								style={{ width: '27.5rem' }}
							/>
						</div>
					</div>

					{/* Round Five */}
					<div className={TemplateDetailsStyles.inputBoxContainer}>
						<div className={TemplateDetailsStyles.inputBoxes}>
							<InputBox
								label='Round'
								placeholder='eg, 1'
								value={roundFive.round}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundFive({ ...roundFive, roundError: 'Digits only' });
									} else {
										setRoundFive({ ...roundFive, round: e.target.value, roundError: '' });
									}
								}}
								errorMessage={roundFive.roundError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Exercises'
								placeholder='eg, 5'
								value={roundFive.exercises}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundFive({ ...roundFive, exercisesError: 'Digits only' });
									} else {
										setRoundFive({ ...roundFive, exercises: e.target.value, exercisesError: '' });
									}
								}}
								errorMessage={roundFive.exercisesError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Reps'
								placeholder='eg, 15'
								value={roundFive.reps}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundFive({ ...roundFive, repsError: 'Digits only' });
									} else {
										setRoundFive({ ...roundFive, reps: e.target.value, repsError: '' });
									}
								}}
								errorMessage={roundFive.repsError}
								style={{ width: '8.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<TextareaBox
								label='Rep Type'
								placeholder='eg, Normal reps for each exercise'
								value={roundFive.repType}
								onChange={(e) => setRoundFive({ ...roundFive, repType: e.target.value, repTypeError: '' })}
								errorMessage={roundFive.repTypeError}
								style={{ width: '27.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<InputBox
								label='Next Round Text'
								placeholder='Move to next round, No Rest'
								value={roundFive.nextRound}
								onChange={(e) => setRoundFive({ ...roundFive, nextRound: e.target.value, nextRoundError: '' })}
								errorMessage={roundFive.nextRoundError}
								style={{ width: '27.5rem' }}
							/>
						</div>
					</div>

					{/* Round Six */}
					<div className={TemplateDetailsStyles.inputBoxContainer}>
						<div className={TemplateDetailsStyles.inputBoxes}>
							<InputBox
								label='Round'
								placeholder='eg, 1'
								value={roundSix.round}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundSix({ ...roundSix, roundError: 'Digits only' });
									} else {
										setRoundSix({ ...roundSix, round: e.target.value, roundError: '' });
									}
								}}
								errorMessage={roundSix.roundError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Exercises'
								placeholder='eg, 5'
								value={roundSix.exercises}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundSix({ ...roundSix, exercisesError: 'Digits only' });
									} else {
										setRoundSix({ ...roundSix, exercises: e.target.value, exercisesError: '' });
									}
								}}
								errorMessage={roundSix.exercisesError}
								style={{ width: '8.5rem' }}
							/>
							<InputBox
								label='Reps'
								placeholder='eg, 15'
								value={roundSix.reps}
								onChange={(e) => {
									if (e.target.value.length > 0 && !validateOnlyNumber(e.target.value)) {
										setRoundSix({ ...roundSix, repsError: 'Digits only' });
									} else {
										setRoundSix({ ...roundSix, reps: e.target.value, repsError: '' });
									}
								}}
								errorMessage={roundSix.repsError}
								style={{ width: '8.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<TextareaBox
								label='Rep Type'
								placeholder='eg, Normal reps for each exercise'
								value={roundSix.repType}
								onChange={(e) => setRoundSix({ ...roundSix, repType: e.target.value, repTypeError: '' })}
								errorMessage={roundSix.repTypeError}
								style={{ width: '27.5rem' }}
							/>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<InputBox
								label='Next Round Text'
								placeholder='Move to next round, No Rest'
								value={roundSix.nextRound}
								onChange={(e) => setRoundSix({ ...roundSix, nextRound: e.target.value, nextRoundError: '' })}
								errorMessage={roundSix.nextRoundError}
								style={{ width: '27.5rem' }}
							/>
						</div>
					</div>
				</div>

				<ModalButton label={editBtnEnable ? 'Update Exercise' : 'Add Exercise'} icon={editBtnEnable ? 'edit' : 'plus'} onClick={handleSubmit} />
			</ModalForm>
		</Modal>
	);
};

export default AddNewMuscleModal;

