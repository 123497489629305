import React from 'react';
import WorkOutCardStyle from './WorkoutCard.module.css'

const WorkOutCard = ({ item, index }) => {

    return (
    <div className={WorkOutCardStyle.WorkOutCard_Container} key={index + 1}>
        <h3 className={WorkOutCardStyle.title}> {item.muscleType} </h3>

        {item &&
            item.rounds.length > 0 &&
            item.rounds.map((subItem, index) => (
                <React.Fragment key={3 + index}>
                    <h4 className={WorkOutCardStyle.roundTitle}> {subItem.roundTitle} </h4>
                    <div className={WorkOutCardStyle.innerContent}>
                        {subItem &&
                            subItem.list.length > 0 &&
                            subItem.list.map((i, ind) => (
                                <div className='flex' style={{
                                    width: '100%',
                                    borderBottom: '1px solid #F3F3F3',
                                    // minHeight: '2rem'
                                }} key={2 + ind}>

                                    <p className={WorkOutCardStyle.roundTitle} style={{
                                        width: '1rem'
                                    }}>
                                        <svg className={['table__button--icon', WorkOutCardStyle.button_icon].join(' ')}>
                                            <use xlinkHref={`/assets/sprite.svg#icon-adstick`} />
                                        </svg>
                                    </p>

                                    <p className={WorkOutCardStyle.roundName} style={{
                                        width: '100%'
                                    }}>
                                        {i.title.split('(')[0] ? i.title.split('(')[0] : i.title}
                                        <br />
                                        <span className={WorkOutCardStyle.roundInfo} >{i.title.split('(')[1] && '(' + i.title.split('(')[1]}</span>
                                    </p>

                                    <p className={WorkOutCardStyle.roundNumbers}> {i.count ? i.count : ''} </p>


                                </div>
                            ))}
                    </div>
                </React.Fragment>
            ))}
    </div>
    )
}

export default WorkOutCard;