import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFormByIndependentAgreementAction } from '../../redux/actions/formActions';
import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';
import { trainerInsuranceAction } from '../../redux/actions/trainerActions';
import { phoneMasking } from '../../utils/phonemask';
import classes from './Trainers.module.css'
import { validatePhoneMaskingString } from '../../utils/validators';

const TrainerData = ({
	showFilter,
	trainerList,
	setShowAddModal,
	setModalDeleteState,
	setModalDoumentState,
	setModalInsuranceState,
	setSelectUpdateModel,
	handleEditModalTrainer,
	setModalChangePasswordState,
	setModalLogOutUserState,
	onChangeHandler,
	sortTrainersBy,
	setSortTrainersBy,
	setSortTrainersByValue,
	sortTrainersByValue
}) => {
	const dispatch = useDispatch();
	const getUserInfo = useSelector((state) => state.getUserInfo);

	const sortByName = () => {
		if (sortTrainersBy === 'day_time') {
			setSortTrainersByValue(1);
			setSortTrainersBy('name');
			return;
		}
		setSortTrainersBy('name');
		if (sortTrainersByValue === 1) {
			setSortTrainersByValue(-1);
		} else if (sortTrainersByValue === -1) {
			setSortTrainersBy('');
			setSortTrainersByValue(0);
		} else {
			setSortTrainersByValue(1)
		}
	}

	const sortByNeedClients = () => {
		if (sortTrainersBy === 'name') {
			setSortTrainersByValue(1);
			setSortTrainersBy('day_time');
			return;
		}
		setSortTrainersBy('day_time')
		if (sortTrainersByValue === 1) {
			setSortTrainersByValue(-1);
		} else if (sortTrainersByValue === -1) {
			setSortTrainersBy('');
			setSortTrainersByValue(0);
		} else {
			setSortTrainersByValue(1)
		}
	}

	const classesForNameBtn = sortTrainersBy === 'name' ? classes.sort__icon__button : classes.sort__icon__button__hover;
	const classesForNameIcon = sortTrainersBy === 'name' ? classes.sort__icon : classes.sort__icon__hover;
	const classesForNeedClientsBtn = sortTrainersBy === 'day_time' ? classes.sort__icon__button : classes.sort__icon__button__hover;
	const classesForNeedClientsIcon = sortTrainersBy === 'day_time' ? classes.sort__icon : classes.sort__icon__hover;
	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '65vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>
							Name
							<button className={classesForNameBtn} onClick={sortByName}>
								<svg className={classesForNameIcon}>
									<use xlinkHref={`./assets/sprite.svg#${sortTrainersBy === 'name' ? sortTrainersByValue === -1 ? 'icon-arrow-up' :  'icon-arrow-down': 'icon-arrow-down' }`} />
								</svg>
							</button>
						</th>
						<th>Email</th>
						<th>Phone</th>
						<th>OS</th>
						<th>
							Needs Clients
							<button className={classesForNeedClientsBtn} onClick={sortByNeedClients}>
								<svg className={classesForNeedClientsIcon}>
									<use xlinkHref={`./assets/sprite.svg#${ sortTrainersBy === 'day_time' ? sortTrainersByValue === -1 ? 'icon-arrow-up' :  'icon-arrow-down': 'icon-arrow-down' }`} />
								</svg>
							</button>
						</th>
						<th>Total Sessions</th>
						{getUserInfo &&
							getUserInfo.userInfo &&
							getUserInfo.userInfo.data &&
							(getUserInfo.userInfo.data.role === 'gym' ||
								getUserInfo.userInfo.data.role === 'gymadmin' ||
								getUserInfo.userInfo.data.role === 'superadmin') && <th>Active</th>}
						<th />
					</tr>
				</thead>
				<tbody>
					{trainerList.loading ? (
						<tr>
							<td colSpan='8' style={{ textAlign: 'center' }}>
								<Spinner />
							</td>
						</tr>
					) : trainerList &&
						trainerList.userInfo &&
						trainerList.userInfo.status &&
						trainerList.userInfo.data.result &&
						trainerList.userInfo.data.result.length > 0 ? (
						trainerList.userInfo.data.result.map((d, index) => (
							<tr key={d._id} style={{backgroundColor: d.day_time ? d.day_time !== 'NA' ? '#D4EBF2' : '#ECECEC': '#ECECEC' }}>
								<td>{index + 1}</td>
								<td style={{ textAlign: 'left', textTransform: 'capitalize' }}>{d.name}</td>
								<td>{d.email}</td>
								<td>{d.phone && validatePhoneMaskingString(d.phone) ? phoneMasking(d.phone) :d.phone}</td>
								<td>{d.platform ? d.platform : 'N/A'}</td>
								<td>{d.day_time || 'NA'}</td>
								<td>{d.sessions ? d.sessions.totalSessionHr : 0} Hours </td>
								{getUserInfo &&
									getUserInfo.userInfo &&
									getUserInfo.userInfo.data &&
									(getUserInfo.userInfo.data.role === 'gym' ||
										getUserInfo.userInfo.data.role === 'gymadmin' ||
										getUserInfo.userInfo.data.role === 'superadmin') && (
										<td>
											<label className='switch'>
												<input
													id='active'
													checked={d.active}
													onChange={() => {
														onChangeHandler(d);
													}}
													type='checkbox'
													className='checkbox'
													name='active'
												/>
												<span className='slider round' />
											</label>
										</td>
									)}

								<td>
									<div className='table__iconBox'>
										{getUserInfo &&
											getUserInfo.userInfo &&
											getUserInfo.userInfo.data &&
											getUserInfo.userInfo.data.role === 'gym' && (
												<Link to={`trainer-sessions/${d._id}/${d.name}`} className='table__button--second'>
													<span>View Sessions</span>
													<div className='table__button--secondIconContainer'>
														<svg className='table__button--secondIcon'>
															<use xlinkHref={`/assets/sprite.svg#icon-chevron-right`} />
														</svg>
													</div>
												</Link>
											)}

										{getUserInfo &&
											getUserInfo.userInfo &&
											getUserInfo.userInfo.data &&
											getUserInfo.userInfo.data.role === 'gym' && (
												<Fragment>
													<button
														className='table__button table__button--doc'
														onClick={() => {
															setSelectUpdateModel(d);
															setModalDoumentState(true);
															dispatch(getFormByIndependentAgreementAction(d._id));
														}}>
														<svg className='table__button--icon-doc'>
															<use xlinkHref={`/assets/sprite.svg#icon-document`} />
														</svg>
														<span>Trainer Agreement</span>
													</button>
													<button
														className='table__button table__button--doc'
														onClick={() => {
															setModalInsuranceState(true);
															dispatch(trainerInsuranceAction(d._id));
														}}>
														<svg className='table__button--icon-doc'>
															<use xlinkHref={`/assets/sprite.svg#icon-file-picture`} />
														</svg>
														<span>Trainer Insurance</span>
													</button>
												</Fragment>
											)}

										{getUserInfo &&
											getUserInfo.userInfo &&
											getUserInfo.userInfo.data &&
											(getUserInfo.userInfo.data.role === 'gym' ||
												getUserInfo.userInfo.data.role === 'gymadmin' ||
												getUserInfo.userInfo.data.role === 'superadmin') && (
												<Fragment>
													<button
														className='table__button'
														onClick={() => {
															setShowAddModal(true);
															setSelectUpdateModel(d);
															handleEditModalTrainer(d);
														}}>
														<svg className='table__button--icon'>
															<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
														</svg>
														<span>Edit Trainer Details</span>
													</button>
													<button
														className='table__button table__button--changepwd'
														onClick={() => {
															setSelectUpdateModel(d);
															setModalChangePasswordState(true);
														}}>
														<svg className='table__button--icon-green'>
															<use xlinkHref={`/assets/sprite.svg#icon-key`} />
														</svg>
														<span>Reset Password</span>
													</button>
													<button
														className='table__button table__button--delete'
														onClick={() => {
															setModalDeleteState(true);
															setSelectUpdateModel(d);
														}}>
														<svg className='table__button--icon-red'>
															<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
														</svg>
														<span>Delete Trainer</span>
													</button>
													<button
														className='table__button table__button--delete'
														onClick={() => {
															setSelectUpdateModel(d);
															setModalLogOutUserState(true);
														}}>
															<svg className='table__button--icon-red'>
															<use xlinkHref={`/assets/sprite.svg#icon-logout`} />
														</svg>
														<span>Logout User</span>
													</button>
												</Fragment>
											)}
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan='8' style={{ textAlign: 'center' }}>
								<NoData title='No Data Found!' subTitle='We could not find any trainer. Please add one' height='40vh' />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default TrainerData;
