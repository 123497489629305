import React, { Fragment, useState, useEffect } from 'react';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import FileUpload from '../../components/formInputs/FileUpload';
import InputSections from '../../components/Modal/InputsSectionColumn';

import AppSettingStyles from './AppSetting.module.css';
import { getGymAppSetting, setGymAppSetting } from '../../redux/actions/AppSettingsActions';
import { toast } from 'react-toastify';
import MyProfileScreenSkeleton from '../../components/Skeletons/AppSettings/MyProfileScreenSkeleton';

const MyProfileScreen = ({ token }) => {
	const [ imageSrc, setImageSrc ] = useState('');
	const [ uploadFileData, setUploadFileData ] = useState({ data: '', error: '' });
	const [ loading, setLoading ] = useState(false);

	const [ myProfileScreenData, setMyProfileScreenData ] = useState({});
	const [ screenHeadingText, setScreenHeadingText ] = useState({ value: '', error: '' });
	const [ yourTrainerDetailsText, setYourTrainerDetails ] = useState({ value: '', error: '' });
	const [ yourGymDetailsText, setYourGymDetailsText ] = useState({ value: '', error: '' });
	const [ yourPreviousOrdersText, setYourPreviousOrdersText ] = useState({ value: '', error: '' });
	const [ logoutButtonText, setLogoutButtonText ] = useState({ value: '', error: '' });

	useEffect(
		() => {
			if (token) getData();
		},
		[ token ]
	);

	useEffect(
		() => {
			if (myProfileScreenData && myProfileScreenData._id) {
				setScreenHeadingText({ value: myProfileScreenData.profileScreenHeadingText, error: '' });
				setYourTrainerDetails({ value: myProfileScreenData.profileTrainerDetailText, error: '' });
				setYourGymDetailsText({ value: myProfileScreenData.profileGymDetailText, error: '' });
				setYourPreviousOrdersText({ value: myProfileScreenData.profilePreviousOrderText, error: '' });
				setLogoutButtonText({ value: myProfileScreenData.profileLogoutText, error: '' });
				setImageSrc(myProfileScreenData.profileHeaderImageURL);
			}
		},
		[ myProfileScreenData ]
	);

	const getData = async () => {
		setLoading(true);

		try {
			const { data } = await getGymAppSetting('profile', token);
			setMyProfileScreenData(data);
		} catch (error) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (screenHeadingText.value === '' || screenHeadingText.value.trim() === '') {
			setScreenHeadingText({ ...screenHeadingText, error: 'Please enter screen heading text' });
		} else if (yourTrainerDetailsText.value === '' || yourTrainerDetailsText.value.trim() === '') {
			setYourTrainerDetails({ ...yourTrainerDetailsText, error: 'Please enter your trainer details text' });
		} else if (yourGymDetailsText.value === '' || yourGymDetailsText.value.trim() === '') {
			setYourGymDetailsText({ ...yourGymDetailsText, error: 'Please enter your gym details text' });
		} else if (yourPreviousOrdersText.value === '' || yourPreviousOrdersText.value.trim() === '') {
			setYourPreviousOrdersText({
				...yourPreviousOrdersText,
				error: 'Please enter your previous orders text'
			});
		} else if (logoutButtonText.value === '' || logoutButtonText.value.trim() === '') {
			setLogoutButtonText({
				...logoutButtonText,
				error: 'Please enter logout button text'
			});
		} else if (!imageSrc) {
			setUploadFileData({ ...uploadFileData, error: 'Please upload background image' });
		} else {
			let formData = new FormData();
			formData.append('profileScreenHeadingText', screenHeadingText.value);
			formData.append('profileTrainerDetailText', yourTrainerDetailsText.value);
			formData.append('profileGymDetailText', yourGymDetailsText.value);
			formData.append('profilePreviousOrderText', yourPreviousOrdersText.value);
			formData.append('profileLogoutText', logoutButtonText.value);
			uploadFileData.data.length > 0 && formData.append('profileHeaderImageURL', uploadFileData.data[0]);

			try {
				setLoading(true);
				await setGymAppSetting('profile', formData, token);
				// toast.success(data && data.message);
				toast.success('My Profile screen settings updated!');
				getData();
			} catch (error) {
				toast.error(error);
			}
			setLoading(false);
		}
	};

	const handleChangeImage = (e) => {
		var file = e.target.files[0];
		var reader = new FileReader();
		if (e.target.files[0]) {
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				setImageSrc(reader.result);
			};
		}
	};

	return (
		<div className={AppSettingStyles.screenCard}>
			<h3 className={AppSettingStyles.screenCardTitle}>My Profile Screen Settings</h3>
			{loading ? (
				<MyProfileScreenSkeleton />
			) : (
				<Fragment>
					<div className='flex'>
						<InputSections>
							<div className='flex'>
								<InputBox
									label='My Profile Screen Heading Text'
									placeholder='eg, Shop'
									value={screenHeadingText.value}
									onChange={(e) => setScreenHeadingText({ value: e.target.value, error: '' })}
									errorMessage={screenHeadingText.error}
								/>
								<InputBox
									label='Your Trainer Details Text'
									placeholder='eg, Your Trainer Details'
									value={yourTrainerDetailsText.value}
									onChange={(e) => setYourTrainerDetails({ value: e.target.value, error: '' })}
									errorMessage={yourTrainerDetailsText.error}
								/>
							</div>
							<div className='flex'>
								<InputBox
									label='Your Gym Details Text'
									placeholder='eg, Your Gym Details'
									value={yourGymDetailsText.value}
									onChange={(e) => setYourGymDetailsText({ value: e.target.value, error: '' })}
									errorMessage={yourGymDetailsText.error}
								/>
								<InputBox
									label='Your Previous Orders Text'
									placeholder='eg, Your Previous Orders'
									value={yourPreviousOrdersText.value}
									onChange={(e) => setYourPreviousOrdersText({ value: e.target.value, error: '' })}
									errorMessage={yourPreviousOrdersText.error}
								/>
							</div>
							<InputBox
								label='Logout Button Text'
								placeholder='eg, Logout from your account'
								value={logoutButtonText.value}
								onChange={(e) => setLogoutButtonText({ value: e.target.value, error: '' })}
								errorMessage={logoutButtonText.error}
							/>
						</InputSections>
						<FileUpload
							label='Header Image'
							icon='upload'
							image={imageSrc}
							onChange={(e) => {
								handleChangeImage(e);
								setUploadFileData({ data: e.target.files, error: '' });
							}}
							errorMessage={uploadFileData.error}
							style={{ padding: '.5rem 1.5rem', paddingBottom: '1.5rem' }}
						/>
					</div>

					<ModalButton label='Save' icon='plus' onClick={handleSubmit} />
				</Fragment>
			)}
		</div>
	);
};

export default MyProfileScreen;
