import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const AdminSidebar = ({ current }) => {
	return (
		<Fragment>
			<div className={`${current === 'dashboard' ? 'currentNav ' : ''}`}>
				<li className='navigation__list--item'>
					<Link to='/' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-dashboard`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/' className='navigation__list--link'>
						<span className='navigation__list--link--span'>
							<svg className='navigation__icon'>
								<use xlinkHref={`/assets/sprite.svg#icon-dashboard`} />
							</svg>
							<span>Dashboard</span>
						</span>
					</Link>
				</li>
				{current === 'dashboard' && <div className='activeNav' />}
			</div>
			<div className={`${current === 'users' && 'currentNav'}`}>
				<li className='navigation__list--item'>
					<Link to='/users' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-users`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/users' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-users`} />
						</svg>
						<span>Users</span>
					</Link>
				</li>
				{current === 'users' && <div className='activeNav' />}
			</div>
			<div className={`${current === 'resources' && 'currentNav'}`}>
				<li className='navigation__list--item'>
					<Link to='/resources' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-users`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/resources' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-stack`} />
						</svg>
						<span>Resources Type</span>
					</Link>
				</li>
				{current === 'resources' && <div className='activeNav' />}
			</div>
			<div className={`${current === 'gyms' && 'currentNav'}`}>
				<li className='navigation__list--item'>
					<Link to='/gyms' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-gym`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/gyms' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-gym`} />
						</svg>
						<span>Gyms</span>
					</Link>
				</li>
				{current === 'gyms' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'gymstaff' && 'currentNav'}`}>
				<li className='navigation__list--item'>
					<Link to='/gym-role-manger' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-gym`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/gym-role-manger' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-gym`} />
						</svg>
						<span>Gyms Staff</span>
					</Link>
				</li>
				{current === 'gymstaff' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'appversion' && 'currentNav'}`}>
				<li className='navigation__list--item'>
					<Link to='/appversion' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-phone`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/appversion' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-phone`} />
						</svg>
						<span>App Versions</span>
					</Link>
				</li>
				{current === 'appversion' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'notification' ? 'currentNav' : ''}`}>
				<li className='navigation__list--item'>
					<Link to='/notification' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-notification`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='notification' className='navigation__list--link'>
						<span className='navigation__list--link--span'>
							<svg className='navigation__icon'>
								<use xlinkHref={`/assets/sprite.svg#icon-notification`} />
							</svg>
							<span>Notifications</span>
						</span>
					</Link>
				</li>
				{current === 'notification' && <div className='activeNav' />}
			</div>
		</Fragment>
	);
};

export default AdminSidebar;
