import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';
import { convertDateTimeToDDSlashMMSlashYYYY, convertDateTimeToYYYYDashMMDashDD, timeStringformatAMPM } from '../../utils/date';

const BookingsData = ({ data }) => {
	const {
		showFilter,
		setSelectedId,
		openUpdateBookedResourceModal,
		setShowCancelBookingModal,
		pageNumber,
		pageLimit
	} = data

	const getUserInfo = useSelector((state) => state.getUserInfo);
	const resourceBookingList = useSelector((state) => state.resourceBookingList)
	const isValidTime = (bookingDate, fromTime) => {
		const timeWithTodayStr =
			convertDateTimeToYYYYDashMMDashDD(new Date(bookingDate)) + ' ' + fromTime;
		const timeWithToday = new Date(timeWithTodayStr);

		if (timeWithToday > new Date()) {
			return false;
		} else {
			return true;
		}
	};
	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '65vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>
							Resource Name
						</th>
						<th>Booked By (Trainer)</th>
						<th>Booked From</th>
						<th>Booked Till</th>
						<th>Booking Date</th>
						<th>Reservation Fees</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{resourceBookingList.loading ? (
						<tr>
							<td colSpan='8' style={{ textAlign: 'center' }}>
								<Spinner />
							</td>
						</tr>
					) : resourceBookingList &&
						resourceBookingList.userInfo &&
						resourceBookingList.userInfo.status &&
						resourceBookingList.userInfo.data.result &&
						resourceBookingList.userInfo.data.result.length > 0 ? (
						resourceBookingList.userInfo.data.result.map((d, index) => (
							<tr key={d._id}>
								<td>{pageNumber > 1 ? ((pageLimit * (pageNumber - 1)) + index + 1) : index + 1}</td>
								<td style={{ textAlign: 'left', textTransform: 'capitalize' }}>{d.resource.name}</td>
								<td>{d.trainer.name || 'NA'}</td>
								<td>{timeStringformatAMPM(d.fromTime)}</td>
								<td>{timeStringformatAMPM(d.toTime)}</td>
								<td>{d.bookingDate ? convertDateTimeToDDSlashMMSlashYYYY(new Date(d.bookingDate)) : 'NA'}</td>
								<td>${d.reservationFees ? d.reservationFees : 0}</td>
								<td>
									<div className='table__iconBox'>
										{getUserInfo &&
											getUserInfo.userInfo &&
											getUserInfo.userInfo.data &&
											(getUserInfo.userInfo.data.role === 'gym' ||
												getUserInfo.userInfo.data.role === 'gymadmin' ||
												getUserInfo.userInfo.data.role === 'superadmin') && (
												<Fragment>
													<button
														className={`table__button ${isValidTime(d.bookingDate, d.fromTime) && 'table__button--disabled'}`}
														onClick={() => {
															if (isValidTime(d.bookingDate, d.fromTime)) {
																return;
															}
															setSelectedId(d._id);
															openUpdateBookedResourceModal(d._id);
														}}>
														<svg className={`table__button--icon ${isValidTime(d.bookingDate, d.fromTime) && 'table__button--icon--disabled'}`}>
															<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
														</svg>
														<span>Edit Booking Details</span>
													</button>
													<button
														className={`table__button table__button--delete ${isValidTime(d.bookingDate, d.fromTime) && 'table__button--disabled'}`}
														onClick={() => {
															if (isValidTime(d.bookingDate, d.fromTime)) {
																return;
															}
															setSelectedId(d._id)
															setShowCancelBookingModal(true);
														}}>
														<svg className={`table__button--icon-red ${isValidTime(d.bookingDate, d.fromTime) && 'table__button--icon--disabled'}`}>
															<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
														</svg>
														<span>Cancel Boooking</span>
													</button>
												</Fragment>
											)}
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan='8' style={{ textAlign: 'center' }}>
								<NoData title='No Data Found!' subTitle='We could not find any bookings.' height='40vh' />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default BookingsData;
