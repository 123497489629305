export const amountToString = ( num ) => {
  const formatter = new Intl.NumberFormat( 'en-US', {
    maximumSignificantDigits: 3,
  } );
  return formatter.format( num );
};

export const amountToStringWithSymbol = ( num ) => {
  const formatter = new Intl.NumberFormat( 'en-US', {
    style: 'currency',
    currency: 'USD'
  } );
  return formatter.format( num );
};
