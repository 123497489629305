import React from 'react';
import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';
import { phoneMasking } from '../../utils/phonemask';
import { validatePhoneMaskingString } from '../../utils/validators';

const GymData = ({
	showFilter,
	gymList,
	setModalDeleteState,
	setShowAddModal,
	setSelectUpdateModel,
	setModalChangePasswordState,
	onChangeHandler,
	handleEditModalGym,
	setModalSettingState,
	setAppMenu
}) => {
	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '65vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>Name</th>
						<th>Email</th>
						<th>Phone</th>
						<th>Active</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{gymList.loading ? (
						<tr>
							<td colSpan='7' style={{ textAlign: 'center' }}>
								<Spinner />
							</td>
						</tr>
					) : gymList &&
						gymList.userInfo &&
						gymList.userInfo.status &&
						gymList.userInfo.data.result &&
						gymList.userInfo.data.result.length > 0 ? (
						gymList.userInfo.data.result.map((d, index) => (
							<tr key={d._id}>
								<td>{index + 1}</td>
								<td style={{ textAlign: 'left' }}>{d.name}</td>
								<td>{d.email}</td>
								<td>{validatePhoneMaskingString(d.phone) ? phoneMasking(d.phone) :d.phone}</td>  
								<td>
									<label className='switch'>
										<input
											id={d._id}
											checked={d.active}
											onChange={() => {
												onChangeHandler(d);
											}}
											type='checkbox'
											className='checkbox'
											name='active'
										/>
										<span className='slider round' />
									</label>
								</td>
								<td>
									<div className='table__iconBox'>
										<button
											className='table__button'
											onClick={() => {
												setShowAddModal(true);
												setSelectUpdateModel(d);
												handleEditModalGym(d);
											}}>
											<svg className='table__button--icon'>
												<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
											</svg>
											<span>Edit Gym Details</span>
										</button>
										<button
											className='table__button table__button--setting'
											onClick={() => {
												if (d.appMenu) {
													setAppMenu({
														isVideo: d.appMenu && d.appMenu.isVideo,
														isCheckin: d.appMenu && d.appMenu.isCheckin,
														isPOS: d.appMenu && d.appMenu.isPOS,
														isBMICalculator: d.appMenu && d.appMenu.isBMICalculator,
														isLiabilityFormMandatory: d.appMenu && d.appMenu.isLiabilityFormMandatory,
														isDeleteUser: d.appMenu && d.appMenu.isDeleteUser,
														resource: d.appMenu && d.appMenu.resource,
													});
												} else {
													setAppMenu({
														isVideo: false,
														isCheckin: false,
														isPOS: false,
														isBMICalculator: false,
														isLiabilityFormMandatory: false,
														isDeleteUser: false,
														resource: false,
													});
												}
												setSelectUpdateModel(d);
												setModalSettingState(true);
											}}>
											<svg className='table__button--icon-setting'>
												<use xlinkHref={`/assets/sprite.svg#icon-setting`} />
											</svg>
											<span>App Settings</span>
										</button>
										<button
											className='table__button table__button--changepwd'
											onClick={() => {
												setModalChangePasswordState(true);
												setSelectUpdateModel(d);
											}}>
											<svg className='table__button--icon-green'>
												<use xlinkHref={`/assets/sprite.svg#icon-key`} />
											</svg>
											<span>Reset Password</span>
										</button>
										<button
											className='table__button table__button--delete'
											onClick={() => {
												setModalDeleteState(true);
												setSelectUpdateModel(d);
											}}>
											<svg className='table__button--icon-red'>
												<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
											</svg>
											<span>Delete Gym</span>
										</button>
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan='7' style={{ textAlign: 'center' }}>
								<NoData title='No Data Found!' subTitle='We could not find any gym. Please add one' height='40vh' />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default GymData;
