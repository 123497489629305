import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Content from '../../components/Content';
import SelectBoxes from './SelectBoxes';
import RoundCard from './RoundCard';
import Spinner from '../../components/Spinner/Spinner';

import TemplateAssignStyles from './TemplateAssign.module.css';

import {
	assignTemplateExerciseAction,
	getAllEnableNutritionTemplateAction,
	getAllEnableTemplateAction,
	getAssignTemplateByUserAction,
	getMuscleTypeAction,
	// getTemplateExerciseMuscleTypeAction
} from '../../redux/actions/templateActions';
import {
	ASSIGN_TEMPLATE_EXERCISE_RESET,
	GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_RESET,
	GET_ALL_ENABLE_TEMPLATE_RESET,
	GET_ASSIGN_TEMPLATE_BY_USER_RESET,
	GET_TEMPLATE_EXERCISE_MUSCLETYPE_RESET,
	GET_TEMPLATE_MUSCLETYPE_RESET
} from '../../redux/constants/templateConstants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import NoData from '../../components/NoData';
import ModalButton from '../../components/Modal/ModalButton';
import { ButtonPrefix } from '../../components/formInputs/Button';

const TemplateAssignUser = ({ match }) => {
	const dispatch = useDispatch();
	const [templatesList, setTemplatesList] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState('');

	// const [muscleType, setMuscleType] = useState([]);
	// const [selectedMuscleType, setSelectedMuscleType] = useState('');
	// const getMuscleType = useSelector((state) => state.getMuscleType);

	const [exercises, setExercises] = useState([]);
	const [nutritionTemplateList, setNutritionTemplateList] = useState([]);
	const [selectedNutrition, setSelectedNutrition] = useState('');

	const [checked, setChecked] = useState('');
	const getUserInfo = useSelector((state) => state.getUserInfo);
	const getAllEnableTemplate = useSelector((state) => state.getAllEnableTemplate);
	const getTemplateExerciseByUser = useSelector((state) => state.getTemplateExerciseByUser);
	const assignExrciseTemplate = useSelector((state) => state.assignExrciseTemplate);
	const getAllEnableNutritionTemplate = useSelector((state) => state.getAllEnableNutritionTemplate);
	console.log(getAllEnableNutritionTemplate, 'getAllEnableNutritionTemplate');

	useEffect(
		() => {
			dispatch(getMuscleTypeAction());
			dispatch(getAllEnableNutritionTemplateAction());
			dispatch(getAllEnableTemplateAction({ pageNumber: 1, pageSize: 1000, filter: '' }));
			return () => {
				dispatch({ type: GET_TEMPLATE_MUSCLETYPE_RESET });
				dispatch({ type: GET_ALL_ENABLE_TEMPLATE_RESET });
				dispatch({ type: GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_RESET });
			};
		},
		[dispatch]
	);

	// useEffect(
	// 	() => {
	// 		if (
	// 			getMuscleType &&
	// 			getMuscleType.categories &&
	// 			getMuscleType.categories.data &&
	// 			getMuscleType.categories.data.length > 0
	// 		) {
	// 			setMuscleType(getMuscleType.categories.data);
	// 			setSelectedMuscleType(getMuscleType.categories.data[0].value);
	// 		}
	// 	},
	// 	[getMuscleType]
	// );

	useEffect(
		() => {
			if (
				getAllEnableTemplate &&
				getAllEnableTemplate.categories &&
				getAllEnableTemplate.categories.data &&
				getAllEnableTemplate.categories.data.length > 0
			) {
				setTemplatesList(getAllEnableTemplate.categories.data);
				setSelectedTemplate(getAllEnableTemplate.categories.data[0]._id);
			}
		},
		[getAllEnableTemplate]
	);

	useEffect(
		() => {
			if (
				getAllEnableNutritionTemplate &&
				getAllEnableNutritionTemplate.categories &&
				getAllEnableNutritionTemplate.categories.data &&
				getAllEnableNutritionTemplate.categories.data.length > 0
			) {
				setNutritionTemplateList(getAllEnableNutritionTemplate.categories.data);
			}
		},
		[getAllEnableNutritionTemplate]
	);

	useEffect(
		() => {
			if (selectedTemplate !== '') {
				dispatch(getAssignTemplateByUserAction(selectedTemplate, match.params.userId));
			}
			return () => {
				dispatch({ type: GET_ASSIGN_TEMPLATE_BY_USER_RESET });
			}
		},
		[selectedTemplate, dispatch]
	);


	useEffect(
		() => {
			if (assignExrciseTemplate && assignExrciseTemplate.category && assignExrciseTemplate.category.status) {
				toast.success(assignExrciseTemplate.category.message);
				dispatch({ type: ASSIGN_TEMPLATE_EXERCISE_RESET });
			} else if (assignExrciseTemplate && assignExrciseTemplate.category && !assignExrciseTemplate.category.status) {
				toast.error(assignExrciseTemplate.category.message);
				dispatch({ type: ASSIGN_TEMPLATE_EXERCISE_RESET });
			}
		},
		[assignExrciseTemplate, dispatch]
	);

	useEffect(
		() => {
			if (
				getTemplateExerciseByUser &&
				getTemplateExerciseByUser.categories &&
				getTemplateExerciseByUser.categories.data &&
				getTemplateExerciseByUser.categories.data &&
				getTemplateExerciseByUser.categories.data.length > 0 &&
				Array.isArray(getTemplateExerciseByUser.categories.data)
			) {
				console.log(getTemplateExerciseByUser, 'getTemplateExerciseByUser 1');
				setExercises(getTemplateExerciseByUser.categories.data);

				if (getTemplateExerciseByUser.categories && getTemplateExerciseByUser.categories.nutritionTemplate) {
					setSelectedNutrition(getTemplateExerciseByUser.categories.nutritionTemplate) 
				}

				setChecked(getTemplateExerciseByUser.categories.data[0].muscleType)
			} else if (getTemplateExerciseByUser &&
				getTemplateExerciseByUser.categories &&
				!getTemplateExerciseByUser.categories.status) {
				console.log(getTemplateExerciseByUser, 'getTemplateExerciseByUser 3');
				setExercises([]);
			}
		},
		[getTemplateExerciseByUser]
	);


	const handleAssignExercise = () => {
		dispatch(
			assignTemplateExerciseAction({
				user: match.params.userId,
				template: selectedTemplate,
				nutrition: selectedNutrition,
				exerciseList: exercises,
			})
		);
	};

	return (
		<Content currentMenu='users' headerTitle={match.params.userName ? match.params.userName : 'Assign Template'}>
			<SelectBoxes
				data={{
					nutritionTemplateList,
					selectedNutrition,
					setSelectedNutrition,
					templatesList,
					selectedTemplate,
					setSelectedTemplate,
					getUserInfo,
					handleAssignExercise
				}}
			/>

			<div className="tabset">
				{getTemplateExerciseByUser && getTemplateExerciseByUser.loading ? (
					<Spinner />
				) : (
					exercises &&
					exercises.length > 0 &&
					exercises.map((type, indexType) => (
						<Fragment key={type.value + indexType}>
							<input
								type="radio"
								name="tabset"
								id={"tab" + indexType}
								aria-controls={'tab-' + type.muscleType}
								checked={checked === type.muscleType}
								onChange={() => setChecked(type.muscleType)}
							/>
							<label style={{ textTransform: 'capitalize' }} htmlFor={"tab" + indexType}>{type.muscleType}</label>
						</Fragment>
					))
				)}

				<div className="tab-panels">
					{exercises &&
						exercises.length > 0 &&
						exercises.map((type, indexType) => (
							<section id={"tab-" + type.muscleType} className="tab-panel">
								<div className={TemplateAssignStyles.roundCards}>
									{type.exercises &&
										type.exercises.length > 0 ? (
										type.exercises.map((exerciseData, mainIndex) => (
											exerciseData &&
											exerciseData.exercise &&
											exerciseData.exercise.length > 0 && (
												<RoundCard data={{ exerciseData, mainIndex, exercises, indexType, setExercises }} key={mainIndex + 1} />
											)
										))
									) : (
										getTemplateExerciseByUser &&
										!getTemplateExerciseByUser.loading &&
										getTemplateExerciseByUser.categories &&
										!getTemplateExerciseByUser.categories.status &&
										<NoData title='No Data Found!' subTitle='We could not find any exercise template.' height='40vh' />
									)}
								</div>
							</section>
						))}
				</div>
			</div>

		</Content >
	);
};

export default TemplateAssignUser;
