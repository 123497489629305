import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import LogOut from './LogoutModal';
import { logout } from '../redux/actions/userActions';

const Content = ({
	children,
	headerTitle,
	currentMenu,
	addBtn,
	addBtnText,
	addBtnIcon,
	addBtnClick,
	search,
	searchIcon,
	searchPlaceholder,
	searchvalue,
	searchOnChange,

	listFilter,
	listType,
	getTypeList,
	onTypeListChange,

	deleteBtn,
	deleteBtnText,
	onDeleteTemplateHandler
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [sideBar, setSideBar] = useState(true);
	const [modalLogOutState, setModalLogOutState] = useState(false);

	const toggleMenu = () => {
		let navigation = document.querySelector('.navigation');
		let mainContent = document.querySelector('.mainContent');
		navigation.classList.toggle('active');
		mainContent.classList.toggle('mainContent__active');
		setSideBar(!sideBar);
	};

	const userLogin = useSelector((state) => state.userLogin);

	useEffect(
		() => {
			if (userLogin && !userLogin.userInfo) {
				history.push('/login');
			}
		},
		[userLogin, history]
	);

	useEffect(
		() => {
			if (!headerTitle) {
				toggleMenu();
			}
		},
		[headerTitle]
	);

	const onLogOutModalClose = () => {
		setModalLogOutState(false);
	};

	const onLogOutHandler = () => {
		setModalLogOutState(false);
		dispatch(logout());
	};

	return (
		<main className='container'>
			<Sidebar current={currentMenu} setModalLogOutState={setModalLogOutState} />
			<div className='mainContent'>
				{/* style={{
					backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .95)), url(/assets/login-bg.jpg)'
				}}> */}
				<button className='sidebarnav__button' onClick={toggleMenu}>
					<svg className='sidebarnav__icon'>
						<use xlinkHref={`/assets/sprite.svg#icon-${sideBar ? 'chevron-left' : 'chevron-right'}`} />
					</svg>
					<svg className='sidebarnav__icon--box'>
						<use xlinkHref={`/assets/sprite.svg#icon-sidebar-arrow-box`} />
					</svg>
				</button>
				{headerTitle && (
					<Header
						title={headerTitle}
						addBtn={addBtn}
						addBtnText={addBtnText}
						addBtnIcon={addBtnIcon}
						addBtnClick={addBtnClick}
						search={search}
						searchIcon={searchIcon}
						searchPlaceholder={searchPlaceholder}
						searchvalue={searchvalue}
						searchOnChange={searchOnChange}
						listFilter={listFilter}
						listType={listType}
						getTypeList={getTypeList}
						onTypeListChange={onTypeListChange}
						deleteBtn={deleteBtn}
						deleteBtnText={deleteBtnText}
						onDeleteTemplateHandler={onDeleteTemplateHandler}
					/>
				)}
				<div className='content'>
					{children}
					<LogOut data={{ modalLogOutState, onLogOutModalClose, onLogOutHandler }} />
				</div>
			</div>
		</main>
	);
};

export default Content;
