import React, { useState } from 'react';
import './SelectBox.css';

const SelectBox = ({ disabled = false, children, icon, name, label, value, onChange, errorMessage, style, containerStyle, iconStyles={} }) => {
	return (
		<div className='form_input'>
			{label && (
				<label htmlFor={label} className='form_input__label'>
					{label}
				</label>
			)}
			<div className='textBox'>
				{icon && (
					<label className='textBox__label'>
						<svg style={iconStyles} className='textBox__label--icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
						</svg>
					</label>
				)}
				<div className='textBox__input' style={containerStyle}>
					<select disabled={disabled} value={value} name={name} id={name} className='textBox__input--box' onChange={onChange} style={style}>
						{children}
					</select>
				</div>
			</div>

			{errorMessage && <p className='form_input__error'>{errorMessage}</p>}
		</div>
	);
};

export default SelectBox;
