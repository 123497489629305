import React, { Fragment } from 'react';
import HeadingCardsTrainer from '../../components/ReportCard/headingCardsTrainer';
import './ReportHeader.css';
import { amountToString, amountToStringWithSymbol } from '../../utils/numbers';

const ReportHeaderTrainer = ({ data, role = '', style }) => {
	const { generalCountReport, labelEarning, descriptionSessions, descriptionEarnings, descriptionGrowth } = data;

	const total_earning = generalCountReport.report && generalCountReport.report.data && generalCountReport.report.data.total_earning && amountToStringWithSymbol(generalCountReport.report.data.total_earning).replace('$', '');

	return (
		<Fragment>
			{generalCountReport &&
				generalCountReport.report &&
				generalCountReport.report.data && (
					<div className='reportHeader--tab'>
						{generalCountReport.report.data && (
							<HeadingCardsTrainer
								label='Total Sessions'
								description={descriptionSessions}
								count={generalCountReport.report.data.total_session ? generalCountReport.report.data.total_session.toFixed(2) : 0}
								icon='stopwatch'
								style={style}
								textColor='black'
							/>
						)}
						{generalCountReport.report.data && (
							<HeadingCardsTrainer
								label={labelEarning}
								description={descriptionEarnings} //this year 
								count={total_earning ? total_earning.split(".")[0] : 0}
								icon='dollar'
								style={style}
								textColor='green'
							/>
						)}

						{
							role &&
							(role === 'gym' || role === 'gymadmin') &&
							generalCountReport.report.data && (
								<HeadingCardsTrainer
									label='Total Trainers'
									description='registered with us'
									count={generalCountReport.report.data.trainerCount ? generalCountReport.report.data.trainerCount : 0}
									icon='trainer'
									style={style}
									textColor='black'
								/>
							)
						}

						{generalCountReport.report.data && (
							<HeadingCardsTrainer
								label='Total Users'
								description='registered with us'
								count={generalCountReport.report.data.userCount ? generalCountReport.report.data.userCount : 0}
								icon='users'
								style={style}
								textColor='black'
							/>
						)}


						{/* {generalCountReport.report.data && (
							<HeadingCardsTrainer
								label='Total Growth'
								description={descriptionGrowth}
								count={generalCountReport.report.data.growth_percentage ? generalCountReport.report.data.growth_percentage.toFixed(2) + ' %' : 0}
								icon={generalCountReport.report.data.growth_percentage > 0 ? 'grow' : 'trending-down'}
								style={style}
								textColor={generalCountReport.report.data.growth_percentage > 0 ? 'green' : 'red'}
							/>
						)} 
						{role &&
							role === 'admin' &&
							generalCountReport.report.data && (
								<HeadingCardsTrainer
									label='Total Gyms'
									description='registered with us'
									count={generalCountReport.report.data.gymCount ? generalCountReport.report.data.gymCount.toFixed() : 0}
									icon='gym'
									style={style}
									textColor='black'
								/>
							)}*/}
					</div>
				)}
		</Fragment>
	);
};

export default ReportHeaderTrainer;
