import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';

const GymSidebar = ({ current, userData }) => {
	return (
		<Fragment>
			<div className={`${current === 'dashboard' ? 'currentNav ' : ''}`}>
				<li className='navigation__list--item'>
					<Link to='/' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-dashboard`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/' className='navigation__list--link'>
						<span className='navigation__list--link--span'>
							<svg className='navigation__icon'>
								<use xlinkHref={`/assets/sprite.svg#icon-dashboard`} />
							</svg>
							<span>Dashboard</span>
						</span>
					</Link>
				</li>
				{current === 'dashboard' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'users' && 'currentNav'}`}>
				<li className='navigation__list--item'>
					<Link to='/users' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-users`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/users' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-users`} />
						</svg>
						<span>Users</span>
					</Link>
				</li>
				{current === 'users' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'gymstaff' && 'currentNav'}`}>
				<li className='navigation__list--item'>
					<Link to='/gym-role-manger' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-gym`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='/gym-role-manger' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-gym`} />
						</svg>
						<span>Gyms Staff</span>
					</Link>
				</li>
				{current === 'gymstaff' && <div className='activeNav' />}
			</div>
			{userData.userInfo &&
				userData.userInfo.data &&
				userData.userInfo.data.appMenu &&
				userData.userInfo.data.appMenu.resource && (
					<div className={`${current === 'resources' && 'currentNav'}`}>
						<li className='navigation__list--itemHover hasChildren'>
							<div className='navigation__list--link'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-stack`} />
								</svg>
								<span>Resources</span>
							</div>
							<ul>
								<li>
									<NavLink to='/resources' activeClassName='navigation__sub__active'>
										<svg className='navigation__icon'>
											<use xlinkHref={`/assets/sprite.svg#icon-stack`} />
										</svg>
										<span>All Resources</span>
									</NavLink>
								</li>
								<li>
									<NavLink to='/resource-bookings' activeClassName='navigation__sub__active'>
										<svg className='navigation__icon'>
											<use xlinkHref={`/assets/sprite.svg#icon-stack`} />
										</svg>
										<span>Resources Bookings</span>
									</NavLink>
								</li>
							</ul>
						</li>
						{current === 'resources' && <div className='activeNav' />}
					</div>
				)}
			<div className={`${current === 'trainers' ? 'currentNav ' : ''}`}>
				<li className='navigation__list--item'>
					<Link to='/trainers' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-trainer`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover hasChildren'>
					<div className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-trainer`} />
						</svg>
						<span>Trainers</span>
					</div>
					<ul>
						<li>
							<NavLink to='/trainers' activeClassName='navigation__sub__active'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-trainer`} />
								</svg>
								<span>All Trainers</span>
							</NavLink>
						</li>
						<li>
							<NavLink to='/company' activeClassName='navigation__sub__active'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-archive`} />
								</svg>
								<span>Trainer Companies</span>
							</NavLink>
						</li>
						<li>
							<NavLink to='/all-trainer-sessions' activeClassName='navigation__sub__active'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-trainer`} />
								</svg>
								<span>All trainer sessions</span>
							</NavLink>
						</li>
					</ul>
				</li>
				{current === 'trainers' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'videos' ? 'currentNav ' : ''}`}>
				<li className='navigation__list--item'>
					<Link to='/videos' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-film`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover hasChildren'>
					<div className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-film`} />
						</svg>
						<span>Videos</span>
					</div>
					<ul>
						<li>
							<NavLink to='/videos' activeClassName='navigation__sub__active'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-film`} />
								</svg>
								All Videos
							</NavLink>
						</li>
						<li>
							<NavLink to='/video-categories' activeClassName='navigation__sub__active'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-archive`} />
								</svg>
								Video Categories
							</NavLink>
						</li>
					</ul>
				</li>
				{current === 'videos' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'products' ? 'currentNav' : ''}`}>
				<li className='navigation__list--item'>
					<Link to='/products' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-shopping-bag`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover hasChildren'>
					<div className='navigation__list--link navigation__list-parent--link'>
						<span className='navigation__list--link--span'>
							<svg className='navigation__icon'>
								<use xlinkHref={`/assets/sprite.svg#icon-shopping-bag`} />
							</svg>
							<span>Products</span>
						</span>
					</div>
					<ul>
						<li>
							<NavLink to='/products' activeClassName='navigation__sub__active'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-shopping-bag`} />
								</svg>
								All Products
							</NavLink>
						</li>
						<li>
							<NavLink to='/product-categories' activeClassName='navigation__sub__active'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-shopping-basket`} />
								</svg>
								Product Categories
							</NavLink>
						</li>
						<li>
							<NavLink to='/order-history' activeClassName='navigation__sub__active'>
								<svg className='navigation__icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-shopping-basket`} />
								</svg>
								Orders
							</NavLink>
						</li>
					</ul>
				</li>
				{current === 'products' && <div className='activeNav' />}
			</div>

			<div className={`${current === 'notification' ? 'currentNav' : ''}`}>
				<li className='navigation__list--item'>
					<Link to='/notification' className='navigation__list--link'>
						<svg className='navigation__icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-notification`} />
						</svg>
						&nbsp;
					</Link>
				</li>
				<li className='navigation__list--itemHover'>
					<Link to='notification' className='navigation__list--link'>
						<span className='navigation__list--link--span'>
							<svg className='navigation__icon'>
								<use xlinkHref={`/assets/sprite.svg#icon-notification`} />
							</svg>
							<span>Notifications</span>
						</span>
					</Link>
				</li>
				{current === 'notification' && <div className='activeNav' />}
			</div>
			<div className={`${current === 'workout' ? 'currentNav' : ''}`}>
				<li className='navigation__list--itemHover'>
					<Link to='/workout-templates' className='navigation__list--link'>
						<span className='navigation__list--link--span'>
							<svg className='navigation__icon'>
								<use xlinkHref={`/assets/sprite.svg#icon-setting`} />
							</svg>
							<span>Workout</span>
						</span>
					</Link>
					{current === 'workout' && <div className='activeNav' />}
				</li>
			</div>
			{userData &&
				userData.userInfo &&
				userData.userInfo.data &&
				userData.userInfo.data.isSpecialApp && (
					<Fragment>
						<div className={`${current === 'app-settings' ? 'currentNav' : ''}`}>
							<li className='navigation__list--item'>
								<Link to='/app-settings' className='navigation__list--link'>
									<svg className='navigation__icon'>
										<use xlinkHref={`/assets/sprite.svg#icon-notification`} />
									</svg>
									&nbsp;
								</Link>
							</li>
							<li className='navigation__list--itemHover'>
								<Link to='/app-settings' className='navigation__list--link'>
									<span className='navigation__list--link--span'>
										<svg className='navigation__icon'>
											<use xlinkHref={`/assets/sprite.svg#icon-setting`} />
										</svg>
										<span>App Settings</span>
									</span>
								</Link>
							</li>
							{current === 'app-settings' && <div className='activeNav' />}

						</div>

						<div className={`${current === 'templates' ? 'currentNav' : ''}`}>
							<li className='navigation__list--item'>
								<Link to='/templates' className='navigation__list--link'>
									<svg className='navigation__icon'>
										<use xlinkHref={`/assets/sprite.svg#icon-shopping-bag`} />
									</svg>
									&nbsp;
								</Link>
							</li>
							<li className='navigation__list--itemHover hasChildren'>
								<div className='navigation__list--link navigation__list-parent--link'>
									<span className='navigation__list--link--span'>
										<svg className='navigation__icon'>
											<use xlinkHref={`/assets/sprite.svg#icon-dumbell`} />
										</svg>
										<span>Templates</span>
									</span>
								</div>
								<ul>
									<li>
										<NavLink to='/templates' activeClassName='navigation__sub__active'>
											<svg className='navigation__icon'>
												<use xlinkHref={`/assets/sprite.svg#icon-dumbell`} />
											</svg>
											Exercise
										</NavLink>
									</li>
									<li>
										<NavLink to='/nutrition-templates' activeClassName='navigation__sub__active'>
											<svg className='navigation__icon'>
												<use xlinkHref={`/assets/sprite.svg#icon-dumbell`} />
											</svg>
											Nutrition
										</NavLink>
									</li>
								</ul>
							</li>
							{current === 'templates' && <div className='activeNav' />}
						</div>
					</Fragment>
				)}
		</Fragment>
	);
};

export default GymSidebar;
