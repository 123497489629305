import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';

const CreateResourceTypeModal = ({ data }) => {

    const {
        newResourceType,
        setNewResourceType,
            
        newResourceTypeError,
        setNewResourceTypeError,
            
		selectedId,
        showAddResourceTypeModal,
        handleResourceTypeModalClose,
        handleCreateResourceTypeSubmit,
    } = data;

    return (
        <Modal show={showAddResourceTypeModal}  >
			<ModalHeading
				heading={selectedId ? 'Update Resource Type' : 'Add New Resource Type'}
				onClose={handleResourceTypeModalClose}
			/>
			<ModalForm onSubmit={handleCreateResourceTypeSubmit} style={{ marginBottom: '2.5rem' }}>
				<InputsSection>
					<InputBox
						label='Resource Type'
						icon='stack'
						placeholder='eg, Room, Hall, Equipment'
						value={newResourceType}
						onChange={(event) => {
							setNewResourceType(event.target.value);
							setNewResourceTypeError('');
						}}
						errorMessage={newResourceTypeError}
                    />
				</InputsSection>
				<ModalButton
					label={selectedId ? 'Update Resource Type' : 'Add'}
					icon={selectedId ? 'edit' : 'plus'}
					onClick={(e) => {
						e.preventDefault();
						handleCreateResourceTypeSubmit(selectedId);
					}}
				/>
			</ModalForm>
		</Modal>
	);
};

export default CreateResourceTypeModal;
