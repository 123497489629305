export const TRAINER_REQUEST = 'TRAINER_REQUEST';
export const TRAINER_SUCCESS = 'TRAINER_SUCCESS';
export const TRAINER_FAIL = 'TRAINER_FAIL';
export const TRAINER_RESET = 'TRAINER_RESET';

export const GET_ENABLED_TRAINER_REQUEST = 'GET_ENABLED_TRAINER__REQUEST';
export const GET_ENABLED_TRAINER_SUCCESS = 'GET_ENABLED_TRAINER__SUCCESS';
export const GET_ENABLED_TRAINER_FAIL = 'GET_ENABLED_TRAINER__FAIL';
export const GET_ENABLED_TRAINER_RESET = 'GET_ENABLED_TRAINER__RESET';

export const DELETE_TRAINER_REQUEST = 'DELETE_TRAINER_REQUEST';
export const DELETE_TRAINER_SUCCESS = 'DELETE_TRAINER_SUCCESS';
export const DELETE_TRAINER_FAIL = 'DELETE_TRAINER_FAIL';
export const DELETE_TRAINER_RESET = 'DELETE_TRAINER_RESET';

export const CREATE_NEW_TRAINER_REQUEST = 'CREATE_NEW_TRAINER_REQUEST';
export const CREATE_NEW_TRAINER_SUCCESS = 'CREATE_NEW_TRAINER_SUCCESS';
export const CREATE_NEW_TRAINER_FAIL = 'CREATE_NEW_TRAINER_FAIL';
export const CREATE_NEW_TRAINER_RESET = 'CREATE_NEW_TRAINER_RESET';

export const UPDATE_TRAINER_REQUEST = 'UPDATE_TRAINER_REQUEST';
export const UPDATE_TRAINER_SUCCESS = 'UPDATE_TRAINER_SUCCESS';
export const UPDATE_TRAINER_FAIL = 'UPDATE_TRAINER_FAIL';
export const UPDATE_TRAINER_RESET = 'UPDATE_TRAINER_RESET';

export const CHANGE_STATUS_TRAINER_REQUEST = 'CHANGE_STATUS_TRAINER_REQUEST';
export const CHANGE_STATUS_TRAINER_SUCCESS = 'CHANGE_STATUS_TRAINER_SUCCESS';
export const CHANGE_STATUS_TRAINER_FAIL = 'CHANGE_STATUS_TRAINER_FAIL';
export const CHANGE_STATUS_TRAINER_RESET = 'CHANGE_STATUS_TRAINER_RESET';

export const TRAINER_INSURANCE_REQUEST = 'TRAINER_INSURANCE_REQUEST';
export const TRAINER_INSURANCE_SUCCESS = 'TRAINER_INSURANCE_SUCCESS';
export const TRAINER_INSURANCE_FAIL = 'TRAINER_INSURANCE_FAIL';
export const TRAINER_INSURANCE_RESET = 'TRAINER_INSURANCE_RESET';
