import {
	CANCEL_RESOURCE_BOOKING_FAIL,
	CANCEL_RESOURCE_BOOKING_REQUEST,
    CANCEL_RESOURCE_BOOKING_RESET,
    CANCEL_RESOURCE_BOOKING_SUCCESS,
    CHANGE_STATUS_RESOURCE_FAIL,
    CHANGE_STATUS_RESOURCE_REQUEST,
    CHANGE_STATUS_RESOURCE_RESET,
    CHANGE_STATUS_RESOURCE_SUCCESS,
    CHANGE_STATUS_RESOURCE_TYPE_FAIL,
    CHANGE_STATUS_RESOURCE_TYPE_REQUEST,
    CHANGE_STATUS_RESOURCE_TYPE_RESET,
    CHANGE_STATUS_RESOURCE_TYPE_SUCCESS,
    CREATE_NEW_RESOURCE_FAIL,
    CREATE_NEW_RESOURCE_REQUEST,
    CREATE_NEW_RESOURCE_RESET,
    CREATE_NEW_RESOURCE_SUCCESS,
    CREATE_NEW_RESOURCE_TYPE_FAIL,
    CREATE_NEW_RESOURCE_TYPE_REQUEST,
    CREATE_NEW_RESOURCE_TYPE_RESET,
    CREATE_NEW_RESOURCE_TYPE_SUCCESS,
    CREATE_RESOURCE_BOOKING_FAIL,
    CREATE_RESOURCE_BOOKING_REQUEST,
    CREATE_RESOURCE_BOOKING_RESET,
    CREATE_RESOURCE_BOOKING_SUCCESS,
    DELETE_RESOURCE_FAIL,
    DELETE_RESOURCE_REQUEST,
    DELETE_RESOURCE_RESET,
    DELETE_RESOURCE_SUCCESS,
    DELETE_RESOURCE_TYPE_FAIL,
    DELETE_RESOURCE_TYPE_REQUEST,
    DELETE_RESOURCE_TYPE_RESET,
    DELETE_RESOURCE_TYPE_SUCCESS,
    GET_ALL_RESOURCE_BOOKINGS_FAIL,
    GET_ALL_RESOURCE_BOOKINGS_REQUEST,
    GET_ALL_RESOURCE_BOOKINGS_RESET,
    GET_ALL_RESOURCE_BOOKINGS_SUCCESS,
    GET_ALL_RESOURCE_FAIL,
    GET_ALL_RESOURCE_REQUEST,
    GET_ALL_RESOURCE_RESET,
    GET_ALL_RESOURCE_SUCCESS,
    GET_ALL_RESOURCE_TYPE_FAIL,
    GET_ALL_RESOURCE_TYPE_REQUEST,
    GET_ALL_RESOURCE_TYPE_RESET,
    GET_ALL_RESOURCE_TYPE_SUCCESS,
    GET_AVAILABLE_RESOURCE_FAIL,
    GET_AVAILABLE_RESOURCE_REQUEST,
    GET_AVAILABLE_RESOURCE_RESET,
    GET_AVAILABLE_RESOURCE_SUCCESS,
    GET_ENABLED_RESOURCE_TYPES_FAIL,
    GET_ENABLED_RESOURCE_TYPES_REQUEST,
    GET_ENABLED_RESOURCE_TYPES_RESET,
    GET_ENABLED_RESOURCE_TYPES_SUCCESS,
    GET_TIME_RESOURCE_FAIL,
    GET_TIME_RESOURCE_REQUEST,
    GET_TIME_RESOURCE_RESET,
    GET_TIME_RESOURCE_SUCCESS,
    UPDATE_BOOKED_RESOURCE_FAIL,
    UPDATE_BOOKED_RESOURCE_REQUEST,
    UPDATE_BOOKED_RESOURCE_RESET,
    UPDATE_BOOKED_RESOURCE_SUCCESS,
    UPDATE_RESOURCE_FAIL,
    UPDATE_RESOURCE_REQUEST,
    UPDATE_RESOURCE_RESET,
    UPDATE_RESOURCE_SUCCESS,
    UPDATE_RESOURCE_TYPE_FAIL,
    UPDATE_RESOURCE_TYPE_REQUEST,
    UPDATE_RESOURCE_TYPE_RESET,
    UPDATE_RESOURCE_TYPE_SUCCESS
} from '../constants/resourceConstants';

export const getResourceListReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_RESOURCE_REQUEST:
			return { loading: true };

		case GET_ALL_RESOURCE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_ALL_RESOURCE_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_RESOURCE_RESET:
			return {};

		default:
			return state;
	}
};

export const getResourceBookingListReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_RESOURCE_BOOKINGS_REQUEST:
			return { loading: true };

		case GET_ALL_RESOURCE_BOOKINGS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_ALL_RESOURCE_BOOKINGS_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_RESOURCE_BOOKINGS_RESET:
			return {};

		default:
			return state;
	}
};

export const getResourceTypeListReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_RESOURCE_TYPE_REQUEST:
			return { loading: true };

		case GET_ALL_RESOURCE_TYPE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_ALL_RESOURCE_TYPE_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_RESOURCE_TYPE_RESET:
			return {};

		default:
			return state;
	}
};

export const createNewResourceReducer = (state = {}, action) => {
	switch (action.type) {
		case CREATE_NEW_RESOURCE_REQUEST:
			return { loading: true };

		case CREATE_NEW_RESOURCE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CREATE_NEW_RESOURCE_FAIL:
			return { loading: false, error: action.payload };

		case CREATE_NEW_RESOURCE_RESET:
			return {};

		default:
			return state;
	}
};

export const updateResourceReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_RESOURCE_REQUEST:
			return { loading: true };

		case UPDATE_RESOURCE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_RESOURCE_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_RESOURCE_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteResourceReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_RESOURCE_REQUEST:
			return { loading: true };

		case DELETE_RESOURCE_SUCCESS:
			return { userInfo: action.payload.data, loading: false };

		case DELETE_RESOURCE_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_RESOURCE_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteResourceBookingReducer = (state = {}, action) => {
	switch (action.type) {
		case CANCEL_RESOURCE_BOOKING_REQUEST:
			return { loading: true };

		case CANCEL_RESOURCE_BOOKING_SUCCESS:
			return { userInfo: action.payload.userInfo, loading: false };

		case CANCEL_RESOURCE_BOOKING_FAIL:
			return { loading: false, error: action.payload };

		case CANCEL_RESOURCE_BOOKING_RESET:
			return {};

		default:
			return state;
	}
};

export const changeStatusResourceReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_STATUS_RESOURCE_REQUEST:
			return { loading: true };

		case CHANGE_STATUS_RESOURCE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_STATUS_RESOURCE_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_STATUS_RESOURCE_RESET:
			return {};

		default:
			return state;
	}
};

// Resource Type Reducers
export const getEnabledResourceTypesListReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ENABLED_RESOURCE_TYPES_REQUEST:
			return { loading: true };

		case GET_ENABLED_RESOURCE_TYPES_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_ENABLED_RESOURCE_TYPES_FAIL:
			return { loading: false, error: action.payload };

		case GET_ENABLED_RESOURCE_TYPES_RESET:
			return {};

		default:
			return state;
	}
};

export const createNewResourceTypeReducer = (state = {}, action) => {
	switch (action.type) {
		case CREATE_NEW_RESOURCE_TYPE_REQUEST:
			return { loading: true };

		case CREATE_NEW_RESOURCE_TYPE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CREATE_NEW_RESOURCE_TYPE_FAIL:
			return { loading: false, error: action.payload };

		case CREATE_NEW_RESOURCE_TYPE_RESET:
			return {};

		default:
			return state;
	}
};

export const updateResourceTypeReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_RESOURCE_TYPE_REQUEST:
			return { loading: true };

		case UPDATE_RESOURCE_TYPE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_RESOURCE_TYPE_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_RESOURCE_TYPE_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteResourceTypeReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_RESOURCE_TYPE_REQUEST:
			return { loading: true };

		case DELETE_RESOURCE_TYPE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case DELETE_RESOURCE_TYPE_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_RESOURCE_TYPE_RESET:
			return {};

		default:
			return state;
	}
};

export const changeStatusResourceTypeReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_STATUS_RESOURCE_TYPE_REQUEST:
			return { loading: true };

		case CHANGE_STATUS_RESOURCE_TYPE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_STATUS_RESOURCE_TYPE_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_STATUS_RESOURCE_TYPE_RESET:
			return {};

		default:
			return state;
	}
};

export const getAvailableResourcesReducer = (state = {}, action) => {
	switch (action.type) {
	  case GET_AVAILABLE_RESOURCE_REQUEST:
		return {loading: true};
  
	  case GET_AVAILABLE_RESOURCE_SUCCESS:
		return {userInfo: action.payload, loading: false};
  
	  case GET_AVAILABLE_RESOURCE_FAIL:
		return {loading: false, error: action.payload};
  
	  case GET_AVAILABLE_RESOURCE_RESET:
		return {userInfo: {}, loading: false};
  
	  default:
		return state;
	}
  };
  
  export const getAvailableTimeForResourceBookingReducer = (
	state = {},
	action,
  ) => {
	switch (action.type) {
	  case GET_TIME_RESOURCE_REQUEST:
		return {loading: true};
  
	  case GET_TIME_RESOURCE_SUCCESS:
		return {userInfo: action.payload, loading: false};
  
	  case GET_TIME_RESOURCE_FAIL:
		return {loading: false, error: action.payload};
  
	  case GET_TIME_RESOURCE_RESET:
		return {userInfo: {}, loading: false};
  
	  default:
		return state;
	}
  };
  
  export const createResourceBookingReducer = (state = {}, action) => {
	switch (action.type) {
	  case CREATE_RESOURCE_BOOKING_REQUEST:
		return {loading: true};
  
	  case CREATE_RESOURCE_BOOKING_SUCCESS:
		return {userInfo: action.payload, loading: false};
  
	  case CREATE_RESOURCE_BOOKING_FAIL:
		return {loading: false, error: action.payload};
  
	  case CREATE_RESOURCE_BOOKING_RESET:
		return {};
  
	  default:
		return state;
	}
  };

export const updateBookedResourceReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_BOOKED_RESOURCE_REQUEST:
			return { loading: true };

		case UPDATE_BOOKED_RESOURCE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_BOOKED_RESOURCE_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_BOOKED_RESOURCE_RESET:
			return {};

		default:
			return state;
	}
};
