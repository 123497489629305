import {
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT,
	CREATE_NEW_USER_REQUEST,
	CREATE_NEW_USER_SUCCESS,
	CREATE_NEW_USER_FAIL,
	CREATE_NEW_USER_RESET,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LIST_FAIL,
	USER_LIST_RESET,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAIL,
	DELETE_USER_RESET,
	CHANGE_STATUS_USER_REQUEST,
	CHANGE_STATUS_USER_SUCCESS,
	CHANGE_STATUS_USER_FAIL,
	CHANGE_STATUS_USER_RESET,
	GET_USER_BY_TOKEN_REQUEST,
	GET_USER_BY_TOKEN_SUCCESS,
	GET_USER_BY_TOKEN_FAIL,
	GET_USER_BY_TOKEN_RESET,
	CHANGE_PASSWORD_USER_RESET,
	CHANGE_PASSWORD_USER_FAIL,
	CHANGE_PASSWORD_USER_SUCCESS,
	CHANGE_PASSWORD_USER_REQUEST,
	CHANGE_ADMIN_REQUEST,
	CHANGE_ADMIN_SUCCESS,
	CHANGE_ADMIN_FAIL,
	CHANGE_ADMIN_RESET,
	LOGOUT_USER_REQUEST,
	LOGOUT_USER_SUCCESS,
	LOGOUT_USER_FAIL,
	LOGOUT_USER_RESET,
} from '../constants/userConstants';

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_REQUEST:
			return { loading: true };

		case USER_LOGIN_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case USER_LOGIN_FAIL:
			return { loading: false, error: action.payload };

		case USER_LOGOUT:
			return {};

		default:
			return state;
	}
};

export const createNewUserReducer = (state = {}, action) => {
	switch (action.type) {
		case CREATE_NEW_USER_REQUEST:
			return { loading: true };

		case CREATE_NEW_USER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CREATE_NEW_USER_FAIL:
			return { loading: false, error: action.payload };

		case CREATE_NEW_USER_RESET:
			return {};

		default:
			return state;
	}
};


export const getUserListReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LIST_REQUEST:
			return { loading: true };

		case USER_LIST_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case USER_LIST_FAIL:
			return { loading: false, error: action.payload };

		case USER_LIST_RESET:
			return {};

		default:
			return state;
	}
};



export const deleteUserReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_USER_REQUEST:
			return { loading: true };

		case DELETE_USER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case DELETE_USER_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_USER_RESET:
			return {};

		default:
			return state;
	}
};



export const changeStatusUserReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_STATUS_USER_REQUEST:
			return { loading: true };

		case CHANGE_STATUS_USER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_STATUS_USER_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_STATUS_USER_RESET:
			return {};

		default:
			return state;
	}
};



export const getUserByTokenReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_USER_BY_TOKEN_REQUEST:
			return { loading: true };

		case GET_USER_BY_TOKEN_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_USER_BY_TOKEN_FAIL:
			return { loading: false, error: action.payload };

		case GET_USER_BY_TOKEN_RESET:
			return {};

		default:
			return state;
	}
};

export const changePasswordUserReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_PASSWORD_USER_REQUEST:
			return { loading: true };

		case CHANGE_PASSWORD_USER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_PASSWORD_USER_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_PASSWORD_USER_RESET:
			return {};

		default:
			return state;
	}
};


export const changeAdminUserReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_ADMIN_REQUEST:
			return { loading: true };

		case CHANGE_ADMIN_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_ADMIN_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_ADMIN_RESET:
			return {};

		default:
			return state;
	}
};


export const logOutUserReducer = (state = {}, action) => {
	switch (action.type) {
		case LOGOUT_USER_REQUEST:
			return { loading: true };

		case LOGOUT_USER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case LOGOUT_USER_FAIL:
			return { loading: false, error: action.payload };

		case LOGOUT_USER_RESET:
			return {};

		default:
			return state;
	}
};

