import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import Navigation from './navigation';
import './App.css';

import { ToastContainer } from 'react-toastify';
import { getUserByTokenAction } from './redux/actions/userActions';
import { getAllEnabledGymListAction } from './redux/actions/gymActions';

const App = () => {
	const dispatch = useDispatch();
	const userLogin = useSelector((state) => state.userLogin);
	useEffect(
		() => {
			dispatch(getUserByTokenAction());
			dispatch(getAllEnabledGymListAction());
		},
		[dispatch]
	);

	
	return (
		<Fragment>
			<Navigation userLogin={userLogin} />
			<ToastContainer position='bottom-right' />
		</Fragment>
	);
};

export default App;
