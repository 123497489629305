import React, { useCallback, useState } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import TextareaBox from '../../components/formInputs/TextareaBox';
import { validateOnlyChacters, validateOnlyNumber } from '../../utils/validators';
import TemplateDetailsStyles from './WorkoutDetailScreen.module.css';
import SelectBox from '../../components/formInputs/SelectBox';

const AddNewWorkoutModal = ({ addModalActive, handleSubmit, handleAddModalClose, data, workoutData, setWorkoutData }) => {
	const {
		getMuscleTypeList,
		muscleTypeName,
		setMuscleTypeName,
		round,
		setRound,
		workoutTitleError,
		roundTwo,
		setRoundTwo,
		roundThree,
		setRoundThree,
		workoutFieldsError,
		roundFour,
		setRoundFour,
		roundFive,
		setRoundFive,
		roundSix,
		setRoundSix,
		editBtnEnable,
	} = data;


	const handleAddRound = (e) => {
		e.preventDefault()
		const updatedWorkoutData = { ...workoutData };
		updatedWorkoutData.workOut.rounds.push({ text: '', list: [{ title: '', count: '' }] });
		setWorkoutData(updatedWorkoutData);
	};

	const handleAddExercise = (roundIndex) => {
		const updatedWorkoutData = { ...workoutData };
		updatedWorkoutData.workOut.rounds[roundIndex].list.push({ title: '', count: '' });
		setWorkoutData(updatedWorkoutData);

		const exerciseElements = document.querySelectorAll(`.exercise-${roundIndex}`);
		const lastExerciseElement = exerciseElements[exerciseElements.length - 1];
		if (lastExerciseElement) {
			lastExerciseElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}
	};

	const handleSubtitleChange = (index, value) => {
		const updatedWorkoutData = { ...workoutData };
		updatedWorkoutData.workOut.rounds[index].roundTitle = value;
		setWorkoutData(updatedWorkoutData);
	};

	const handleExerciseChange = (subtitleIndex, exerciseIndex, property, value) => {
		const updatedWorkoutData = { ...workoutData };
		updatedWorkoutData.workOut.rounds[subtitleIndex].list[exerciseIndex][property] = value;
		setWorkoutData(updatedWorkoutData);
	};

	const handleExerciseDelete = (roundIndex, exerciseIndex) => {
		const updatedWorkoutData = { ...workoutData };
		updatedWorkoutData.workOut.rounds[roundIndex].list.splice(exerciseIndex, 1);
		setWorkoutData(updatedWorkoutData);
	};

	return (

		<Modal show={addModalActive} style={{ height: '58rem' }}>
			<ModalHeading heading='Add New Workout' onClose={handleAddModalClose} />
			<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
				<div>
					<div className={TemplateDetailsStyles.inputBoxTopContainer}style={{padding: '1.2rem'}} >
						<InputBox
							label='Workout title'
							placeholder='Enter workout title'
							// value={workoutData.workOut.muscleType}
							style={{ padding: '0rem 0rem', width: '24rem' }}
							inputStyles={{ marginBottom: '0rem' }}
							onChange={(e) =>{
								setWorkoutData(prevWorkoutData => ({
									...prevWorkoutData,
									workOut: {
									  ...prevWorkoutData.workOut,
									  muscleType: e.target.value
									}
								  }));
								}}
						errorMessage={workoutTitleError.value}
						/>
					</div>
				</div>
				<div className={TemplateDetailsStyles.roundInfoContainer}>
					<span>Rounds</span>
					{/* {subtitleIndex === workoutData.rounds.length - 1 && ( */}
					<button onClick={handleAddRound} className={TemplateDetailsStyles.custom_button1}>Add Round +</button>
					{/* )} */}
				</div>
				<div>
					<div 
					className={TemplateDetailsStyles.inputBoxContainers} 
					style={workoutFieldsError.value !== '' ? { height: '24rem' }: {height: '27rem'} }>
						{workoutData.workOut.rounds.map((round, roundIndex) => (
							<div key={roundIndex}>
								<div className={TemplateDetailsStyles.inputBoxContainer}>
									<InputBox
										label='Round title'
										placeholder='eg, 2 Rounds'
										style={{ padding: '0rem 0rem' }}
										inputStyles={{ marginBottom: '0rem' }}
										value={round.roundTitle}
										onChange={(e) => handleSubtitleChange(roundIndex, e.target.value)}
										// errorMessage={'Please enter number of rounds'}

									/>
									<div className={TemplateDetailsStyles.info_container}>
										<span className={TemplateDetailsStyles.excersie_name}>Exercise name</span>
										<span className={TemplateDetailsStyles.excersie_name}>Repetitions</span>
									</div>
									{round.list.map((exercise, exerciseIndex) => (
										<div key={exerciseIndex}>
											<div className={TemplateDetailsStyles.inputBoxes}>
												<InputBox
													inputStyles={{ marginBottom: '0rem' }}
													placeholder='Enter excersie name'
													value={exercise.title}
													onChange={(e) => handleExerciseChange(roundIndex, exerciseIndex, 'title', e.target.value)}
													style={{ width: '28.5rem', padding: '0rem 0rem' }}
												// errorMessage={round.repsError}

												/>
												<InputBox
													placeholder='eg, 15'
													inputStyles={{ marginBottom: '0rem' }}
													value={exercise.count}
													onChange={(e) => handleExerciseChange(roundIndex, exerciseIndex, 'count', e.target.value)}
													// errorMessage={round.repsError}
													style={{ width: '5.6rem', padding: '0rem 0rem' }}

												/>
													{exerciseIndex > 0 &&<button
													className={TemplateDetailsStyles.templateCardAction}
													onClick={() => handleExerciseDelete(roundIndex, exerciseIndex)}
													>
													<svg className={TemplateDetailsStyles.templateCardActionIcon}>
														<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
													</svg>
												</button>}
											</div>
											{exerciseIndex === round.list.length - 1 && (
												<button onClick={() => handleAddExercise(roundIndex)} className={TemplateDetailsStyles.custom_button}>Add Exercise +</button>
												)}
										</div>
									))}
								</div>
							</div>
						))}
					</div>
						{workoutFieldsError.value !== '' ? <p style={{color: 'red', marginBottom: '2rem', fontSize: '1.2rem'}}>**{workoutFieldsError.value}</p> : null}
				</div>
				<div className={TemplateDetailsStyles.saveButton}>
					<ModalButton label={editBtnEnable ? 'Update Exercise' : 'Add Workout'} icon={editBtnEnable ? 'edit' : 'plus'} onClick={handleSubmit} />
				</div>
			</ModalForm>
		</Modal>
	);
};

export default AddNewWorkoutModal;

