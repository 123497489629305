import React, { useEffect, useState } from 'react';
import Content from '../../components/Content';
import TemplateRound from './TemplateRound';
import AddNewMuscleModal from './AddNewMuscleModal';
import TemplateDetailsStyles from './TemplateDetails.module.css';
import { createTemplateExerciseAction, deleteExerciseTemplateAction, getMuscleTypeAction, getTemplateExerciseMuscleTypeAction } from '../../redux/actions/templateActions';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TEMPLATE_EXERCISE_RESET, DELETE_EXERCISE_TEMPLATE_RESET, GET_TEMPLATE_MUSCLETYPE_RESET } from '../../redux/constants/templateConstants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';
import DeleteExerciseTemplate from './DeleteExerciseTemplate';

const TemplateDetailsScreen = ({ match }) => {
	const dispatch = useDispatch();
	const [addMuscleModal, setAddMuscleModal] = useState(false);
	const [editBtnEnable, setEditBtnEnable] = useState(false);
	const [modalDeleteState, setModalDeleteState] = useState(false);
	const [templateId, setTemplateId] = useState('');

	const createExrciseTemplate = useSelector(state => state.createExrciseTemplate);
	const templateExerciseMuscleTypeList = useSelector(state => state.getTemplateExerciseMuscleType);
	const getMuscleTypeList = useSelector(state => state.getMuscleType);
	const [muscleType, setMuscleType] = useState('chest');
	const deleteExerciseTemplate = useSelector((state) => state.deleteExerciseTemplate);

	const [exerciseList, setExerciseList] = useState([]);

	const [muscleTypeName, setMuscleTypeName] = useState({ value: '', error: '' });
	const [round, setRound] = useState({
		round: 1,
		roundError: '',
		exercises: '',
		exercisesError: '',
		reps: '',
		repsError: '',
		repType: '',
		repTypeError: '',
		nextRound: '',
		nextRoundError: ''
	});

	const [roundTwo, setRoundTwo] = useState({
		round: 2,
		roundError: '',
		exercises: '',
		exercisesError: '',
		reps: '',
		repsError: '',
		repType: '',
		repTypeError: '',
		nextRound: '',
		nextRoundError: ''
	});
	const [roundThree, setRoundThree] = useState({
		round: 3,
		roundError: '',
		exercises: '',
		exercisesError: '',
		reps: '',
		repsError: '',
		repType: '',
		repTypeError: '',
		nextRound: '',
		nextRoundError: ''
	});
	const [roundFour, setRoundFour] = useState({
		round: 4,
		roundError: '',
		exercises: '',
		exercisesError: '',
		reps: '',
		repsError: '',
		repType: '',
		repTypeError: '',
		nextRound: '',
		nextRoundError: ''
	});
	const [roundFive, setRoundFive] = useState({
		round: 5,
		roundError: '',
		exercises: '',
		exercisesError: '',
		reps: '',
		repsError: '',
		repType: '',
		repTypeError: '',
		nextRound: '',
		nextRoundError: ''
	});
	const [roundSix, setRoundSix] = useState({
		round: 6,
		roundError: '',
		exercises: '',
		exercisesError: '',
		reps: '',
		repsError: '',
		repType: '',
		repTypeError: '',
		nextRound: '',
		nextRoundError: ''
	});


	useEffect(
		() => {
			dispatch(getMuscleTypeAction());
			return () => {
				dispatch({ type: GET_TEMPLATE_MUSCLETYPE_RESET });
			}
		},
		[dispatch]
	);


	useEffect(
		() => {
			if (!muscleType) {
				if (getMuscleTypeList && getMuscleTypeList.length > 0) {
					setMuscleType(getMuscleTypeList[0].value);
				}
			}
		}, [muscleType, getMuscleTypeList]
	)

	useEffect(
		() => {
			dispatch(getTemplateExerciseMuscleTypeAction(match.params.templateId, muscleType));
			// return () => {
			// 	dispatch({ type: ADD_TEMPLATE_EXERCISE_RESET });
			// }
		},
		[muscleType, dispatch]
	);

	useEffect(
		() => {
			if (createExrciseTemplate && createExrciseTemplate.category && createExrciseTemplate.category.status) {
				handleAddModalClose();
				toast.success(createExrciseTemplate.category.message);
				dispatch({ type: ADD_TEMPLATE_EXERCISE_RESET });
				dispatch(getTemplateExerciseMuscleTypeAction(match.params.templateId, muscleType));
			} else if (createExrciseTemplate && createExrciseTemplate.category && !createExrciseTemplate.category.status) {
				dispatch({ type: ADD_TEMPLATE_EXERCISE_RESET });
				toast.error(createExrciseTemplate.category.message);
			}
		},
		[createExrciseTemplate, dispatch]
	);

	useEffect(() => {
		if (templateExerciseMuscleTypeList &&
			templateExerciseMuscleTypeList.categories &&
			templateExerciseMuscleTypeList.categories.data &&
			templateExerciseMuscleTypeList.categories.data.exercises &&
			templateExerciseMuscleTypeList.categories.data.exercises.length > 0) {
			setExerciseList(templateExerciseMuscleTypeList.categories.data.exercises);
			setTemplateId(templateExerciseMuscleTypeList.categories.data._id);
			setEditBtnEnable(true);
		} else {
			setExerciseList([]);
			setEditBtnEnable(false);
		}
	}, [templateExerciseMuscleTypeList]);

	useEffect(
		() => {
			if (deleteExerciseTemplate && deleteExerciseTemplate.category && deleteExerciseTemplate.category.status) {
				toast.success(deleteExerciseTemplate.category.message);
				dispatch({ type: DELETE_EXERCISE_TEMPLATE_RESET });
				dispatch(getTemplateExerciseMuscleTypeAction(match.params.templateId, muscleType));
			} else if (deleteExerciseTemplate && deleteExerciseTemplate.category && !deleteExerciseTemplate.category.status) {
				dispatch({ type: DELETE_EXERCISE_TEMPLATE_RESET });
				toast.error(deleteExerciseTemplate.category.message);
			}
		},
		[deleteExerciseTemplate, dispatch]
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (muscleTypeName.value === '' || muscleTypeName.value.trim() === '') {
			setMuscleTypeName({ ...muscleTypeName, error: 'Please select muscle type' });
		} else if (round.exercises === '' || round.exercises.trim() === '') {
			setRound({ ...round, exercisesError: 'Required' });
		} else if (round.reps === '' || round.reps.trim() === '') {
			setRound({ ...round, repsError: 'Required' });
		} else if (round.repType === '' || round.repType.trim() === '') {
			setRound({ ...round, repTypeError: 'Required' });
		} else if (round.nextRound === '' || round.nextRound.trim() === '') {
			setRound({ ...round, nextRoundError: 'Required' });
		} else if (roundTwo.round === '') {
			setRoundTwo({ ...roundTwo, roundError: 'Required' });
		} else if (roundTwo.exercises === '' || roundTwo.exercises.trim() === '') {
			setRoundTwo({ ...roundTwo, exercisesError: 'Required' });
		} else if (roundTwo.reps === '' || roundTwo.reps.trim() === '') {
			setRoundTwo({ ...roundTwo, repsError: 'Required' });
		} else if (roundTwo.repType === '' || roundTwo.repType.trim() === '') {
			setRoundTwo({ ...roundTwo, repTypeError: 'Required' });
		} else if (roundTwo.nextRound === '' || roundTwo.nextRound.trim() === '') {
			setRoundTwo({ ...roundTwo, nextRoundError: 'Required' });
		} else if (roundThree.round === '') {
			setRoundThree({ ...roundThree, roundError: 'Required' });
		} else if (roundThree.exercises === '' || roundThree.exercises.trim() === '') {
			setRoundThree({ ...roundThree, exercisesError: 'Required' });
		} else if (roundThree.reps === '' || roundThree.reps.trim() === '') {
			setRoundThree({ ...roundThree, repsError: 'Required' });
		} else if (roundThree.repType === '' || roundThree.repType.trim() === '') {
			setRoundThree({ ...roundThree, repTypeError: 'Required' });
		} else if (roundThree.nextRound === '' || roundThree.nextRound.trim() === '') {
			setRoundThree({ ...roundThree, nextRoundError: 'Required' });
		} else if (roundFour.round === '') {
			setRoundFour({ ...roundFour, roundError: 'Required' });
		} else if (roundFour.exercises === '' || roundFour.exercises.trim() === '') {
			setRoundFour({ ...roundFour, exercisesError: 'Required' });
		} else if (roundFour.reps === '' || roundFour.reps.trim() === '') {
			setRoundFour({ ...roundFour, repsError: 'Required' });
		} else if (roundFour.repType === '' || roundFour.repType.trim() === '') {
			setRoundFour({ ...roundFour, repTypeError: 'Required' });
		} else if (roundFour.nextRound === '' || roundFour.nextRound.trim() === '') {
			setRoundFour({ ...roundFour, nextRoundError: 'Required' });
		} else if (roundFive.round === '') {
			setRoundFive({ ...roundFive, roundError: 'Required' });
		} else if (roundFive.exercises === '') {
			setRoundFive({ ...roundFive, exercisesError: 'Required' });
		} else if (roundFive.reps === '' || roundFive.reps.trim() === '') {
			setRoundFive({ ...roundFive, repsError: 'Required' });
		} else if (roundFive.repType === '' || roundFive.repType.trim() === '') {
			setRoundFive({ ...roundFive, repTypeError: 'Required' });
		} else if (roundFive.nextRound === '' || roundFive.nextRound.trim() === '') {
			setRoundFive({ ...roundFive, nextRoundError: 'Required' });
		} else if (roundSix.round === '') {
			setRoundSix({ ...roundSix, roundError: 'Required' });
		} else if (roundSix.exercises === '') {
			setRoundSix({ ...roundSix, exercisesError: 'Required' });
		} else if (roundSix.reps === '' || roundSix.reps.trim() === '') {
			setRoundSix({ ...roundSix, repsError: 'Required' });
		} else if (roundSix.repType === '' || roundSix.repType.trim() === '') {
			setRoundSix({ ...roundSix, repTypeError: 'Required' });
		} else if (roundSix.nextRound === '' || roundSix.nextRound.trim() === '') {
			setRoundSix({ ...roundSix, nextRoundError: 'Required' });
		}
		else {
			const data = {

				"template": match.params.templateId,
				"muscleType": muscleTypeName.value,
				"exerciseList": [
					{
						"round": round.round,
						"exercise": round.exercises,
						"reps": round.reps,
						"repType": round.repType,
						"nextRoundText": round.nextRound
					},
					{
						"round": roundTwo.round,
						"exercise": roundTwo.exercises,
						"reps": roundTwo.reps,
						"repType": roundTwo.repType,
						"nextRoundText": roundTwo.nextRound
					},
					{
						"round": roundThree.round,
						"exercise": roundThree.exercises,
						"reps": roundThree.reps,
						"repType": roundThree.repType,
						"nextRoundText": roundThree.nextRound
					},
					{
						"round": roundFour.round,
						"exercise": roundFour.exercises,
						"reps": roundFour.reps,
						"repType": roundFour.repType,
						"nextRoundText": roundFour.nextRound
					},
					{
						"round": roundFive.round,
						"exercise": roundFive.exercises,
						"reps": roundFive.reps,
						"repType": roundFive.repType,
						"nextRoundText": roundFive.nextRound
					},
					{
						"round": roundSix.round,
						"exercise": roundSix.exercises,
						"reps": roundSix.reps,
						"repType": roundSix.repType,
						"nextRoundText": roundSix.nextRound
					},

				]
			};
			dispatch(createTemplateExerciseAction(data));

		}
	};

	const handleAddModalClose = () => {
		setMuscleTypeName({ value: '', error: '' });
		setExerciseList([]);
		clearData();
		setAddMuscleModal(false);
	};

	const clearData = () => {
		setRound({
			round: 1,
			roundError: '',
			exercises: '',
			exercisesError: '',
			reps: '',
			repsError: '',
			repType: '',
			repTypeError: '',
			nextRound: '',
			nextRoundError: ''
		});
		setRoundTwo({
			round: 2,
			roundError: '',
			exercises: '',
			exercisesError: '',
			reps: '',
			repsError: '',
			repType: '',
			repTypeError: '',
			nextRound: '',
			nextRoundError: ''
		});
		setRoundThree({
			round: 3,
			roundError: '',
			exercises: '',
			exercisesError: '',
			reps: '',
			repsError: '',
			repType: '',
			repTypeError: '',
			nextRound: '',
			nextRoundError: ''
		});
		setRoundFour({
			round: 4,
			roundError: '',
			exercises: '',
			exercisesError: '',
			reps: '',
			repsError: '',
			repType: '',
			repTypeError: '',
			nextRound: '',
			nextRoundError: ''
		});
		setRoundFive({
			round: 5,
			roundError: '',
			exercises: '',
			exercisesError: '',
			reps: '',
			repsError: '',
			repType: '',
			repTypeError: '',
			nextRound: '',
			nextRoundError: ''
		});
		setRoundSix({
			round: 6,
			roundError: '',
			exercises: '',
			exercisesError: '',
			reps: '',
			repsError: '',
			repType: '',
			repTypeError: '',
			nextRound: '',
			nextRoundError: ''
		});
	}

	const showHandleAddModal = () => {
		setMuscleTypeName({ value: muscleType, error: '' });
		setAddMuscleModal(true);
		if (exerciseList && exerciseList.length > 0) {
			setRound({
				round: 1,
				roundError: '',
				exercises: exerciseList[0].exercise,
				exercisesError: '',
				reps: exerciseList[0].reps,
				repsError: '',
				repType: exerciseList[0].repType,
				repTypeError: '',
				nextRound: exerciseList[0].nextRoundText,
				nextRoundError: ''
			});
			setRoundTwo({
				round: 2,
				roundError: '',
				exercises: exerciseList[1].exercise,
				exercisesError: '',
				reps: exerciseList[1].reps,
				repsError: '',
				repType: exerciseList[1].repType,
				repTypeError: '',
				nextRound: exerciseList[1].nextRoundText,
				nextRoundError: ''
			});
			setRoundThree({
				round: 3,
				roundError: '',
				exercises: exerciseList[2].exercise,
				exercisesError: '',
				reps: exerciseList[2].reps,
				repsError: '',
				repType: exerciseList[2].repType,
				repTypeError: '',
				nextRound: exerciseList[2].nextRoundText,
				nextRoundError: ''
			});
			setRoundFour({
				round: 4,
				roundError: '',
				exercises: exerciseList[3].exercise,
				exercisesError: '',
				reps: exerciseList[3].reps,
				repsError: '',
				repType: exerciseList[3].repType,
				repTypeError: '',
				nextRound: exerciseList[3].nextRoundText,
				nextRoundError: ''
			});
			setRoundFive({
				round: 5,
				roundError: '',
				exercises: exerciseList[4].exercise,
				exercisesError: '',
				reps: exerciseList[4].reps,
				repsError: '',
				repType: exerciseList[4].repType,
				repTypeError: '',
				nextRound: exerciseList[4].nextRoundText,
				nextRoundError: ''
			});
			setRoundSix({
				round: 6,
				roundError: '',
				exercises: exerciseList[5].exercise,
				exercisesError: '',
				reps: exerciseList[5].reps,
				repsError: '',
				repType: exerciseList[5].repType,
				repTypeError: '',
				nextRound: exerciseList[5].nextRoundText,
				nextRoundError: ''
			});
		} else {
			clearData();
		}
	};

	const onMuscleTypeChange = (e) => {
		setMuscleType(e);
		dispatch(getTemplateExerciseMuscleTypeAction(match.params.templateId, e));
	}


	const colors = ['#ff6968', '#7a54fe', '#ff8f61', '#2ac3ff', '#5a65ff', '#96da45'];

	const onDeleteModalClose = () => {
		setModalDeleteState(false);
	};

	const onDeleteHandler = () => {
		dispatch(deleteExerciseTemplateAction(templateId));
		onDeleteModalClose();
	};

	return (
		<Content
			currentMenu='templates'
			headerTitle={match.params.templateName ? match.params.templateName : 'Template Detail'}
			addBtn={true}
			addBtnText={editBtnEnable ? 'Edit Exercise' : 'Add Exercise'}
			addBtnClick={() => showHandleAddModal()}
			addBtnIcon={editBtnEnable ? 'edit' : 'plus'}
			listFilter={true}
			listType={muscleType}
			getTypeList={getMuscleTypeList.categories && getMuscleTypeList.categories.data.length > 0 && getMuscleTypeList.categories.data}
			onTypeListChange={(e) => { onMuscleTypeChange(e) }}
			deleteBtn={editBtnEnable ? true : false}
			deleteBtnText='Delete Exercise'
			onDeleteTemplateHandler={() => setModalDeleteState(true)}
		>

			<div className={TemplateDetailsStyles.roundCards}>
				{templateExerciseMuscleTypeList && templateExerciseMuscleTypeList.loading ? (
					<Spinner />
				) :
					templateExerciseMuscleTypeList &&
						templateExerciseMuscleTypeList.categories &&
						templateExerciseMuscleTypeList.categories.data &&
						templateExerciseMuscleTypeList.categories.data.exercises &&
						templateExerciseMuscleTypeList.categories.data.exercises.length > 0 ? (
						templateExerciseMuscleTypeList.categories.data.exercises.map((template, index) =>
							<TemplateRound key={index + 1} data={template} color={colors[index]} />
						)
					) : (
						<div className='not_data_found'>
							<NoData title='No Data Found!' subTitle='We could not find any exercise template. please add one.' height='40vh' />
						</div>
					)}

			</div>

			<AddNewMuscleModal
				addModalActive={addMuscleModal}
				handleSubmit={handleSubmit}
				handleAddModalClose={handleAddModalClose}
				data={{
					editBtnEnable,
					getMuscleTypeList,
					muscleTypeName,
					setMuscleTypeName,
					round,
					setRound,
					roundTwo,
					setRoundTwo,
					roundThree,
					setRoundThree,
					roundFour,
					setRoundFour,
					roundFive,
					setRoundFive,
					roundSix,
					setRoundSix
				}}
			/>

			<DeleteExerciseTemplate data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }} />


		</Content>
	);
};

export default TemplateDetailsScreen;

