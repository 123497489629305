import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/Spinner/Spinner';
import Content from '../../components/Content';
import DeleteUser from './DeleteUser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { validateEmail, validateOnlyChacters, validateOnlyNumber, validatePassword, validatePhone } from '../../utils/validators';
import GymStaffData from './GymStaffData';
import CreateStaffUser from './CreateStaffUser';
import {
	CHANGE_STATUS_GYM_STAFF_RESET,
	CREATE_NEW_GYM_STAFF_RESET,
	DELETE_GYM_STAFF_RESET
} from '../../redux/constants/gymStaffConstants';
import {
	changeStatusGymStaffAction,
	createNewStaffGymAction,
	deleteGymStaffAction,
	getGymStaffListAction
} from '../../redux/actions/gymStaffActions';
import { changePasswordUserAction } from '../../redux/actions/userActions';
import { CHANGE_PASSWORD_USER_RESET } from '../../redux/constants/userConstants';
import { getAllEnabledGymListAction } from '../../redux/actions/gymActions';
import ChangePwdUser from '../UsersScreen/ChangePwdUser';
import Pagination from '../../components/Pagination';
import { inputPhoneMasking, unMasking } from '../../utils/phonemask';

const GymStaffScreen = ({ showFilter = false, history }) => {
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.userLogin);
	const staffList = useSelector((state) => state.gymStaffList);
	const userData = useSelector((state) => state.getUserInfo);
	const gymList = useSelector((state) => state.gymEnabledList);

	const createGymStaff = useSelector((state) => state.createGymStaff);
	const deleteGymStaff = useSelector((state) => state.deleteGymStaff);
	const changeStatusGymStaff = useSelector((state) => state.changeStatusGymStaff);
	const changePasswordUser = useSelector((state) => state.changePasswordUser);

	const [showAddModal, setShowAddModal] = useState(false);
	const [modalDeleteState, setModalDeleteState] = useState(false);
	const [modalChangePasswordState, setModalChangePasswordState] = useState(false);
	const [selectUpdateModel, setSelectUpdateModel] = useState({});
	const [id, setUserId] = useState('');
	const [roleList, setRoleList] = useState([]);

	const [search, setSearch] = useState('');
	const [totalPageSize, setTotalPageSize] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const pageLimit = 20;

	const [role, setRole] = useState('');
	const [roleError, setRoleError] = useState('');
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [address, setAddress] = useState('');
	const [addressError, setAddressError] = useState('');
	const [userType, setUserType] = useState('');

	const [gymId, setGymId] = useState('');
	const [gymError, setGymIdError] = useState('');

	useEffect(
		() => {
			if (
				userData &&
				userData.userInfo &&
				userData.userInfo.data &&
				(userData.userInfo.data.role === 'superadmin' || userData.userInfo.data.role === 'admin')
			) {
				setUserType(userData.userInfo.data.role);
				setRoleList([
					// {
					// 	_id: 1,
					// 	name: 'Super Admin',
					// 	value: 'superadmin'
					// },
					{
						_id: 2,
						name: 'Gym Admin',
						value: 'gymadmin'
					}
				]);

				dispatch(getAllEnabledGymListAction());
				dispatch(getGymStaffListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
				setTotalPageSize(staffList.totalPageSize);
			} else if (userData && userData.userInfo && userData.userInfo.data && userData.userInfo.data.role === 'gym') {
				setUserType(userData.userInfo.data.role);
				setRoleList([
					{
						_id: 2,
						name: 'Gym Admin',
						value: 'gymadmin'
					}
				]);

				dispatch(getAllEnabledGymListAction());
				dispatch(getGymStaffListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
				setTotalPageSize(staffList.totalPageSize);
			} else {
				history.push('/');
			}
		},
		[userInfo, userData, dispatch]
	);

	useEffect(
		() => {
			if (createGymStaff && createGymStaff.userInfo && createGymStaff.userInfo.status) {
				clearData();
				setShowAddModal(false);
				setSearch('');
				toast.success(createGymStaff.userInfo.message);
				dispatch({ type: CREATE_NEW_GYM_STAFF_RESET });
				dispatch(getGymStaffListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (createGymStaff && createGymStaff.userInfo && !createGymStaff.userInfo.status) {
				dispatch({ type: CREATE_NEW_GYM_STAFF_RESET });
				toast.error(createGymStaff.userInfo.message);
			}
		},
		[createGymStaff, pageNumber, pageLimit, dispatch]
	);

	useEffect(
		() => {
			if (deleteGymStaff && deleteGymStaff.userInfo && deleteGymStaff.userInfo.status) {
				toast.success(deleteGymStaff.userInfo.message);
				setSelectUpdateModel({});
				dispatch({ type: DELETE_GYM_STAFF_RESET });
				dispatch(getGymStaffListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (deleteGymStaff && deleteGymStaff.userInfo && !deleteGymStaff.userInfo.status) {
				toast.error(deleteGymStaff.userInfo.message);
				dispatch({ type: DELETE_GYM_STAFF_RESET });
				dispatch(getGymStaffListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		[deleteGymStaff, pageNumber, pageLimit, dispatch]
	);

	useEffect(
		() => {
			if (changeStatusGymStaff && changeStatusGymStaff.userInfo && changeStatusGymStaff.userInfo.status) {
				toast.success(changeStatusGymStaff.userInfo.message);
				setSelectUpdateModel({});
				dispatch({ type: CHANGE_STATUS_GYM_STAFF_RESET });
				dispatch(getGymStaffListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (changeStatusGymStaff && changeStatusGymStaff.userInfo && !changeStatusGymStaff.userInfo.status) {
				toast.error(changeStatusGymStaff.userInfo.message);
				dispatch({ type: CHANGE_STATUS_GYM_STAFF_RESET });
				dispatch(getGymStaffListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		[changeStatusGymStaff, pageNumber, pageLimit, dispatch]
	);

	useEffect(
		() => {
			if (changePasswordUser && changePasswordUser.userInfo && changePasswordUser.userInfo.status) {
				toast.success(changePasswordUser.userInfo.message);
				setSelectUpdateModel({});
				dispatch({ type: CHANGE_PASSWORD_USER_RESET });
			} else if (changePasswordUser && changePasswordUser.userInfo && !changePasswordUser.userInfo.status) {
				toast.error(changePasswordUser.userInfo.message);
				dispatch({ type: CHANGE_PASSWORD_USER_RESET });
			}
		},
		[changePasswordUser, pageNumber, pageLimit, dispatch]
	);

	const handleAddModalClose = () => {
		clearData();
		setShowAddModal(false);
	};

	const clearData = () => {
		setGymId('');
		setSelectUpdateModel({});
		setEmail('');
		setName('');
		setPhone('');
		setRole('');
		setAddress('');
		setPassword('');
		setRoleError('');
		setNameError('');
		setPhoneError('');
		setEmailError('');
		setPasswordError('');
		setAddressError('');
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (name === '' && name.trim() === '') {
			setNameError('Please enter name');
		} else if (!validateOnlyChacters(name)) {
			setNameError('Name should have only alphabets');
		} else if (email === '' && email.trim() === '') {
			setEmailError('Please enter email address');
		} else if (!validateEmail(email)) {
			setEmailError('Please enter valid email');
		} else if (phone === '' && phone.trim() === '') {
			setPhoneError('Please enter phone');
		} else if (!validatePhone(unMasking(phone))) {
			setPhoneError('Phone number must be 10 digits');
		} else if (address === '' && address.trim() === '') {
			setAddressError('Please enter address');
		} else if (id === '' && !validatePassword(password)) {
			setPasswordError('Please enter a valid password');
		} else if (id === '' && password.length < 7) {
			setPasswordError('Password must be at least 8 characters');
		} else if (role === '' && role.trim() === '') {
			setRoleError('Please select role');
		} else if (userType === 'admin' && gymId === '' && gymId.trim() === '') {
			setGymIdError('Please select gym');
		} else {
			if (id !== '') {
				dispatch(createNewStaffGymAction({ id, name, email, address, phone: unMasking(phone), role, gym: gymId }));
			} else {
				dispatch(createNewStaffGymAction({ name, email, password, address, phone: unMasking(phone), role, gym: gymId }));
			}
		}
	};

	const onDeleteModalClose = () => {
		setModalDeleteState(false);
	};

	const onDeleteHandler = () => {
		dispatch(deleteGymStaffAction(selectUpdateModel._id));
		onDeleteModalClose();
	};

	const handlePageChange = async (currentPage) => {
		const selectedPage = currentPage.selected;
		dispatch(getGymStaffListAction({ pageNumber: selectedPage + 1, pageSize: pageLimit, filter: '' }));
		setPageNumber(selectedPage + 1);
	};

	const handleEditModalUser = (d) => {
		setSelectUpdateModel(d);
		setUserId(d._id);
		setEmail(d.email);
		setAddress(d.address);
		setPhone(inputPhoneMasking(d.phone));
		setName(d.name);
		setRole(d.role);
		if (userType === 'admin') {
			setGymId(d.gym);
		} else {
			setGymId(null);
		}
		setShowAddModal(true);
	};

	const onChangeHandler = (d) => {
		dispatch(
			changeStatusGymStaffAction(d._id, {
				enable: d.active ? true : false
			})
		);
	};

	const onChangePwdModalClose = () => {
		setModalChangePasswordState(false);
	};

	const onChangePwdUserHandler = () => {
		let d = selectUpdateModel;
		let type = 'byAdmin';
		dispatch(changePasswordUserAction({ id: d._id, type, email: d.email }));
		onChangePwdModalClose();
	};

	const onSearchHandler = (event) => {
		setSearch(event.target.value);
		if (search.trim !== '' && search.length > 0) {
			setPageNumber(1);
		}
		dispatch(getGymStaffListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: event.target.value }));
	};

	return (
		<Content
			currentMenu='gymstaff'
			headerTitle='Gym Staff'
			addBtn={true}
			addBtnText='Add Gym User'
			addBtnIcon='plus'
			addBtnClick={() => setShowAddModal(true)}
			search={true}
			searchPlaceholder='Search Gym Users...'
			searchIcon='search'
			searchvalue={search}
			searchOnChange={onSearchHandler}>

			<div>
				<GymStaffData
					staffList={staffList}
					showFilter={showFilter}
					setModalDeleteState={setModalDeleteState}
					setSelectUpdateModel={setSelectUpdateModel}
					handleEditModalUser={handleEditModalUser}
					onChangeHandler={onChangeHandler}
					setModalChangePasswordState={setModalChangePasswordState}
				/>
				{totalPageSize > 1 && (
					<div className='tableContainer--paginater'>
						<Pagination
							list={staffList.userInfo}
							onPageChange={handlePageChange}
							rowsPerPage={pageLimit}
							totalPageSize={totalPageSize}
							pageNumber={pageNumber}
						/>
					</div>
				)}
			</div>


			<CreateStaffUser
				data={{
					showAddModal,
					handleAddModalClose,
					handleSubmit,
					name,
					email,
					phone,
					address,
					password,
					role,

					setName,
					setEmail,
					setPhone,
					setRole,
					setAddress,
					setPassword,

					nameError,
					phoneError,
					emailError,
					addressError,
					passwordError,
					roleError,

					setRoleError,
					setNameError,
					setPhoneError,
					setEmailError,
					setAddressError,
					setPasswordError,

					userType,
					gymId,
					setGymId,
					gymError,
					setGymIdError,

					roleList,
					gymList,
					selectUpdateModel
				}}
			/>
			<DeleteUser data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }} />

			<ChangePwdUser data={{ modalChangePasswordState, onChangePwdModalClose, onChangePwdUserHandler }} />

			{/* <ChangePassword data={{
				selectUpdateModel,
				setSelectUpdateModel,
				modalChangePasswordState,
				setModalChangePasswordState,
			}} /> */}
		</Content>
	);
};

export default GymStaffScreen;
