import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Content from '../../components/Content'
import Spinner from '../../components/Spinner/Spinner'
import DeleteGym from './DeleteGym'
import GymData from './GymData'
import CreateEditGym from './CreateEditGym'
import ChangePwdUser from '../UsersScreen/ChangePwdUser'
import Pagination from '../../components/Pagination'
import MenuSettingUser from './MenuSettingUser'

import {
  validateEmail,
  validateOnlyChacters,
  validatePackage,
  validatePassword,
  validatePhone,
  validateUrl,
} from '../../utils/validators'
import { changePasswordUserAction } from '../../redux/actions/userActions'
import { changeMenuSettingsAction } from '../../redux/actions/gymActions'
import {
  changeStatusGymAction,
  createNewGymAction,
  deleteGymAction,
  getGymListAction,
} from '../../redux/actions/gymActions'
import { CHANGE_PASSWORD_USER_RESET } from '../../redux/constants/userConstants'
import {
  CHANGE_MENU_SETTINGS_RESET,
  CHANGE_STATUS_GYM_RESET,
  CREATE_NEW_GYM_RESET,
  DELETE_GYM_RESET,
  GYM_RESET,
} from '../../redux/constants/gymConstants'
import { inputPhoneMasking, unMasking } from '../../utils/phonemask'
// import GymsStyle from './Gyms.module.css';
// import { useLocation } from 'react-router-dom';

const GymsScreen = ({ showFilter = false, history }) => {
  // const location = useLocation();
  // console.log(location, window.location.href);
  const dispatch = useDispatch()
  const getUserInfo = useSelector((state) => state.getUserInfo)

  const createGym = useSelector((state) => state.createGym)
  const changeStatusGym = useSelector((state) => state.changeStatusGym)
  const deleteGym = useSelector((state) => state.deleteGym)
  const changePasswordUser = useSelector((state) => state.changePasswordUser)
  const changeAppMenuSetting = useSelector(
    (state) => state.changeAppMenuSetting
  )

  const gymList = useSelector((state) => state.gymList)
  const [showAddModal, setShowAddModal] = useState(false)
  const [modalDeleteState, setModalDeleteState] = useState(false)
  const [modalChangePasswordState, setModalChangePasswordState] =
    useState(false)
  const [selectUpdateModel, setSelectUpdateModel] = useState({})
  const [uploadFileData, setUploadFileData] = useState('')
  const [uploadFileDataError, setUploadFileDataError] = useState('')
  const [uploadDarkFileData, setUploadDarkFileData] = useState('')
  const [uploadDarkFileDataError, setUploadDarkFileDataError] = useState('')

  const [id, setGymId] = useState('')

  const [imageSrc, setImageSrc] = useState('')
  const [imageSrcError, setImageSrcError] = useState('')
  const [darkimageSrc, setDarkImageSrc] = useState('')
  const [modalSettingState, setModalSettingState] = useState(false)
  const [appMenu, setAppMenu] = useState({})

  const [search, setSearch] = useState('')
  const [totalPageSize, setTotalPageSize] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const pageLimit = 20

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [address, setAddress] = useState('')
  const [addressError, setAddressError] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState('')

  const [specialApp, setSpecialApp] = useState(false)
  const [gymPackageName, setGymPackageName] = useState({ value: '', error: '' })
  const [gymUrl, setGymUrl] = useState({ value: '', error: '' })

  useEffect(() => {
    if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      getUserInfo.userInfo.data.role === 'admin'
    ) {
      dispatch(
        getGymListAction({
          pageNumber: pageNumber,
          pageSize: pageLimit,
          filter: '',
        })
      )

      return () => {
        dispatch({ type: GYM_RESET })
      }
    } else {
      history.push('/')
    }
  }, [getUserInfo, dispatch, pageNumber, history])

  useEffect(() => {
    if (
      gymList &&
      gymList.userInfo &&
      gymList.userInfo.status &&
      gymList.userInfo.data.result.length > 0
    ) {
      setTotalPageSize(gymList.userInfo.data.totalPageSize)
    }
  }, [gymList])

  useEffect(() => {
    if (createGym && createGym.userInfo && createGym.userInfo.status) {
      clearData()
      setShowAddModal(false)
      setSearch('')
      toast.success(createGym.userInfo.message)
      dispatch({ type: CREATE_NEW_GYM_RESET })
      dispatch(
        getGymListAction({
          pageNumber: pageNumber,
          pageSize: pageLimit,
          filter: '',
        })
      )
    } else if (createGym && createGym.userInfo && !createGym.userInfo.status) {
      dispatch({ type: CREATE_NEW_GYM_RESET })
      toast.error(createGym.userInfo.message)
    } else if (deleteGym && deleteGym.userInfo && deleteGym.userInfo.status) {
      toast.success(deleteGym.userInfo.message)
      dispatch({ type: DELETE_GYM_RESET })
      dispatch(
        getGymListAction({
          pageNumber: pageNumber,
          pageSize: pageLimit,
          filter: '',
        })
      )
    } else if (deleteGym && deleteGym.userInfo && !deleteGym.userInfo.status) {
      toast.error(deleteGym.userInfo.message)
      dispatch({ type: DELETE_GYM_RESET })
      dispatch(
        getGymListAction({
          pageNumber: pageNumber,
          pageSize: pageLimit,
          filter: '',
        })
      )
    } else if (
      changeStatusGym &&
      changeStatusGym.userInfo &&
      changeStatusGym.userInfo.status
    ) {
      toast.success(changeStatusGym.userInfo.message)
      setSelectUpdateModel({})
      dispatch({ type: CHANGE_STATUS_GYM_RESET })
      dispatch(
        getGymListAction({
          pageNumber: pageNumber,
          pageSize: pageLimit,
          filter: '',
        })
      )
    } else if (
      changeStatusGym &&
      changeStatusGym.userInfo &&
      !changeStatusGym.userInfo.status
    ) {
      toast.error(changeStatusGym.userInfo.message)
      dispatch({ type: CHANGE_STATUS_GYM_RESET })
      dispatch(
        getGymListAction({
          pageNumber: pageNumber,
          pageSize: pageLimit,
          filter: '',
        })
      )
    } else if (
      changePasswordUser &&
      changePasswordUser.userInfo &&
      changePasswordUser.userInfo.status
    ) {
      toast.success(changePasswordUser.userInfo.message)
      setSelectUpdateModel({})
      dispatch({ type: CHANGE_PASSWORD_USER_RESET })
    } else if (
      changePasswordUser &&
      changePasswordUser.userInfo &&
      !changePasswordUser.userInfo.status
    ) {
      toast.error(changePasswordUser.userInfo.message)
      dispatch({ type: CHANGE_PASSWORD_USER_RESET })
    } else if (
      changeAppMenuSetting &&
      changeAppMenuSetting.userInfo &&
      changeAppMenuSetting.userInfo.status
    ) {
      toast.success(changeAppMenuSetting.userInfo.message)
      setSelectUpdateModel({})
      dispatch({ type: CHANGE_MENU_SETTINGS_RESET })
      dispatch(
        getGymListAction({
          pageNumber: pageNumber,
          pageSize: pageLimit,
          filter: '',
        })
      )
    } else if (
      changeAppMenuSetting &&
      changeAppMenuSetting.userInfo &&
      !changeAppMenuSetting.userInfo.status
    ) {
      toast.error(changeAppMenuSetting.userInfo.message)
      dispatch({ type: CHANGE_MENU_SETTINGS_RESET })
    }
  }, [
    createGym,
    changeStatusGym,
    changePasswordUser,
    changeAppMenuSetting,
    deleteGym,
    dispatch,
  ])

  const clearData = () => {
    setGymId('')
    setEmail('')
    setPhone('')
    setAddress('')
    setName('')
    setEmailError('')
    setPassword('')
    setPasswordError('')
    setPhoneError('')
    setAddressError('')
    setNameError('')
    setImageSrc('')
    setUploadFileData('')
    setUploadFileDataError('')
    setUploadDarkFileData('')
    setUploadDarkFileDataError('')
    setDarkImageSrc('')
    setSelectUpdateModel({})
    setSpecialApp(false)
    setGymPackageName({ value: '', error: '' })
    setGymUrl({ value: '', error: '' })
  }

  const handleAddModalClose = () => {
    clearData()
    setShowAddModal(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // debugger
    if (name === '' && name.trim() === '') {
      setNameError('Please enter name')
    } else if (!validateOnlyChacters(name)) {
      setNameError('Name should have only alphabets')
    } else if (email === '' && email.trim() === '') {
      setEmailError('Please enter email address')
    } else if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address')
    } else if (phone === '' && phone.trim() === '') {
      setPhoneError('Please enter phone')
    } else if (!validatePhone(unMasking(phone))) {
      setPhoneError('Phone number must be 10 digits')
    } else if (address === '' && address.trim() === '') {
      setAddressError('Please enter address')
    } else if (!imageSrc) {
      setUploadFileDataError('Please select gym light logo')
    } else if (!darkimageSrc) {
      setUploadDarkFileDataError('Please select gym dark logo')
    } else if (id === '' && !validatePassword(password)) {
      setPasswordError('Please enter a valid password')
    } else if (id === '' && password.length < 7) {
      setPasswordError('Password must be at least 8 characters')
    } else if (
      specialApp &&
      (gymPackageName.value === '' || gymPackageName.value.trim() === '')
    ) {
      setGymPackageName({
        ...gymPackageName,
        error: 'Please enter gym package name',
      })
    } else if (specialApp && !validatePackage(gymPackageName.value)) {
      setGymPackageName({
        ...gymPackageName,
        error: 'Please enter valid gym package name',
      })
    } else if (
      specialApp &&
      (gymUrl.value === '' || gymUrl.value.trim() === '')
    ) {
      setGymUrl({ ...gymUrl, error: 'Please enter gym URL' })
    } else if (specialApp && !validateUrl(gymUrl.value)) {
      setGymUrl({ ...gymUrl, error: 'Please enter valid gym url' })
    } else {
      let fileData = new FormData()
      if (id !== '') {
        fileData.append('id', id)
      } else {
        fileData.append('password', password)
      }

      fileData.append('name', name)
      fileData.append('email', email)
      fileData.append('address', address)
      fileData.append('phone', unMasking(phone))
      fileData.append('isSpecialApp', specialApp)
      fileData.append('packageName', gymPackageName.value)
      fileData.append('webUrl', gymUrl.value)

      uploadFileData.length > 0 && fileData.append('image', uploadFileData[0])
      uploadDarkFileData.length > 0 &&
        fileData.append('darkImage', uploadDarkFileData[0])

      dispatch(createNewGymAction(fileData))
    }
  }

  const onDeleteModalClose = () => {
    setSelectUpdateModel({})
    dispatch(
      getGymListAction({
        pageNumber: pageNumber,
        pageSize: pageLimit,
        filter: '',
      })
    )
    setModalDeleteState(false)
  }

  const onDeleteHandler = () => {
    dispatch(deleteGymAction(selectUpdateModel._id))
    onDeleteModalClose()
  }

  const handlePageChange = async (currentPage) => {
    const selectedPage = currentPage.selected
    dispatch(
      getGymListAction({
        pageNumber: selectedPage + 1,
        pageSize: pageLimit,
        filter: '',
      })
    )
    setPageNumber(selectedPage + 1)
  }

  const onChangeHandler = (d) => {
    dispatch(
      changeStatusGymAction(d._id, {
        enable: d.active ? true : false,
      })
    )
  }

  const handleEditModalGym = (d) => {
    setGymId(d._id)
    setEmail(d.email)
    setPhone(inputPhoneMasking(d.phone))
    setName(d.name)
    setAddress(d.address)
    setImageSrc(d.photo)
    setDarkImageSrc(d.photoDark)
    setSpecialApp(d.isSpecialApp ? d.isSpecialApp : false)
    setGymPackageName({ value: d.packageName, error: '' })
    setGymUrl({ value: d.webUrl, error: '' })
    setShowAddModal(true)
  }

  const onChangePwdModalClose = () => {
    setModalChangePasswordState(false)
  }

  const onChangePwdUserHandler = () => {
    let d = selectUpdateModel
    let type = 'byAdmin'
    dispatch(changePasswordUserAction({ id: d._id, type, email: d.email }))
    onChangePwdModalClose()
  }

  const onSettingHandler = () => {
    let d = selectUpdateModel
    onSettingModalClose(false)
    dispatch(changeMenuSettingsAction(d._id, appMenu))
  }

  const onSettingModalClose = () => {
    setModalSettingState(false)
  }

  const onSearchHandler = (event) => {
    setSearch(event.target.value)
    if (search.trim !== '' && search.length > 0) {
      setPageNumber(1)
    }
    dispatch(
      getGymListAction({
        pageNumber: pageNumber,
        pageSize: pageLimit,
        filter: event.target.value,
      })
    )
  }

  return (
    <Content
      currentMenu='gyms'
      headerTitle='Gyms'
      addBtn={
        getUserInfo &&
        getUserInfo.userInfo &&
        getUserInfo.userInfo.data &&
        getUserInfo.userInfo.data.role === 'admin'
          ? true
          : false
      }
      addBtnText='Add New Gym'
      addBtnIcon='plus'
      addBtnClick={() => setShowAddModal(true)}
      search={true}
      searchPlaceholder='Search Gyms...'
      searchIcon='search'
      searchvalue={search}
      searchOnChange={onSearchHandler}>
      <div>
        <GymData
          gymList={gymList}
          showFilter={showFilter}
          setShowAddModal={setShowAddModal}
          setSelectUpdateModel={setSelectUpdateModel}
          setModalDeleteState={setModalDeleteState}
          onChangeHandler={onChangeHandler}
          handleEditModalGym={handleEditModalGym}
          setModalChangePasswordState={setModalChangePasswordState}
          setModalSettingState={setModalSettingState}
          setAppMenu={setAppMenu}
        />
        {totalPageSize > 1 && (
          <div className='tableContainer--paginater'>
            <Pagination
              list={gymList.userInfo}
              onPageChange={handlePageChange}
              rowsPerPage={pageLimit}
              totalPageSize={totalPageSize}
              pageNumber={pageNumber}
            />
          </div>
        )}
      </div>

      <CreateEditGym
        data={{
          showAddModal,
          handleAddModalClose,
          handleSubmit,
          name,
          email,
          address,
          phone,
          setName,
          setEmail,
          setPhone,
          setAddress,
          nameError,
          addressError,
          phoneError,
          emailError,

          setNameError,
          setAddressError,
          setPhoneError,
          setEmailError,
          selectUpdateModel,
          setSelectUpdateModel,
          specialApp,
          setSpecialApp,
          gymPackageName,
          setGymPackageName,
          gymUrl,
          setGymUrl,
          password,
          setPassword,
          passwordError,
          setPasswordError,

          imageSrc,
          setImageSrc,
          imageSrcError,
          setImageSrcError,
          setUploadFileData,
          uploadFileDataError,
          setUploadFileDataError,
          uploadDarkFileData,
          setUploadDarkFileData,
          uploadDarkFileDataError,
          setUploadDarkFileDataError,
          darkimageSrc,
          setDarkImageSrc,
        }}
      />

      <DeleteGym
        data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }}
      />

      <ChangePwdUser
        data={{
          modalChangePasswordState,
          onChangePwdModalClose,
          onChangePwdUserHandler,
        }}
      />

      <MenuSettingUser
        data={{
          appMenu,
          setAppMenu,
          modalSettingState,
          onSettingModalClose,
          onSettingHandler,
        }}
      />

      {/* <ChangePassword data={{
				selectUpdateModel,
				setSelectUpdateModel,
				modalChangePasswordState,
				setModalChangePasswordState,
			}} /> */}
    </Content>
  )
}

export default GymsScreen
