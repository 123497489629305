import React, { useEffect, useMemo, useState } from 'react';
import Content from '../../components/Content';
import TemplateRound from './TemplateRound';
import AddNewWorkoutModal from './AddNewWorkoutModal';
import TemplateDetailsStyles from './WorkoutDetailScreen.module.css';
import { createTemplateExerciseAction, deleteExerciseTemplateAction, getMuscleTypeAction, getTemplateExerciseMuscleTypeAction } from '../../redux/actions/templateActions';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TEMPLATE_EXERCISE_RESET, ADD_WORKOUT_RESET, CHANGE_ROOM_RESET, CHANGE_WORKOUT_RESET, DELETE_EXERCISE_TEMPLATE_RESET, DELETE_WORKOUT_RESET, GET_TEMPLATE_MUSCLETYPE_RESET } from '../../redux/constants/templateConstants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';
import DeleteExerciseTemplate from './DeleteExerciseTemplate';
import { createWorkoutById, deleteWorkoutById, getWorkoutById, updateWorkoutById } from '../../redux/actions/workoutAction';

const WorkoutDetailScreen = ({ match }) => {
	const dispatch = useDispatch();
	const [addMuscleModal, setAddMuscleModal] = useState(false);
	const [editBtnEnable, setEditBtnEnable] = useState(false);
	const [modalDeleteState, setModalDeleteState] = useState(false);
	const [templateId, setTemplateId] = useState('');

	const createExrciseTemplate = useSelector(state => state.createExrciseTemplate);
	const templateExerciseMuscleTypeList = useSelector(state => state.getTemplateExerciseMuscleType);
	const getMuscleTypeList = useSelector(state => state.getMuscleType);
	const workoutList = useSelector(state => state.getAllWorkout);
	const createWorkout = useSelector(state => state.createWorkout);
	const deleteWorkout = useSelector(state => state.deleteWorkout);
	const editWorkout = useSelector(state => state.editWorkout);
	const [muscleType, setMuscleType] = useState('chest');
	const deleteExerciseTemplate = useSelector((state) => state.deleteExerciseTemplate);

	const [exerciseList, setExerciseList] = useState([]);
	const [editModalShow, setEditModalShow] = useState(false);

	const [muscleTypeName, setMuscleTypeName] = useState({ value: '', error: '' });
	const [workoutTitleError, setWorkoutTitleError] = useState({ value: '' })
	const [workoutFieldsError, setWorkoutFieldsError] = useState({ value: '' })

	const initialWorkoutData = useMemo(() => ({
		title: '',
		subtitles: [
			{ text: '', exercises: [{ name: '', repetitions: '' },] },
		],
	}), []);

	useEffect(
		() => {
			if (createWorkout && createWorkout.data && createWorkout.data.status) {
				setAddMuscleModal(false);
				toast.success('Workout has been added successfully');
				dispatch({ type: ADD_WORKOUT_RESET });
				dispatch(getWorkoutById(match.params.roomId))
			}
			else if (createWorkout && createWorkout.data && !createWorkout.data.status) {
				dispatch({ type: ADD_WORKOUT_RESET });
				toast.error('Try Again ! Workout has not been added');
			}
		},
		[createWorkout, dispatch, match.params.roomId]
	);

	useEffect(
		() => {
			if (deleteWorkout && deleteWorkout.data && deleteWorkout.data.status) {
				setAddMuscleModal(false);
				toast.success('Workout has been deleted successfully');
				dispatch({ type: DELETE_WORKOUT_RESET });
				dispatch(getWorkoutById(match.params.roomId))
			}
			else if (deleteWorkout && deleteWorkout.data && !deleteWorkout.data.status) {
				dispatch({ type: DELETE_WORKOUT_RESET });
				toast.error('Try Again ! Workout has not been deleted');
			}
		},
		[createWorkout, deleteWorkout, dispatch, match.params.roomId]
	);

	useEffect(
		() => {
			if (editWorkout && editWorkout.data && editWorkout.data.status) {
				// setAddMuscleModal(false);
				toast.success('Workout has been updated successfully');
				dispatch({ type: CHANGE_WORKOUT_RESET });
				dispatch(getWorkoutById(match.params.roomId))
			}
			else if (editWorkout && editWorkout.data && !editWorkout.data.status) {
				dispatch({ type: CHANGE_WORKOUT_RESET });
				toast.error('Try Again ! Workout has not updated');
			}
		},
		[createWorkout, deleteWorkout, dispatch, editWorkout, match.params.roomId]
	);


	const [workoutData, setWorkoutData] = useState({
		workOut: {
			muscleType: '',
			rounds: [
				{ roundTitle: '', list: [{ title: '', count: '', description: '' },] },
			],
			enable: true,
		}
	});

	React.useEffect(() => {
		dispatch(getWorkoutById(match.params.roomId))
	}, []);

	const handleUpdate = (workoutId, data) => {
		dispatch(updateWorkoutById(match.params.roomId, workoutId, data))
	}


	const enableTemplateHandler = (workoutId, data, status) => {
		const newData = { ...data, enable: status }
		dispatch(updateWorkoutById(match.params.roomId, workoutId, {
			workOut: newData
		}))
	};


	const handleSubmit = (e) => {
		e.preventDefault();
		const isNumeric = (value) => !isNaN(value) && !isNaN(parseFloat(value));

		// Check if workout title is empty or contains only whitespace
		if (workoutData.workOut.muscleType.trim() === '') {
			setWorkoutTitleError({ ...workoutTitleError, value: 'Please enter the workout title' })
			return;
		}
		else setWorkoutTitleError({ ...workoutTitleError, value: '' })
		for (const round of workoutData.workOut.rounds) {
			if (round.roundTitle.trim() === '') {
				setWorkoutFieldsError({ ...workoutFieldsError, value: 'Round title cannot be empty' });
				return;
			}
			else setWorkoutFieldsError({ ...workoutFieldsError, value: '' })
			for (const exercise of round.list) {
				if (exercise.title.trim() === '') {
					setWorkoutFieldsError({ ...workoutFieldsError, value: 'Excerise name cannot be empty' });
					return;
				}
				// const repetitions = exercise.count.trim();
				// if (!isNumeric(repetitions)) {
				// 	setWorkoutFieldsError({ ...workoutFieldsError, value: 'Please enter a valid repetitions' });
				// 	return;
				// }
				else setWorkoutFieldsError({ ...workoutFieldsError, value: '' })
			}
		}
		dispatch(createWorkoutById(match.params.roomId, workoutData))
	}

	const handleAddModalClose = () => {
		setWorkoutData({
			workOut: {
				muscleType: '',
				rounds: [
					{ roundTitle: '', list: [{ title: '', count: '', description: '' },] },
				],
				enable: true,
			}
		})
		setAddMuscleModal(false);
	};

	const showHandleAddModal = () => {
		setMuscleTypeName({ value: muscleType, error: '' });
		setAddMuscleModal(true);
		// if (exerciseList && exerciseList.length > 0) {
		// } else {
		// 	clearData();
		// }
	};

	const onMuscleTypeChange = (e) => {
		setMuscleType(e);
		dispatch(getTemplateExerciseMuscleTypeAction(match.params.templateId, e));
	}


	const onDeleteModalClose = () => {
		setModalDeleteState(false);
	};

	const onDeleteHandler = (workoutId) => {
		dispatch(deleteWorkoutById(match.params.roomId, workoutId));
		onDeleteModalClose();
	};

	return (
		<Content
			currentMenu='workout'
			headerTitle={'Workout'}
			addBtn={true}
			addBtnText={editBtnEnable ? 'Edit Workout' : 'Add Workout'}
			addBtnClick={() => showHandleAddModal()}
			addBtnIcon={editBtnEnable ? 'edit' : 'plus'}
			// listFilter={true}
			listType={muscleType}
			// getTypeList={getMuscleTypeList.categories && getMuscleTypeList.categories.data.length > 0 && getMuscleTypeList.categories.data}
			onTypeListChange={(e) => { onMuscleTypeChange(e) }}
			deleteBtn={editBtnEnable ? true : false}
			deleteBtnText='Delete Exercise'
			onDeleteTemplateHandler={() => setModalDeleteState(true)}
		>

			<div className={TemplateDetailsStyles.roundCards}>
				{workoutList && workoutList.loading ? (
					<Spinner />
				) :
					workoutList.data &&
						workoutList.data.length > 0 ? (
						workoutList.data.map((workout) =>
							workout.workOut.length > 0 ? (
								workout.workOut.map((workout, index) =>
								(<TemplateRound key={index + 1} props={{ onDeleteHandler, handleUpdate, enableTemplateHandler }} data={workout} />

								)))
								:
								(<div className='not_data_found'>
									<NoData title='No Data Found!' subTitle='We could not find any exercise template. please add one.' height='40vh' />
								</div>)

						)) : (
						<div className='not_data_found'>
							<NoData title='No Data Found!' subTitle='We could not find any exercise template. please add one.' height='40vh' />
						</div>)
				}

			</div>

			<AddNewWorkoutModal
				addModalActive={addMuscleModal}
				handleSubmit={handleSubmit}
				handleAddModalClose={handleAddModalClose}
				workoutData={workoutData}
				setWorkoutData={setWorkoutData}
				data={{
					editBtnEnable,
					getMuscleTypeList,
					muscleTypeName,
					workoutTitleError,
					workoutFieldsError,
					setMuscleTypeName,
				}}
			/>

			<DeleteExerciseTemplate data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }} />
		</Content>
	);
};

export default WorkoutDetailScreen;

