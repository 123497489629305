import React from 'react';
import InputsSection from '../../components/Modal/InputsSection';
import TemplateAssignStyles from './TemplateAssign.module.css';

import {
	RangeDatePicker,
} from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";

import moment from "moment";  

const RangeDatePickerModal = ({ data }) => {
	const {
		fromDate,
		toDate,
		setFromDate,
		setToDate,
		datePickerHandler
	} = data; 

	return (
		<div className='flex'>
			<InputsSection style={{ padding: '1rem' }}>
				<div className={TemplateAssignStyles.muscleType}>
					<RangeDatePicker 
						startDate={new Date(fromDate.replace(/-/g, "/"))} //moment(fromDate).format('MM/DD/YYYY')
						endDate={new Date(toDate.replace(/-/g, "/"))} //moment(toDate).format('MM/DD/YYYY')
						// // minDate={new Date(2022, 0, 1)}
						startDatePlaceholder="From"
						endDatePlaceholder="To"
						dateFormat="MM/DD/YYYY"
						monthFormat="MMM YYYY"
						disabled={false}
						singleCalendar={true}
						onChange={(startDate, endDate) => {
							//console.log(startDate, 'startDate', endDate, 'toDate');
							setFromDate(moment(startDate).format('MM/DD/YYYY'));
							setToDate(moment(endDate).format('MM/DD/YYYY'));
							datePickerHandler(moment(startDate).format('MM/DD/YYYY'), moment(endDate).format('MM/DD/YYYY'));
						}}
					/>

				</div>
			</InputsSection>
		</div>
	);
};

export default RangeDatePickerModal;
