import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';

const ResourceTypeData = ({data}) => {
    const {
        setShowDeleteResourceTypeModal,
		showFilter,
		setSelectedId,
		onUpdateResourceType,
		changeResourceTypeStatus
    } = data;

	const getUserInfo = useSelector((state) => state.getUserInfo);
    const resourceTypeList = useSelector((state) => state.resourceTypeList);

	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '65vh' }}>
			<table className='table'>
				<thead>
					<tr style={{backgroundColor: 'blue'}}>
						<th>#</th>
						<th>
							Name
							{/* <button className={classesForNameBtn} onClick={sortByName}>
								<svg className={classesForNameIcon}>
									<use xlinkHref={`./assets/sprite.svg#${sortTrainersBy === 'name' ? sortTrainersByValue === -1 ? 'icon-arrow-up' :  'icon-arrow-down': 'icon-arrow-down' }`} />
								</svg>
							</button> */}
						</th>
						<th>Active</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{resourceTypeList.loading ? (
						<tr>
							<td colSpan='8' style={{ textAlign: 'center' }}>
								<Spinner />
							</td>
						</tr>
					) : resourceTypeList &&
                        resourceTypeList.userInfo &&
                        resourceTypeList.userInfo.status &&
                        resourceTypeList.userInfo.data.result &&
                        resourceTypeList.userInfo.data.result.length > 0 ? (
						resourceTypeList.userInfo.data.result.map((d, index) => (
							<tr key={d._id}>
								<td>{index + 1}</td>
								<td style={{ textAlign: 'left', textTransform: 'capitalize' }}>{d.name}</td>
								<td>
								{getUserInfo &&
									getUserInfo.userInfo &&
									getUserInfo.userInfo.data &&
									(getUserInfo.userInfo.data.role === 'admin') && (
										<td>
											<label className='switch'>
												<input
													id='active'
													checked={d.enable}
													onChange={() => {
														changeResourceTypeStatus(d._id);
													}}
													type='checkbox'
													className='checkbox'
													name='active'
												/>
												<span className='slider round' />
											</label>
										</td>
										)}
								</td>
								<td>
								<div className='table__iconBox'>
										{getUserInfo &&
											getUserInfo.userInfo &&
											getUserInfo.userInfo.data &&
											(getUserInfo.userInfo.data.role === 'admin') && (
												<Fragment>
													<button
														className='table__button'
														onClick={() => {
															setSelectedId(d._id);
															onUpdateResourceType(d._id);
														}}>
														<svg className='table__button--icon'>
															<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
														</svg>
														<span>Edit Resource Type Details</span>
													</button>
													<button
														className='table__button table__button--delete'
														onClick={() => {
															setSelectedId(d._id);
															setShowDeleteResourceTypeModal(true);
														}}>
														<svg className='table__button--icon-red'>
															<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
														</svg>
														<span>Delete Resource Type</span>
													</button>
												</Fragment>
											)}
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan='8' style={{ textAlign: 'center' }}>
								<NoData title='No Data Found!' subTitle='We could not find any resource type. Please add one' height='40vh' />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default ResourceTypeData;
