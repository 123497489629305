export const GYM_REQUEST = 'GYM_REQUEST';
export const GYM_SUCCESS = 'GYM_SUCCESS';
export const GYM_FAIL = 'GYM_FAIL';
export const GYM_RESET = 'GYM_RESET';

export const DELETE_GYM_REQUEST = 'DELETE_GYM_REQUEST';
export const DELETE_GYM_SUCCESS = 'DELETE_GYM_SUCCESS';
export const DELETE_GYM_FAIL = 'DELETE_GYM_FAIL';
export const DELETE_GYM_RESET = 'DELETE_GYM_RESET';

export const CREATE_NEW_GYM_REQUEST = 'CREATE_NEW_GYM_REQUEST';
export const CREATE_NEW_GYM_SUCCESS = 'CREATE_NEW_GYM_SUCCESS';
export const CREATE_NEW_GYM_FAIL = 'CREATE_NEW_GYM_FAIL';
export const CREATE_NEW_GYM_RESET = 'CREATE_NEW_GYM_RESET';

export const UPDATE_GYM_REQUEST = 'UPDATE_GYM_REQUEST';
export const UPDATE_GYM_SUCCESS = 'UPDATE_GYM_SUCCESS';
export const UPDATE_GYM_FAIL = 'UPDATE_GYM_FAIL';
export const UPDATE_GYM_RESET = 'UPDATE_GYM_RESET';

export const CHANGE_STATUS_GYM_REQUEST = 'CHANGE_STATUS_GYM_REQUEST';
export const CHANGE_STATUS_GYM_SUCCESS = 'CHANGE_STATUS_GYM_SUCCESS';
export const CHANGE_STATUS_GYM_FAIL = 'CHANGE_STATUS_GYM_FAIL';
export const CHANGE_STATUS_GYM_RESET = 'CHANGE_STATUS_GYM_RESET';

export const GET_ALL_ENABLE_GYM_REQUEST = 'GET_ALL_ENABLE_GYM_REQUEST';
export const GET_ALL_ENABLE_GYM_SUCCESS = 'GET_ALL_ENABLE_GYM_SUCCESS';
export const GET_ALL_ENABLE_GYM_FAIL = 'GET_ALL_ENABLE_GYM_FAIL';
export const GET_ALL_ENABLE_GYM_RESET = 'GET_ALL_ENABLE_GYM_RESET';

export const GET_MENU_SETTINGS_REQUEST = 'GET_MENU_SETTINGS_REQUEST';
export const GET_MENU_SETTINGS_SUCCESS = 'GET_MENU_SETTINGS_SUCCESS';
export const GET_MENU_SETTINGS_FAIL = 'GET_MENU_SETTINGS_FAIL';
export const GET_MENU_SETTINGS_RESET = 'GET_MENU_SETTINGS_RESET';

export const CHANGE_MENU_SETTINGS_REQUEST = 'CHANGE_MENU_SETTINGS_REQUEST';
export const CHANGE_MENU_SETTINGS_SUCCESS = 'CHANGE_MENU_SETTINGS_SUCCESS';
export const CHANGE_MENU_SETTINGS_FAIL = 'CHANGE_MENU_SETTINGS_FAIL';
export const CHANGE_MENU_SETTINGS_RESET = 'CHANGE_MENU_SETTINGS_RESET';
