import React from 'react';
import Modal from './Modal';
import InputsSectionColumn from './Modal/InputsSectionColumn';
import ModalButton from './Modal/ModalButton';
import ModalForm from './Modal/ModalForm';
import ModalHeading from './Modal/ModalHeading';

const LogoutModal = ({ data }) => {
	const { modalLogOutState, onLogOutModalClose, onLogOutHandler } = data;

	return (
		<Modal show={modalLogOutState}>
			<ModalHeading heading='Are you leaving?' onClose={onLogOutModalClose} />
			<ModalForm>
				<p className='modal__text'>
					Made your mind to logout?<br />If yes, please click on logout button, else close this modal to stay signed in.
				</p>

				<ModalButton varient='danger' label='Log Out' icon='logout' onClick={onLogOutHandler} />
			</ModalForm>
		</Modal>
	);
};

export default LogoutModal;
