import React from 'react';
import TextBox from '../InputBox/TextBox.module.css';

const Toggle = ({ label, checked = true, onChange }) => {
	return (
		<div className={TextBox.form_input}>
			{label && (
				<label htmlFor={label} className={TextBox.form_input__label}>
					{label}
				</label>
			)}
			<label className='switch'>
				<input
					id='special_app'
					checked={checked}
					onChange={onChange}
					type='checkbox'
					className='checkbox'
					name='active'
				/>
				<span className='slider round' />
			</label>
		</div>
	);
};

export default Toggle;
