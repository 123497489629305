import React from 'react';
import { Link } from 'react-router-dom';
import TemplateStyles from './TemplatesScreen.module.css';

const TemplateData = ({ props }) => {
	const {
		data,
		setTemplateId,
		onEditTemplateHandler,
		setModalDeleteState,
		enableTemplateHandler
	} = props;

	return (
		<div>
			<div className={TemplateStyles.templateData}>
				<Link to={`/templates/${data._id}/${data.templateName}`} className={TemplateStyles.template}>
					<div className={TemplateStyles.templateImage} style={{ backgroundColor: data.templateColor }}>
						<svg className={TemplateStyles.templateImageIcon}>
							<use xlinkHref={`/assets/sprite.svg#icon-dumbell`} />
						</svg>
					</div>
					<h1 className={TemplateStyles.templateName}>{data.templateName}</h1>
					<svg className={TemplateStyles.templateButtonIcon} style={{ fill: data.templateColor }}>
						<use xlinkHref={`/assets/sprite.svg#icon-arrow_right`} />
					</svg>
				</Link>
				<div className={TemplateStyles.templateCardActions}>
					<label className='switch'>
						<input
							checked={data.enable}
							onChange={() => {
								enableTemplateHandler(data._id, data.enable);
							}}
							type='checkbox'
							className='checkbox'
							name='active'
						/>
						<span className='slider round' />
					</label>
					<div className={TemplateStyles.templateCardActionBtn}>
						<button
							className={TemplateStyles.templateCardAction}
							onClick={() => {
								onEditTemplateHandler(data);
							}}>
							<svg className={TemplateStyles.templateCardActionIcon}>
								<use xlinkHref={`/assets/sprite.svg#icon-edit`} />
							</svg>
						</button>

						<button
							className={TemplateStyles.templateCardAction}
							onClick={() => {
								setTemplateId(data._id);
								setModalDeleteState(true);
							}}>
							<svg className={TemplateStyles.templateCardActionIcon}>
								<use xlinkHref={`/assets/sprite.svg#icon-delete`} />
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div className={TemplateStyles.templateBar} style={{ backgroundColor: data.templateColor }} />
		</div>
	);
};

export default TemplateData;
