const convertDateTimeToDDSlashMMSlashYYYY = date => {
  const day = date.toLocaleString('default', {day: '2-digit'});
  const month = date.toLocaleString('default', {month: '2-digit'});
  const year = date.toLocaleString('default', {year: 'numeric'});
  return `${day}/${month}/${year}`;
};

const convertDateTimeToMMSlashDDSlashYYYY = date => {
  const day = date.toLocaleString('default', {day: '2-digit'});
  const month = date.toLocaleString('default', {month: '2-digit'});
  const year = date.toLocaleString('default', {year: 'numeric'});
  return `${month}/${day}/${year}`;
};

const convertDateTimeToYYYYDashMMDashDD = date => {
  const day = date.toLocaleString('default', {day: '2-digit'});
  const month = date.toLocaleString('default', {month: '2-digit'});
  const year = date.toLocaleString('default', {year: 'numeric'});
  return `${year}-${month}-${day}`;
};

const convertDateTimeToDate = date => {
  date = date || new Date();
  const day = date.toLocaleString('default', {day: '2-digit'});
  const month = date.toLocaleString('default', {month: '2-digit'});
  const year = date.toLocaleString('default', {year: 'numeric'});
  return `${year}-${month}-${day}`;
};

function addMinutes(time, minsToAdd) {
  const newTime = new Date(
    new Date('1970/01/01 ' + time).getTime() + minsToAdd * 60000,
  ).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  return newTime;
}

// Provide time like '18:00' to get time like 6:00 PM
function timeStringformatAMPM(time) {
  var hours = Number(time.substring(0, 2));
  var minutes = Number(time.substring(3));
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export {
  convertDateTimeToDate,
  convertDateTimeToDDSlashMMSlashYYYY,
  convertDateTimeToMMSlashDDSlashYYYY,
  convertDateTimeToYYYYDashMMDashDD,
  addMinutes,
  timeStringformatAMPM,
};
