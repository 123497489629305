import React from 'react';
import Modal from '../../components/Modal';
import ModalButton from '../../components/Modal/ModalButton';
import ModalForm from '../../components/Modal/ModalForm';
import ModalHeading from '../../components/Modal/ModalHeading';

const DeleteResourceTypeModal = ({ data }) => {
	const { showDeleteResourceTypeModal, onDeleteResourceTypeModalClose, onDeleteResourceTypeHandler, selectedId } = data;

	return (
		<Modal show={showDeleteResourceTypeModal}>
			<ModalHeading heading='Delete Resource Type' onClose={onDeleteResourceTypeModalClose} />
			<ModalForm>
				<p className='modal__text'>Are you sure you want to delete this Resource Type?</p>
				<ModalButton varient='danger' label='Delete' icon='delete' onClick={ () => onDeleteResourceTypeHandler(selectedId) } />
			</ModalForm>
		</Modal>
	);
};

export default DeleteResourceTypeModal;
