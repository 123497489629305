import React, { useState, useEffect } from 'react';
import SkeletonStyles from './Skeletons.module.css';

const AddSkeleton = ({ rows = 2, textBoxes = 3 }) => {
	const [ totalRows, setTotalRows ] = useState([]);
	const [ totalTextBoxes, setTotalTextBoxes ] = useState([]);

	useEffect(
		() => {
			const totrow = [];
			for (let i = 0; i < rows; i++) {
				totrow.push(i);
			}
			setTotalRows(totrow);
		},
		[ rows ]
	);

	useEffect(
		() => {
			const totbox = [];
			for (let i = 0; i < textBoxes; i++) {
				totbox.push(i);
			}
			setTotalTextBoxes(totbox);
		},
		[ textBoxes ]
	);

	return (
		<div className='modalContainer__form'>
			{totalRows &&
				totalRows.length > 0 &&
				totalRows.map((row) => (
					<div key={row} className='modalContainer__form--section'>
						{totalTextBoxes &&
							totalTextBoxes.length > 0 &&
							totalTextBoxes.map((textBox) => (
								<div key={textBox} style={{ marginTop: '1.5rem' }}>
									<div className={[ SkeletonStyles.labelSkeleton, SkeletonStyles.skeleton ].join(' ')} />
									<div className={[ SkeletonStyles.textBoxSkeleton2, SkeletonStyles.skeleton ].join(' ')} />
								</div>
							))}
					</div>
				))}
			<div className={[ SkeletonStyles.buttonSkeleton3, SkeletonStyles.skeleton ].join(' ')} />
		</div>
	);
};

export default AddSkeleton;
