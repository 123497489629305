import axios from 'axios';
import {
	GET_ALL_TEMPLATE_REQUEST,
	GET_ALL_TEMPLATE_SUCCESS,
	GET_ALL_TEMPLATE_FAIL,

	ADD_TEMPLATE_REQUEST,
	ADD_TEMPLATE_SUCCESS,
	ADD_TEMPLATE_FAIL,

	CHANGE_TEMPLATE_STATUS_REQUEST,
	CHANGE_TEMPLATE_STATUS_SUCCESS,
	CHANGE_TEMPLATE_STATUS_FAIL,

	DELETE_TEMPLATE_REQUEST,
	DELETE_TEMPLATE_SUCCESS,
	DELETE_TEMPLATE_FAIL,

	GET_ALL_ENABLE_TEMPLATE_REQUEST,
	GET_ALL_ENABLE_TEMPLATE_SUCCESS,
	GET_ALL_ENABLE_TEMPLATE_FAIL,

	ADD_TEMPLATE_EXERCISE_REQUEST,
	ADD_TEMPLATE_EXERCISE_SUCCESS,
	ADD_TEMPLATE_EXERCISE_FAIL,
	ASSIGN_TEMPLATE_EXERCISE_FAIL,
	ASSIGN_TEMPLATE_EXERCISE_SUCCESS,
	ASSIGN_TEMPLATE_EXERCISE_REQUEST,

	GET_TEMPLATE_EXERCISE_MUSCLETYPE_REQUEST,
	GET_TEMPLATE_EXERCISE_MUSCLETYPE_SUCCESS,
	GET_TEMPLATE_EXERCISE_MUSCLETYPE_FAIL,

	GET_TEMPLATE_MUSCLETYPE_REQUEST,
	GET_TEMPLATE_MUSCLETYPE_SUCCESS,
	GET_TEMPLATE_MUSCLETYPE_FAIL,
	GET_ASSIGN_TEMPLATE_BY_USER_REQUEST,
	GET_ASSIGN_TEMPLATE_BY_USER_SUCCESS,
	GET_ASSIGN_TEMPLATE_BY_USER_FAIL,
	DELETE_EXERCISE_TEMPLATE_REQUEST,
	DELETE_EXERCISE_TEMPLATE_SUCCESS,
	DELETE_EXERCISE_TEMPLATE_FAIL,


	GET_ALL_NUTRITIONS_TEMPLATE_REQUEST,
	GET_ALL_NUTRITIONS_TEMPLATE_SUCCESS,
	GET_ALL_NUTRITIONS_TEMPLATE_FAIL,

	ADD_NUTRITIONS_TEMPLATE_REQUEST,
	ADD_NUTRITIONS_TEMPLATE_SUCCESS,
	ADD_NUTRITIONS_TEMPLATE_FAIL,

	CHANGE_NUTRITIONS_TEMPLATE_STATUS_REQUEST,
	CHANGE_NUTRITIONS_TEMPLATE_STATUS_SUCCESS,
	CHANGE_NUTRITIONS_TEMPLATE_STATUS_FAIL,

	DELETE_NUTRITIONS_TEMPLATE_REQUEST,
	DELETE_NUTRITIONS_TEMPLATE_SUCCESS,
	DELETE_NUTRITIONS_TEMPLATE_FAIL,

	GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_REQUEST,
	GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_SUCCESS,
	GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_FAIL,
	ADD_ROOM_FAIL,
	ADD_ROOM_SUCCESS,
	ADD_ROOM_REQUEST,
	GET_WORKOUT_REQUEST,
	GET_WORKOUT_SUCCESS,
	GET_ALL_ROOM_FAIL,

} from '../constants/templateConstants';
import { BASE_URL } from './ip';

export const getAllTemplateAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_TEMPLATE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/template/get-template?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}`, config);

		dispatch({
			type: GET_ALL_TEMPLATE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_TEMPLATE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const createTemplateAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ADD_TEMPLATE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/template/add-template`, formData, config);

		dispatch({
			type: ADD_TEMPLATE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: ADD_TEMPLATE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const TemplateStatusAction = (id, enableStatus) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_TEMPLATE_STATUS_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.patch(
			`${BASE_URL}/template/enable-disable-template?id=${id}`,
			{ enable: enableStatus ? false : true },
			config
		);

		dispatch({
			type: CHANGE_TEMPLATE_STATUS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_TEMPLATE_STATUS_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteTemplateAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_TEMPLATE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.delete(`${BASE_URL}/template/delete-template?id=${id}`, config);

		dispatch({
			type: DELETE_TEMPLATE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_TEMPLATE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const deleteExerciseTemplateAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_EXERCISE_TEMPLATE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.delete(`${BASE_URL}/template/delete-exercise-template?id=${id}`, config);

		dispatch({
			type: DELETE_EXERCISE_TEMPLATE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_EXERCISE_TEMPLATE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const getAllEnableTemplateAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_ENABLE_TEMPLATE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/template/get-enabled-template?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}`, config);

		dispatch({
			type: GET_ALL_ENABLE_TEMPLATE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_ENABLE_TEMPLATE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};





export const createTemplateExerciseAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ADD_TEMPLATE_EXERCISE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/template/add-exercise-template`, formData, config);

		dispatch({
			type: ADD_TEMPLATE_EXERCISE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: ADD_TEMPLATE_EXERCISE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const assignTemplateExerciseAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ASSIGN_TEMPLATE_EXERCISE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/template/assign-exercise-template-to-user`, formData, config);

		dispatch({
			type: ASSIGN_TEMPLATE_EXERCISE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: ASSIGN_TEMPLATE_EXERCISE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const getTemplateExerciseMuscleTypeAction = (template, muscleType) => async (dispatch, getState) => {
	try {

		dispatch({ type: GET_TEMPLATE_EXERCISE_MUSCLETYPE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		let baseUrl = `${BASE_URL}/template/get-exercise-template-muscle-type?muscleType=${muscleType}&template=${template}`;

		const { data } = await axios.get(baseUrl, config);

		dispatch({
			type: GET_TEMPLATE_EXERCISE_MUSCLETYPE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_TEMPLATE_EXERCISE_MUSCLETYPE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getAssignTemplateByUserAction = (template, user) => async (dispatch, getState) => {
	try {

		dispatch({ type: GET_ASSIGN_TEMPLATE_BY_USER_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		let baseUrl = `${BASE_URL}/template/get-assign-template-by-user?&user=${user}&template=${template}`;

		const { data } = await axios.get(baseUrl, config);

		dispatch({
			type: GET_ASSIGN_TEMPLATE_BY_USER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ASSIGN_TEMPLATE_BY_USER_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getMuscleTypeAction = () => async (dispatch, getState) => {
	try {

		dispatch({ type: GET_TEMPLATE_MUSCLETYPE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		let baseUrl = `${BASE_URL}/template/get-muscle-type`;
		const { data } = await axios.get(baseUrl, config);

		dispatch({
			type: GET_TEMPLATE_MUSCLETYPE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_TEMPLATE_MUSCLETYPE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};



export const getAllNutritionTemplateAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_NUTRITIONS_TEMPLATE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/template/get-nutritions?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}`, config);

		dispatch({
			type: GET_ALL_NUTRITIONS_TEMPLATE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_NUTRITIONS_TEMPLATE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const createNutritionTemplateAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ADD_NUTRITIONS_TEMPLATE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/template/add-nutrition-document`, formData, config);

		dispatch({
			type: ADD_NUTRITIONS_TEMPLATE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: ADD_NUTRITIONS_TEMPLATE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const nutritionTemplateStatusAction = (id, enableStatus) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_NUTRITIONS_TEMPLATE_STATUS_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.patch(
			`${BASE_URL}/template/enable-disable-nutrition?id=${id}`,
			{ enable: enableStatus ? false : true },
			config
		);

		dispatch({
			type: CHANGE_NUTRITIONS_TEMPLATE_STATUS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_NUTRITIONS_TEMPLATE_STATUS_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteNutritionTemplateAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_NUTRITIONS_TEMPLATE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.delete(`${BASE_URL}/template/delete-nutrition?id=${id}`, config);

		dispatch({
			type: DELETE_NUTRITIONS_TEMPLATE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_NUTRITIONS_TEMPLATE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getAllEnableNutritionTemplateAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/template/get-enabled-nutritions`, config);

		dispatch({
			type: GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};



