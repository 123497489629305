import axios from 'axios';
import {
	GYM_STAFF_REQUEST,
	GYM_STAFF_SUCCESS,
	GYM_STAFF_FAIL,

	DELETE_GYM_STAFF_SUCCESS,
	DELETE_GYM_STAFF_REQUEST,
	DELETE_GYM_STAFF_FAIL,

	CREATE_NEW_GYM_STAFF_REQUEST,
	CREATE_NEW_GYM_STAFF_SUCCESS,
	CREATE_NEW_GYM_STAFF_FAIL,

	UPDATE_GYM_STAFF_REQUEST,
	UPDATE_GYM_STAFF_RESET,
	UPDATE_GYM_STAFF_FAIL,
	UPDATE_GYM_STAFF_SUCCESS,
	CHANGE_STATUS_GYM_STAFF_REQUEST,
	CHANGE_STATUS_GYM_STAFF_SUCCESS,
	CHANGE_STATUS_GYM_STAFF_FAIL
} from '../constants/gymStaffConstants';
import { BASE_URL } from './ip';

export const getGymStaffListAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GYM_STAFF_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/gym-staff/get-gym-staff?pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}`, config);

		dispatch({
			type: GYM_STAFF_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GYM_STAFF_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const deleteGymStaffAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_GYM_STAFF_REQUEST });


		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};


		const { data } = await axios.delete(`${BASE_URL}/gym-staff/delete-gym-staff?gymID=${id}`, config);

		dispatch({
			type: DELETE_GYM_STAFF_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_GYM_STAFF_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};




export const createNewStaffGymAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CREATE_NEW_GYM_STAFF_REQUEST });


		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};
 
		const { data } = await axios.post(`${BASE_URL}/gym-staff/create-gym-staff`, formData, config);

		dispatch({
			type: CREATE_NEW_GYM_STAFF_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CREATE_NEW_GYM_STAFF_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};



export const changeStatusGymStaffAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_STATUS_GYM_STAFF_REQUEST });

		
		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};


		const { data } = await axios.patch(`${BASE_URL}/gym-staff/enable-disable-gym-staff?userID=${id}`, formData, config);

		dispatch({
			type: CHANGE_STATUS_GYM_STAFF_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_STATUS_GYM_STAFF_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};