export const GYM_STAFF_REQUEST = 'GYM_STAFF_REQUEST';
export const GYM_STAFF_SUCCESS = 'GYM_STAFF_SUCCESS';
export const GYM_STAFF_FAIL = 'GYM_STAFF_FAIL';
export const GYM_STAFF_RESET = 'GYM_STAFF_RESET'; 

export const DELETE_GYM_STAFF_REQUEST = 'DELETE_GYM_STAFF_REQUEST';
export const DELETE_GYM_STAFF_SUCCESS = 'DELETE_GYM_STAFF_SUCCESS';
export const DELETE_GYM_STAFF_FAIL = 'DELETE_GYM_STAFF_FAIL';
export const DELETE_GYM_STAFF_RESET = 'DELETE_GYM_STAFF_RESET'; 

export const CREATE_NEW_GYM_STAFF_REQUEST = 'CREATE_NEW_GYM_STAFF_REQUEST';
export const CREATE_NEW_GYM_STAFF_SUCCESS = 'CREATE_NEW_GYM_STAFF_SUCCESS';
export const CREATE_NEW_GYM_STAFF_FAIL = 'CREATE_NEW_GYM_STAFF_FAIL';
export const CREATE_NEW_GYM_STAFF_RESET = 'CREATE_NEW_GYM_STAFF_RESET'; 
 
export const UPDATE_GYM_STAFF_REQUEST = 'UPDATE_GYM_STAFF_REQUEST';
export const UPDATE_GYM_STAFF_SUCCESS = 'UPDATE_GYM_STAFF_SUCCESS';
export const UPDATE_GYM_STAFF_FAIL = 'UPDATE_GYM_STAFF_FAIL';
export const UPDATE_GYM_STAFF_RESET = 'UPDATE_GYM_STAFF_RESET';  


export const CHANGE_STATUS_GYM_STAFF_REQUEST = 'CHANGE_STATUS_GYM_STAFF_REQUEST';
export const CHANGE_STATUS_GYM_STAFF_SUCCESS = 'CHANGE_STATUS_GYM_STAFF_SUCCESS';
export const CHANGE_STATUS_GYM_STAFF_FAIL = 'CHANGE_STATUS_GYM_STAFF_FAIL';
export const CHANGE_STATUS_GYM_STAFF_RESET = 'CHANGE_STATUS_GYM_STAFF_RESET';
 