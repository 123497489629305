import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import Button from '../../components/formInputs/Button';
import Spinner from '../../components/Spinner/Spinner';
import ChangePassword from './ChangePassword';
import MyProfileStyle from './MyProfile.module.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSectionColumn from '../../components/Modal/InputsSectionColumn';
import EditProfile from './EditProfile';
import { getUserByTokenAction } from '../../redux/actions/userActions';
import {
	validateEmail,
	validateOnlyChacters,
	validateOnlyNumberAllDecimal,
	validatePhone,
	validatePhoneMaskingString
} from '../../utils/validators';
import { createNewTrainerAction } from '../../redux/actions/trainerActions';
import { createNewGymAction } from '../../redux/actions/gymActions';
import { createNewStaffGymAction } from '../../redux/actions/gymStaffActions';
import { CREATE_NEW_TRAINER_RESET } from '../../redux/constants/trainerConstants';
import { CREATE_NEW_GYM_STAFF_RESET } from '../../redux/constants/gymStaffConstants';
import { CREATE_NEW_GYM_RESET } from '../../redux/constants/gymConstants';
import formStyle from '../DeclarationForms/form.module.css';
import SignatureCanvas from 'react-signature-canvas';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';
import {
	createIndepentAgreementByTrainerAction,
	getFormByIndependentAgreementAction
} from '../../redux/actions/formActions';
import {
	CREATE_NEW_INDEPENT_AGREEMENT_RESET,
	GET_NEW_INDEPENT_AGREEMENT_RESET
} from '../../redux/constants/formConstants';
import { inputPhoneMasking, phoneMasking, unMasking } from '../../utils/phonemask';
import moment from 'moment';
import { amountToStringWithSymbol } from '../../utils/numbers';

const MyProfileScreen = () => {
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.getUserInfo);
	const [showEditModal, setShowEditModal] = useState(false);
	const [modalChangePasswordState, setModalChangePasswordState] = useState(false);
	const [selectUpdateModel, setSelectUpdateModel] = useState({});

	const createTrainer = useSelector((state) => state.createTrainer);
	const createGym = useSelector((state) => state.createGym);
	const createGymStaff = useSelector((state) => state.createGymStaff);
	const createFormByIndependentAgreement = useSelector((state) => state.createFormByIndependentAgreement);
	const getFormByIndependentAgreement = useSelector((state) => state.getFormByIndependentAgreement);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [address, setAddress] = useState('');
	const [imageSrc, setImageSrc] = useState('');
	const [nameError, setNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [addressError, setAddressError] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [imageSrcError, setImageSrcError] = useState('');
	const [uploadFileData, setUploadFileData] = useState('');
	const [uploadFileDataError, setUploadFileDataError] = useState('');
	const [darkimageSrc, setDarkImageSrc] = useState('');
	const [uploadDarkFileData, setUploadDarkFileData] = useState('');
	const [uploadDarkFileDataError, setUploadDarkFileDataError] = useState('');

	const [id, setId] = useState('');
	const [sessionCharges, setSessionCharges] = useState('');
	const [sessionChargesError, setSessionChargesError] = useState('');

	const [trainerSignature, setTrainerSignature] = useState('');
	const [trainerSignatureSrc, setTrainerSignatureSrc] = useState('');

	const [gymSignature, setGymSignature] = useState('');
	const [trainerSignatureDate, setTrainerSignatureDate] = useState('');
	const [gymSignatureDate, setGymSignatureDate] = useState('');
	const [trainerSignatureError, setTrainerSignatureError] = useState('');
	const [trainerSignatureDateError, setTrainerSignatureDateError] = useState('');

	useEffect(
		() => {
			if (userData && userData.userInfo && userData.userInfo.data) {
				setSelectUpdateModel(userData.userInfo.data);
			}
		},
		[userData]
	);

	useEffect(
		() => {
			if (createTrainer && createTrainer.userInfo && createTrainer.userInfo.status) {
				clearData();
				setShowEditModal(false);
				dispatch({ type: CREATE_NEW_TRAINER_RESET });
				toast.success(createTrainer.userInfo.message);
				dispatch(getUserByTokenAction());
			} else if (createTrainer && createTrainer.userInfo && !createTrainer.userInfo.status) {
				dispatch({ type: CREATE_NEW_TRAINER_RESET });
				toast.error(createTrainer.userInfo.message);
			}
		},
		[createTrainer, dispatch]
	);

	useEffect(
		() => {
			if (createGym && createGym.userInfo && createGym.userInfo.status) {
				clearData();
				setShowEditModal(false);
				toast.success(createGym.userInfo.message);
				dispatch({ type: CREATE_NEW_GYM_RESET });
				dispatch(getUserByTokenAction());
			} else if (createGym && createGym.userInfo && !createGym.userInfo.status) {
				dispatch({ type: CREATE_NEW_GYM_RESET });
				toast.error(createGym.userInfo.message);
			}
		},
		[createGym, dispatch]
	);

	useEffect(
		() => {
			if (createGymStaff && createGymStaff.userInfo && createGymStaff.userInfo.status) {
				clearData();
				setShowEditModal(false);
				toast.success(createGymStaff.userInfo.message);
				dispatch({ type: CREATE_NEW_GYM_STAFF_RESET });
				dispatch(getUserByTokenAction());
			} else if (createGymStaff && createGymStaff.userInfo && !createGymStaff.userInfo.status) {
				dispatch({ type: CREATE_NEW_GYM_STAFF_RESET });
				toast.error(createGymStaff.userInfo.message);
			}
		},
		[createGymStaff, dispatch]
	);

	useEffect(
		() => {
			if (createGymStaff && createGymStaff.userInfo && createGymStaff.userInfo.status) {
				clearData();
				setShowEditModal(false);
				toast.success(createGymStaff.userInfo.message);
				dispatch({ type: CREATE_NEW_GYM_STAFF_RESET });
				dispatch(getUserByTokenAction());
			} else if (createGymStaff && createGymStaff.userInfo && !createGymStaff.userInfo.status) {
				dispatch({ type: CREATE_NEW_GYM_STAFF_RESET });
				toast.error(createGymStaff.userInfo.message);
			}
		},
		[createGymStaff, dispatch]
	);

	useEffect(
		() => {
			if (
				createFormByIndependentAgreement &&
				createFormByIndependentAgreement.userInfo &&
				createFormByIndependentAgreement.userInfo.status
			) {
				clearData();
				dispatch({ type: CREATE_NEW_INDEPENT_AGREEMENT_RESET });
				toast.success(createFormByIndependentAgreement.userInfo.message);
			} else if (
				createFormByIndependentAgreement &&
				createFormByIndependentAgreement.userInfo &&
				!createFormByIndependentAgreement.userInfo.status
			) {
				dispatch({ type: CREATE_NEW_INDEPENT_AGREEMENT_RESET });
				toast.error(createFormByIndependentAgreement.userInfo.message);
			}
		},
		[createFormByIndependentAgreement, dispatch]
	);

	useEffect(
		() => {
			dispatch(getFormByIndependentAgreementAction(selectUpdateModel._id));

			return () => {
				dispatch({ type: GET_NEW_INDEPENT_AGREEMENT_RESET });
			};
		},
		[selectUpdateModel, dispatch]
	);

	useEffect(
		() => {
			if (
				getFormByIndependentAgreement &&
				getFormByIndependentAgreement.userInfo &&
				getFormByIndependentAgreement.userInfo.data
			) {
				setTrainerSignatureSrc(getFormByIndependentAgreement.userInfo.data.trainerSignature);
				setTrainerSignatureDate(moment(getFormByIndependentAgreement.userInfo.data.trainerSignatureDate).format('YYYY-MM-DD'));
				setGymSignature(getFormByIndependentAgreement.userInfo.data.gymSignature); 
				setGymSignatureDate(moment(getFormByIndependentAgreement.userInfo.data.gymSignatureDate).format('YYYY-MM-DD'));  
			}
		},
		[getFormByIndependentAgreement, dispatch]
	);

	const onEditHandler = () => {

		let d = selectUpdateModel;
		setId(d._id);
		setEmail(d.email);
		setPhone(inputPhoneMasking(d.phone));
		setName(d.name);
		setAddress(d.address);
		setImageSrc(d.photo);
		setDarkImageSrc(d.photoDark);
		d.sessionCharges && setSessionCharges(d.sessionCharges);
		setShowEditModal(true);
	};

	const clearData = () => {
		setEmailError('');
		setPhoneError('');
		setAddressError('');
		setNameError('');
		setImageSrcError('');
		setUploadFileDataError('');
		setUploadDarkFileDataError('');
		setTrainerSignatureDateError('');
		setTrainerSignatureError('');
	};

	const handleEditModalClose = () => {
		clearData();
		setShowEditModal(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (name === '' && name.trim() === '') {
			setNameError('Please enter name');
		} else if (!validateOnlyChacters(name)) {
			setNameError('Please enter only characters');
		} else if (email === '' && email.trim() === '') {
			setEmailError('Please enter email address');
		} else if (!validateEmail(email)) {
			setEmailError('Please enter a valid email address');
		} else if (phone === '' && phone.trim() === '') {
			setPhoneError('Please enter phone');
		} else if (!validatePhone(unMasking(phone))) {
			setPhoneError('Phone number must be 10 digits');
		} else if (address === '' && address.trim() === '') {
			setAddressError('Please enter your address');
		} else if (!imageSrc) {
			setUploadFileDataError('Please select files to upload');
		} else if (sessionCharges === '') {
			setSessionChargesError('Please enter your session charge');
		} else if (!validateOnlyNumberAllDecimal(sessionCharges)) {
			setSessionChargesError('Please enter valid session charge');
		} else {
			let fileData = new FormData();
			if (
				userData &&
				userData.userInfo &&
				userData.userInfo.status &&
				userData.userInfo.data &&
				userData.userInfo.data.role === 'gymadmin'
			) {
				dispatch(createNewStaffGymAction({ id, name, email, address, phone: unMasking(phone) }));
			} else if (
				userData &&
				userData.userInfo &&
				userData.userInfo.status &&
				userData.userInfo.data &&
				userData.userInfo.data.role === 'trainer'
			) {
				fileData.append('id', id);
				fileData.append('name', name);
				fileData.append('email', email);
				fileData.append('address', address);
				fileData.append('phone', unMasking(phone));
				fileData.append('sessionCharges', sessionCharges);

				for (let i = 0; i < uploadFileData.length; i++) {
					fileData.append('image', uploadFileData[i]);
				}

				dispatch(createNewTrainerAction(fileData));
			} else if (
				userData &&
				userData.userInfo &&
				userData.userInfo.status &&
				userData.userInfo.data &&
				userData.userInfo.data.role === 'gym'
			) {
				fileData.append('id', id);
				fileData.append('name', name);
				fileData.append('email', email);
				fileData.append('address', address);
				fileData.append('phone', unMasking(phone));
				fileData.append('sessionCharges', sessionCharges);
				for (let i = 0; i < uploadFileData.length; i++) {
					fileData.append('image', uploadFileData[i]);
				}

				uploadFileData.length > 0 && fileData.append('image', uploadFileData[0]);
				uploadDarkFileData.length > 0 && fileData.append('darkImage', uploadDarkFileData[0]);

				dispatch(createNewGymAction(fileData));
			} else {
				fileData.append('id', id);
				fileData.append('name', name);
				fileData.append('email', email);
				fileData.append('address', address);
				fileData.append('phone', unMasking(phone));
				for (let i = 0; i < uploadFileData.length; i++) {
					fileData.append('image', uploadFileData[i]);
				}
				dispatch(createNewGymAction(fileData));
			}
		}
	};

	const onFormHandler = (e) => {
		e.preventDefault();
		if (trainerSignature.isEmpty()) {
			setTrainerSignatureError('Trainer signature is invalid');
		} else if (trainerSignatureDate === '' && trainerSignatureDate.trim() === '') {
			setTrainerSignatureError('');
			setTrainerSignatureDateError('Please select signature date');
		} else {
			let base64 = '';
			if (!trainerSignatureSrc) {
				base64 = trainerSignature.getTrimmedCanvas().toDataURL('image/png');
			} else {
				base64 = trainerSignatureSrc;
			}
			dispatch(createIndepentAgreementByTrainerAction({ trainerSignature: base64, trainerSignatureDate }));
		}
	};

	const onClearHandler = () => {
		setTrainerSignatureSrc('');
		setTrainerSignatureDate('');
		if (trainerSignature) {
			trainerSignature.clear();
		}
	};

	return (
		<Content currentMenu='my-profile' headerTitle='My Profile'>
			{userData && userData.loading ? (
				<Spinner />
			) : (
				<div className={MyProfileStyle.profileDetails}>
					<div>
						{userData && userData.userInfo && userData.userInfo.status && userData.userInfo.data ? (
							<img
								src={
									userData.userInfo.data.role === 'gym' ? (
										userData.userInfo.data.photoDark
									) : (
										userData.userInfo.data.photo
									)
								}
								alt={userData.userInfo.data.name}
								className={MyProfileStyle.profileLogo}
							/>
						) : (
							<img src='/assets/logo.png' alt='logo' className={MyProfileStyle.profileLogo} />
						)}

						{userData &&
							userData.userInfo &&
							userData.userInfo.status &&
							userData.userInfo.data && (
								<div className={MyProfileStyle.profileCardStats}>
									<h1>PERSONAL INFORMATION</h1>
									<p className={MyProfileStyle.profileName}> {userData.userInfo.data.name} </p>
									<div className={MyProfileStyle.logindecs}>
										Your are logged in with {userData.userInfo.data.role} account.
									</div>
									<h6 className={MyProfileStyle.profileContactHeading}>CONTACT INFORMATION</h6>

									<div className={MyProfileStyle.profileContact}>
										<div className={MyProfileStyle.profileColumn}>Phone :</div>
										<div>{validatePhoneMaskingString(userData.userInfo.data.phone) ? phoneMasking(userData.userInfo.data.phone) :userData.userInfo.data.phone}</div>
									</div>
									<div className={MyProfileStyle.profileContact}>
										<div className={MyProfileStyle.profileColumn}>Email :</div>
										<div>{userData.userInfo.data.email}</div>
									</div>
									<div className={MyProfileStyle.profileContact}>
										<div className={MyProfileStyle.profileColumn}>Address :</div>
										<div style={{ width: '40vh' }}>{userData.userInfo.data.address}</div>
									</div>

									{userData &&
										userData.userInfo &&
										userData.userInfo.status &&
										userData.userInfo.data &&
										(userData.userInfo.data.role === 'gym' || userData.userInfo.data.role === 'trainer') && (
											<div className={MyProfileStyle.profileContact}>
												<div className={MyProfileStyle.profileColumn}>Session Charges :</div>
												<div>{amountToStringWithSymbol( userData.userInfo.data.sessionCharges )}</div>
											</div>
										)}
								</div>
							)}
						{userData &&
							userData.userInfo &&
							userData.userInfo.data &&
							userData.userInfo.data.role !== 'admin' && (
								<div style={{ display: 'flex', gap: '4rem' }}>
									<Button label='Edit Profile' icon='edit' onClick={onEditHandler} />

									<Button
										label='Change Password'
										icon='key'
										onClick={() => {
											setModalChangePasswordState(true);
											setSelectUpdateModel(userData.userInfo.data);
										}}
									/>
								</div>
							)}
					</div>
					{userData &&
						userData.userInfo &&
						userData.userInfo.data &&
						userData.userInfo.data.role === 'trainer' && (
							<div className={MyProfileStyle.trainerContainer}>
								<div className={MyProfileStyle.modal__headingContainer}>
									<h2 className={MyProfileStyle.modal__heading2}>INDEPENDENT CONTRACTOR AGREEMENT</h2>
								</div>
								<ModalForm style={{ overflowY: 'scroll', maxHeight: '55rem' }}>
									<InputsSectionColumn>
										{
											selectUpdateModel &&
												selectUpdateModel.gym &&
												selectUpdateModel.gym.packageName === 'io.thealgorithm.jarfit' ? (
												<div>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															THIS AGREEMENT (the "Agreement") is being made on , by and between 	{selectUpdateModel && selectUpdateModel.gym && selectUpdateModel.gym.name},  located at &nbsp; {selectUpdateModel && selectUpdateModel.gym && selectUpdateModel.gym.address}
															(the "CONTRACTOR"). The full name, address, email address and phone number of both parties appear again at the end of this document.
														</p>
														<p className={formStyle.paragraphInnerText}>
															By their respective signatures at the bottom of this document both parties hereby acknowledge that they have read and understood all the
															terms contained herein and that they have the authority to bind themselves and their  respective companies to the terms contained in
															this Agreement.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>WORK TO BE PERFORMED:</strong>  CONTRACTOR hereby agrees to work for CLIENT as an independent contractor, providing the services described below
															starting on or about and for an indefinite period thereafter, until CONTRACTOR's services are no longer needed by CLIENT.
															The CLIENT shall have the right to terminate CONTRACTOR's services at any time it deems appropriate provided CLIENT complies with the relevant
															notice provisions of this Agreement. The CONTRACTOR agrees to devote the necessary amount of time, energy and attention
															required to satisfactorily complete, conclude or achieve the following duties and responsibilities (‘Description of Services"):
														</p>
														<ul className={formStyle.paragraphText} style={{ listStyle: 'none', marginLeft: '3rem' }}>
															<li>-Consultations  </li>
															<li>-Fitness Assessments  </li>
															<li>-1-on-1 Training  </li>
															<li>-Semi-Private Training  </li>
															<li>-Group Training  </li>
															<li>-Physical Therapy  </li>
															<li>-Massage Therapy </li>
														</ul>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>SCORE OF WORK:</strong> CONTRACTOR's required services as stated herein, as well as any future assignments provided by CLIENT,
															shall be determined  on a case-by-case  basis only. CLIENT shall be under  no legal obligation to guarantee  CONTRACTOR any minimum number of assignments or any minimum number of hours of work. All work performed by CONTRACTOR for CLIENT shall be governed exclusively by the covenants contained in this Agreement. The CONTRACTOR  shall perform any and all responsibilities and duties that may be associated within the Description of Services set for above, including, but not limited to, work which may already be in progress. The CONTRACTOR shall retain sole and absolute discretion in the manner and means for the carrying out of his/her activities and responsibilities contained  in this Agreement,  and shall have full discretion within the Scope of Work, but shall not engage in any activity which is not expressly set forth by this Agreement without first obtaining prior written authorization from CLIENT.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>INDEPENDENT CONTRACTOR:</strong> CONTRACTOR and CLIENT specifically agree that the CONTRACTOR is performing the services described in this Agreement as an independent contractor and shall not be deemed an employee, partner, agent, or joint venturer of CLIENT under any circumstances. Nothing in this Agreement shall  be construed  as creating  an employer­ employee relationship. The CONTRACTOR shall not have the authority to bind the CLIENT in any manner, unless specifically authorized to do so in writing. The CONTRACTOR  shall have no claim against CLIENT hereunder  or otherwise for vacation pay, sick leave, retirement benefits, social security, worker's compensation, health or disability benefits, unemployment insurance benefits, or employee benefits of any kind. CONTRACTOR further agrees to be responsible for all of his/her own federal and state taxes, withholdings, and acknowledges that CLIENT will not make any FICA payments on CONTRACTOR's behalf. CONTRACTOR shall pay all taxes incurred while performing services under this Agreement-including all applicable income taxes and, if CONTRACTOR is  not a corporation,  self-employment (Social  Security)  taxes.  The CONTRACTOR further acknowledges and recognized that s/he shall complete and return to the CLIENT an IRS Form 1099 and related tax statements. The CONTRACTOR herein pledges and agrees to indemnify the CLIENT for any damages or expenses, including any related attorney's fees and legal expenses, incurred by the CLIENT as a result of CONTRACTOR's failure to make such required payments. Upon demand, CONTRACTOR shall provide CLIENT with proof that such payments have been made.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>EQUIPMENT & MEANS oF SERVICE: </strong>CONTRACTOR is responsible for providing all of his/her own equipment with which to complete the services contemplated by this Agreement. The CLIENT may, in its sole discretion, provide certain equipment if deemed necessary for a particular assignment or task without thereby creating a duty on CLIENT's part to do so again in the future. CONTRACTOR has the sole right to control and direct the means, manner, and method by which the services required herein will be performed. CONTRACTOR shall select the routes taken, days he/she is available to work, and manner in which the work is to be performed. CONTRACTOR shall always perform the work him/herself and does not have the right to refer any given assignment to an employee or subcontractor without prior written approval from CLIENT. The CONTRACTOR shall not receive any training from CLIENT in the professional skills necessary to perform the services required by this Agreement. Any directions or advice provided to the CONTRACTOR regarding the Description of Services shall be considered a suggestion only and not an instruction.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>COMPENSATION: </strong> In consideration  for the services to be performed by the CONTRACTOR, CLIENT hereby agrees to pay CONTRACTOR as follows:
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>Compensation Terms:</strong> The Independent Contractor shall be entitled to full compensation for their performance of those tasks, responsibilities, and/or duties related to the Scope of Work as follows:
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>Compensation Terms:</strong> All Trainers will be compensated by their clients directly and will pay 	{selectUpdateModel && selectUpdateModel.gym && selectUpdateModel.gym.name}  for each session serviced by the first of each month.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															$10 per 1-on-1 session
															$7.50 PER PERSON in semi-private or group sessions
														</p>
														<p className={formStyle.paragraphInnerText}>
															Additionally, 5% of each Trainer's monthly session total must be added to monthly fees for maintenance and utilities.
														</p>
														<p className={formStyle.paragraphInnerText}>
															Rent payments are to be paid to 	{selectUpdateModel && selectUpdateModel.gym && selectUpdateModel.gym.name} ON THE FIRST OF EACH MONTH and no later than the 5th of each month.
															Total Compensation Amount: 0.00
														</p>
														<p className={formStyle.paragraphInnerText}>
															Said compensation  shall become due and payable to the CONTRACTOR upon receipt of an invoice by the CLIENT. The invoice must include the following  information: (a) an invoice number; (b) the dates  or assignments  covered  by the invoice; and (c) a description of the work performed. CONTRACTOR's  invoices  shall be payable pursuant to the following  schedule and method:
														</p>
														<p className={formStyle.paragraphInnerText}>
															Compensation Schedule: N/A
														</p>
														<p className={formStyle.paragraphInnerText}>
															Compensation Method: N/A
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>	EXPENSES: </strong>   CONTRACTOR  shall be responsible for all expenses incurred while performing services  under  this Agreement. This includes but is not limited to, automobile, truck, and other travel expenses; vehicle maintenance and repair costs; vehicle and other license fees and permits; insurance premiums ; road, fuel, and other taxes; fines; radio, pager, or cell phone expenses; meals; and all salary, expenses, and other compensation paid to employees or contract personnel the CONTRACTOR hires to assist on the work contemplated by this Agreement.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>	CONTRACTOR's REPRESENTATIONS AND WARRANTIES: </strong> The CONTRACTOR hereby represents thats/he has complied with all Federal, State and local laws regarding business  permits, licenses, reporting requirements,  tax withholding requirements, and other legal requirements of any kind that may be required to  carry out the services contemplated  by this Agreement  and shall provide proof of same upon request by the CLIENT. The CONTRACTOR also represents  and  warrants  that  his/her relationship with the CLIENT will not cause or require thats/he breach any obligation or confidence related to any confidential, trade secret and/or proprietary information of any other person, company or entity. Furthermore, the CONTRACTOR acknowledges thats/he has not brought and will not bring or use in the performance of his or her duties for the CLIENT any proprietary or confidential information, whether or not in writing, of a former contracted company or other entity without that entity's written permission or authorization. The breach of this condition shall result in automatic termination of the relationship as of the time of the occurring breach.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>	DEFINITION OF "PROPRIETARY INFORMATION":   </strong>For the purpose of this Agreement, "Proprietary Information" shall include, but is not limited to, any information, observation, data, written materials, records, documents, drawings, photographs, layouts, computer programs, software, multi-media, social media, firmware, inventions, discoveries, improvements, developments, tools, machines, apparatus, appliances, designs, work products , logo, system, promotional ideas and material, customer lists, customer files, needs, practices, pricing information, process, test, concept, formulas, method, marketing information, technique, trade secrets, products and/or research related to the actual or anticipated research development, products, organization, marketing, advertising, business or finances of the CLIENT, its affiliates, subsidiaries or other related entities. The CONTRACTOR herein acknowledges that the CLIENT has made, or may make, available to the CONTRACTOR its Proprietary Information including, without limitation, trade secrets, inventions, patents and copyrighted materials. The CONTRACTOR  acknowledges  that this information has economic value, actual or potential value, that is not generally known to the public or to others who could obtain economic value from its disclosure or use, and that this information is subject to a reasonable effort by the CLIENT to maintain its secrecy and confidentiality. The CONTRACTOR shall comply with any reasonable rules established from time to time by the CLIENT for the protection of the confidentiality of any Proprietary Information.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>OWNERSHIP oF SOCIAL MEDIA: </strong>	 The CLIENT shall have sole ownership over any social medial contacts acquired throughout the CONTRACTOR's term of service, including, but not limited to: "followers" or "friends" which may be or have been acquired through such accounts as email addresses, biogs, Twitter, Facebook, YouTube or any other social media network that has
															been used or created on behalf of the CLIENT.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>RETURN OF PROPRIETARY INFORMATION:</strong> Any and all documents, records and books which may be related to the
															Description of Services as set forth in this Agreement, or any other Proprietary Information shared with
															CONTRACTOR, shall be maintained by the CONTRACTOR at his/her principal place of business and be open to
															inspection by the CLIENT during regular working business hours. The documents, records and books which
															the CLIENT shall have the right to inspect and receive copies of include, but are not limited to, any
															and all contract documents, any change or purchase orders, and any other items related to the work which
															has been authorized by the CLIENT on an existing or a potential project related to the services
															contemplated by this Agreement. Upon termination of this Agreement, or upon the request of CLIENT, the
															CONTRACTOR shall promptly and immediately deliver to CLIENT any and all property in its possession or
															under its care and control, including but not limited to, documents, records, or books, or any other
															Proprietary Information such as customer names and lists, trade secrets and intellectual property, or
															items such as computers, equipment, pass keys, tools, plans, recordings, software, and all related
															records or accounting/financial information. CONTRACTOR acknowledges that any breach or threatened
															breach of this Section of the Agreement will result in irreparable harm to CLIENT for which monetary
															damages could be an inadequate remedy. Therefore, CLIENT shall be entitled to equitable relief,
															including an injunction, in the event of such breach or threatened breach by CONTRACTOR as outlined in
															this Agreement. Such equitable relief shall be in addition to CLIENT's rights and remedies otherwise
															available at law.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong> PATENT APPLICATIONS: </strong> Excluded from this Agreement are any inventions and/or improvements which are
															related to the CLIENT's business that were made by the CONTRACTOR prior to commencement of this
															Agreement as follows: (i) as embodied in the United States Letters Patent or any application for a
															United States Letters Patent that was filed prior to commencement of this Agreement; or (ii) one in the
															possession of a former company who has already applied and who now owns the invention; or (ili) as set
															forth in any attachment hereto. Except as otherwise noted on the back of the signature page hereof,
															there are no inventions heretofore made or conceived by the CONTRACTOR that s/he deems to be excluded
															from the scope of this Agreement and CONTRACTOR hereby releases the CLIENT from any and all claims by
															the CONTRACTOR by reason of any use by CLIENT of any invention heretofore made or conceived by the
															CONTRACTOR.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>EXCIUSIVITY, MARKETING AND ADVERTISING:</strong>	 CONTRACTOR understands that while working on an assignment
															provided by CLIENT he/she represents CLIENT and not any other business, including his/her own business.
															While on assignment for CLIENT, CONTRACTOR shall not advertise his/her own business, shall not solicit
															work for him/herself, and shall only distribute CLIENT's business cards, name, and marketing materials.
															While not on one of CLIENT's assignments, CONTRACTOR may pursue other work for him/herself as long as it
															does not directly compete with CLIENT as described in this Agreement.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>CLIENT'S RIGHT TO SUSPEND OR ALTER WORK:</strong> Ihe CLIENT reserves the right to inspect, stop and/or alter the
															work of the CONTRACTOR at any time to assure its conformity with this Agreement and the CLIENT's needs.
															At any time, the CLIENT may, without cause, direct the CONTRACTOR, by way of providing 14 Days days
															prior written notice, to suspend, delay or interrupt work or services pursuant to this Agreement, in
															whole or in part, for such periods of time as the CLIENT in its sole discretion may see fit or
															necessary. Any such suspension shall be effected by the delivery of a written notice to the CONTRACTOR
															of said suspension specifying the extent to which the performance of the work or services under this
															Agreement is suspended, and the date upon which the suspension becomes effective. The suspension of work
															and/or services shall be treated as an excusable delay. Moreover, if at any time the CLIENT believes
															that the CONTRACTOR may not be adequately performing its obligations under this Agreement or may be
															likely to fail to complete their work/services on time as required, then the CLIENT may request from the
															CONTRACTOR provide written assurances of performance and a written plan to correct observed deficiencies
															in performance. Any failure to provide such written assurances constitutes grounds to declare a default
															under this Agreement.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>TERMINATION:</strong> Either party may terminate this Agreement in whole or in part, whenever the they shall
															determine that termination is in their best interest. Termination shall be effected by providing Odays
															written notice of termination specifying the extent to which performance of the work and/or services
															under this Agreement is terminated, and the date upon which such termination shall become effective. The
															CONTRACTOR shall then be entitled to recover any costs expended up to that point, but no other loss,
															damage, expense or liability may be claimed, requested or recovered except as provided in this
															Agreement. In no event shall the CLIENT be liable for any costs incurred by or on behalf of the
															CONTRACTOR after the effective date of the notice of termination. The termination pursuant to the
															provisions contained within this paragraph shall not be construed as a waiver of any right or remedy
															otherwise available to the CLIENT. In addition, if the CONTRACTOR its convicted of any crime or offense,
															fails or refuses to comply with the written policies or reasonable directive of CLIENT, is guilty of
															serious misconduct in connection with performance hereunder, or materially breaches any provisions of
															this Agreement, the CLIENT may terminate the engagement of the CONTRACTOR immediately and without prior
															written notice.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>EXECUTION: </strong> During and throughout the duration of this Agreement, and upon the request of and without any compensation
															other than that which is herein contained, the CONTRACTOR shall execute any documents and take action which the CLIENT may deem necessary or appropriate to ensure the implementation of all the provisions of this Agreement, including without limitation, assisting the CLIENT in obtaining and/or maintaining any patents, copyrights or similar rights to any Proprietary Information assigned and allocated to the CLIENT. The CONTRACTOR further agrees that the obligations and undertakings herein stated within this section shall continue beyond termination of this Agreement. Should  the CONTRACTOR  be called upon for any such assistance after termination, then the CONTRACTOR shall be entitled to fair and reasonable payment in addition to reimbursement of any expenses which may have been incurred at the request of the CLIENT. The CONTRACTOR nevertheless agrees to execute and deliver any agreements and documents prepared by the CLIENT and to do all other lawful acts required to establish, document and protect such rights.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>INJUNCTIVE RELIEF: </strong> CONTRACTOR hereby acknowledges (i) the unique nature of the protections and provisions established and contained within this Agreement; (ii) that the CLIENT will suffer irreparable harm if CONTRACTOR were to breach any of said protections or provisions or his/her obligations under this Agreement; and (iii) that monetary damages may be inadequate to compensate the CLIENT for such a breach. Therefore, if CONTRACTOR were to breach any of the provisions of this Agreement, then CLIENT shall be entitled to injunctive relief, in addition to any  other  remedies  at law or equity,  to enforce such provisions.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>LIABILITY: </strong>CONTRACTOR warrants and acknowledges that he/she shall be liable for any loss or any other financial liability suffered by CLIENT due to CONTRACTOR's failure to perform an assignment as contemplated  by this Agreement.  Other than a documented medical emergency or an "Act of Nature" beyond CONTRACTOR's control, CONTRACTOR shall be solely responsible for any loss caused by CONTRACTOR's failure to perform. In addition, CLIENT shall not be liable for any loss or damage to CONTRACTOR's equipment under the terms of this Agreement. CONTRACTOR's equipment shall be CONTRACTOR's sole and exclusive responsibility.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>INDEMNIFICATION: </strong> The CONTRACTOR shall defend, indemnify, hold harmless, and insure the CLIENT from any and all potential damages, expenses or liabilities which may result from or arise out of any negligence or misconduct on part of the CONTRACTOR, or from any breach or default of this Agreement which may be caused or occasioned by the acts of the CONTRACTOR. The CONTRACTOR shall also insure that all of its employees and affiliates take all actions necessary to comply with all the terms and conditions established and set forth in this Agreement.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>NOTICE: </strong> Any and all notices, which may be required hereunder by any party to the other party, shall be executed by either personal delivery in writing, or by mail, registered or certified, postage pre-paid with a return receipt requested. Mailed notices must be addressed to the parties at the addresses contained in this Agreement.  However, each party may change their address, thus requiring written notice of such change of address  in accordance with this section. Any hand delivered  notice shall be deemed communicated as of actual receipt; mailed notices shall be deemed communicated after five (5) days of mailing. The CONTRACTOR herein agrees to keep the CLIENT informed of any change  of  business  and/or  mailing addresses, as well as telephone, facsimile, email or any other relevant means of contact and communication.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															CONTINUING EFFECTS. The CONTRACTOR's obligations with regards to all trade secrets and confidential information contained in this Agreement , shall continue to be in effect beyond the scope of the relationship as aforementioned, and said obligations shall continue to be binding upon not only the CONTRACTOR, but also the spouse, affiliates, assigns, heirs, executors, administrators and/or other legal representatives as well.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>CHOICE OF LAW: </strong>  This Agreement is to be construed  pursuant to the current laws of the State of Colorado without  giving effect to any conflict of laws principle. Jurisdiction  and  venue for any claim arising out of this Agreement  shall be made in  the State of Colorado, in the County of Douglas.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>MEDIATION, LITIGATION & ARBITRATION: </strong>  If a dispute arises out of or relates to this Agreement, or the alleged breach thereof, and if the dispute is not settled through negotiation, the parties agree first to try in good faith to settle the dispute through mediation. The mediation process shall be administered by the Colorado Mediation Services, or another administrator mutually agreed between the parties, and shall be a condition precedent to resorting to arbitration, litigation, or some other dispute resolution procedure. If the mediation process is unsuccessful, either party shall have the option of seeking either arbitration  or filing a legal action in a court of competent jurisdiction. If the aggrieved party seeks arbitration, then the dispute shall be submitted to binding arbitration by the American Arbitration Association in accordance with the Association's  commercial  rules  then in effect. The arbitration shall be conducted in the state of Colorado and shall be binding on both parties. Judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction to do  so.  Costs  of arbitration, including attorney fees, will be allocated by the arbitrator. If, alternatively, the aggrieved party seeks to file an action in court, then the action must be brought a court of competent jurisdiction in the State of Colorado.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>LEGAL FEES: </strong> Should any party initiate litigation, arbitration, mediation or any other legal proceeding ("Proceeding") against another party to enforce, interpret or otherwise seek to obtain legal or judicial relief in connection with this Agreement, the prevailing party in said proceeding shall be entitled to recover from the unsuccessful party any and all legal fees, cost,
															expenses, attorney's fees and any other cost or expense and fees arising from (i) such proceeding, whether or not such proceeding progresses to judgment, and (ii) any post-judgment or post-award proceeding, including without limitation, one to enforce any judgment or award resulting from any such Proceeding. Any such judgment or award shall contain a specific provision for the recovery of all such attorney's fees, costs, and expenses, as well as specific provisions for the recovery of all such subsequently incurred costs, expenses and actual attorney's fees.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>ENTIRE UNDERSTANDING: </strong> This document and any schedules attached hereto constitute the entire understanding and agreement of the parties, and any and all prior agreements, understandings, and representations are hereby terminated and canceled in their entirety and carry no further force or effect. This Agreement shall  be considered  a separate  and an independent document of which it shall supersede  any and all other Agreements,  either oral or written, between the parties hereto, except for any separately signed Confidentiality, Trade Secret, Non-Compete or Non-Disclosure Agreements to the extent that these terms are not in conflict with those set forth herein.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong>	HEADINGS: </strong> The headings of the sections of this Agreement are inserted for convenience only and shall not be deemed to constitute part of this Agreement or to affect the construction thereof.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong> SEVERABILITY:</strong> If any part of this Agreement is determined  to be void, invalid, inoperative or unenforceable  by a court of competent jurisdiction or by any other legally constituted body having jurisdiction to make such determination, such decision shall not affect any other provisions  hereof  and the remainder  of this Agreement  shall be effective as though such void, invalid, inoperative or unenforceable provision had not been contained herein.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong> MODIFICATIONS OR AMENDMENTS: </strong> No amendment, change or modification of this Agreement shall be valid unless in writing and signed by both parties hereto with the same degree of formality as this Agreement.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong> COUNTERPART's: </strong> This Agreement, at the discretion of the parties herein, may be executed in counterparts, each of which shall be deemed an original and all of which together shall constitute a single integrated document.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong> WAIVER: </strong> If either party fails to enforce any provision contained within this Agreement, it shall not be construed as a waiver or limitation of that party's right to subsequently enforce and compel strict compliance with every provision of this Agreement.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong> 	DRAFTING AMBIGUITIES: </strong>  All parties to this Agreement have reviewed and had the opportunity to revise this Agreement, and have had the opportunity to have legal counsel review and or revise this Agreement. The rule of construction that ambiguities are to be resolved against the drafting party shall not be employed in the interpretation of this Agreement or of any amendments or exhibits herein.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															<strong> COPIES: </strong> Both the CONTRACTOR and the CLIENT hereby acknowledges that they have received a signed copy of this Agreement.
														</p>
													</p>
													<p className={formStyle.paragraphText}>
														<p className={formStyle.paragraphInnerText}>
															IN WITNESS WHERE OF the undersigned have executed this Agreement as of the day and year first written above. The parties hereto agree that facsimile signatures shall be as effective as if originals.
														</p>
													</p>
												</div>
											) : (
												<div>
													<p className={formStyle.paragraphText}>
														THIS AGREEMENT (the “Agreement") is being made on, by and between &nbsp;
														{selectUpdateModel && selectUpdateModel.gym && selectUpdateModel.gym.name}, located at &nbsp;
														{selectUpdateModel && selectUpdateModel.gym && selectUpdateModel.gym.address} (the “CONTRACTOR’).
														The full name, address, email address and phone number of both parties appear again at the end of
														this document.
													</p>
													<p className={formStyle.paragraphText}>
														By their respective signatures at the bottom of this document both parties hereby acknowledge that
														they have read and understood all the terms contained herein and that they have the authority to
														bind themselves and their respective companies to the terms contained in this Agreement. Work To
														Be Perrormeo, CONTRACTOR hereby agrees to work for CLIENT as an independent contractor, providing
														the services described below starting on or about and for an indefinite period thereafter, until
														CONTRACTOR's services are no longer needed by CLIENT. The CLIENT shall have the right to terminate
														CONTRACTOR's services at any time it deems appropriate provided CLIENT complies with the relevant
														notice provisions of this Agreement.
													</p>
													<p className={formStyle.paragraphText}>
														The CONTRACTOR agrees to devote the necessary amount of time, energy and attention required to
														satisfactorily complete, conclude or achieve the following duties and responsibilities
														(‘Description of Services"):
													</p>
													<ul className={formStyle.paragraphText} style={{ listStyle: 'none', marginLeft: '3rem' }}>
														<li>-Consultations </li>
														<li>-Fitness Assessments </li>
														<li>-1-on-1 Training </li>
														<li>-Semi-Private Training </li>
														<li>-Group Training </li>
														<li>-Physical Therapy </li>
														<li>-Massage Therapy </li>
													</ul>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u> SCORE OF WORK. </u>{' '}
														</strong>{' '}
														CONTRACTOR's required services as stated herein, as well as any future assignments provided by
														CLIENT, shall be determined on a case-by-case basis only. CLIENT shall be under no legal
														obligation to guarantee CONTRACTOR any minimum number of assignments or any minimum number of
														hours of work. All work performed by CONTRACTOR for CLIENT shall be governed exclusively by the
														covenants contained in this Agreement. The CONTRACTOR shall perform any and all responsibilities
														and duties that may be associated within the Description of Services set for above, including, but
														not limited to, work which may already be in progress. The CONTRACTOR shall retain sole and
														absolute discretion in the manner and means for the carrying out of his/her activities and
														responsibilities contained in this Agreement, and shall have full discretion within the Scope of
														Work, but shall not engage in any activity which is not expressly set forth by this Agreement
														without first obtaining prior written authorization from CLIENT.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>INDEPENDENT CONTRACTOR. </u>{' '}
														</strong>
														CONTRACTOR and CLIENT specifically agree that the CONTRACTOR is performing the services described
														in this Agreement as an independent contractor and shall not be deemed an employee, partner,
														agent, or joint venturer of CLIENT under any circumstances. Nothing in this Agreement shall be
														construed as creating an employer- employee relationship. The CONTRACTOR shall not have the
														authority to bind the CLIENT in any manner, unless specifically authorized to do so in writing.
														The CONTRACTOR shall have no claim against CLIENT hereunder or otherwise for vacation pay, sick
														leave, retirement benefits, social security, worker's compensation, health or disability benefits,
														unemployment insurance benefits, or employee benefits of any kind. CONTRACTOR further agrees to be
														responsible for all of his/her own federal and state taxes, withholdings, and acknowledges that
														CLIENT will not make any FICA payments on CONTRACTOR's behalf. CONTRACTOR shall pay all taxes
														incurred while performing services under this Agreement—including all applicable income taxes and,
														if CONTRACTOR is not a corporation, self-employment (Social Security) taxes. The CONTRACTOR
														further acknowledges and recognized that s/he shall complete and return to the CLIENT an IRS Form
														1099 and related tax statements. The CONTRACTOR herein pledges and agrees to indemnify the CLIENT
														for any damages or expenses, including any related attorney's fees and legal expenses, incurred by
														the CLIENT as a result of CONTRACTOR's failure to make such required payments. Upon demand,
														CONTRACTOR shall provide CLIENT with proof that such payments have been made.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>EQUIPMENT & MEANS OF SERVICES.</u>{' '}
														</strong>
														CONTRACTOR is responsible for providing all of his/her own equipment with which to complete the
														services contemplated by this Agreement. The CLIENT may, in its sole discretion, provide certain
														equipment if deemed necessary for a particular assignment or task without thereby creating a duty
														on CLIENT's part to do so again in the future. CONTRACTOR has the sole right to control and direct
														the means, manner, and method by which the services required herein will be performed. CONTRACTOR
														shall select the routes taken, days he/she is available to work, and manner in which the work is
														to be performed. CONTRACTOR shall always perform the work him/herself and does not have the right
														to refer any given assignment to an employee or subcontractor without prior written approval from
														CLIENT. The CONTRACTOR shall not receive any training from CLIENT in the professional skills
														necessary to perform the services required by this Agreement. Any directions or advice provided to
														the CONTRACTOR regarding the Description of Services shall be considered a suggestion only and not
														an instruction.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>COMPENSATION.</u>{' '}
														</strong>
														In consideration for the services to be performed by the CONTRACTOR, CLIENT hereby agrees to pay
														CONTRACTOR as follows:
													</p>
													<p className={formStyle.paragraphText}>
														Compensation Terms: The Independent Contractor shall be entitled to full compensation for their
														performance of those tasks, responsibilities, and/or duties related to the Scope of Work as
														follows:
													</p>
													<p className={formStyle.paragraphText}>
														Compensation Terms: All Trainers will be compensated by their clients directly and will pay &nbsp;{' '}
														{selectUpdateModel && selectUpdateModel.gym && selectUpdateModel.gym.name} for each session
														serviced by the first of each month.
													</p>
													<p className={formStyle.paragraphText}>
														$10 per 1-on-1 session <br />
														$7.50 PER PERSON in semi-private or group sessions
													</p>
													<p className={formStyle.paragraphText}>
														Additionally, 5% of each Trainer's monthly session total must be added to monthly fees for
														maintenance and utilities.
													</p>
													<p className={formStyle.paragraphText}>
														Rent payments are to be paid to &nbsp;{' '}
														{selectUpdateModel && selectUpdateModel.gym && selectUpdateModel.gym.name} ON THE FIRST OF EACH
														MONTH and no later than the 5th of each month.
													</p>
													<p className={formStyle.paragraphText}>Total Compensation Amount: 0.00</p>
													<p className={formStyle.paragraphText}>
														Said compensation shall become due and payable to the CONTRACTOR upon receipt of an invoice by the
														CLIENT. The invoice must include the following information: (a) an invoice number; (b) the dates
														or assignments covered by the invoice; and (c) a description of the work performed. CONTRACTOR's
														invoices shall be payable pursuant to the following schedule and method:
													</p>
													<p className={formStyle.paragraphText}>
														Compensation Schedule: N/A <br />
														Compensation Method: N/A
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>EXPENSES. </u>{' '}
														</strong>
														CONTRACTOR shall be responsible for all expenses incurred while performing services under this
														Agreement. This includes but is not limited to, automobile, truck, and other travel expenses;
														vehicle maintenance and repair costs; vehicle and other license fees and permits; insurance
														premiums; road, fuel, and other taxes; fines; radio, pager, or cell phone expenses; meals; and all
														salary, expenses, and other compensation paid to employees or contract personnel the CONTRACTOR
														hires to assist on the work contemplated by this Agreement.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>CONTRACTOR'S REPRESENTATIONS AND WARRANTIES.</u>{' '}
														</strong>
														The CONTRACTOR hereby represents that s/he has complied with all Federal, State and local laws
														regarding business permits, licenses, reporting requirements, tax withholding requirements, and
														other legal requirements of any kind that may be required to carry out the services contemplated
														by this Agreement and shall provide proof of same upon request by the CLIENT. The CONTRACTOR also
														represents and warrants that his/her relationship with the CLIENT will not cause or require that
														s/ne breach any obligation or confidence related to any confidential, trade secret and/or
														proprietary information of any other person, company or entity. Furthermore, the CONTRACTOR
														acknowledges that s/he has not brought and will not bring or use in the performance of his or her
														duties for the CLIENT any proprietary or confidential information, whether or not in writing, of a
														former contracted company or other entity without that entity's written permission or
														authorization. The breach of this condition shall result in automatic termination of the
														relationship as of the time of the occurring breach.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>DEFINITION OF "PROPRIETARY INFORMATION."</u>{' '}
														</strong>
														For the purpose of this Agreement, "Proprietary Information" shall include, but is not limited to,
														any information, observation, data, written materials, records, documents, drawings, photographs,
														layouts, computer programs, software, multi-media, social media, firmware, inventions,
														discoveries, improvements, developments, tools, machines, apparatus, appliances, designs, work
														products, logo, system, promotional ideas and material, customer lists, customer files, needs,
														practices, pricing information, process, test, concept, formulas, method, marketing information,
														technique, trade secrets, products and/or research related to the actual or anticipated research
														development, products, organization, marketing, advertising, business or finances of the CLIENT,
														its affiliates, subsidiaries or other related entities. The CONTRACTOR herein acknowledges that
														the CLIENT has made, or may make, available to the CONTRACTOR its Proprietary Information
														including, without limitation, trade secrets, inventions, patents and copyrighted materials. The
														CONTRACTOR acknowledges that this information has economic value, actual or potential value, that
														is not generally known to the public or to others who could obtain economic value from its
														disclosure or use, and that this information is subject to a reasonable effort by the CLIENT to
														maintain its secrecy and confidentiality. The CONTRACTOR shall comply with any reasonable rules
														established from time to time by the CLIENT for the protection of the confidentiality of any
														Proprietary Information.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>OWNERSHIP OF SOCIAL MEDIA.</u>{' '}
														</strong>
														The CLIENT shall have sole ownership over any social medial contacts acquired throughout the
														CONTRACTOR's term of service, including, but not limited to: "followers" or "friends" which may be
														or have been acquired through such accounts as email addresses, blogs, Twitter, Facebook, YouTube
														or any other social media network that has been used or created on behalf of the CLIENT.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>RETURN OF PROPRIETARY INFORMATION.</u>{' '}
														</strong>
														Any and all documents, records and books which may be related to the Description of Services as
														set forth in this Agreement, or any other Proprietary Information shared with CONTRACTOR, shall be
														maintained by the CONTRACTOR at his/her principal place of business and be open to inspection by
														the CLIENT during regular working business hours. The documents, records and books which the
														CLIENT shall have the right to inspect and receive copies of include, but are not limited to, any
														and all contract documents, any change or purchase orders, and any other items related to the work
														which has been authorized by the CLIENT on an existing or a potential project related to the
														services contemplated by this Agreement. Upon termination of this Agreement, or upon the request
														of CLIENT, the CONTRACTOR shall promptly and immediately deliver to CLIENT any and all property in
														its possession or under its care and control, including but not limited to, documents, records, or
														books, or any other Proprietary Information such as customer names and lists, trade secrets and
														intellectual property, or items such as computers, equipment, pass keys, tools, plans, recordings,
														software, and all related records or accounting/financial information. CONTRACTOR acknowledges
														that any breach or threatened breach of this Section of the Agreement will result in irreparable
														harm to CLIENT for which monetary damages could be an inadequate remedy. Therefore, CLIENT shall
														be entitled to equitable relief, including an injunction, in the event of such breach or
														threatened breach by CONTRACTOR as outlined in this Agreement. Such equitable relief shall be in
														addition to CLIENT's rights and remedies otherwise available at law.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>PATENT APPLICATIONS</u>{' '}
														</strong>
														Excluded from this Agreement are any inventions and/or improvements which are related to the
														CLIENT's business that were made by the CONTRACTOR prior to commencement of this Agreement as
														follows: (i) as embodied in the United States Letters Patent or any application for a United
														States Letters Patent that was filed prior to commencement of this Agreement; or (ii) one in the
														possession of a former company who has already applied and who now owns the invention; or (ili) as
														set forth in any attachment hereto. Except as otherwise noted on the back of the signature page
														hereof, there are no inventions heretofore made or conceived by the CONTRACTOR that s/he deems to
														be excluded from the scope of this Agreement and CONTRACTOR hereby releases the CLIENT from any
														and all claims by the CONTRACTOR by reason of any use by CLIENT of any invention heretofore made
														or conceived by the CONTRACTOR.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>EXCIUSIVITY, MARKETING AND ADVERTISING.</u>{' '}
														</strong>
														CONTRACTOR understands that while working on an assignment provided by CLIENT he/she represents
														CLIENT and not any other business, including his/her own business. While on assignment for CLIENT,
														CONTRACTOR shall not advertise his/her own business, shall not solicit work for him/herself, and
														shall only distribute CLIENT's business cards, name, and marketing materials. While not on one of
														CLIENT's assignments, CONTRACTOR may pursue other work for him/herself as long as it does not
														directly compete with CLIENT as described in this Agreement.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>CLIENT'S RIGHT TO SUSPEND OR ALTER WORK.</u>{' '}
														</strong>
														Ihe CLIENT reserves the right to inspect, stop and/or alter the work of the CONTRACTOR at any time
														to assure its conformity with this Agreement and the CLIENT's needs. At any time, the CLIENT may,
														without cause, direct the CONTRACTOR, by way of providing 14 Days days prior written notice, to
														suspend, delay or interrupt work or services pursuant to this Agreement, in whole or in part, for
														such periods of time as the CLIENT in its sole discretion may see fit or necessary. Any such
														suspension shall be effected by the delivery of a written notice to the CONTRACTOR of said
														suspension specifying the extent to which the performance of the work or services under this
														Agreement is suspended, and the date upon which the suspension becomes effective. The suspension
														of work and/or services shall be treated as an excusable delay. Moreover, if at any time the
														CLIENT believes that the CONTRACTOR may not be adequately performing its obligations under this
														Agreement or may be likely to fail to complete their work/services on time as required, then the
														CLIENT may request from the CONTRACTOR provide written assurances of performance and a written
														plan to correct observed deficiencies in performance. Any failure to provide such written
														assurances constitutes grounds to declare a default under this Agreement.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>TERMINATION.</u>{' '}
														</strong>{' '}
														Either party may terminate this Agreement in whole or in part, whenever the they shall determine
														that termination is in their best interest. Termination shall be effected by providing Odays
														written notice of termination specifying the extent to which performance of the work and/or
														services under this Agreement is terminated, and the date upon which such termination shall become
														effective. The CONTRACTOR shall then be entitled to recover any costs expended up to that point,
														but no other loss, damage, expense or liability may be claimed, requested or recovered except as
														provided in this Agreement. In no event shall the CLIENT be liable for any costs incurred by or on
														behalf of the CONTRACTOR after the effective date of the notice of termination. The termination
														pursuant to the provisions contained within this paragraph shall not be construed as a waiver of
														any right or remedy otherwise available to the CLIENT. In addition, if the CONTRACTOR its
														convicted of any crime or offense, fails or refuses to comply with the written policies or
														reasonable directive of CLIENT, is guilty of serious misconduct in connection with performance
														hereunder, or materially breaches any provisions of this Agreement, the CLIENT may terminate the
														engagement of the CONTRACTOR immediately and without prior written notice.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>EXECUTION.</u>{' '}
														</strong>{' '}
														During and throughout the duration of this Agreement, and upon the request of and without any
														compensation other than that which is herein contained, the CONTRACTOR shall execute any documents
														and take action which the CLIENT may deem necessary or appropriate to ensure the implementation of
														all the provisions of this Agreement, including without limitation, assisting the CLIENT in
														obtaining and/or maintaining any patents, copyrights or similar rights to any Proprietary
														Information assigned and allocated to the CLIENT. The CONTRACTOR further agrees that the
														obligations and undertakings herein stated within this section shall continue beyond termination
														of this Agreement. Should the CONTRACTOR be called upon for any such assistance after termination,
														then the CONTRACTOR shall be entitled to fair and reasonable payment in addition to reimbursement
														of any expenses which may have been incurred at the request of the CLIENT. The CONTRACTOR
														nevertheless agrees to execute and deliver any agreements and documents prepared by the CLIENT and
														to do all other lawful acts required to establish, document and protect such rights.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u> INJUNCTIVE RELIEF.</u>{' '}
														</strong>
														CONTRACTOR hereby acknowledges (i) the unique nature of the protections and provisions established
														and contained within this Agreement; (ii) that the CLIENT will suffer irreparable harm if
														CONTRACTOR were to breach any of said protections or provisions or his/her obligations under this
														Agreement; and (iii) that monetary damages may be inadequate to compensate the CLIENT for such a
														breach. Therefore, if CONTRACTOR were to breach any of the provisions of this Agreement, then
														CLIENT shall be entitled to injunctive relief, in addition to any other remedies at law or equity,
														to enforce such provisions.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>LIABILITY.</u>{' '}
														</strong>
														CONTRACTOR warrants and acknowledges that he/she shall be liable for any loss or any other
														financial liability suffered by CLIENT due to CONTRACTOR's failure to perform an assignment as
														contemplated by this Agreement. Other than a documented medical emergency or an "Act of Nature"
														beyond CONTRACTOR's control, CONTRACTOR shall be solely responsible for any loss caused by
														CONTRACTOR's failure to perform. In addition, CLIENT shall not be liable for any loss or damage to
														CONTRACTOR's equipment under the terms of this Agreement. CONTRACTOR's equipment shall be
														CONTRACTOR's sole and exclusive responsibility.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>INDEMNIFICATION.</u>{' '}
														</strong>
														The CONTRACTOR shall defend, indemnify, hold harmless, and insure the CLIENT from any and all
														potential damages, expenses or liabilities which may result from or arise out of any negligence or
														misconduct on part of the CONTRACTOR, or from any breach or default of this Agreement which may be
														caused or occasioned by the acts of the CONTRACTOR. The CONTRACTOR shall also insure that all of
														its employees and affiliates take all actions necessary to comply with all the terms and
														conditions established and set forth in this Agreement.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>NOTICE.</u>{' '}
														</strong>
														Any and all notices, which may be required hereunder by any party to the other party, shall be
														executed by either personal delivery in writing, or by mail, registered or certified, postage
														pre-paid with a return receipt requested. Mailed notices must be addressed to the parties at the
														addresses contained in this Agreement. However, each party may change their address, thus
														requiring written notice of such change of address in accordance with this section. Any hand
														delivered notice shall be deemed communicated as of actual receipt; mailed notices shall be deemed
														communicated after five (5) days of mailing. The CONTRACTOR herein agrees to keep the CLIENT
														informed of any change of business and/or mailing addresses, as well as telephone, facsimile,
														email or any other relevant means of contact and communication.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>CONTINUING EFFECTS.</u>
														</strong>
														The CONTRACTOR's obligations with regards to all trade secrets and confidential information
														contained in this Agreement, shall continue to be in effect beyond the scope of the relationship
														as aforementioned, and said obligations shall continue to be binding upon not only the CONTRACTOR,
														but also the spouse, affiliates, assigns, heirs, executors, administrators and/or other legal
														representatives as well.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>CHOICE OF LAW.</u>
														</strong>
														This Agreement is to be construed pursuant to the current laws of the State of Colorado without
														giving effect to any conflict of laws principle. Jurisdiction and venue for any claim arising out
														of this Agreement shall be made in the State of Colorado, in the County of Douglas.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>MEDIATION, LITIGATION & ARBITRATION.</u>
														</strong>
														If a dispute arises out of or relates to this Agreement, or the alleged breach thereof, and if the
														dispute is not settled through negotiation, the parties agree first to try in good faith to settle
														the dispute through mediation. The mediation process shall be administered by the Colorado
														Mediation Services, or another administrator mutually agreed between the parties, and shall be a
														condition precedent to resorting to arbitration, litigation, or some other dispute resolution
														procedure. If the mediation process is unsuccessful, either party shall have the option of seeking
														either arbitration or filing a legal action in a court of competent jurisdiction. If the aggrieved
														party seeks arbitration, then the dispute shall be submitted to binding arbitration by the
														American Arbitration Association in accordance with the Association's commercial rules then in
														effect. The arbitration shall be conducted in the state of Colorado and shall be binding on both
														parties. Judgment upon the award rendered by the arbitrator may be entered in any court having
														jurisdiction to do so. Costs of arbitration, including attorney fees, will be allocated by the
														arbitrator. If, alternatively, the aggrieved party seeks to file an action in court, then the
														action must be brought a court of competent jurisdiction in the State of Colorado.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>LEGAL FEES.</u>
														</strong>
														Should any party initiate litigation, arbitration, mediation or any other legal proceeding
														("Proceeding") against another party to enforce, interpret or otherwise seek to obtain legal or
														judicial relief in connection with this Agreement, the prevailing party in said proceeding shall
														be entitled to recover from the unsuccessful party any and all legal fees, cost, expenses,
														attorney's fees and any other cost or expense and fees arising from (i) such proceeding, whether
														or not such proceeding progresses to judgment, and (ii) any post-judgment or post-award
														proceeding, including without limitation, one to enforce any judgment or award resulting from any
														such Proceeding. Any such judgment or award shall contain a specific provision for the recovery of
														all such attorney's fees, costs, and expenses, as well as specific provisions for the recovery of
														all such subsequently incurred costs, expenses and actual attorney's fees.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>ENTIRE UNDERSTANDING.</u>
														</strong>
														This document and any schedules attached hereto constitute the entire understanding and agreement
														of the parties, and any and all prior agreements, understandings, and representations are hereby
														terminated and canceled in their entirety and carry no further force or effect. This Agreement
														shall be considered a separate and an independent document of which it shall supersede any and all
														other Agreements, either oral or written, between the parties hereto, except for any separately
														signed Confidentiality, Trade Secret, Non-Compete or Non-Disclosure Agreements to the extent that
														these terms are not in conflict with those set forth herein.
													</p>

													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>HEADINGS.</u>
														</strong>
														The headings of the sections of this Agreement are inserted for convenience only and shall not be
														deemed to constitute part of this Agreement or to affect the construction thereof.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>SEVERABILITY.</u>
														</strong>
														If any part of this Agreement is determined to be void, invalid, inoperative or unenforceable by a
														court of competent jurisdiction or by any other legally constituted body having jurisdiction to
														make such determination, such decision shall not affect any other provisions hereof and the
														remainder of this Agreement shall be effective as though such void, invalid, inoperative or
														unenforceable provision had not been contained herein.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>MODIFICATIONS OR AMENDMENTS.</u>
														</strong>
														No amendment, change or modification of this Agreement shall be valid unless in writing and signed
														by both parties hereto with the same degree of formality as this Agreement.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>COUNTERPARTS.</u>{' '}
														</strong>
														This Agreement, at the discretion of the parties herein, may be executed in counterparts, each of
														which shall be deemed an original and all of which together shall constitute a single integrated
														document.Waiver.lf either party fails to enforce any provision contained within this Agreement, it
														shall not be construed as a waiver or limitation of that party's right to subsequently enforce and
														compel strict compliance with every provision of this Agreement.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>DRAFTING and AMBIGUITIES.</u>
														</strong>
														All parties to this Agreement have reviewed and had the opportunity to revise this Agreement, and
														have had the opportunity to have legal counsel review and or revise this Agreement.The rule of
														construction that ambiguities are to be resolved against the drafting party shall not be employed
														in the interpretation of this Agreement or of any amendments or exhibits herein.
													</p>
													<p className={formStyle.paragraphText}>
														<strong>
															{' '}
															<u>COPIES.</u>
														</strong>
														Both the CONTRACTOR and the CLIENT hereby acknowledges that they have received a signed copy of
														this Agreement.
													</p>

													<p className={formStyle.paragraphText}>
														<strong>IN WITNESS WHEREOF </strong> the undersigned have executed this Agreement as of the day
														and year first written above. The parties hereto agree that facsimile signatures shall be as
														effective as if originals.<br />
													</p>
												</div>
											)}
									</InputsSectionColumn>
									<InputsSection>
										<div style={{ display: 'flex', gap: '2rem' }}>
											<div style={{ minWidth: '50rem' }}>
												<div style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '1rem' }}>
													{gymSignature && <img alt='image' src={gymSignature} style={{ width: '15vh' }} />}
												</div>
												<p
													className={formStyle.paragraphText}
													style={{ display: 'flex', justifyContent: 'space-between' }}>
													David Hayes Plowman / Owner and Operator <br />
													Against the &nbsp; {selectUpdateModel &&
														selectUpdateModel.gym &&
														selectUpdateModel.gym.name}{' '}
													<br />
												</p>
											</div>
											<div
												className={formStyle.paragraphText}
												style={{ display: 'flex', justifyContent: 'end', alignItems: 'start', flexDirection: 'column' }}>
												<InputBox
													label=''
													icon='calendar'
													disabled={true}
													placeholder='Gym Signature'
													value={gymSignatureDate}
													type='date'
												/>
												<br />
												Date <br />
											</div>
										</div>
									</InputsSection>
									<InputsSection>
										<div style={{ display: 'flex', gap: '2rem' }}>
											<div style={{ minWidth: '50rem' }}>
												<div style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '1rem' }}>
													{trainerSignatureSrc ? (
														<img alt='image' src={trainerSignatureSrc} style={{ width: '15vh' }}/>
													) : (
														<SignatureCanvas
															penColor='black'
															canvasProps={{ width: 500, height: 150, className: 'sigCanvas' }}
															ref={(ref) => {
																setTrainerSignature(ref);
															}}
														/>
													)}
												</div>
												{trainerSignatureError && (
													<p style={{ fontSize: '1.2rem', color: '#ff3366' }}>{trainerSignatureError}</p>
												)}

												<p
													className={formStyle.paragraphText}
													style={{ display: 'flex', justifyContent: 'space-between' }}>
													{selectUpdateModel && selectUpdateModel.name} <br />
													Independent Contractor / Title <br />
													{trainerSignatureSrc ? (
														''
													) : (
														<button className={MyProfileStyle.signDataAddBtn} onClick={onClearHandler}>
															Clear
														</button>
													)}
												</p>
											</div>
											<div
												className={formStyle.paragraphText}
												style={{ display: 'flex', justifyContent: 'end', alignItems: 'start', flexDirection: 'column' }}>
												<InputBox
													label=''
													icon='calendar'
													placeholder='Trainer Signature'
													value={trainerSignatureDate}
													type='date'
													onChange={(event) => {
														setTrainerSignatureDate(event.target.value);
														setTrainerSignatureDateError('');
													}}
													errorMessage={trainerSignatureDateError}
												/>
												<br />
												Date <br />
											</div>
										</div>
									</InputsSection>
								</ModalForm>
								<div className={MyProfileStyle.modal__headingContainer} style={{ paddingLeft: '4rem' }}>
									{selectUpdateModel && selectUpdateModel.gym && trainerSignatureSrc ? (
										''
									) : createFormByIndependentAgreement && createFormByIndependentAgreement.loading ? (
										<ModalButton label='Saving Form' icon='plus' />
									) : (
										<ModalButton label='Submit' icon='plus' onClick={onFormHandler} />
									)}
								</div>
							</div>
						)}
				</div>
			)}

			<ChangePassword
				data={{
					selectUpdateModel,
					setSelectUpdateModel,
					modalChangePasswordState,
					setModalChangePasswordState
				}}
			/>

			<EditProfile
				data={{
					name,
					email,
					address,
					phone,
					setName,
					setEmail,
					setPhone,
					setAddress,
					sessionCharges,
					setSessionCharges,
					sessionChargesError,
					setSessionChargesError,

					nameError,
					addressError,
					phoneError,
					emailError,

					setNameError,
					setAddressError,
					setPhoneError,
					setEmailError,

					imageSrc,
					setImageSrc,
					imageSrcError,
					setImageSrcError,
					setUploadFileData,
					uploadFileDataError,
					setUploadFileDataError,
					setUploadDarkFileData,
					uploadDarkFileDataError,
					setUploadDarkFileDataError,
					darkimageSrc,
					setDarkImageSrc,

					handleEditModalClose,
					handleSubmit,
					showEditModal,
					setShowEditModal,
					selectUpdateModel,
					setSelectUpdateModel
				}}
			/>
		</Content>
	);
};

export default MyProfileScreen;
