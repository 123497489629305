import axios from "axios";
import { BASE_URL } from "./ip";
import { ADD_ROOM_REQUEST, ADD_ROOM_SUCCESS, ADD_WORKOUT_FAIL, ADD_WORKOUT_REQUEST, ADD_WORKOUT_SUCCESS, CHANGE_ROOM_FAIL, CHANGE_ROOM_REQUEST, CHANGE_ROOM_SUCCESS, CHANGE_WORKOUT_FAIL, CHANGE_WORKOUT_REQUEST, CHANGE_WORKOUT_SUCCESS, DELETE_ROOM_FAIL, DELETE_ROOM_REQUEST, DELETE_ROOM_SUCCESS, DELETE_WORKOUT_FAIL, DELETE_WORKOUT_REQUEST, DELETE_WORKOUT_SUCCESS, GET_ALL_ROOM_FAIL, GET_ALL_ROOM_REQUEST, GET_ALL_ROOM_SUCCESS, GET_WORKOUT_FAIL, GET_WORKOUT_REQUEST, GET_WORKOUT_SUCCESS } from "../constants/templateConstants";

export const createRoom = (formData) => async (dispatch, getState) => {
	console.log(formData)
	try {
		dispatch({ type: ADD_ROOM_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/room`, formData, config);

		dispatch({
			type: ADD_ROOM_SUCCESS,
			payload: data
		});
	} catch (err) {
		console.log(err)
		// dispatch({
		// 	type: ADD_ROOM_FAIL,
		// 	payload: err.response && err.response.data.message ? err.response.data.message : err.message
		// });
	}
};

export const getAllRoom = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_ROOM_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/room`, config);

		dispatch({
			type: GET_ALL_ROOM_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_ROOM_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const updateRoom = (id,formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_ROOM_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.patch(`${BASE_URL}/room/${id}`, formData, config);

		dispatch({
			type: CHANGE_ROOM_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_ROOM_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteRoomAction = (roomId) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_ROOM_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.delete(`${BASE_URL}/room/${roomId}`, config);

		dispatch({
			type: DELETE_ROOM_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_ROOM_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
	}

export const createWorkoutById = (roomId, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ADD_WORKOUT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/room/workout/${roomId}`, formData, config);

		dispatch({
			type: ADD_WORKOUT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: ADD_WORKOUT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const getWorkoutById = (roomId) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_WORKOUT_REQUEST });

		// const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				// token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/room/workout/${roomId}`, config);

		dispatch({
			type: GET_WORKOUT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_WORKOUT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const updateWorkoutById = (roomId, workoutId, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_WORKOUT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.patch(`${BASE_URL}/room/byId/${roomId}/${workoutId}`,formData, config);

		dispatch({
			type: CHANGE_WORKOUT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: CHANGE_WORKOUT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

export const deleteWorkoutById = (roomId, workoutId) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_WORKOUT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.delete(`${BASE_URL}/room/room-workout/${roomId}/${workoutId}`,config);

		dispatch({
			type: DELETE_WORKOUT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_WORKOUT_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};

