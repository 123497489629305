import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/Spinner/Spinner';
import Content from '../../components/Content';
import UsersData from './UsersData';
import DeleteUser from './DeleteUser';
import CreateUser from './CreateUser';
import {
	activeUserAction,
	createNewUserAction,
	deleteUserAction,
	getUserListAction,
	logout,
	logOutUserAction
} from '../../redux/actions/userActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { getTrainerListAction } from '../../redux/actions/trainerActions';
import { TRAINER_RESET } from '../../redux/constants/trainerConstants';
import {
	CHANGE_PASSWORD_USER_RESET,
	CHANGE_STATUS_USER_RESET,
	CREATE_NEW_USER_RESET,
	DELETE_USER_RESET,
	LOGOUT_USER_RESET
} from '../../redux/constants/userConstants';
import { validateOnlyChacters, validateEmail, validateOnlyNumber, validatePhone } from '../../utils/validators';
import FormScreen1 from '../DeclarationForms/FormScreen1';
import Pagination from '../../components/Pagination';
import { inputPhoneMasking, unMasking } from '../../utils/phonemask';
import LogOutUser from '../../components/Modal/LogOutUser';

const UsersScreen = ({ showFilter = false, history }) => {
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.userLogin);
	const userRole = useSelector((state) => state.getUserInfo);

	const userList = useSelector((state) => state.userList);
	const trainerList = useSelector((state) => state.trainerList);

	const createUser = useSelector((state) => state.createUser);
	const deleteUser = useSelector((state) => state.deleteUser);
	const changeStatusUser = useSelector((state) => state.changeStatusUser);
	const changePasswordUser = useSelector((state) => state.changePasswordUser);
	const [modalLogOutUserState, setModalLogOutUserState] = useState(false);

	const [ showAddModal, setShowAddModal ] = useState(false);
	const [ modalDeleteState, setModalDeleteState ] = useState(false);
	const [ modalDoumentState, setModalDoumentState ] = useState(false);
	const logOutUser = useSelector((state) => state.logOutUser);

	const [ selectUpdateModelUser, setSelectUpdateModelUser ] = useState({});
	const [ search, setSearch ] = useState('');
	const [ _id, setUserId ] = useState('');

	const [ totalPageSize, setTotalPageSize ] = useState(1);
	const [ pageNumber, setPageNumber ] = useState(1);
	const pageLimit = 15;

	const [ name, setName ] = useState('');
	const [ nameError, setNameError ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ emailError, setEmailError ] = useState('');
	const [ phone, setPhone ] = useState('');
	const [ phoneError, setPhoneError ] = useState('');
	const [ gender, setGender ] = useState('');
	const [ genderError, setGenderError ] = useState('');
	const [ age, setAge ] = useState('');
	const [ ageError, setAgeError ] = useState('');
	const [ dob, setDOB ] = useState('');
	const [ dobError, setDOBError ] = useState('');
	const [ trainer, setTrainer ] = useState('');
	const [ trainerIdError, setTrainerError ] = useState('');

	useEffect(
		() => {
			if (userRole && userRole.userInfo && userRole.userInfo.data && userRole.userInfo.data.role === 'user') {
				dispatch(logout());
				history.push('/login');
			}
		},
		[ history, userRole, dispatch ]
	);

	useEffect(
		() => {
			dispatch(getUserListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
		},
		[ dispatch, pageNumber ]
	);

	useEffect(
		() => {
			if (userList && userList.userInfo && userList.userInfo.status && userList.userInfo.data.result.length > 0) {
				setTotalPageSize(userList.userInfo.data.totalPageSize);
			}
		},
		[ userList ]
	);

	useEffect(
		() => {
			if (userInfo && userInfo.data && userInfo.data.token) {
				dispatch({ type: TRAINER_RESET });
				dispatch(getTrainerListAction({ pageNumber: 1, pageSize: 1000, filter: '' }, true));
			} else dispatch(getTrainerListAction({ pageNumber: 1, pageSize: 1000, filter: '' }, true));
		},
		[ userInfo, dispatch ]
	);

	useEffect(
		() => {
			if (createUser && createUser.userInfo && createUser.userInfo.status) {
				clearData();
				setShowAddModal(false);
				setSearch('');
				toast.success(createUser.userInfo.message);
				dispatch({ type: CREATE_NEW_USER_RESET });
				dispatch(getUserListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (createUser && createUser.userInfo && !createUser.userInfo.status) {
				dispatch({ type: CREATE_NEW_USER_RESET });
				toast.error(createUser.userInfo.message);
			}
		},
		[ createUser, pageNumber, pageLimit, dispatch ]
	);

	useEffect(
		() => {
			if (deleteUser && deleteUser.userInfo && deleteUser.userInfo.status) {
				toast.success(deleteUser.userInfo.message);
				setSelectUpdateModelUser({});
				dispatch({ type: DELETE_USER_RESET });
				dispatch(getUserListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (deleteUser && deleteUser.userInfo && !deleteUser.userInfo.status) {
				toast.error(deleteUser.userInfo.message);
				dispatch({ type: DELETE_USER_RESET });
				dispatch(getUserListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		[ deleteUser, pageNumber, pageLimit, dispatch ]
	);

	useEffect(
		() => {
			if (changeStatusUser && changeStatusUser.userInfo && changeStatusUser.userInfo.status) {
				toast.success(changeStatusUser.userInfo.message);
				setSelectUpdateModelUser({});
				dispatch({ type: CHANGE_STATUS_USER_RESET });
				dispatch(getUserListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (changeStatusUser && changeStatusUser.userInfo && !changeStatusUser.userInfo.status) {
				toast.error(changeStatusUser.userInfo.message);
				dispatch({ type: CHANGE_STATUS_USER_RESET });
				dispatch(getUserListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		[ changeStatusUser, pageNumber, pageLimit, dispatch ]
	);

	useEffect(
		() => {
			if (changePasswordUser && changePasswordUser.userInfo && changePasswordUser.userInfo.status) {
				toast.success(changePasswordUser.userInfo.message);
				setSelectUpdateModelUser({});
				dispatch({ type: CHANGE_PASSWORD_USER_RESET });
			} else if (changePasswordUser && changePasswordUser.userInfo && !changePasswordUser.userInfo.status) {
				toast.error(changePasswordUser.userInfo.message);
				dispatch({ type: CHANGE_PASSWORD_USER_RESET });
			}
		},
		[ changePasswordUser, pageNumber, pageLimit, dispatch ]
	);

	useEffect(
		() => {
			if (logOutUser && logOutUser.userInfo && logOutUser.userInfo.status) {
				toast.success(logOutUser.userInfo.message);
				setSelectUpdateModelUser({});
				dispatch({ type: LOGOUT_USER_RESET });
			} else if (logOutUser && logOutUser.userInfo && !logOutUser.userInfo.status) {
				toast.error(logOutUser.userInfo.message);
				dispatch({ type: LOGOUT_USER_RESET });
			}
		},
		// eslint-disable-next-line
		[logOutUser, dispatch]
	);


	const handleAddModalClose = () => {
		clearData();
		setShowAddModal(false);
	};

	const clearData = () => {
		setSelectUpdateModelUser({});
		setEmail('');
		setPhone('');
		setTrainer('');
		setName('');
		setTrainer('');
		setEmailError('');
		setPhoneError('');
		setTrainerError('');
		setNameError('');
		setAge('');
		setAgeError('');
		setDOB('');
		setDOBError('');
		setGender('');
		setGenderError('');
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (name === '' && name.trim() === '') {
			setNameError('Please enter name');
		} else if (!validateOnlyChacters(name)) {
			setNameError('Name should have only alphabets');
		} else if (email === '' && email.trim() === '') {
			setEmailError('Please enter email address');
		} else if (!validateEmail(email)) {
			setEmailError('Please enter valid email');
		} else if (phone === '' && phone.trim() === '') {
			setPhoneError('Please enter phone');
		} else if (!validatePhone(unMasking(phone))) {
			setPhoneError('Phone number must be 10 digits');
		} else if (gender === '' && gender.trim() === '') {
			setGenderError('Please select gender.');
		} else if (dob === '' && dob.trim() === '') {
			setDOBError('Please enter date of birth');
		} else if (age === '' && age.trim() === '') {
			setDOBError('Please enter age.');
		} else if (age < 15) {
			setDOBError('Age must be greater than 15.');
		} else if (trainer === '' && trainer.trim() === '') {
			setTrainerError('Please select trainer');
		} else {
			if (_id !== '') {
				dispatch(createNewUserAction({ _id, name, email, gender, age, dob, phone: unMasking(phone), trainer }));
			} else {
				dispatch(createNewUserAction({ name, email, gender, age, dob, phone: unMasking(phone), trainer }));
			}
		}
	};

	const onDeleteModalClose = () => {
		setModalDeleteState(false);
	};

	const onDeleteHandler = () => {
		dispatch(deleteUserAction(selectUpdateModelUser._id));
		onDeleteModalClose();
	};

	const onFormHandler = () => {
		setModalDoumentState(false);
	};

	const onFormModalClose = () => {
		setModalDoumentState(false);
		setSelectUpdateModelUser({});
	};

	// const onChangePwdUserHandler = () => {
	// 	let d = selectUpdateModelUser;
	// 	let type = 'byAdmin';
	// 	dispatch(changePasswordUserAction({ 'id': d._id, type, 'email': d.email }));
	// 	onChangePwdModalClose();
	// };

	const handlePageChange = async (currentPage) => {
		const selectedPage = currentPage.selected;
		dispatch(getUserListAction({ pageNumber: selectedPage + 1, pageSize: pageLimit, filter: '' }));
		setPageNumber(selectedPage + 1);
	};

	const handleEditModalUser = (d) => {
		setUserId(d._id);
		setEmail(d.email);
		setPhone(inputPhoneMasking(d.phone));
		setName(d.name);
		if(d.trainer && d.trainer._id){
			setTrainer(d.trainer._id); 
		}
		setSelectUpdateModelUser(d);
		setGender(d.gender);
		setAge(d.age);
		setDOB(d.dob);
		setShowAddModal(true);
	};

	const onChangeHandler = (d) => {
		dispatch(
			activeUserAction(d._id, {
				enable: d.active ? true : false
			})
		);
	};

	const onSearchHandler = (event) => {
		setSearch(event.target.value);
		if (search.trim !== '' && search.length > 0) {
			setPageNumber(1);
		}
		dispatch(getUserListAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: event.target.value }));
	};

	const onLogOutUserClose = () => {
		setModalLogOutUserState(false);
	};

	const onLogOutUserHandler = () => {
		let d = selectUpdateModelUser;
		let type = 'byAdmin';
		dispatch(logOutUserAction({ id: d._id, type, email: d.email }));
		onLogOutUserClose();
	};


	return (
		<Content
			currentMenu='users'
			headerTitle='Users'
			addBtn={
				userRole && userRole.userInfo && userRole.userInfo.data && userRole.userInfo.data.role === 'trainer' ? (
					true
				) : (
					false
				)
			}
			addBtnText='Add New User'
			addBtnIcon='plus'
			addBtnClick={() => setShowAddModal(true)}
			search={true}
			searchPlaceholder='Search Users...'
			searchIcon='search'
			searchvalue={search}
			searchOnChange={onSearchHandler}>
			<UsersData
				userList={userList}
				showFilter={showFilter}
				setModalDeleteState={setModalDeleteState}
				setSelectUpdateModelUser={setSelectUpdateModelUser}
				handleEditModalUser={handleEditModalUser}
				onChangeHandler={onChangeHandler}
				setModalDoumentState={setModalDoumentState}
				setModalLogOutUserState={setModalLogOutUserState}
			/>

			{totalPageSize > 1 && (
				<div className='tableContainer--paginater'>
					<p className='totalCountData'>
						Total:<span>{`${totalPageSize}`} </span>
					</p>
					<Pagination
						list={userList.userInfo}
						onPageChange={handlePageChange}
						rowsPerPage={pageLimit}
						totalPageSize={totalPageSize}
						pageNumber={pageNumber}
					/>
				</div>
			)}

			<CreateUser
				data={{
					showAddModal,
					handleAddModalClose,
					handleSubmit,
					name,
					email,
					phone,
					setName,
					setEmail,
					setPhone,
					nameError,
					phoneError,
					emailError,
					setNameError,
					setPhoneError,
					setEmailError,
					gender,
					setGender,
					setGenderError,
					genderError,
					dob,
					dobError,
					setDOB,
					setDOBError,
					age,
					setAge,
					setAgeError,
					ageError,
					trainer,
					setTrainer,
					setTrainerError,
					trainerIdError,
					trainerList,
					selectUpdateModelUser,
					setSelectUpdateModelUser
				}}
			/>
			<DeleteUser data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }} />
			<LogOutUser data={{ modalLogOutUserState, onLogOutUserClose, onLogOutUserHandler }} />

			<FormScreen1 data={{ selectUpdateModelUser, modalDoumentState, onFormModalClose, onFormHandler }} />
		</Content>
	);
};

export default UsersScreen;
