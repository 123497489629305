export const GET_ALL_ROOM_REQUEST = 'GET_ALL_ROOM_REQUEST';
export const GET_ALL_ROOM_SUCCESS = 'GET_ALL_ROOM_SUCCESS';
export const GET_ALL_ROOM_FAIL = 'GET_ALL_ROOM_FAIL';
export const GET_ALL_ROOM_RESET = 'GET_ALL_ROOM_RESET';

export const ADD_ROOM_REQUEST = 'ADD_ROOM_REQUEST';
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export const ADD_ROOM_FAIL = 'ADD_ROOM_FAIL';
export const ADD_ROOM_RESET = 'ADD_ROOM_RESET';

export const ADD_WORKOUT_REQUEST = 'ADD_WORKOUT_REQUEST';
export const ADD_WORKOUT_SUCCESS = 'ADD_WORKOUT_SUCCESS';
export const ADD_WORKOUT_FAIL = 'ADD_WORKOUT_FAIL';
export const ADD_WORKOUT_RESET = 'ADD_WORKOUT_RESET';

export const GET_WORKOUT_REQUEST = 'GET_WORKOUT_REQUEST';
export const GET_WORKOUT_SUCCESS = 'GET_WORKOUT_SUCCESS';
export const GET_WORKOUT_FAIL = 'GET_WORKOUT_FAIL';
export const GET_WORKOUT_RESET = 'ADD_WORKOUT_RESET';

export const CHANGE_ROOM_REQUEST = 'CHANGE_ROOM_REQUEST';
export const CHANGE_ROOM_SUCCESS = 'CHANGE_ROOM_SUCCESS';
export const CHANGE_ROOM_FAIL = 'CHANGE_ROOM_FAIL';
export const CHANGE_ROOM_RESET = 'CHANGE_ROOM_RESET';

export const DELETE_ROOM_REQUEST = 'DELETE_ROOM_REQUEST';
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export const DELETE_ROOM_FAIL = 'DELETE_ROOM_FAIL';
export const DELETE_ROOM_RESET = 'DELETE_ROOM_RESET';

export const DELETE_WORKOUT_REQUEST = 'DELETE_WORKOUT_REQUEST';
export const DELETE_WORKOUT_SUCCESS = 'DELETE_WORKOUT_SUCCESS';
export const DELETE_WORKOUT_FAIL = 'DELETE_WORKOUT_FAIL';
export const DELETE_WORKOUT_RESET = 'DELETE_WORKOUT_RESET';

export const CHANGE_WORKOUT_REQUEST = 'CHANGE_WORKOUT_REQUEST';
export const CHANGE_WORKOUT_SUCCESS = 'CHANGE_WORKOUT_SUCCESS';
export const CHANGE_WORKOUT_FAIL = 'CHANGE_WORKOUT_FAIL';
export const CHANGE_WORKOUT_RESET = 'CHANGE_WORKOUT_RESET';

export const GET_ALL_TEMPLATE_REQUEST = 'GET_ALL_TEMPLATE_REQUEST';
export const GET_ALL_TEMPLATE_SUCCESS = 'GET_ALL_TEMPLATE_SUCCESS';
export const GET_ALL_TEMPLATE_FAIL = 'GET_ALL_TEMPLATE_FAIL';
export const GET_ALL_TEMPLATE_RESET = 'GET_ALL_TEMPLATE_RESET';

export const ADD_TEMPLATE_REQUEST = 'ADD_TEMPLATE_REQUEST';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_TEMPLATE_FAIL = 'ADD_TEMPLATE_FAIL';
export const ADD_TEMPLATE_RESET = 'ADD_TEMPLATE_RESET';

export const CHANGE_TEMPLATE_STATUS_REQUEST = 'CHANGE_TEMPLATE_STATUS_REQUEST';
export const CHANGE_TEMPLATE_STATUS_SUCCESS = 'CHANGE_TEMPLATE_STATUS_SUCCESS';
export const CHANGE_TEMPLATE_STATUS_FAIL = 'CHANGE_TEMPLATE_STATUS_FAIL';
export const CHANGE_TEMPLATE_STATUS_RESET = 'CHANGE_TEMPLATE_STATUS_RESET';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAIL = 'DELETE_TEMPLATE_FAIL';
export const DELETE_TEMPLATE_RESET = 'DELETE_TEMPLATE_RESET';

export const GET_ALL_ENABLE_TEMPLATE_REQUEST = 'GET_ALL_ENABLE_TEMPLATE_REQUEST';
export const GET_ALL_ENABLE_TEMPLATE_SUCCESS = 'GET_ALL_ENABLE_TEMPLATE_SUCCESS';
export const GET_ALL_ENABLE_TEMPLATE_FAIL = 'GET_ALL_ENABLE_TEMPLATE_FAIL';
export const GET_ALL_ENABLE_TEMPLATE_RESET = 'GET_ALL_ENABLE_TEMPLATE_RESET';


export const ADD_TEMPLATE_EXERCISE_REQUEST = 'ADD_TEMPLATE_EXERCISE_REQUEST';
export const ADD_TEMPLATE_EXERCISE_SUCCESS = 'ADD_TEMPLATE_EXERCISE_SUCCESS';
export const ADD_TEMPLATE_EXERCISE_FAIL = 'ADD_TEMPLATE_EXERCISE_FAIL';
export const ADD_TEMPLATE_EXERCISE_RESET = 'ADD_TEMPLATE_EXERCISE_RESET';


export const ASSIGN_TEMPLATE_EXERCISE_REQUEST = 'ASSIGN_TEMPLATE_EXERCISE_REQUEST';
export const ASSIGN_TEMPLATE_EXERCISE_SUCCESS = 'ASSIGN_TEMPLATE_EXERCISE_SUCCESS';
export const ASSIGN_TEMPLATE_EXERCISE_FAIL = 'ASSIGN_TEMPLATE_EXERCISE_FAIL';
export const ASSIGN_TEMPLATE_EXERCISE_RESET = 'ASSIGN_TEMPLATE_EXERCISE_RESET';


export const GET_TEMPLATE_EXERCISE_MUSCLETYPE_REQUEST = 'GET_TEMPLATE_EXERCISE_MUSCLETYPE_REQUEST';
export const GET_TEMPLATE_EXERCISE_MUSCLETYPE_SUCCESS = 'GET_TEMPLATE_EXERCISE_MUSCLETYPE_SUCCESS';
export const GET_TEMPLATE_EXERCISE_MUSCLETYPE_FAIL = 'GET_TEMPLATE_EXERCISE_MUSCLETYPE_FAIL';
export const GET_TEMPLATE_EXERCISE_MUSCLETYPE_RESET = 'GET_TEMPLATE_EXERCISE_MUSCLETYPE_RESET';

export const GET_ASSIGN_TEMPLATE_BY_USER_REQUEST = 'GET_ASSIGN_TEMPLATE_BY_USER_REQUEST';
export const GET_ASSIGN_TEMPLATE_BY_USER_SUCCESS = 'GET_ASSIGN_TEMPLATE_BY_USER_SUCCESS';
export const GET_ASSIGN_TEMPLATE_BY_USER_FAIL = 'GET_ASSIGN_TEMPLATE_BY_USER_FAIL';
export const GET_ASSIGN_TEMPLATE_BY_USER_RESET = 'GET_ASSIGN_TEMPLATE_BY_USER_RESET';



export const GET_TEMPLATE_MUSCLETYPE_REQUEST = 'GET_TEMPLATE_MUSCLETYPE_REQUEST';
export const GET_TEMPLATE_MUSCLETYPE_SUCCESS = 'GET_TEMPLATE_MUSCLETYPE_SUCCESS';
export const GET_TEMPLATE_MUSCLETYPE_FAIL = 'GET_TEMPLATE_MUSCLETYPE_FAIL';
export const GET_TEMPLATE_MUSCLETYPE_RESET = 'GET_TEMPLATE_MUSCLETYPE_RESET';


export const DELETE_EXERCISE_TEMPLATE_REQUEST = 'DELETE_EXERCISE_TEMPLATE_REQUEST';
export const DELETE_EXERCISE_TEMPLATE_SUCCESS = 'DELETE_EXERCISE_TEMPLATE_SUCCESS';
export const DELETE_EXERCISE_TEMPLATE_FAIL = 'DELETE_EXERCISE_TEMPLATE_FAIL';
export const DELETE_EXERCISE_TEMPLATE_RESET = 'DELETE_EXERCISE_TEMPLATE_RESET';



export const GET_ALL_NUTRITIONS_TEMPLATE_REQUEST = 'GET_ALL_NUTRITIONS_TEMPLATE_REQUEST';
export const GET_ALL_NUTRITIONS_TEMPLATE_SUCCESS = 'GET_ALL_NUTRITIONS_TEMPLATE_SUCCESS';
export const GET_ALL_NUTRITIONS_TEMPLATE_FAIL = 'GET_ALL_NUTRITIONS_TEMPLATE_FAIL';
export const GET_ALL_NUTRITIONS_TEMPLATE_RESET = 'GET_ALL_NUTRITIONS_TEMPLATE_RESET';

export const ADD_NUTRITIONS_TEMPLATE_REQUEST = 'ADD_NUTRITIONS_TEMPLATE_REQUEST';
export const ADD_NUTRITIONS_TEMPLATE_SUCCESS = 'ADD_NUTRITIONS_TEMPLATE_SUCCESS';
export const ADD_NUTRITIONS_TEMPLATE_FAIL = 'ADD_NUTRITIONS_TEMPLATE_FAIL';
export const ADD_NUTRITIONS_TEMPLATE_RESET = 'ADD_NUTRITIONS_TEMPLATE_RESET';

export const CHANGE_NUTRITIONS_TEMPLATE_STATUS_REQUEST = 'CHANGE_NUTRITIONS_TEMPLATE_STATUS_REQUEST';
export const CHANGE_NUTRITIONS_TEMPLATE_STATUS_SUCCESS = 'CHANGE_NUTRITIONS_TEMPLATE_STATUS_SUCCESS';
export const CHANGE_NUTRITIONS_TEMPLATE_STATUS_FAIL = 'CHANGE_NUTRITIONS_TEMPLATE_STATUS_FAIL';
export const CHANGE_NUTRITIONS_TEMPLATE_STATUS_RESET = 'CHANGE_NUTRITIONS_TEMPLATE_STATUS_RESET';

export const DELETE_NUTRITIONS_TEMPLATE_REQUEST = 'DELETE_NUTRITIONS_TEMPLATE_REQUEST';
export const DELETE_NUTRITIONS_TEMPLATE_SUCCESS = 'DELETE_NUTRITIONS_TEMPLATE_SUCCESS';
export const DELETE_NUTRITIONS_TEMPLATE_FAIL = 'DELETE_NUTRITIONS_TEMPLATE_FAIL';
export const DELETE_NUTRITIONS_TEMPLATE_RESET = 'DELETE_NUTRITIONS_TEMPLATE_RESET';

export const GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_REQUEST = 'GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_REQUEST';
export const GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_SUCCESS = 'GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_SUCCESS';
export const GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_FAIL = 'GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_FAIL';
export const GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_RESET = 'GET_ALL_ENABLE_NUTRITIONS_TEMPLATE_RESET';
