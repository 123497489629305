import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/formInputs/InputBox';
import ModalButton from '../../components/Modal/ModalButton';

const AddNewTemplateModal = ({ addModalActive, handleAddModalClose, handleSubmit, data }) => {
	const { templateId, templateName, setTemplateName, templateColor, setTemplateColor } = data;
	return (
		<Modal show={addModalActive}>
			<ModalHeading heading={templateId ? 'Update Room' : 'Add New Room'} onClose={handleAddModalClose} />

			<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
				<InputsSection>
					<InputBox
						style={{height: '5rem'}}
						label='Room Name'
						icon='gym'
						placeholder='Enter Room name'
						value={templateName.value}
						onChange={(e) => setTemplateName({ value: e.target.value, error: '' })}
						errorMessage={templateName.error}
					/>
					<InputBox
						style={{height: '5rem'}}
						type="color"
						label='Color'
						icon='color'
						placeholder='eg, #019767'
						value={templateColor.value}
						onChange={(e) => setTemplateColor({ value: e.target.value, error: '' })}
						errorMessage={templateColor.error}
					/>
				</InputsSection>
				<ModalButton label={templateId ? 'Update Room' : 'Add Room'} icon={templateId ? 'edit' : 'plus'} onClick={handleSubmit} />
			</ModalForm>
		</Modal>
	);
};

export default AddNewTemplateModal;
