import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import { createTemplateAction, deleteTemplateAction, getAllTemplateAction, TemplateStatusAction } from '../../redux/actions/templateActions';
import {
	ADD_ROOM_RESET,
	ADD_TEMPLATE_RESET,
	CHANGE_ROOM_RESET,
	CHANGE_TEMPLATE_STATUS_RESET,
	DELETE_ROOM_RESET,
	DELETE_TEMPLATE_RESET,
	GET_ALL_TEMPLATE_RESET
} from '../../redux/constants/templateConstants';
import AddNewTemplateModal from './AddNewTemplateModal';
import TemplateData from './TemplateData';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import TemplateStyles from './WorkoutTemplateScreen.module.css';
import DeleteTemplate from './DeleteTemplate';
import Spinner from '../../components/Spinner/Spinner';
// import Pagination from '../../components/Pagination';
import NoData from '../../components/NoData';
import { createRoom as createRoomAction, deleteRoomAction, getAllRoom, updateRoom as updateRoomAction } from '../../redux/actions/workoutAction';

const WorkoutDetailScreen = () => {
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.userLogin);
	const userRole = useSelector((state) => state.getUserInfo);

	const userList = useSelector((state) => state.userList);
	const templatesData = useSelector((state) => state.getAllTemplate);
	const createTemplate = useSelector((state) => state.createTemplate);
	const deleteTemplate = useSelector((state) => state.deleteTemplate);
	const templateStatus = useSelector((state) => state.templateStatus);

	const roomList = useSelector((state) => state.getAllRooms);
	const createRoom = useSelector((state) => state.createRoom);
	const updateRoom = useSelector((state) => state.updateRoom);
	const deleteRoom = useSelector((state) => state.deleteRoom);

	const [templateName, setTemplateName] = useState({ value: '', error: '' });
	const [templateColor, setTemplateColor] = useState({ value: '', error: '' });
	const [addModalShow, setAddModalShow] = useState(false);
	const [modalDeleteState, setModalDeleteState] = useState(false);
	const [templateId, setTemplateId] = useState('');

	const [search, setSearch] = useState('');
	const [totalPageSize, setTotalPageSize] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const pageLimit = 15;

	useEffect(() => {
		dispatch(getAllRoom())
	}, [dispatch]);

	useEffect(
		() => {
			if (createRoom && createRoom.data && createRoom.data.status) {
				// clearData();
				setAddModalShow(false);
				toast.success('Room has been created successfully');
				dispatch({ type: ADD_ROOM_RESET });
				dispatch(getAllRoom());
			}
			else if (createRoom && createRoom.data && !createRoom.data.status) {
				dispatch({ type: ADD_ROOM_RESET });
				toast.error('Try Again ! Room has been not created successfully');
			}
		},
		[createRoom, dispatch]
	);

	useEffect(
		() => {
			if (updateRoom && updateRoom.data && updateRoom.data.status) {
				// clearData();
				setAddModalShow(false);
				toast.success('Room has been updated successfully');
				dispatch({ type: DELETE_ROOM_RESET });
				dispatch(getAllRoom());
			}
			else if (updateRoom && updateRoom.data && !updateRoom.data.status) {
				dispatch({ type: DELETE_ROOM_RESET });
				toast.error('Try Again ! Room has been not updated successfully');
			}
		},
		[updateRoom, dispatch]
	);

	useEffect(
		() => {
			if (deleteRoom && deleteRoom.data && deleteRoom.data.status) {
				setAddModalShow(false);
				toast.success('Room has been deleted successfully');
				dispatch({ type: CHANGE_ROOM_RESET });
				dispatch(getAllRoom());
			}
			else if (deleteRoom && deleteRoom.data && !deleteRoom.data.status) {
				dispatch({ type: CHANGE_ROOM_RESET });
				toast.error('Try Again ! Room has been not deleted successfully');
			}
		},
		[deleteRoom, dispatch]
	);


	const handleSubmit = (e) => {
		e.preventDefault();
		if (templateName.value === '' || templateName.value.trim() === '') {
			setTemplateName({ ...templateName, error: 'Please enter template name' });
			return;
		} else {
			if (templateId) {
				dispatch(updateRoomAction(
					templateId,
					{
						colorCode: templateColor.value,
						enable: true,
						roomName: templateName.value,
					}));
			} else {
				dispatch(createRoomAction({
					workOut: [],
					colorCode: templateColor.value,
					enable: true,
					roomName: templateName.value,
				}));
			}
		}
	};

	const handleAddModalClose = () => {
		clearData();
		setAddModalShow(false);
	};

	const clearData = () => {
		setTemplateId('');
		setTemplateName({ value: '', error: '' });
		setTemplateColor({ value: '', error: '' });
	};

	const onDeleteModalClose = () => {
		clearData();
		setModalDeleteState(false);
	};

	const onDeleteHandler = () => {
		dispatch(deleteRoomAction(templateId));
		onDeleteModalClose();
	};


	const onEditTemplateHandler = (data) => {
		setTemplateId(data._id);
		setTemplateName({ value: data.roomName, error: '' });
		setTemplateColor({ value: data.colorCode, error: '' });
		setAddModalShow(true);
	};
	return (
		<Content
			currentMenu='workout'
			headerTitle='Workout'
			addBtn={true}
			addBtnText='New Room'
			addBtnClick={() => setAddModalShow(true)}
			addBtnIcon='plus'>

			<div className={TemplateStyles.templates}>
				{roomList && roomList.loading ? (
					<Spinner />
				) :
					roomList.data &&
						roomList.data.length > 0 ? (
						roomList.data.map((data, index) =>
							<TemplateData key={index + 1} props={{ data, setModalDeleteState, onEditTemplateHandler, setTemplateId }} />
						)
					) : (
						<div className='not_data_found'>
							<NoData title='No Data Found!' subTitle='We could not find any template. please add one.' height='40vh' />
						</div>
					)}
			</div>

			<AddNewTemplateModal
				addModalActive={addModalShow}
				handleSubmit={handleSubmit}
				handleAddModalClose={handleAddModalClose}
				data={{ templateId, templateName, setTemplateName, templateColor, setTemplateColor }}
			/>

			<DeleteTemplate data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }} />

		</Content>
	);
};

export default WorkoutDetailScreen;
