import React, { Fragment } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Screens
import LoginScreen from '../screens/LoginScreen';
import HomeScreen from '../screens/HomeScreen';
import CheckinUserScreen from '../screens/CheckinUserScreen';
import UsersScreen from '../screens/UsersScreen';
import GymsScreen from '../screens/GymsScreen';
import TrainersScreen from '../screens/TrainersScreen';
import VideosScreen from '../screens/VideosScreen';
import VideoCategoriesScreen from '../screens/VideoCategoriesScreen';
import VideoDetailsScreen from '../screens/VideoDetailsScreen';
import ProductsScreen from '../screens/ProductsScreen';
import MyProfileScreen from '../screens/MyProfileScreen';
import ProductCategoriesScreen from '../screens/ProductsCategoriesScreen';
import ProductDetailsScreen from '../screens/ProductDetailsScreen';
import GymStaffScreen from '../screens/GymStaffScreen';
import CompanyScreen from '../screens/CompanyScreen';
import TrainerSessionScreen from '../screens/TrainerSessionScreen';
import OrderHistoryScreen from '../screens/OrderHistoryScreen';
import AppVersionScreen from '../screens/AppVersionScreen';
import ReportScreen from '../screens/ReportScreen';
import SendNotification from '../screens/SendNotification';
import TemplatesScreen from '../screens/TemplatesScreen';
import TemplateDetailsScreen from '../screens/TemplateDetailsScreen';
import AppSettingScreen from '../screens/AppSettingScreen';
import TemplateAssignUser from '../screens/TemplateAssignUser';
import NutritionScreen from '../screens/NutritionScreen';
import ResourcesScreen from '../screens/ResourcesScreen';
import ResourceBookingsScreen from '../screens/ResourceBookingsScreen'
import AdsScreen from '../screens/AdsScreen';
import AllTrainerSessions from '../screens/AllTraninerSessions';
import WorkoutTemplateScreen from '../screens/WorkoutTemplateScreen';
import WorkoutDetailScreen from '../screens/WorkoutDetailScreen';
import WorkoutDisplayScreen from '../screens/WorkoutDisplayScreen';

const Navigation = ({ userLogin }) => {
	// console.log(userLogin);
	const getUserInfo = useSelector((state) => state.getUserInfo);

	return (
		<Router>
			<Route exact path='/login' component={LoginScreen} />
			<Route exact path='/' component={ReportScreen} />
			<Route exact path='/check-in' component={CheckinUserScreen} />
			<Route exact path='/users' component={UsersScreen} />
			<Route exact path='/gyms' component={GymsScreen} />
			<Route exact path='/gym-role-manger' component={GymStaffScreen} />
			<Route exact path='/trainers' component={TrainersScreen} />
			<Route exact path='/trainer-sessions' component={TrainerSessionScreen} />
			<Route exact path='/all-trainer-sessions' component={AllTrainerSessions} /> 
			<Route exact path='/trainer-sessions/:trainerId/:trainerName' component={TrainerSessionScreen} />
			<Route exact path='/video-categories' component={VideoCategoriesScreen} />
			<Route exact path='/videos' component={VideosScreen} />
			<Route exact path='/videos/:id' component={VideoDetailsScreen} />
			<Route exact path='/product-categories' component={ProductCategoriesScreen} />
			<Route exact path='/products' component={ProductsScreen} />
			<Route exact path='/order-history' component={OrderHistoryScreen} />
			<Route exact path='/products/:id' component={ProductDetailsScreen} />
			<Route exact path='/my-profile' component={MyProfileScreen} />
			<Route exact path='/appversion' component={AppVersionScreen} />
			<Route exact path='/company' component={CompanyScreen} />
			<Route exact path='/notification' component={SendNotification} />
			<Route exact path='/resources' component={ResourcesScreen} />
			<Route exact path='/resource-bookings' component={ResourceBookingsScreen} />
			<Route exact path='/screen/:id' component={AdsScreen} /> 
			<Route exact path='/workout-templates' component={WorkoutTemplateScreen} />
			<Route exact path='/workout/:roomId/:roomName' component={WorkoutDetailScreen} />
			<Route exact path='/workout-display/:roomId/:roomName' component={WorkoutDisplayScreen} />

			{/* <Route exact path='/templates/:templateId/:templateName' component={TemplateDetailsScreen} />
			<Route exact path='/assign-template/:userId/:userName' component={TemplateAssignToUser} /> */}
			{getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				getUserInfo.userInfo.data.isSpecialApp && (
					<Fragment>
						<Route exact path='/app-settings' component={AppSettingScreen} />
						<Route exact path='/templates' component={TemplatesScreen} />
						<Route exact path='/nutrition-templates' component={NutritionScreen} />
						<Route exact path='/templates/:templateId/:templateName' component={TemplateDetailsScreen} />
						<Route exact path='/assign-template/:userId/:userName' component={TemplateAssignUser} />
					</Fragment>
				)}

			{getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				getUserInfo.userInfo.data.gym &&
				getUserInfo.userInfo.data.gym.isSpecialApp && (
					<Fragment>
						<Route exact path='/app-settings' component={AppSettingScreen} />
						<Route exact path='/templates' component={TemplatesScreen} />
						<Route exact path='/nutrition-templates' component={NutritionScreen} />
						<Route exact path='/templates/:templateId/:templateName' component={TemplateDetailsScreen} />
						<Route exact path='/assign-template/:userId/:userName' component={TemplateAssignUser} />
					</Fragment>
				)}

			{getUserInfo &&
				getUserInfo.userInfo &&
				getUserInfo.userInfo.data &&
				getUserInfo.userInfo.data.role === 'trainer' && (
					<Fragment>
						<Route exact path='/templates' component={TemplatesScreen} />
						<Route exact path='/templates/:templateId/:templateName' component={TemplateDetailsScreen} />
						<Route exact path='/assign-template/:userId/:userName' component={TemplateAssignUser} />
						<Route exact path='/nutrition-templates' component={NutritionScreen} />
					</Fragment>
				)}
		</Router>
	);
};

export default Navigation;
